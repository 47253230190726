import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {EventsWrapper} from '../pages/Events'
import {PostsWrapper} from '../pages/Posts'
import {CharityProfileWrapper} from '../pages/Profile'
import {DonationWrapper} from '../pages/Donations'
import {SubscriptionsWrapper} from '../pages/Subscriptions'
import ContactUs from '../pages/contact-us'
import AdminContactUs from '../pages/AdminContact'
import { RoundupWrapper } from '../pages/Roundup'

const CharityRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='posts' element={<PostsWrapper />} />
        <Route path='events' element={<EventsWrapper />} />
        <Route path='profile' element={<CharityProfileWrapper />} />
        <Route path='donations-management' element={<DonationWrapper />} />
        <Route path='subscriptions' element={<SubscriptionsWrapper />} />
        <Route path='roundup' element={<RoundupWrapper />} />
        <Route path='contact' element={<ContactUs />} />
        <Route path='contacts-us' element={<AdminContactUs />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {CharityRoutes}

import React, {useCallback, useEffect, useState} from 'react'
import {useFormik, FormikProvider} from 'formik'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {Category} from '../../../components/Common'
import {useAuth} from '../../../app/modules/auth'
import {LoadScript, Autocomplete} from '@react-google-maps/api'
import {
  ROLE_TYPE,
  charityUserProfile,
  updateCharityProfile,
} from '../../../app/modules/auth/core/_requests'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import 'react-phone-input-2/lib/bootstrap.css'
import {CustomModal, MODAL_TYPE, MODAL_TYPE_ENUM} from '../../../components/Modal'

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || ''

const initialValues = {
  profilePic: '',
  name: '',
  email: '',
  phone: '',
  charityCategory: [],
  charityLocation: {
    coordinates: {
      lat: '',
      long: '',
    },
    address: {
      lane: '',
      state: '',
      city: '',
      country: '',
      pinCode: '',
    },
  },
  description: '',
  website: '',
  facebook: '',
  instagram: '',
  twitter: '',
}

export interface CharityProfileDetailsType {
  profilePic: any
  name: string
  email: string
  description: string
  phone: string
  charityCategory: string[]
  charityLocation: {
    coordinates: {
      lat: string
      long: string
    }
    address: {
      lane: string
      state: string
      city: string
      country: string
      pinCode: string
    } | null
  }
  website: string
  facebook: string
  instagram: string
  twitter: string
}

const websiteUrlReg = /^(ftp|http|https):\/\/[^ "]+$/
const facebookUrlReg = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9_.-]+\/?$/

const profileDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Title is required'),
  email: Yup.string().email(),
  phone: Yup.number()
    .positive()
    .integer()
    .required('Phone number is required')
    .typeError('Phone number must be a number')
    .test('len', 'Phone number must be exactly 10 digits', (value) => String(value).length === 10),
  website: Yup.string().matches(websiteUrlReg, 'Enter a valid URL'),

  // socialMedia: Yup.object().shape({
  facebook: Yup.string().matches(facebookUrlReg, 'Enter a valid Facebook profile URL'),
  instagram: Yup.string().matches(
    /^(https?:\/\/)?(www\.)?instagram\.com\/(?:[^\/?]+\/)?(?:@)?([a-zA-Z0-9_.-]+)(?:\/?|\/(?:(?:p|tv|reel)\/[^\/?]+\/?)?)$/,
    'Enter a valid Instagram profile URL'
  ),
  twitter: Yup.string().matches(
    /^(https?:\/\/)?(www\.)?twitter\.com\/[a-zA-Z0-9_]+\/?$/,
    'Enter a valid Twitter profile URL'
  ),
  // }),
  address: Yup.object()
    .shape({
      lane: Yup.string().notRequired(),
      state: Yup.string().notRequired(),
      city: Yup.string().notRequired(),
      country: Yup.string().notRequired(),
    })
    .notRequired(),
  description: Yup.string().notRequired(),
})

interface State {
  modalView: boolean
  selectedRow: any
  type: MODAL_TYPE
  charityDetails?: CharityProfileDetailsType | null
  donorDetails?: any | null
  loading?: boolean
  stripeConnectView?: boolean
  accountStatusView?: boolean
  accountRejectedView?: boolean
  profileModelView?: boolean
  deleteLoading?: {
    postsOrEvents?: boolean
    accounts?: boolean
  }
  setDefaultLoading?: 'process' | 'complete' | 'failed' | null
}

export const CharityProfileDetails = () => {
  const {auth, currentUser, logout} = useAuth()
  let file
  const [image, setImage] = useState('')
  const [loading, setLoading] = useState(false)
  const [emailCheck, setEmailCheck] = useState('')
  const [showFullBio, setShowFullBio] = useState(false)
  const wordLimit = 150
  const handleBioChange = (event) => {
    const wordCount = event.target.value.split(/\s+/).filter(Boolean).length
    setShowFullBio(wordCount <= wordLimit)
    formik.handleChange(event)
  }
  const [title, setTitle] = useState('')
  const [autoComplete, setAutoComplete] = useState<any>(null)

  const [charityLocations, setCharityLocation] = useState({
    coordinates: {
      lat: '',
      long: '',
    },
    address: {
      lane: '',
      city: '',
      state: '',
      pinCode: '',
      country: '',
    },
    place_id: '',
  })
  const [inputLocation, setInputLocation] = useState('')
  const [addressError, setAddressError] = useState('')

  const [state, setState] = useState<State>({
    modalView: false,
    selectedRow: null,
    type: null,
    charityDetails: null,
    loading: true,
    deleteLoading: {
      postsOrEvents: false,
      accounts: false,
    },
    stripeConnectView: false,
    accountStatusView: false,
    accountRejectedView: false,
    profileModelView: false,
    setDefaultLoading: null,
  })

  const getProfileDetails = useCallback(
    async (token, role) => {
      try {
        setState({...state, loading: true})
        const result = await charityUserProfile(currentUser?.token, 'charity')
        if (result.status === 200) {
          setState({
            ...state,
            charityDetails: result.data.data,
            loading: false,
          })
          logout()
        } else if (result.status === 222) {
          setState({
            ...state,
            charityDetails: result.data.data,
            profileModelView: true,
            loading: false,
          })
        } else if (result.status === 233) {
          setState({
            ...state,
            charityDetails: result.data.data,
            stripeConnectView: true,
            loading: false,
          })
        } else if (result.status === 244) {
          setState({
            ...state,
            charityDetails: result.data.data,
            accountStatusView: true,
            loading: false,
          })
        } else if (result.status === 255) {
          setState({
            ...state,
            charityDetails: result.data.data,
            accountRejectedView: true,
            loading: false,
          })
          setTitle(result.data.data.adminComment)
        }
      } catch (error: any) {
        toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
        setState({...state, loading: false})
      }
    },
    [state]
  )

  useEffect(() => {
    if (auth && auth.token) {
      getProfileDetails(auth.token, ROLE_TYPE.CHARITY)
    }
  }, [])

  const formik = useFormik<CharityProfileDetailsType>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      try {
        if (!emailCheck) {
          const formData = new FormData()
          formData.append('name', values.name || '')
          formData.append('email', values.email || '')
          formData.append('phone', values.phone || '')
          formData.append('description', values.description || '')
          formData.append('website', values.website || '')
          formData.append('instagram', values.instagram || '')
          formData.append('facebook', values.facebook || '')
          formData.append('twitter', values.twitter || '')
          formData.append('charityLocation', JSON.stringify(charityLocations) || '')
          formData.append('profilePic', image && file ? file : values.profilePic)
          formData.append('charityCategory', values.charityCategory?.toString() || '')
          formData.append('platform', 'webApp')

          setLoading(true)

          const result = await updateCharityProfile('charity', auth?.token, formData)
          if (
            result.status === 233 ||
            result.status === 244 ||
            result.status === 200 ||
            result.status === 255
          ) {
            toast.success(result.data.message || 'Profile updated successfully')
            if (currentUser?.role === 'charity') {
              window.location.reload()
            }
            setLoading(false)
          } else {
            toast(result?.data?.data?.message || 'Something went wrong!')
            setLoading(false)
          }
        }
      } catch (error: any) {
        setLoading(false)
        toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
      }
    },
  })

  useEffect(() => {
    if (state.charityDetails) {
      const {
        profilePic,
        name,
        email,
        phone,
        charityCategory,
        charityLocation,
        description,
        website,
        facebook,
        instagram,
        twitter,
      } = state.charityDetails

      formik.setFieldValue('profilePic', profilePic || '')
      formik.setFieldValue('name', name || '')
      formik.setFieldValue('email', email || '')
      formik.setFieldValue('phone', phone || '')
      formik.setFieldValue('charityCategory', charityCategory || [])

      if (charityLocation) {
        setCharityLocation((prevCharityLocation) => {
          const {_id, ...updatedState} = {
            ...prevCharityLocation,
            ...charityLocation,
            coordinates: {
              lat: charityLocation.coordinates.lat.toString(),
              long: charityLocation.coordinates.long.toString(),
            },
            address: charityLocation.address ? {...charityLocation.address} : null,
          } as any

          return updatedState
        })
      }

      if (charityLocation.address) {
        const {lane, city, state, pinCode, country} = charityLocation.address
        setInputLocation(`${lane}, ${city}, ${state} - ${pinCode}, ${country}`)
      }

      formik.setFieldValue('description', description || '')
      formik.setFieldValue('website', website || '')

      formik.setFieldValue('facebook', facebook || '')
      formik.setFieldValue('instagram', instagram || '')
      formik.setFieldValue('twitter', twitter || '')
    }
  }, [state.charityDetails])

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const place = autoComplete?.getPlace()
      if (place?.address_components && place?.geometry) {
        const {address, lane} = getAddressComponents(place)
        const {city, state, postal_code, country} = address

        setCharityLocation({
          coordinates: {
            lat: place?.geometry.location.lat(),
            long: place?.geometry.location.lng(),
          },
          address: {
            lane,
            city,
            state,
            pinCode: postal_code,
            country,
          },
          place_id: place.place_id,
        })
        if (
          !lane ||
          !city ||
          !state ||
          !country ||
          !postal_code ||
          !place.place_id ||
          !place?.geometry.location.lat() ||
          !place?.geometry.location.lng()
        ) {
          setAddressError('Please enter complete address')
        } else {
          setAddressError('')
        }
        setInputLocation(`${lane}, ${city}, ${state} - ${postal_code}, ${country}`)
      }
    }
  }

  const getAddressComponents = (place) => {
    const address: any = {}
    var lane = place.formatted_address.split(',', 2).join(',')
    for (const component of place.address_components) {
      const componentType = component.types[0]
      switch (componentType) {
        case 'locality':
          address.city = component.long_name
          break

        case 'administrative_area_level_1':
          address.state = component.long_name
          break

        case 'postal_code':
          address.postal_code = component.long_name
          break

        case 'lane':
          address.lane = place.formatted_address.split(',', 2).join(',')
          break

        case 'country':
          address.country = component.long_name
          break

        default:
          break
      }
    }
    return {address, lane}
  }

  // const joinedAddress = charityLocations.address
  //   ? `${charityLocations.address.lane}, ${charityLocations.address.city}, ${charityLocations.address.state} - ${charityLocations.address.pinCode}, ${charityLocations.address.country}`
  //   : ''

  const addressParts = [
    charityLocations.address?.lane,
    charityLocations.address?.city,
    charityLocations.address?.state,
    charityLocations.address?.pinCode,
    charityLocations.address?.country,
  ]

  const joinedAddress = addressParts.filter(Boolean).join(', ')

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6  '>Avatar</label>
          <div className='col-lg-8'>
            <div
              className='image-input image-input-outline'
              data-kt-image-input='true'
              style={{
                backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
              }}
            >
              <label
                htmlFor='profile_img'
                style={{
                  backgroundImage:
                    state.charityDetails?.profilePic && image === ''
                      ? `url(${state.charityDetails?.profilePic})`
                      : image
                      ? `url(${image})`
                      : `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                }}
                className={`input-preview-after input-preview`}
              ></label>

              <>
                <label
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='change'
                  htmlFor='profileImage'
                >
                  <i className='ki-duotone ki-pencil fs-7'>
                    <span className='path1'></span>
                    <span className='path2'></span>
                  </i>

                  <input
                    type='file'
                    id='profileImage'
                    name='avatar'
                    accept='.png, .jpg, .jpeg, .webp'
                    className='input-preview__src w-125px h-125px'
                    onChange={(event: any) => {
                      if (event.target.files && event.target.files?.length > 0) {
                        file = event.target.files[0]
                        setImage(URL.createObjectURL(file))
                        formik.setFieldValue('profilePic', file)
                      }
                    }}
                  />
                </label>
                {(formik.values.profilePic || formik.values.profilePic) && (
                  <span
                    className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                    data-kt-image-input-action='remove'
                    onClick={() => {
                      formik.setFieldValue('profilePic', undefined)
                      setImage('')
                      setState((prev: any) => ({
                        ...prev,
                        charityDetails: {
                          ...prev.charityDetails,
                          profilePic: undefined,
                        },
                      }))
                    }}
                  >
                    <i className='ki-duotone ki-cross fs-2'>
                      <span className='path1'></span>
                      <span className='path2'></span>
                    </i>
                  </span>
                )}
              </>
            </div>
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Title</label>
          <div className='col-lg-8'>
            <input
              type='text'
              autoComplete='off'
              className='form-control form-control-lg form-control mb-3 mb-lg-0'
              placeholder='Charity Title'
              {...formik.getFieldProps('name')}
              maxLength={50}
              disabled={false}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.name}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Email</label>
          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              className='form-control form-control-lg form-control mb-3 mb-lg-0'
              placeholder='Email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue('email', e.target.value)}
              disabled={true}
            />
            {(formik.touched.email && formik.errors.email) ||
              (emailCheck && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.email || emailCheck}</div>
                </div>
              ))}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6 required'>Phone Number</label>
          <div className='col-lg-8 fv-row'>
            <div className='input-group'>
              <span className='input-group-text' style={{fontWeight: 'bold'}}>
                +1
              </span>
              <input
                type='tel'
                autoComplete='off'
                className='form-control form-control-lg mb-3 mb-lg-0'
                placeholder='Phone number'
                {...formik.getFieldProps('phone')}
                maxLength={10}
                pattern='[0-9]{10}'
              />
            </div>
            {formik.touched.phone && formik.errors.phone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.phone}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>Charity Bio (Optional)</label>
          <div className='col-lg-8 fv-row'>
            <div
              style={{
                whiteSpace: showFullBio ? 'normal' : 'nowrap',
                overflow: showFullBio ? 'visible' : 'hidden',
                maxHeight: showFullBio ? 'none' : '3em',
              }}
            ></div>
            <textarea
              autoComplete='off'
              className='form-control form-control-lg form-control mb-3 mb-lg-0'
              placeholder='Charity description (Optional)'
              {...formik.getFieldProps('description')}
              onChange={handleBioChange}
              disabled={false}
              style={{
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all',
              }}
              maxLength={3000}
            />
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.description}</div>
              </div>
            )}
          </div>
        </div>

        <div className='bg-overrides'>
          <Category
            onChange={(values) => {
              formik.setFieldValue(
                'charityCategory',
                values?.map(({label}) => label)
              )
            }}
            isView={false}
            value={formik.getFieldProps('charityCategory').value}
          />
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>Location</label>
          <div className='col-lg-8 fv-row'>
            <LoadScript googleMapsApiKey={API_KEY} libraries={['places']}>
              <Autocomplete
                onLoad={(autocomplete: any) => {
                  setAutoComplete(autocomplete)
                }}
                onPlaceChanged={onPlaceChanged}
              >
                <input
                  placeholder='Location'
                  className='form-control form-control-lg form-control mb-3 mb-lg-0'
                  type='text'
                  autoComplete='off'
                  onChange={() => {
                    setAddressError('')
                  }}
                  defaultValue={inputLocation}
                />
              </Autocomplete>
            </LoadScript>
            {addressError && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{addressError}</div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6 '>
            <span className=''>Website Link (Optional)</span>
          </label>

          <div className='col-lg-8 fv-row'>
            <input
              type='text'
              autoComplete='off'
              className='form-control form-control-lg form-control mb-3 mb-lg-0'
              placeholder='Charity website (Optional)'
              {...formik.getFieldProps('website')}
              disabled={false}
            />
            {formik.touched.website && formik.errors.website && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block' style={{color: 'red'}}>
                  {formik.errors.website}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>Social Media Links</label>
          <div className='col-lg-8 fv-row'>
            <div className='mt-2'>
              <label className='text-dark' htmlFor='facebook'>
                Facebook (Optional)
              </label>
              <input
                type='text'
                autoComplete='off'
                className='form-control form-control-lg form-control mb-3 mb-lg-0'
                id='facebook'
                placeholder='Facebook (Optional)'
                name='facebook'
                value={formik.values.facebook}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.facebook && formik.errors.facebook && (
                <div className='error' style={{color: 'red'}}>
                  {formik.errors.facebook}
                </div>
              )}
            </div>

            <div className='mt-2'>
              <label className='text-dark' htmlFor='instagram'>
                Instagram (Optional)
              </label>
              <input
                type='text'
                autoComplete='off'
                className='form-control form-control-lg form-control mb-3 mb-lg-0'
                id='instagram'
                placeholder='Instagram (Optional)'
                name='instagram'
                value={formik.values.instagram}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.instagram && formik.errors.instagram && (
                <div className='error' style={{color: 'red'}}>
                  {formik.errors.instagram}
                </div>
              )}
            </div>

            {/* <div className='mt-2'>
              <label className='text-dark' htmlFor='twitter'>
                Twitter
              </label>
              <input
                type='text'
                autoComplete='off'
                className='form-control form-control-lg form-control mb-3 mb-lg-0'
                id='twitter'
                placeholder='Twitter'
                name='twitter'
                value={formik.values.twitter}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.twitter && formik.errors.twitter && (
                <div className='error' style={{color: 'red'}}>
                  {formik.errors.twitter}
                </div>
              )}
            </div> */}
          </div>
        </div>

        <div className='card-footer d-flex justify-content-center py-6 px-9 gap-5'>
          <button type='submit' className='btn btn-primary' disabled={loading}>
            {!loading && 'Save Changes'}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <button type='button' className='btn btn-danger ' onClick={logout}>
            Sign Out
          </button>
        </div>
      </form>

      {state.stripeConnectView && (
        <CustomModal
          handleClose={() => {
            setState({...state, stripeConnectView: false})
          }}
          headerView={true}
          type={MODAL_TYPE_ENUM.STRIPE_CONNECT}
        />
      )}
      {state.accountStatusView && (
        <CustomModal
          handleClose={() => {
            setState({...state, accountStatusView: false})
          }}
          headerView={true}
          type={MODAL_TYPE_ENUM.ACCOUNT_STATUS}
        />
      )}
      {state.accountRejectedView && (
        <CustomModal
          handleClose={() => {
            setState({...state, accountRejectedView: false})
          }}
          headerView={true}
          type={MODAL_TYPE_ENUM.SINGLE_TEXT_BUTTON}
          body={{title: title}}
          buttonText='Edit Profile'
          signoutButtonText='Signout'
        />
      )}
      {state.profileModelView && (
        <CustomModal
          type={MODAL_TYPE_ENUM.COMPLETE_CHARITY_PROFILE}
          headerView={true}
          handleClose={() => {
            setState({...state, profileModelView: false})
          }}
          // body={{title: title}}
        />
      )}
    </FormikProvider>
  )
}

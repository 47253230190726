import React, {useState} from 'react'
import {ToastContainer, toast} from 'react-toastify'
import * as Yup from 'yup'
import {SectionWrapper} from '../../../components/Common'
import {PageTitle} from '../../../_metronic/layout/core'
import {useFormik} from 'formik'
import {charityContactUs} from '../../modules/auth/core/_requests'
import {useAuth} from '../../modules/auth'

const ContactUsSchema = Yup.object().shape({
  name: Yup.string().required('Full Name is required'),
  email: Yup.string().email('Invalid email address').required('Email Address is required'),
  phone: Yup.number()
    .positive()
    .integer()
    .required('Phone number is required')
    .typeError('Phone number must be a number')
    .test('len', 'Phone number must be exactly 10 digits', (value) => String(value).length === 10),
  message: Yup.string().required('Your Message is required'),
})

export default function ContactUs() {
  const {currentUser} = useAuth()

  const initialValues = {
    name: currentUser?.name ? currentUser?.name : '',
    email: currentUser?.email ? currentUser?.email : '',
    phone: String(currentUser?.phone) ?? '',
    message: '',
  }

  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: ContactUsSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true)
        const result = await charityContactUs(
          currentUser?.token,
          values.name,
          values.email,
          values.phone,
          values.message
        )
        if (result && result.status === 201) {
          toast.success(result.data.message)
          setLoading(false)
          formik.resetForm()
        }
      } catch (error: any) {
        toast.error(error.response.data.message || 'something went wrong')
        setLoading(false)
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <SectionWrapper title='Contact Us' id='contact_us'>
      <PageTitle>Contact</PageTitle>

      {/* Your form component */}
      <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
        {/* Full Name */}
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label required  fw-bold fs-6'>Full Name</label>
          <div className='col-lg-8'>
            <input
              type='text'
              placeholder='Enter your full name'
              autoComplete='off'
              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
              {...formik.getFieldProps('name')}
              maxLength={50}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.name}</div>
              </div>
            )}
          </div>
        </div>

        {/* Email Address */}
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label required  fw-bold fs-6'>Email Address</label>
          <div className='col-lg-8'>
            <input
              type='email'
              placeholder='Enter your Email'
              autoComplete='off'
              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
              {...formik.getFieldProps('email')}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.email}</div>
              </div>
            )}
          </div>
        </div>

        {/* Phone Number */}
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bold fs-6'>Phone Number</label>
          <div className='col-lg-8 fv-row'>
            <div className='input-group'>
              <span className='input-group-text' style={{border: 'none', fontWeight: 'bold'}}>
                +1
              </span>
              <input
                type='tel'
                autoComplete='off'
                className='form-control form-control-lg form-control-solid px-1'
                placeholder='Phone number'
                {...formik.getFieldProps('phone')}
                maxLength={10}
                pattern='[0-9]{10}'
              />
            </div>
            {formik.touched.phone && formik.errors.phone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.phone}</div>
              </div>
            )}
          </div>
        </div>

        {/* Your Message */}
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label required  fw-bold fs-6'>Your Message</label>
          <div className='col-lg-8'>
            <textarea
              placeholder='Enter Your Message...'
              autoComplete='off'
              className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
              {...formik.getFieldProps('message')}
            />
            {formik.touched.message && formik.errors.message && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.message}</div>
              </div>
            )}
          </div>
        </div>

        {/* Submit Button */}
        <div className='card-footer d-flex justify-content-end py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading}>
            {loading ? (
              <div className='d-flex align-items-center'>
                <span>Submitting ...</span>
                <span
                  className='spinner-border spinner-border-sm mx-2'
                  role='status'
                  aria-hidden='true'
                ></span>
              </div>
            ) : (
              'Submit'
            )}
          </button>
        </div>
      </form>
      <ToastContainer />
    </SectionWrapper>
  )
}

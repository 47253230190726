import {useLocation, useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import {CharityProfileDetails} from '../../../components/Sections'
import {BankAccounts} from '../../../components/Common'
import {useCallback, useEffect, useState} from 'react'
import {CustomModal, MODAL_TYPE, MODAL_TYPE_ENUM} from '../../../components/Modal'
import {getCharity} from '../../modules/auth/core/StorageHelper'
import {
  ACCOUNT_TYPE,
  POST_EVENT_TYPE,
  ROLE_TYPE,
  deletePostOrEvent,
  charityUserProfile,
  charityDeleteBankAccount,
  charitySetDefaultAccount,
  profilePic,
} from '../../modules/auth/core/_requests'
import {useAuth} from '../../modules/auth'
import {CharityProfileDetailsType} from '../../../components/Sections/CharitySections/CharityProfileDetails'
import {toast} from 'react-toastify'
import CommonComment from '../../../components/Common/CommonComment'

export interface State {
  modalView: boolean
  selectedRow: any
  type: MODAL_TYPE
  charityDetails?: CharityProfileDetailsType | null
  donorDetails?: any | null
  loading?: boolean
  stripeConnectView?: boolean
  deleteLoading?: {
    postsOrEvents?: boolean
    accounts?: boolean
  }
  setDefaultLoading?: 'process' | 'complete' | 'failed' | null
}
const CharityProfile: React.FC = () => {
  const id = getCharity()
  const {auth, currentUser} = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const [message, setMessage]: any = useState('')
  const [modelView, setModelView] = useState(false)
  const [title, setTitle] = useState('')
  const [statusCode, setStatusCode] = useState('')
  const [isEditing, setIsEditing] = useState(false)
  const storedValue = 0
  const [state, setState] = useState<State>({
    modalView: false,
    selectedRow: null,
    type: null,
    charityDetails: null,
    loading: true,
    deleteLoading: {
      postsOrEvents: false,
      accounts: false,
    },
    stripeConnectView: false,
    setDefaultLoading: null,
  })
  useEffect(() => {
    const getQueryParams = () => {
      const params = new URLSearchParams(window.location.search)
      const filteredParams = {}
      params.forEach((value, key) => {
        if (key === 'message') {
          setMessage(value)
        } else {
          setStatusCode(value)
        }
      })
      return filteredParams
    }

    getQueryParams()
  }, [])
  useEffect(() => {
    if (message) {
      if (statusCode === '200') {
        toast.success(message)
      } else {
        toast.error(message)
      }
      window.history.replaceState({}, document.title, window.location.pathname)
      setTimeout(() => {
        setMessage('')
        setStatusCode('')
      }, 2000)
    }
  }, [message, statusCode])

  const getProfileDetails = useCallback(
    async (token, role, id) => {
      try {
        setState({...state, loading: true})
        // const result = await charityUserProfile(token, role)
        const result = await charityUserProfile(currentUser?.token, 'charity')
        if (result.status === 200) {
          setState({
            ...state,
            charityDetails: result.data.data,
            loading: false,
          })
        }
      } catch (error: any) {
        if (error.response.status === 600) {
          setTitle(error?.response?.data?.message)
          setModelView(true)
        } else {
          toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
        }
        setState({...state, loading: false})
      }
    },
    [state]
  )

  useEffect(() => {
    if (auth && auth.token) {
      getProfileDetails(auth.token, ROLE_TYPE.CHARITY, id)
      setIsEditing(location.search.includes('edit=true'))
    }
  }, [])

  const handleDeletePostOrEvent = async () => {
    try {
      setState({...state, deleteLoading: {...state.deleteLoading, postsOrEvents: true}})
      const result = await deletePostOrEvent(
        auth?.token,
        state.selectedRow.type,
        state.selectedRow.id
      )
      if (result.status === 200) {
        setState({
          ...state,
          modalView: false,
          selectedRow: null,
          type: null,
          deleteLoading: {...state.deleteLoading, postsOrEvents: false},
        })
      }
    } catch (error: any) {
      setState({
        ...state,
        modalView: false,
        selectedRow: null,
        type: null,
        deleteLoading: {...state.deleteLoading, postsOrEvents: false},
      })
      toast.error(error?.response?.data?.message || error?.message || 'Something went wrong!')
    }
  }

  const handleDeleteAccount = async () => {
    setState({...state, deleteLoading: {...state.deleteLoading, accounts: true}})
    try {
      const result = await charityDeleteBankAccount(state.selectedRow?.bankId, ROLE_TYPE.CHARITY)
      if (result.status === 200) {
        setState({
          ...state,
          modalView: false,
          selectedRow: null,
          type: null,
          deleteLoading: {...state.deleteLoading, accounts: false},
        })
      }
    } catch (error: any) {
      setState({
        ...state,
        modalView: false,
        selectedRow: null,
        type: null,
        deleteLoading: {...state.deleteLoading, accounts: false},
      })
      toast.error(error?.response?.data?.message || error?.message || 'Something went wrong!')
    }
  }

  const setDefaultBankAccount = async (accountId) => {
    try {
      setState({...state, setDefaultLoading: 'process'})
      const result = await charitySetDefaultAccount(auth?.token, ROLE_TYPE.CHARITY, accountId)
      if (result.status === 200) {
        setState({...state, setDefaultLoading: 'complete'})
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Something went wrong!')
      setState({...state, setDefaultLoading: 'failed'})
    }
  }

  const handleEditProfile = () => {
    const params = new URLSearchParams(location.search)
    params.set('edit', isEditing ? 'false' : 'true')
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    })
  }

  const editButton = (
    <div className='d-flex justify-content-end py-3'>
      <button
        onClick={(e) => {
          setIsEditing((prevIsEditing) => !prevIsEditing)
          handleEditProfile()
          e.stopPropagation()
        }}
        type='submit'
        className='btn btn-primary'
      >
        {isEditing ? 'Cancel Edit' : 'Edit Profile'}
      </button>
    </div>
  )

  const [userData, setUseData] = useState({
    userData: {
      name: '',
      profilePic: '',
      email: '',
    },
  })
  const getProfilePic = async (token) => {
    try {
      setState((prev) => ({...prev, loading: true}))
      const result = await profilePic(token)

      if (result.status === 200) {
        setUseData((prev) => ({
          ...prev,
          userData: {
            name: result.data?.data.name || '',
            profilePic: result.data?.data.profilePic || '',
            email: result.data?.data.email || '',
          },
          loading: false,
        }))
      } else {
        toast(result.data.message)
      }
    } catch (error: any) {
      setState((prev) => ({...prev, loading: false, error}))
      toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
    }
  }

  useEffect(() => {
    if (auth && auth.token) {
      getProfilePic(auth.token)
    }
  }, [])

  useEffect(() => {
    if (storedValue === 0) {
      setState({...state, stripeConnectView: true})
    }
  }, [storedValue])

  return (
    <>
      <PageTitle>
        {userData.userData.name?.length > 10
          ? `${userData.userData.name.substring(0, 10)}...`
          : userData.userData.name}
      </PageTitle>

      <CharityProfileDetails
        charityId={id}
        charity={state.charityDetails}
        isView={location.search.includes('edit=true') ? false : true}
        editButton={editButton}
        setCharity={() => {
          setState((prev: any) => ({
            ...prev,
            charityDetails: {
              ...prev.charityDetails,
              profilePic: undefined,
            },
          }))
        }}
        isDeleted={false}
      />

      {/* <CharityPosts
        deleteLoading={state.deleteLoading?.postsOrEvents}
        charityId={id}
        handleView={(row) => {
          setState({...state, modalView: true, selectedRow: row, type: MODAL_TYPE_ENUM.POST})
        }}
        handleDelete={(row) =>
          setState({
            ...state,
            modalView: true,
            selectedRow: {title: row.title, id: row.id, type: POST_EVENT_TYPE.POST},
            type: MODAL_TYPE_ENUM.DELETE,
          })
        }
      /> */}

      {/* <CharityEvents
        deleteLoading={state.deleteLoading?.postsOrEvents}
        charityId={id}
        handleView={(row) =>
          setState({...state, modalView: true, selectedRow: row, type: MODAL_TYPE_ENUM.EVENT})
        }
        handleDelete={(row) =>
          setState({
            ...state,
            modalView: true,
            selectedRow: {title: row.title, id: row.id, type: POST_EVENT_TYPE.EVENT},
            type: MODAL_TYPE_ENUM.DELETE,
          })
        }
      /> */}

      <BankAccounts
        id={id}
        deleteLoading={state.deleteLoading?.accounts}
        setDefaultAccount={setDefaultBankAccount}
        setDefaultLoading={state.setDefaultLoading}
        role={ROLE_TYPE.CHARITY}
        type={ACCOUNT_TYPE.BANK}
        deleteHandler={(ac) => {
          setState({
            ...state,
            modalView: true,
            selectedRow: {...ac, title: ac.name, type: 'account'},
            type: MODAL_TYPE_ENUM.DELETE,
          })
        }}
      />

      {/* <CharityDonations
        charityId={id}
        handleView={(row) =>
          setState({...state, modalView: true, selectedRow: row, type: MODAL_TYPE_ENUM.TRANSACTION})
        }
      /> */}

      <CommonComment id={id} type={ROLE_TYPE.CHARITY_PROFILE} />
      {state.modalView && (
        <CustomModal
          type={state.type}
          body={state.selectedRow}
          deleteLoading={
            state.selectedRow.type === POST_EVENT_TYPE.EVENT ||
            state.selectedRow.type === POST_EVENT_TYPE.POST
              ? state.deleteLoading?.postsOrEvents
              : state.deleteLoading?.accounts
          }
          handleConfirm={() => {
            if (
              state.selectedRow.type === POST_EVENT_TYPE.EVENT ||
              state.selectedRow.type === POST_EVENT_TYPE.POST
            ) {
              handleDeletePostOrEvent()
            }
            if (state.selectedRow.type === 'account') {
              handleDeleteAccount()
            }
          }}
          handleClose={() => setState({...state, modalView: false, selectedRow: null, type: null})}
        />
      )}
      {modelView && (
        <CustomModal
          type={MODAL_TYPE_ENUM.SIGNOUT_CHARITY}
          handleClose={() => setModelView(false)}
          body={{title: title}}
        />
      )}
    </>
  )
}

const CharityProfileWrapper = () => (
  <>
    <CharityProfile />
  </>
)

export {CharityProfileWrapper}

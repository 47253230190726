import {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {DataTableWrapper} from '../../../components/DataTable'
import ActionButton from '../../../components/Common/ActionButton'
import {CustomModal, MODAL_TYPE_ENUM} from '../../../components/Modal'
import {contactStatusUpdate, contactUs} from '../../modules/auth/core/_requests'
import {useAuth} from '../../modules/auth'
import {TContact} from '../../../components/Modal/Body/body'
import {toast} from 'react-toastify'
import {removeDonationsStore} from '../../modules/auth/core/StorageHelper'
import {debounce} from '../../../utils'
interface TInitialState {
  modalView: boolean
  selectedRow: any
  loading: boolean
  contacts: TContact[]
  search: string
  currentPage: number
  totalPages: number
  error: any | null
  filters: Filters
  totalActiveContact: number
  totalInActiveContact: number
}
interface Filters {
  startDate: string
  endDate: string
  status: string
}

const initialState = {
  modalView: false,
  selectedRow: null,
  loading: true,
  contacts: [],
  search: '',
  currentPage: 1,
  totalPages: 1,
  error: null,
  filters: {
    startDate: '',
    endDate: '',
    status: '',
  },
  totalActiveContact: 0,
  totalInActiveContact: 0,
}

const Contact = () => {
  const {auth} = useAuth()
  const [state, setState] = useState<TInitialState>(initialState)
  const [render, setRender] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const totalPage: any = localStorage.getItem('totalPage')
  const currentPage: any = localStorage.getItem('currentPage')

  const getContacts = async (token, page, search, filters, pageSize) => {
    try {
      setState((prev) => ({...prev, loading: true}))
      const result = await contactUs(token, page, search, filters, pageSize)

      if (result.status === 200) {
        setState((prev) => ({
          ...prev,
          contacts: result.data.data.contactUsList,
          currentPage: result.data.data.currentPage,
          totalPages: result.data.data.totalPages,
          loading: false,
          totalActiveContact: result.data.data.totalViewedQueries,
          totalInActiveContact: result.data.data.totalPendingQueries,
        }))
        localStorage.setItem('totalPage', result?.data?.data?.totalPages)
        localStorage.setItem('currentPage', result?.data?.data?.currentPage)
      } else {
        toast(result.data.message)
      }
    } catch (error: any) {
      setState((prev) => ({...prev, loading: false, error}))
      toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
    }
  }

  const updateStatus = async () => {
    if (state.selectedRow.status === 'viewed') {
      return
    }
    setState((prev) => ({...prev, loading: true}))
    try {
      const result = await contactStatusUpdate(auth && auth.token, state.selectedRow._id)

      if (result.status === 200) {
        setRender(true)
        setState((prev) => ({...prev, loading: false}))
        // window.location.reload();
      } else {
        toast(result.data.message)
      }
    } catch (error: any) {
      setState((prev) => ({...prev, loading: false, error}))
      toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
    }
  }
  useEffect(() => {
    if (auth && auth.token) {
      getContacts(
        auth.token,
        totalPage === currentPage ? 1 : state.currentPage,
        state.search,
        state.filters,
        pageSize
      )
    }
    if (render) {
      setRender(false)
    }
  }, [state.currentPage, state.search, state.filters, render, pageSize])

  const debouncedOnChange = debounce(
    (value) =>
      setState((prev) => ({
        ...prev,
        search: value,
      })),
    1000
  )

  const columns = [
    {
      name: 'Name',
      selector: (row, i) => row.name,
      width: '300px',
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center m-2 min-w-350px'>
            <div className='d-flex justify-content-start flex-column'>
              <p
                onClick={() => setState({...state, modalView: true, selectedRow: row})}
                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                data-toggle='tooltip'
                data-placement='top'
                title={row.name}
              >
                {row.name}
              </p>
            </div>
          </div>
        )
      },
      sortable: true,
    },
    {
      name: 'Date & Time',
      selector: (row, i) => row.createdAt,
      minWidth: '200px',
      format: (row, i) => {
        return (
          <div
            className='text-dark text-hover-primary fw-bold fs-6'
            onClick={() => setState({...state, modalView: true, selectedRow: row})}
          >
            {new Date(row.createdAt || '').toLocaleDateString('en-GB')}{' '}
            {(() => {
              const date = new Date(row.createdAt || '')
              const hours = date.getHours()
              const minutes = date.getMinutes()
              const ampm = hours >= 12 ? 'PM' : 'AM'
              const formattedHours = hours % 12 === 0 ? 12 : hours % 12

              return `${formattedHours.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
              })}:${minutes.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
              })} ${ampm}`
            })()}
          </div>
        )
      },
      sortable: true,
    },

    {
      name: 'Status',
      selector: (row, i) => row.status,
      minWidth: '150px',
      format: (row, i) => {
        return (
          <span
            className={`badge uppercase text-hover-primary  badge-light-${
              row.status.includes('viewed') ? 'success' : 'danger'
            }`}
            onClick={() => setState({...state, modalView: true, selectedRow: row})}
          >
            {row.status}
          </span>
        )
      },
      sortable: true,
    },
    {
      name: 'Phone',
      selector: (row, i) => row.phone,
      format: (row, i) => {
        return (
          <span
            className='text-dark fw-bold fs-6 text-hover-primary min-w-100px'
            onClick={() => setState({...state, modalView: true, selectedRow: row})}
          >
            {row.phone}
          </span>
        )
      },
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row, i) => row.email,
      width: '240px',
      format: (row, i) => {
        return (
          <span
            className='text-dark fw-bold fs-6 text-hover-primary min-w-100px'
            onClick={() => setState({...state, modalView: true, selectedRow: row})}
          >
            {row.email}
          </span>
        )
      },
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row, i) => row.status,
      format: (row, i) => {
        return (
          <>
            <ActionButton
              viewCallback={() => setState({...state, modalView: true, selectedRow: row})}
            />
          </>
        )
      },
      sortable: false,
    },
  ]

  return (
    <>
      <DataTableWrapper
        loading={state.loading}
        filters={{
          dropdown: [
            {label: 'Status', options: ['viewed', 'pending'].map((t) => ({value: t, label: t}))},
          ],
          contactDate: true,
        }}
        filtersCallback={(filters) => {
          if (filters?.reset) {
            removeDonationsStore()
            setState((prev) => ({
              ...prev,
              filters: initialState.filters,
              currentPage: initialState.currentPage,
              search: initialState.search,
            }))
          } else {
            setState((prev) => ({
              ...prev,
              currentPage: initialState.currentPage,
              filters: {
                startDate: filters.startDate
                  ? new Date(filters.startDate).toLocaleDateString('en-ZA')
                  : '',
                endDate: filters.endDate
                  ? new Date(filters.endDate).toLocaleDateString('en-ZA')
                  : '',
                status: filters['Status']?.value || '',
              },
            }))
          }
        }}
        columns={columns}
        currentPage={state.currentPage}
        totalPages={state.totalPages}
        handlePageChange={(page) => setState((prev) => ({...prev, currentPage: page}))}
        data={state.contacts}
        handleSearch={debouncedOnChange}
        totalActiveContact={state.totalActiveContact}
        totalInActiveContact={state.totalInActiveContact}
        onPageSizeChange={(newValue: number) => setPageSize(newValue)}
        pageSize={pageSize}
      />
      {state.modalView && (
        <CustomModal
          type={MODAL_TYPE_ENUM.CONTACT}
          body={state.selectedRow}
          handleClose={() => {
            setState({...state, modalView: false, selectedRow: null})
            updateStatus()
          }}
        />
      )}
    </>
  )
}

const ContactWrapper = () => (
  <>
    <PageTitle>Contact</PageTitle>
    <Contact />
  </>
)

export {ContactWrapper}

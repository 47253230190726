import React, {useCallback, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import SectionWrapper from './SectionWrapper'
import SelectOption from './SelectOption'
import {useAuth} from '../../app/modules/auth'
import {changeCardStatus, userAccountDetails} from '../../app/modules/auth/core/_requests'
import {Account, AccountState} from './Accounts'
import AccountCardLoader from './AccountCardLoader'
import {toast} from 'react-toastify'

const initialState = {
  accounts: [],
  loading: false,
  error: null,
  changeStatusId: null,
  index: null,
}

export default function CardDetails({
  id,
  role,
  type,
  setDefaultLoading,
  setDefaultAccount,
  setCardLoading,
  cardLoading,
}) {
  const {auth} = useAuth()

  const [state, setState] = useState<AccountState>(initialState)

  const getAccountDetails = useCallback(
    async (token, type, role, id) => {
      try {
        setState({...state, loading: true})
        const result = await userAccountDetails(token, type, role, id)
        if (result.status === 200) {
          setState({...state, accounts: result.data.data, loading: false})
        }
      } catch (error: any) {
        setState({...state, loading: false})
        toast.error(error?.response?.data?.message || error?.message || 'Something went wrong!')
      } finally {
        setState((prev) => ({...prev, index: null}))
      }
    },
    [state]
  )

  useEffect(() => {
    if (id && auth && auth.token) {
      getAccountDetails(auth.token, type, role, id)
    }
  }, [])

  useEffect(() => {
    if (setDefaultLoading === 'complete') {
      getAccountDetails(auth?.token, type, role, id)
    }
  }, [setDefaultLoading])

  const updateCardStatus = async (customerId, cardId, status, i) => {
    try {
      setState({...state, changeStatusId: cardId})
      const result = await changeCardStatus(auth?.token, {
        customerId: customerId,
        cardId: cardId,
        status: status,
        donorId: id,
      })
      if (result.status === 200) {
        setState({
          ...state,
          accounts: [
            ...state.accounts.slice(0, i),
            Object.assign({}, state.accounts[i], {status: status}),
            ...state.accounts.slice(i + 1),
          ],
          changeStatusId: null,
        })
      } else {
        toast(result.data.message)
        setState({...state, changeStatusId: null})
      }
    } catch (error: any) {
      setState({...state, changeStatusId: null})
      toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
    }
  }

  if (state.loading && setDefaultLoading === null) {
    return (
      <SectionWrapper title='Account Details' id='account_details'>
        <AccountCardLoader />
      </SectionWrapper>
    )
  }

  return (
    <>
      <SectionWrapper title='Card Details' id='card_details'>
        {state.accounts.length > 0 ? (
          state.accounts.map((card: Account, i) => (
            <div key={i} className='d-flex align-items-sm-center mb-7'>
              <div className='symbol symbol-70px symbol-2by3 me-4'>
                <img src={`${toAbsoluteUrl('/media/custom-img/credit-card.png')}`} alt='bank png' />
              </div>
              <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
                <div className='flex-grow-1 me-2'>
                  <span className='text-gray-800 fw-bold fs-6'>{card.name}</span>
                  <span className='text-muted fw-semibold d-block pt-1'>
                    ************{card.last4}
                  </span>
                </div>
                <button
                  className={`btn btn-sm btn-${card?.is_default ? 'primary' : 'secondary'}`}
                  disabled={
                    card?.is_default ||
                    card.status === 'InActive' ||
                    setDefaultLoading === 'process' ||
                    card.status === 'Expired' ||
                    state.loading
                  }
                  onClick={() => {
                    if (!(card?.is_default || card.status === 'InActive')) {
                      setState((prev) => ({...prev, index: i}))
                      setCardLoading()
                      setDefaultAccount(card.cardId)
                    }
                  }}
                >
                  {(setDefaultLoading === 'process' && cardLoading && state.index === i) ||
                  (state.index === i && state.loading)
                    ? 'Loading...'
                    : card?.is_default
                    ? 'DEFAULT'
                    : 'SET DEFAULT'}
                </button>
                <div className='mx-4'>
                  {state.changeStatusId === card.cardId ? (
                    <span className='badge text-black p-4 badge-light'>Loading...</span>
                  ) : (
                    !card.is_default && (
                      <>
                        {card.status === 'Expired' ? (
                          <span className='badge text-danger p-2 badge-light'>Expired</span>
                        ) : (
                          <SelectOption
                            isDisable={card?.is_default}
                            options={[
                              {value: 'Active', title: 'Active'},
                              {value: 'InActive', title: 'Inactive'},
                            ]}
                            selected={card.status}
                            onChange={(e) =>
                              updateCardStatus(card.customerId, card.cardId, e.target.value, i)
                            }
                          />
                        )}
                      </>
                    )
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className='text-center'>
            <h6 className='fw-bolder fs-2x text-gray-900'>Oops! Cards not found</h6>
            <div className=''>
              <img
                src={toAbsoluteUrl('/media/auth/table-404-error.png')}
                className='mw-100 mh-300px theme-light-show'
                alt=''
              />
            </div>
          </div>
        )}
      </SectionWrapper>
    </>
  )
}

/**
 * Renders a confirmation dialog for deleting an item.
 * @param {Object} props - The component props.
 * @param {Object} props.body - The details of the item to be deleted.
 * @param {Function} props.handleClose - A callback function to close the deletion dialog.
 * @param {Function} props.handleConfirm - A callback function to confirm the deletion.
 * @param {boolean} props.deleteLoading - Indicates if the deletion is in progress.
 * @returns {JSX.Element} The DeleteBody component.
 */
export default function DeleteBody({body, handleClose, handleConfirm, deleteLoading, title}) {
  const confirmButtonLabel = deleteLoading ? 'Deleting...' : "Yes, I'm sure"

  return (
    <div className='text-center'>
      <div className='card-body'>
        <h4 className='card-title fw-bold text-black fs-4'>
          {title
            ? 'Are you sure you want to delete this account ? You will not be able to restore the account.'
            : 'Are you sure you want to delete?'}
        </h4>
        <h2 className='card-title fw-bold text-muted my-5 fs-2'>{body?.title}</h2>

        <div className='d-flex my-4 flex-center'>
          <button className='btn btn-sm btn-light me-3' onClick={handleClose}>
            No, cancel
          </button>
          <button
            className='btn btn-sm btn-danger me-3'
            disabled={deleteLoading}
            onClick={handleConfirm}
          >
            {confirmButtonLabel}
          </button>
        </div>
      </div>
    </div>
  )
}

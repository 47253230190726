import React, {useState} from 'react'
import {toast} from 'react-toastify'
import {stripeAccountMail} from '../../../app/modules/auth/core/_requests'
import {useAuth} from '../../../app/modules/auth'

export default function AdminStipeAccountBody({body, handleClose}) {
  const [loading, setLoading] = useState(false)
  const {currentUser} = useAuth()

  const EmailHandler = async () => {
    try {
      setLoading(true)
      const result = await stripeAccountMail(currentUser?.token, body.charityId)
      if (result.status === 200) {
        toast.success(result?.data?.message || 'Email sent successfully')
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Something went wrong!')
    } finally {
      setLoading(false)
      handleClose()
    }
  }

  return (
    <div className='d-flex justify-content-center align-items-center '>
      <div className='card '>
        <div className='card-body p-0 text-center '>
          <p className='card-text text-muted text-dark fw-bolder fs-3 mb-7'>{body.message}</p>
          <button type='submit' className='btn btn-danger mx-2' onClick={() => handleClose()}>
            Cancel
          </button>
          <button
            type='button'
            className='btn btn-primary mx-2'
            onClick={EmailHandler}
            disabled={loading}
          >
            {loading ? (
              <div className='d-flex align-items-center'>
                <span>Sending Mail...</span>
                <span
                  className='spinner-border spinner-border-sm mx-2'
                  role='status'
                  aria-hidden='true'
                ></span>
              </div>
            ) : (
              'Send An E-Mail'
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

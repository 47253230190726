export default function SelectOption({
  options = [],
  selected,
  onChange,
  isDisable,
}: {
  options: {value: string; title: string}[]
  selected: string
  onChange: (e) => void
  isDisable?: boolean
}) {
  return (
    <>
      <select
        className={`${
          ['active', 'verified', 'Active', 'true'].includes(selected?.toLowerCase())
            ? 'text-success'
            : 'text-danger'
        } form-select form-select-solid form-select-sm fw-bold`}
        value={selected}
        onChange={isDisable ? () => {} : onChange}
        disabled={isDisable}
      >
        {options &&
          options.map((ele, i) => {
            return (
              <option className='fs-4 text-gray-700' value={ele.value} key={i}>
                {ele.title}
              </option>
            )
          })}
      </select>
    </>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import CountUp from 'react-countup'

type Props = {
  className: string
  color: string
  numbersColor: string
  title: string
  numbers: string
  titleColor?: string
  onClick?: () => void
}

const TotalCountCard: React.FC<Props> = ({
  className,
  color,
  numbersColor,
  title,
  numbers,
  titleColor,
  onClick,
}) => {
  return (
    <div className={`card bg-${color} hoverable ${className}`} onClick={onClick}>
      <div className='card-body'>
        <span className={`text-${numbersColor} fs-3x ms-n1`}>
          {title.includes('Donations') && '$'}
          <CountUp end={parseInt(numbers)} duration={3} start={0} />
        </span>
        <div className={`text-${titleColor} fw-bold fs-2 mb-2 mt-5`}>{title}</div>
      </div>
    </div>
  )
}

export {TotalCountCard}

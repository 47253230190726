import React, {useCallback, useEffect, useState} from 'react'
import {SectionWrapper} from '.'
import {KTIcon, toAbsoluteUrl} from '../../_metronic/helpers'
import {
  charityPostComment,
  charityUserPostComment,
  deletePostEventComment,
} from '../../app/modules/auth/core/_requests'
import {useAuth} from '../../app/modules/auth'
import {toast} from 'react-toastify'
import {TComment} from '../Modal/Body/body'
import {formatDistanceToNow} from 'date-fns'
import {CustomModal} from '../Modal'
export interface TInitialState {
  comments: TComment[]
  currentPage: number
  totalPages: number
  type: any
  modalView: boolean
  commentCategory: string
  id: string
  deleteLoading: boolean
}

const initialState = {
  comments: [],
  currentPage: 1,
  totalPages: 1,
  type: null,
  modalView: false,
  commentCategory: '',
  id: '',
  deleteLoading: false,
}

export default function CommonComment({id, type}) {
  const {auth, currentUser} = useAuth()
  const [state, setState] = useState<TInitialState>(initialState)
  const [modelView, setModelView] = useState(false)
  const [title, setTitle] = useState('')

  const timeAgo = (dateString: string) => {
    const date = new Date(dateString)
    return formatDistanceToNow(date, {addSuffix: true})
  }

  const getComments = useCallback(
    async (id, token, type, page) => {
      try {
        let result
        if (currentUser?.role === 'charity') {
          result = await charityUserPostComment(token, type, page)
        } else {
          result = await charityPostComment(id, token, type, page)
        }

        if (result.status === 200) {
          setState((prev) => ({
            ...prev,
            // loading: false,
            comments: [...prev.comments, ...(result.data.data?.commentList || [])],
            currentPage: result.data.data?.currentPage || 1,
            totalPages: result.data.data?.totalPages || 1,
          }))
        } else {
          toast(result.data.message)
        }
      } catch (error: any) {
        if (error.response.status === 600) {
          setTitle(error?.response?.data?.message)
          setModelView(true)
        } else {
          toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
        }
      }
    },
    [state.currentPage]
  )

  const handleSeeMore = () => {
    if (auth && auth.token) {
      getComments(id, auth.token, type, state.currentPage + 1)
    }
  }

  useEffect(() => {
    if (auth && auth.token) {
      getComments(id, auth.token, type, state.currentPage)
    }
  }, [])

  const handleDeleteComment = (id, commentCategory) => {
    setState((prev) => ({...prev, id: id, commentCategory: commentCategory, modalView: true}))
  }

  const showSeeMoreButton = state.currentPage < state.totalPages

  const [showFullComments, setShowFullComments] = useState<boolean[]>([])

  const toggleComment = (commentIndex: number) => {
    setShowFullComments((prev) => {
      const newShowFullComments = [...prev]
      newShowFullComments[commentIndex] = !newShowFullComments[commentIndex]
      return newShowFullComments
    })
  }

  useEffect(() => {
    setShowFullComments(new Array<boolean>(state.comments.length).fill(false))
  }, [state.comments])

  return (
    <>
      <SectionWrapper title='Bio Comment Details' id='comments_details'>
        <div>
          {state.comments.length > 0 ? (
            <div>
              <div className='mh-300px overflow-auto'>
                {state.comments?.map((el, index) => {
                  return (
                    <div className='d-flex    my-4'>
                      <div className='d-flex  flex-grow-1'>
                        <div className='symbol symbol-40px me-5'>
                          <img
                            src={
                              el.user?.profilePic
                                ? el.user?.profilePic
                                : toAbsoluteUrl('/media/avatars/blank.png')
                            }
                            alt={el.user.name}
                          />
                        </div>
                        <div className='d-flex flex-column me-9 flex-wrap'>
                          <div className='d-flex align-items-center text-gray-800 fs-6 fw-bold flex-wrap'>
                            <div>
                              <span style={{marginRight: '10px'}}>
                                {el.user?.name.length > 20
                                  ? `${el.user?.name.slice(0, 20)}...`
                                  : el.user?.name}
                              </span>
                            </div>
                            <div>
                              <div className=' text-gray-400'>{timeAgo(el.createdAt)}</div>
                            </div>
                          </div>

                          <span
                            className='text-gray-400 fw-semibold'
                            style={{wordBreak: 'break-word'}}
                          >
                            {showFullComments[index]
                              ? el.content
                              : `${el.content.slice(0, 300)}...`}
                            {el.content.length > 300 && (
                              <span
                                className='text-primary cursor-pointer'
                                onClick={() => toggleComment(index)}
                              >
                                {showFullComments[index] ? ' See less' : ' See more'}
                              </span>
                            )}
                          </span>
                        </div>
                      </div>
                      <div
                        className='symbol symbol-40px me-5'
                        onClick={() => handleDeleteComment(el?._id, el?.commentCategory)}
                      >
                        <span className='symbol-label bg-light-info flex-grow-1'>
                          <KTIcon iconName='trash' className='fs-3 text-danger cursor-pointer' />
                        </span>
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className='d-flex align-items-center my-5' style={{justifyContent: 'center'}}>
                {showSeeMoreButton && (
                  <div className='text-center mt-4 cursor-pointer'>
                    <span className='text-primary' onClick={handleSeeMore}>
                      View More
                    </span>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className='d-flex flex-column align-items-center'>
              <h6 className='fw-bolder fs-2x text-gray-900'>Oops! Comments not found</h6>
              <div className=''>
                <img
                  src={toAbsoluteUrl('/media/auth/table-404-error.png')}
                  className='mw-100 mh-300px theme-light-show'
                  alt=''
                />
              </div>
            </div>
          )}
        </div>
        {state.modalView && (
          <CustomModal
            type={'DELETE'}
            deleteLoading={state.deleteLoading}
            handleConfirm={async () => {
              try {
                setState({...state, deleteLoading: true})
                const result = await deletePostEventComment(
                  auth && auth.token,
                  state.id,
                  state.commentCategory
                )

                if (result.status === 200) {
                  const commentIndex = state.comments.findIndex(
                    (comment) => comment._id === state.id
                  )

                  if (commentIndex !== -1) {
                    const updatedComments = [...state.comments]
                    updatedComments.splice(commentIndex, 1)

                    setState((prev) => ({
                      ...prev,
                      comments: updatedComments,
                      modalView: false,
                      deleteLoading: false,
                    }))
                  }
                } else {
                  toast(result.data.message)
                }
              } catch (error: any) {
                setState({
                  ...state,
                  modalView: false,
                  deleteLoading: false,
                })
                toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
              }
            }}
            handleClose={() => {
              setState({...state, modalView: false})
            }}
          />
        )}

        {/* {currentUser?.role === 'charity' && modelView && (
        <CustomModal
          type={MODAL_TYPE_ENUM.SIGNOUT_CHARITY}
          handleClose={() => setModelView(false)}
          body={{title: title}}
        />
      )} */}
      </SectionWrapper>
    </>
  )
}

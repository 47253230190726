import {useCallback, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {DataTableWrapper} from '../../../components/DataTable'
import ActionButton from '../../../components/Common/ActionButton'
import {SelectOption} from '../../../components/Common'
import {CustomModal, MODAL_TYPE, MODAL_TYPE_ENUM} from '../../../components/Modal'
import {
  ROLE_TYPE,
  charityRoundUpList,
  getDonorsCharityListForCharity,
  roundUpCharityStatus,
  roundUpStatus,
} from '../../modules/auth/core/_requests'
import {useAuth} from '../../modules/auth'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {getName} from '../../modules/auth/core/StorageHelper'
import {toast} from 'react-toastify'
import {useParams} from 'react-router-dom'
import {debounce} from '../../../utils'

export interface TInitialState {
  loading: boolean
  roundups: any[]
  donors: {_id: string; name: string}[]
  charities: any[]
  currentPage: number
  totalPages: number
  error: any
  modalView: boolean
  selectedRow: any
  type: MODAL_TYPE_ENUM | MODAL_TYPE | any
  search: string
  changeStatusId: string | null
  filters: Filters
  totalActiveRoundUp: number
  totalInActiveRoundUp: number
  totalPausedRoundUp: number
}

export interface Filters {
  donor_id?: string
  paymentMethod: string
  status: string
  amount: string
}

const initialState = {
  loading: false,
  roundups: [],
  donors: [],
  currentPage: 1,
  totalPages: 1,
  totalActiveRoundUp: 0,
  totalInActiveRoundUp: 0,
  totalPausedRoundUp: 0,
  error: null,
  modalView: false,
  selectedRow: null,
  type: null,
  search: '',
  changeStatusId: null,
  charities: [],
  filters: {
    donor_id: '',
    paymentMethod: '',
    amount: '',
    status: '',
    startDate: '',
    endDate: '',
  },
}

const ViewCharityRoundup = () => {
  const {auth} = useAuth()
  const [state, setState] = useState<TInitialState>(initialState)
  const [title, setTitle] = useState('')
  const [modelView, setModelView] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const totalPage: any = localStorage.getItem('totalPage')
  const currentPage: any = localStorage.getItem('currentPage')
  const param = useParams()
  const values = getName()
  const charityName =
    state?.roundups[0]?.charity?.name && state?.roundups[0]?.charity?.name.length > 25
      ? state?.roundups[0]?.charity?.name.substring(0, 25) + '..'
      : state?.roundups[0]?.charity?.name
  const rounupBreadcrumbs: Array<PageLink> = [
    {
      title: 'Round Up /',
      path: `/charity-management/${charityName}`,
      isSeparator: false,
      isActive: false,
    },
  ]

  const roundupDetails = useCallback(
    async (token, page, search, filters, pageSize) => {
      try {
        setState({...state, loading: true})
        let result
        result = await charityRoundUpList(
          token,
          'admin',
          page,
          search,
          filters,
          pageSize,
          'charity',
          param.id
        )

        if (result.status === 200) {
          setState((prev) => ({
            ...prev,
            loading: false,
            roundups: result.data.data.roundUpList,
            totalActiveRoundUp: result.data.data.totalActiveRoundUp,
            totalInActiveRoundUp: result.data.data.totalInActiveRoundUp,
            totalPausedRoundUp: result.data.data.totalPausedRoundUp,
            totalPages: result.data.data.totalPages,
            currentPage: result?.data?.data?.currentPage || 1,
          }))
          localStorage.setItem('totalPage', result?.data?.data?.totalPages)
          localStorage.setItem('currentPage', result?.data?.data?.currentPage)
        }
      } catch (error: any) {
        setState({...state, loading: false, error: error})
        if (error?.response?.status === 600) {
          setTitle(error?.response?.data?.message)
          setModelView(true)
        } else {
          toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
        }
      }
    },
    [state, pageSize]
  )

  const getDonorsListData = useCallback(async (token) => {
    try {
      const result = await getDonorsCharityListForCharity(
        token,
        ROLE_TYPE.DONOR,
        'roundUp',
        param.id
      )
      if (result.status === 200) {
        setState((prev) => ({...prev, donors: result.data.data}))
      } else {
        toast(result.data.message)
      }
    } catch (error: any) {
      if (error.response.status === 600) {
        setTitle(error?.response?.data?.message)
        setModelView(true)
      } else {
        toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
      }
    }
  }, [])

  useEffect(() => {
    if (auth && auth.token) {
      getDonorsListData(auth?.token as unknown as string)
    }
  }, [])

  const debouncedOnChange = debounce(
    (value) =>
      setState((prev) => ({
        ...prev,
        search: value,
      })),
    1000
  )

  const charityColumns = [
    {
      name: 'Donor Name',
      selector: (row, i) => row.donor.name,
      width: '250px',
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center m-2 min-w-350px'>
            <div className='symbol symbol-50px me-5'>
              <span
                className='symbol-label bg-light'
                onClick={() =>
                  setState({
                    ...state,
                    selectedRow: row,
                    modalView: true,
                    type: MODAL_TYPE_ENUM.ROUNDUP_TRANSACTION,
                  })
                }
              >
                <img
                  src={
                    row.donor.profilePic
                      ? row.donor.profilePic
                      : toAbsoluteUrl('/media/avatars/blank.png')
                  }
                  className='h-[75px] w-[75px] symbol-label align-self-end'
                  alt=''
                />
              </span>
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <span
                onClick={() =>
                  setState({
                    ...state,
                    selectedRow: row,
                    modalView: true,
                    type: MODAL_TYPE_ENUM.ROUNDUP_TRANSACTION,
                  })
                }
                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                data-toggle='tooltip'
                data-placement='top'
                title={row.donor.name}
              >
                {row.donor.name}
              </span>
            </div>
          </div>
        )
      },
      sortable: true,
    },
    {
      name: 'Date & Time',
      selector: (row, i) => row.createdAt,
      format: (row, i) => {
        return (
          <span
            className='text-dark fw-bold fs-6 min-w-100px text-hover-primary '
            onClick={() =>
              setState({
                ...state,
                selectedRow: row,
                modalView: true,
                type: MODAL_TYPE_ENUM.ROUNDUP_TRANSACTION,
              })
            }
          >
            {new Date(row.createdAt || '').toLocaleDateString('en-GB')}{' '}
            {(() => {
              const date = new Date(row.createdAt || '')
              const hours = date.getHours()
              const minutes = date.getMinutes()
              const ampm = hours >= 12 ? 'PM' : 'AM'
              const formattedHours = hours % 12 === 0 ? 12 : hours % 12

              return `${formattedHours.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
              })}:${minutes.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
              })} ${ampm}`
            })()}{' '}
          </span>
        )
      },
      sortable: true,
    },
    {
      name: 'Payment Method',
      selector: (row, i) => row.paymentMethod,
      format: (row, i) => (
        <>
          <span
            className='text-dark text-hover-primary fw-bold fs-6'
            onClick={() =>
              setState({
                ...state,
                selectedRow: row,
                modalView: true,
                type: MODAL_TYPE_ENUM.ROUNDUP_TRANSACTION,
              })
            }
          >
            {row?.paymentMethod.toUpperCase()}
          </span>
        </>
      ),
      sortable: true,
      sortCompare: (a, b) => a - b,
    },
    {
      name: 'Status',
      selector: (row, i) => row?.status,
      format: (row, i) => {
        const isLoading = state?.changeStatusId === row?._id
        if (isLoading) {
          return <span className='badge text-black p-4 badge-light'>Loading...</span>
        } else {
          return row?.status === 'Active' || row?.status === 'Inactive' ? (
            <>
              <SelectOption
                options={[
                  {value: 'Active', title: 'Active'},
                  {value: 'InActive', title: 'Inactive'},
                ]}
                selected={row?.status}
                onChange={async (e) => {
                  try {
                    setState({...state, changeStatusId: row._id})
                    const value = e.target.value
                    const result = await roundUpCharityStatus(row._id, auth?.token)
                    if (result.status === 200) {
                      setState({
                        ...state,
                        roundups: [
                          ...state.roundups.slice(0, i),
                          Object.assign({}, state.roundups[i], {
                            status: value,
                          }),
                          ...state.roundups.slice(i + 1),
                        ],
                        changeStatusId: null,
                      })
                    } else {
                      toast(result.data.message)
                      setState({...state, changeStatusId: null})
                    }
                  } catch (error: any) {
                    setState({...state, changeStatusId: null})
                    toast(
                      error?.response?.data?.message || error?.message || 'Something went wrong!'
                    )
                  }
                }}
              />
            </>
          ) : (
            <>
              <span className='badge capitalize bg-gray-100 px-6 py-3  text-danger'>
                {row?.status}
              </span>
            </>
          )
        }
      },
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row, i) => row.status,
      format: (row, i) => {
        return (
          <>
            <ActionButton
              viewCallback={() =>
                setState({
                  ...state,
                  selectedRow: row,
                  modalView: true,
                  type: MODAL_TYPE_ENUM.ROUNDUP_TRANSACTION,
                })
              }
            />
          </>
        )
      },
      sortable: false,
    },
  ]

  const donorColumns = [
    {
      name: 'Donor Name',
      selector: (row, i) => row.donorName,
      width: '250px',
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center m-2 min-w-350px'>
            <div className='symbol symbol-50px me-5'>
              <span
                className='symbol-label bg-light'
                onClick={() =>
                  setState({
                    ...state,
                    selectedRow: row,
                    modalView: true,
                    type: MODAL_TYPE_ENUM.ROUNDUP_TRANSACTION,
                  })
                }
              >
                <img
                  src={
                    row.donorProfilePic
                      ? row.donorProfilePic
                      : toAbsoluteUrl('/media/avatars/blank.png')
                  }
                  className='h-[75px] w-[75px] symbol-label align-self-end'
                  alt=''
                />
              </span>
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <span
                onClick={() =>
                  setState({
                    ...state,
                    selectedRow: row,
                    modalView: true,
                    type: MODAL_TYPE_ENUM.ROUNDUP_TRANSACTION,
                  })
                }
                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                data-toggle='tooltip'
                data-placement='top'
                title={row.donorName}
              >
                {row.donorName}
              </span>
            </div>
          </div>
        )
      },
      sortable: true,
    },
    {
      name: 'Charity',
      selector: (row, i) => row.totalCharities,
      width: '150px',
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center m-2 min-w-350px'>
            <div className='d-flex justify-content-start flex-column'>
              <span
                onClick={() =>
                  setState({
                    ...state,
                    selectedRow: row,
                    modalView: true,
                    type: MODAL_TYPE_ENUM.ROUNDUP_TRANSACTION,
                  })
                }
                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
              >
                {row.totalCharities}
              </span>
            </div>
          </div>
        )
      },
      sortable: true,
    },
    {
      name: 'Date & Time',
      selector: (row, i) => row.createdAt,
      format: (row, i) => {
        return (
          <span
            className='text-dark fw-bold fs-6 min-w-100px text-hover-primary '
            onClick={() =>
              setState({
                ...state,
                selectedRow: row,
                modalView: true,
                type: MODAL_TYPE_ENUM.ROUNDUP_TRANSACTION,
              })
            }
          >
            {new Date(row.createdAt || '').toLocaleDateString('en-GB')}{' '}
            {(() => {
              const date = new Date(row.createdAt || '')
              const hours = date.getHours()
              const minutes = date.getMinutes()
              const ampm = hours >= 12 ? 'PM' : 'AM'
              const formattedHours = hours % 12 === 0 ? 12 : hours % 12

              return `${formattedHours.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
              })}:${minutes.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
              })} ${ampm}`
            })()}{' '}
          </span>
        )
      },
      sortable: true,
    },
    {
      name: 'Payment Method',
      selector: (row, i) => row.paymentMethod,
      format: (row, i) => (
        <>
          <span
            className='text-dark text-hover-primary fw-bold fs-6'
            onClick={() =>
              setState({
                ...state,
                selectedRow: row,
                modalView: true,
                type: MODAL_TYPE_ENUM.ROUNDUP_TRANSACTION,
              })
            }
          >
            {row?.paymentMethod.toUpperCase()}
          </span>
        </>
      ),
      sortable: true,
      sortCompare: (a, b) => a - b,
    },
    {
      name: 'Status',
      selector: (row, i) => row.status,
      format: (row, i) => {
        const isLoading = state.changeStatusId === row._id
        if (isLoading) {
          return <span className='badge text-black p-4 badge-light'>Loading...</span>
        } else {
          return row.status === 'Active' || row.status === 'Inactive' ? (
            <>
              <SelectOption
                options={[
                  {value: 'Active', title: 'Active'},
                  {value: 'InActive', title: 'Inactive'},
                ]}
                selected={row.status}
                onChange={async (e) => {
                  try {
                    setState({...state, changeStatusId: row._id})
                    const value = e.target.value
                    const result = await roundUpStatus(row._id, auth?.token)
                    if (result.status === 200) {
                      setState({
                        ...state,
                        roundups: [
                          ...state.roundups.slice(0, i),
                          Object.assign({}, state.roundups[i], {
                            status: value,
                          }),
                          ...state.roundups.slice(i + 1),
                        ],
                        changeStatusId: null,
                      })
                    } else {
                      toast(result.data.message)
                      setState({...state, changeStatusId: null})
                    }
                  } catch (error: any) {
                    setState({...state, changeStatusId: null})
                    toast(
                      error?.response?.data?.message || error?.message || 'Something went wrong!'
                    )
                  }
                }}
              />
            </>
          ) : (
            <>
              <span className='badge capitalize bg-gray-100 px-6 py-3  text-danger'>
                {row.status}
              </span>
            </>
          )
        }
      },
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row, i) => row.status,
      format: (row, i) => {
        return (
          <>
            <ActionButton
              viewCallback={() =>
                setState({
                  ...state,
                  selectedRow: row,
                  modalView: true,
                  type: MODAL_TYPE_ENUM.ROUNDUP_TRANSACTION,
                })
              }
            />
          </>
        )
      },
      sortable: false,
    },
  ]

  useEffect(() => {
    if (auth && auth?.token) {
      roundupDetails(
        auth?.token,
        totalPage === currentPage ? 1 : state.currentPage,
        state.search,
        state.filters,
        pageSize
      )
    }
  }, [state.currentPage, state.search, state.filters, pageSize])

  return (
    <>
      <PageTitle breadcrumbs={charityName && rounupBreadcrumbs}>
        {charityName && charityName}
      </PageTitle>

      <DataTableWrapper
        filters={{
          dropdown: [
            {
              label: 'Donor Name',
              options: state.donors?.map((t) => ({
                value: t['_id'],
                label: t['name'],
              })),
            },
            {
              label: 'Status',
              options: ['Active', 'InActive', 'Paused'].map((t) => ({
                value: t,
                label: t.toLowerCase(),
              })),
            },
            {
              label: 'Payment Method',
              options: ['Card', 'Bank Account'].map((t) => ({
                value: t.toLowerCase().replace(' ', '_'),
                label: t,
              })),
            },
          ],
          date: true,
        }}
        filtersCallback={(filters) => {
          if (filters?.reset) {
            state.filters.donor_id = ''
            setState((prev) => ({
              ...prev,
              filters: initialState.filters,
              currentPage: initialState.currentPage,
              search: initialState.search,
            }))
          } else {
            setState((prev) => ({
              ...prev,
              currentPage: initialState.currentPage,
              filters: {
                endDate: filters.endDate
                  ? new Date(filters.endDate).toLocaleDateString('en-ZA')
                  : '',
                startDate: filters.startDate
                  ? new Date(filters.startDate).toLocaleDateString('en-ZA')
                  : '',
                donor_id: filters['Donor Name']?.value || '',
                paymentMethod: filters['Payment Method']?.value || '',
                status: filters['Status']?.value || '',
                amount: filters['Donation Amount']?.value || '',
              },
            }))
          }
        }}
        loading={state.loading}
        columns={charityColumns}
        data={state.roundups}
        handleSearch={debouncedOnChange}
        currentPage={state.currentPage}
        totalPages={state.totalPages}
        handlePageChange={(page) => setState({...state, currentPage: page})}
        totalActiveRoundup={state.totalActiveRoundUp}
        totalInActiveRoundup={state.totalInActiveRoundUp}
        totalPausedRoundup={state.totalPausedRoundUp}
        onPageSizeChange={(newValue: number) => setPageSize(newValue)}
        pageSize={pageSize}
      />

      {state.modalView && (
        <CustomModal
          type={'CHARITY_ROUNDUP_TRANSACTION'}
          body={state.selectedRow}
          handleClose={() => setState({...state, modalView: false, selectedRow: null, type: null})}
        />
      )}
    </>
  )
}

const ViewCharityRoundupWrapper = () => (
  <>
    <ViewCharityRoundup />
  </>
)

export {ViewCharityRoundupWrapper}

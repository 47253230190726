import React from 'react'
import ContentLoader from 'react-content-loader'

export default function DashBoardLoader() {
  return (
    <>
      <ContentLoader
        speed={2}
        width={'100%'}
        height={'100%'}
        viewBox='0 0 1200 600'
        backgroundColor='#C4C4C4'
        foregroundColor='#ecebeb'
      >
        <rect x='23' y='11' rx='10' ry='10' width='370' height='200' />
        <rect x='420' y='11' rx='10' ry='10' width='370' height='200' />
        <rect x='820' y='11' rx='10' ry='10' width='370' height='200' />
        <rect x='23' y='240' rx='10' ry='10' width='370' height='600' />
        <rect x='420' y='240' rx='10' ry='10' width='775' height='600' />
      </ContentLoader>
    </>
  )
}

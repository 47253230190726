import {useCallback, useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {DataTableWrapper} from '../../../components/DataTable'
import ActionButton from '../../../components/Common/ActionButton'
import {SelectOption} from '../../../components/Common'
import {CustomModal, MODAL_TYPE, MODAL_TYPE_ENUM} from '../../../components/Modal'
import {
  ROLE_TYPE,
  getDonorsCharityList,
  getDonorsCharityListForCharity,
  getDonorsCharityLists,
  updateSubscriptionStatus,
  viewDonorSubscriptionDetails,
} from '../../modules/auth/core/_requests'
import {useAuth} from '../../modules/auth'
import {debounce} from '../../../utils'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {
  getCharityDonorStore,
  getViewDonationsStore,
  removeCharityDonorStore,
  removeViewDonationsStore,
} from '../../modules/auth/core/StorageHelper'
import {toast} from 'react-toastify'
import {TSubscription} from '../../../components/Modal/Body/body'
import {planTypeObj} from '../../../utils'

export interface TInitialState {
  loading: boolean
  subscriptions: TSubscription[]
  donors: {_id: string; name: string}[]
  charities: any[]
  currentPage: number
  totalPages: number
  error: any
  modalView: boolean
  selectedRow: any
  type: MODAL_TYPE_ENUM | MODAL_TYPE | any
  search: string
  changeStatusId: string | null
  filters: Filters
  totalActiveSubscriptions: number
  totalInActiveSubscriptions: number
  totalPausedSubscriptions: number
}

export interface Filters {
  id: string
  planType: string
  status: string
  amount: string
}

const initialState = {
  loading: false,
  subscriptions: [],
  donors: [],
  currentPage: 1,
  totalPages: 1,
  error: null,
  modalView: false,
  selectedRow: null,
  totalActiveSubscriptions: 0,
  totalInActiveSubscriptions: 0,
  totalPausedSubscriptions: 0,
  type: null,
  search: '',
  changeStatusId: null,
  charities: [],
  filters: {
    id: '',
    charity_id: '',
    planType: '',
    amount: '',
    status: '',
  },
}

const ViewSubscriptions = () => {
  const {auth} = useAuth()
  const [state, setState] = useState<TInitialState>(initialState)
  const values = getCharityDonorStore()
  const [pageSize, setPageSize] = useState(10)
  const totalPage: any = localStorage.getItem('totalPage')
  const currentPage: any = localStorage.getItem('currentPage')

  const subscriptionDetails = useCallback(
    async (token, id, page, search, filters, pageSize) => {
      try {
        setState({...state, loading: true})

        const result = await viewDonorSubscriptionDetails(
          token,
          id,
          page,
          search,
          filters,
          pageSize
        )
        if (result.status === 200) {
          setState((prev) => ({
            ...prev,
            loading: false,
            subscriptions: result.data.data.subscriptionList,
            totalActiveSubscriptions: result.data.data.totalActiveSubscription,
            totalInActiveSubscriptions: result.data.data.totalInActiveSubscription,
            totalPausedSubscriptions: result.data.data.totalPausedSubscription,
            totalPages: result.data.data.totalPages,
            currentPage: result?.data?.data?.currentPage || 1,
          }))
          localStorage.setItem('totalPage', result?.data?.data?.totalPages)
          localStorage.setItem('currentPage', result?.data?.data?.currentPage)
        } else {
          toast(result.data.message)
        }
      } catch (error: any) {
        setState({...state, loading: false, error: error})
        toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
      }
    },
    [state, pageSize]
  )

  useEffect(() => {
    if (auth && auth?.token) {
      if (values) state.filters.id = values?.id

      subscriptionDetails(
        auth?.token,
        state.filters.id,
        totalPage === currentPage ? 1 : state.currentPage,
        state.search,
        state.filters,
        pageSize
      )
    }
  }, [state.currentPage, state.search, state.filters, values?.id, pageSize])

  const getDonorsListData = useCallback(async (token) => {
    try {
      if (values) {
        const result =
          values?.role === 'charity'
            ? await getDonorsCharityListForCharity(
                token,
                ROLE_TYPE.DONOR,
                'transaction',
                values?.id
              )
            : await getDonorsCharityLists(token, ROLE_TYPE.DONOR, 'transaction', values?.id)

        const cahrityResult =
          values?.role === 'charity'
            ? await getDonorsCharityListForCharity(
                token,
                ROLE_TYPE.CHARITY,
                'transaction',
                values?.id
              )
            : await getDonorsCharityLists(token, ROLE_TYPE.CHARITY, 'transaction', values?.id)
        if (result.status === 200) {
          setState((prev) => ({...prev, donors: result.data.data}))
        } else {
          toast(result.data.message)
        }
        if (cahrityResult.status === 200) {
          setState((prev) => ({...prev, charities: cahrityResult.data.data}))
        } else {
          toast(cahrityResult.data.message)
        }
      } else {
        const result = await getDonorsCharityList(token, ROLE_TYPE.DONOR, 'transaction')
        const cahrityResult = await getDonorsCharityList(token, ROLE_TYPE.CHARITY, 'transaction')
        if (result.status === 200) {
          setState((prev) => ({...prev, donors: result.data.data}))
        } else {
          toast(result.data.message)
        }
        if (cahrityResult.status === 200) {
          setState((prev) => ({...prev, charities: cahrityResult.data.data}))
        } else {
          toast(cahrityResult.data.message)
        }
      }
    } catch (error: any) {
      toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
    }
  }, [])
  useEffect(() => {
    if (auth && auth.token) {
      getDonorsListData(auth?.token as unknown as string)
    }
  }, [])

  const debouncedOnChange = debounce(
    (value) =>
      setState((prev) => ({
        ...prev,
        search: value,
      })),
    1000
  )

  const adminColumns = [
    {
      name: 'Charity Name',
      selector: (row, i) => row.charityName,
      width: '200px',
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center m-2 min-w-350px'>
            <div className='symbol symbol-50px me-5'>
              <span
                className='symbol-label bg-light'
                onClick={() =>
                  setState({
                    ...state,
                    selectedRow: row,
                    modalView: true,
                    type: MODAL_TYPE_ENUM.SUBSCRIPTION,
                  })
                }
              >
                <img
                  src={row.profilePic ? row.profilePic : toAbsoluteUrl('/media/avatars/blank.png')}
                  className='h-[75px] w-[75px] symbol-label align-self-end'
                  alt=''
                />
              </span>
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <span
                onClick={() =>
                  setState({
                    ...state,
                    selectedRow: row,
                    modalView: true,
                    type: MODAL_TYPE_ENUM.SUBSCRIPTION,
                  })
                }
                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                data-toggle='tooltip'
                data-placement='top'
                title={row.charityName}
              >
                {row.charityName}
              </span>
            </div>
          </div>
        )
      },
      sortable: true,
    },
    {
      name: 'Donor Name',
      selector: (row, i) => row.donorName,
      width: '200px',
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center m-2 min-w-350px'>
            <div
              className='symbol symbol-50px me-5'
              onClick={() =>
                setState({
                  ...state,
                  selectedRow: row,
                  modalView: true,
                  type: MODAL_TYPE_ENUM.SUBSCRIPTION,
                })
              }
            >
              <span className='symbol-label bg-light'>
                <img
                  src={row.donorPic ? row.donorPic : toAbsoluteUrl('/media/avatars/blank.png')}
                  className='h-[75px] w-[75px] symbol-label align-self-end'
                  alt=''
                />
              </span>
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <span
                onClick={() =>
                  setState({
                    ...state,
                    selectedRow: row,
                    modalView: true,
                    type: MODAL_TYPE_ENUM.SUBSCRIPTION,
                  })
                }
                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                data-toggle='tooltip'
                data-placement='top'
                title={row.donorName}
              >
                {row.donorName}
              </span>
            </div>
          </div>
        )
      },
      sortable: true,
    },
    {
      name: 'Subscription Plan',
      selector: (row, i) => row.planType,
      format: (row, i) => {
        return (
          <span
            className='text-dark fw-bold text-hover-primary fs-6 min-w-100px'
            onClick={() =>
              setState({
                ...state,
                selectedRow: row,
                modalView: true,
                type: MODAL_TYPE_ENUM.SUBSCRIPTION,
              })
            }
          >
            {planTypeObj[`${row.planType}`]}
          </span>
        )
      },
      sortable: true,
    },
    {
      name: 'Donation Amount',
      selector: (row, i) => parseFloat(row.amount),
      format: (row, i) => (
        <>
          <span
            className='text-dark text-hover-primary fw-bold fs-6'
            onClick={() =>
              setState({
                ...state,
                selectedRow: row,
                modalView: true,
                type: MODAL_TYPE_ENUM.SUBSCRIPTION,
              })
            }
          >
            ${row.amount}
          </span>
        </>
      ),
      sortable: true,
      sortCompare: (a, b) => a - b,
    },
    {
      name: 'Status',
      selector: (row, i) => row.status,
      format: (row, i) => {
        return state.changeStatusId === row._id ? (
          <span className='badge text-black p-4  badge-light'>Loading...</span>
        ) : row.status === 'Active' ? (
          <>
            <SelectOption
              options={[
                {value: 'Active', title: 'Active'},
                {value: 'InActive', title: 'Inactive'},
              ]}
              selected={row.status}
              onChange={async (e) => {
                try {
                  setState({...state, changeStatusId: row._id})
                  const value = e.target.value
                  const result = await updateSubscriptionStatus(auth?.token, {
                    subId: row.subscriptionId,
                    status: value,
                    donorId: row.donorId,
                  })
                  if (result.status === 200) {
                    window.location.reload()
                    setState({
                      ...state,
                      subscriptions: [
                        ...state.subscriptions.slice(0, i),
                        Object.assign({}, state.subscriptions[i], {
                          status: value,
                        }),
                        ...state.subscriptions.slice(i + 1),
                      ],
                      changeStatusId: null,
                    })
                  } else {
                    toast(result.data.message)
                    setState({...state, changeStatusId: null})
                  }
                } catch (error: any) {
                  setState({...state, changeStatusId: null})
                  toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
                }
              }}
            />
          </>
        ) : (
          <>
            <span className='badge capitalize bg-gray-100 px-6 py-3  text-danger'>
              {row.status.toLowerCase()}
            </span>
          </>
        )
      },
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row, i) => row.status,
      format: (row, i) => {
        return (
          <>
            <ActionButton
              viewCallback={() =>
                setState({
                  ...state,
                  selectedRow: row,
                  modalView: true,
                  type: MODAL_TYPE_ENUM.SUBSCRIPTION,
                })
              }
            />
          </>
        )
      },
      sortable: false,
    },
  ]

  const donationAmountRange = Array.from({length: 41}, (_, index) => {
    const rangeStart = index * 50
    const rangeEnd = (index + 1) * 50

    if (index === 40) {
      return `>${rangeStart}`
    }

    return `${rangeStart}-${rangeEnd}`
  })

  return (
    <>
      {values?.role === 'donor' ? (
        <DataTableWrapper
          filters={{
            dropdown: [
              {
                label: 'Charity Name',
                options: state.charities?.map((t) => ({
                  value: t['_id'],
                  label: t['name'],
                })),
                selected: values?.id,
              },
              {
                label: 'Status',
                options: ['Active', 'InActive', 'Paused'].map((t) => ({
                  value: t,
                  label: t.toLowerCase(),
                })),
              },
              {
                label: 'Subscription Plan',
                options: ['day', 'week', 'month', 'year'].map((t) => ({
                  value: t,
                  label: t,
                })),
              },
              {
                label: 'Donation Amount',
                options: donationAmountRange.map((t) => ({
                  value: t,
                  label: t,
                })),
              },
            ],
          }}
          filtersCallback={(filters) => {
            if (filters?.reset) {
              window.location.reload()
              removeCharityDonorStore()
              state.filters.id = ''
              setState((prev) => ({
                ...prev,
                filters: initialState.filters,
                currentPage: initialState.currentPage,
                search: initialState.search,
              }))
            } else {
              setState((prev) => ({
                ...prev,
                currentPage: initialState.currentPage,
                filters: {
                  id: filters['Donor Name']?.value || '',
                  charity_id: filters['Charity Name']?.value || '',
                  planType: filters['Subscription Plan']?.value || '',
                  status: filters['Status']?.value || '',
                  amount: filters['Donation Amount']?.value || '',
                },
              }))
            }
          }}
          loading={state.loading}
          columns={adminColumns}
          data={state.subscriptions}
          handleSearch={debouncedOnChange}
          currentPage={state.currentPage}
          totalPages={state.totalPages}
          handlePageChange={(page) => setState({...state, currentPage: page})}
          totalActiveSubscriptions={state.totalActiveSubscriptions}
          totalInactiveSubscriptions={state.totalInActiveSubscriptions}
          totalPausedSubscriptions={state.totalPausedSubscriptions}
          onPageSizeChange={(newValue: number) => setPageSize(newValue)}
          pageSize={pageSize}
        />
      ) : (
        <DataTableWrapper
          filters={{
            dropdown: [
              {
                label: 'Donor Name',
                options: state.donors?.map((t) => ({
                  value: t['_id'],
                  label: t['name'],
                })),
                selected: values?.id,
              },
              {
                label: 'Charity Name',
                options: state.charities?.map((t) => ({
                  value: t['_id'],
                  label: t['name'],
                })),
                selected: values?.id,
              },
              {
                label: 'Status',
                options: ['Active', 'InActive', 'Paused'].map((t) => ({
                  value: t,
                  label: t.toLowerCase(),
                })),
              },
              {
                label: 'Subscription Plan',
                options: ['day', 'week', 'month', 'year'].map((t) => ({
                  value: t,
                  label: t,
                })),
              },
              {
                label: 'Donation Amount',
                options: donationAmountRange.map((t) => ({
                  value: t,
                  label: t,
                })),
              },
            ],
          }}
          filtersCallback={(filters) => {
            if (filters?.reset) {
              window.location.reload()
              removeCharityDonorStore()
              state.filters.id = ''
              setState((prev) => ({
                ...prev,
                filters: initialState.filters,
                currentPage: initialState.currentPage,
                search: initialState.search,
              }))
            } else {
              setState((prev) => ({
                ...prev,
                currentPage: initialState.currentPage,
                filters: {
                  id: filters['Donor Name']?.value || '',
                  charity_id: filters['Charity Name']?.value || '',
                  planType: filters['Subscription Plan']?.value || '',
                  status: filters['Status']?.value || '',
                  amount: filters['Donation Amount']?.value || '',
                },
              }))
            }
          }}
          loading={state.loading}
          columns={adminColumns}
          data={state.subscriptions}
          handleSearch={debouncedOnChange}
          currentPage={state.currentPage}
          totalPages={state.totalPages}
          handlePageChange={(page) => setState({...state, currentPage: page})}
          totalActiveSubscriptions={state.totalActiveSubscriptions}
          totalInactiveSubscriptions={state.totalInActiveSubscriptions}
          totalPausedSubscriptions={state.totalPausedSubscriptions}
          onPageSizeChange={(newValue: number) => setPageSize(newValue)}
          pageSize={pageSize}
        />
      )}

      {state.modalView && (
        <CustomModal
          type={state.type}
          body={state.selectedRow}
          handleClose={() => setState({...state, modalView: false, selectedRow: null, type: null})}
        />
      )}
    </>
  )
}

const ViewSubscriptionsWrapper = () => (
  <>
    <PageTitle>Subscription</PageTitle>
    <ViewSubscriptions />
  </>
)

export {ViewSubscriptionsWrapper}

import React, {FC, useCallback, useEffect, useState} from 'react'
import {DataTableWrapper} from '../../DataTable'
import {SectionWrapper, SelectOption} from '../../Common'
import ActionButton from '../../Common/ActionButton'
import {
  POST_EVENT_TYPE,
  charityProfilePostEvent,
  charityProfileUserPostEvent,
  updatePostAndEventStatus,
} from '../../../app/modules/auth/core/_requests'
import {useAuth} from '../../../app/modules/auth'
import {toast} from 'react-toastify'

interface CharityPostsProps {
  handleView: any
  handleDelete: any
  charityId: any
  deleteLoading: any
  getDetails?: any
}
const CharityPosts: FC<CharityPostsProps> = ({
  handleView,
  handleDelete,
  charityId,
  deleteLoading,
  getDetails,
}) => {
  const {auth, currentUser} = useAuth()

  const [state, setState] = useState({posts: [], loading: true, error: null, changeStatusId: null})
  const [pageSize, setPageSize] = useState(10)

  const getPostsByCharity = useCallback(
    async (token, type, id) => {
      try {
        let result
        if (currentUser?.role === 'charity') {
          result = await charityProfileUserPostEvent(token, type, pageSize)
        } else {
          result = await charityProfilePostEvent(token, type, id, pageSize)
        }
        if (result.status === 200) {
          setState({...state, posts: result.data.data.postList, loading: false})
        }
      } catch (error: any) {
        setState({...state, posts: [], loading: false, error: error})
      }
    },
    [state, pageSize]
  )

  useEffect(() => {
    if (charityId || auth) {
      getPostsByCharity(auth?.token, POST_EVENT_TYPE.POST, charityId)
    }
  }, [deleteLoading, pageSize])

  const postColumns = [
    {
      name: 'Post Title',
      selector: (row, i) => row.title,
      width: '300px',
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center m-2'>
            <div className='symbol symbol-50px me-5' onClick={() => handleView(row)}>
              <img
                src={row?.media[0]?.thumbnailURL ? row.media[0]?.thumbnailURL : row.media[0]?.url}
                className='h-[70px] w-[70px] symbol-label align-self-center'
                alt=''
              />
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <span
                onClick={() => handleView(row)}
                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
              >
                {row.title}
              </span>
            </div>
          </div>
        )
      },
      sortable: true,
    },

    {
      name: 'Date & Time',
      selector: (row, i) => row.date,
      format: (row, i) => {
        return (
          <div
            className='text-dark text-hover-primary fw-bold fs-6'
            onClick={() => handleView(row)}
          >
            {new Date(row.date || '').toLocaleDateString('en-GB')}{' '}
            {(() => {
              const date = new Date(row.date || '')
              const hours = date.getHours()
              const minutes = date.getMinutes()
              const ampm = hours >= 12 ? 'PM' : 'AM'
              const formattedHours = hours % 12 === 0 ? 12 : hours % 12

              return `${formattedHours.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
              })}:${minutes.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
              })} ${ampm}`
            })()}
          </div>
        )
      },
      sortable: true,
    },

    {
      name: 'Status',
      selector: (row, i) => row.status,
      format: (row, i) => {
        return state.changeStatusId === row.id ? (
          <span className='badge text-black p-4 badge-light'>Loading...</span>
        ) : (
          <SelectOption
            options={[
              {value: 'Active', title: 'Active'},
              {value: 'InActive', title: 'Inactive'},
            ]}
            selected={row.status}
            onChange={async (e) => {
              try {
                setState({...state, changeStatusId: row.id})
                const value = e.target.value
                const result = await updatePostAndEventStatus(auth?.token, {
                  id: row.id,
                  status: value,
                  type: POST_EVENT_TYPE.POST,
                })
                if (result.status === 200) {
                  setState({
                    ...state,
                    posts: [
                      ...state.posts.slice(0, i),
                      Object.assign({}, state.posts[i], {status: value}),
                      ...state.posts.slice(i + 1),
                    ],
                    changeStatusId: null,
                  })
                } else {
                  toast(result.data.message)
                  setState({...state, changeStatusId: null})
                }
              } catch (error: any) {
                setState({...state, changeStatusId: null})
                toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
              }
            }}
          />
        )
      },
      sortable: true,
    },

    {
      name: 'Action',
      selector: (row, i) => row.status,
      format: (row, i) => {
        return (
          <>
            <ActionButton
              deleteCallback={() => handleDelete(row)}
              viewCallback={() => handleView(row)}
            />
          </>
        )
      },
      sortable: false,
    },
  ]

  return (
    <>
      <SectionWrapper title='Post Details' id='post_details'>
        <DataTableWrapper
          loading={state.loading}
          viewDetailsLink={{
            id: charityId,
            role: POST_EVENT_TYPE.POST,
            link: `/view-post/${charityId}`,
          }}
          getDetails={getDetails}
          columns={postColumns}
          data={state.posts}
        />
      </SectionWrapper>
    </>
  )
}

export default CharityPosts

import {Navigate, Route, Routes} from 'react-router-dom'
import {AuthLayout} from '../modules/auth/AuthLayout'
import {CharityProfileDetails} from '../pages/CharityProfile'
import AdminContactUs from '../pages/AdminContact'

const CharitySignupRoutes = () => {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        {/* Redirect to Dashboard after success login/registration */}
        <Route path='auth/*' element={<Navigate to='/charity-profile' />} />
        {/* Pages */}
        <Route path='charity-profile' element={<CharityProfileDetails />} />
        <Route path='contact' element={<AdminContactUs />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {CharitySignupRoutes}

import React, {useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {addStripeBank, addStripeBankAccount} from '../../../app/modules/auth/core/_requests'
import {ToastContainer, toast} from 'react-toastify'

const validationSchema = Yup.object({
  account_holder_name: Yup.string()
    .required('Account Holder Name is required')
    .matches(/^[A-Za-z\s]+$/, 'Only characters are allowed'),
  account_number: Yup.string()
    .required('Account Number is required')
    .matches(/^\d+$/, 'Account Number must contain only digits')
    .length(12, 'Account Number must be exactly 12 digits'),
  routing_number: Yup.string()
    .required('Routing Number is required')
    .matches(/^\d+$/, 'Routing Number must contain only digits')
    .length(9, 'Routing Number must be exactly 9 digits'),
  account_holder_type: Yup.string().required('Account Holder Type is required'),
})
const token = `Bearer ${process.env.REACT_APP_STRIPE_KEY}`

const AddBankAccountBody = ({handleClose, setLoader}) => {
  const [loading, setLoading] = useState(false)
  const handleAddStripeBankAccount = async (values) => {
    try {
      setLoading(true)
      setLoader(true)
      const data = {
        account_holder_name: '',
        account_number: '',
        routing_number: '',
        account_holder_type: '',
      }
      for (const key in values) {
        if (key in data) {
          data[key] = values[key]
        }
      }

      const response = await addStripeBank(token, data)
      if (response.status === 200) {
        try {
          const responses = await addStripeBankAccount(values, response.data.id)
          if (response && responses.status === 201) {
            toast.success(responses?.data?.message || 'Stripe bank account added successfully')
            handleClose()
            formik.resetForm()
          }
        } catch (error: any) {
          toast.error(error.response.data.message || 'Error adding Stripe bank account')
        }
      }
    } catch (error: any) {
      toast.error(error.response.data.message)
    } finally {
      setLoading(false)
      setLoader(false)
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }
  }

  const formik = useFormik({
    initialValues: {
      account_holder_name: '',
      account_number: '',
      routing_number: '',
      account_holder_type: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      handleAddStripeBankAccount(values)
      // Reset the form fields after submission
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='card-body p-0'>
        <div className='d-flex flex-column align-items-center'>
          {/* Account Number Field */}
          <div className='form-group'>
            <input
              type='text'
              id='account_number'
              name='account_number'
              autoComplete='off'
              className={`form-control form-control-lg m-4 ${
                formik.touched.account_number && formik.errors.account_number ? 'is-invalid' : ''
              }`}
              placeholder='Account Number'
              value={formik.values.account_number}
              onChange={(e) => {
                // Check if the input value matches the pattern before updating the formik values
                if (/^[0-9]{0,12}$/.test(e.target.value)) {
                  formik.handleChange(e)
                }
              }}
              onBlur={formik.handleBlur}
              maxLength={12}
              pattern='[0-9]{12}'
            />
            {formik.touched.account_number && formik.errors.account_number && (
              <div className='invalid-feedback' style={{marginLeft: '10px'}}>
                {formik.errors.account_number}
              </div>
            )}
          </div>
          {/* Bank Name Field */}
          <div className='form-group'>
            <input
              type='text'
              id='account_holder_name'
              name='account_holder_name'
              autoComplete='off'
              className={`form-control form-control-lg m-4 ${
                formik.touched.account_holder_name && formik.errors.account_holder_name
                  ? 'is-invalid'
                  : ''
              }`}
              placeholder='Account Holder Name'
              value={formik.values.account_holder_name.toUpperCase()}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.account_holder_name && formik.errors.account_holder_name && (
              <div className='invalid-feedback' style={{marginLeft: '10px'}}>
                {formik.errors.account_holder_name}
              </div>
            )}
          </div>

          {/* Routing Number Field */}
          <div className='form-group'>
            <input
              type='text'
              id='routing_number'
              name='routing_number'
              autoComplete='off'
              className={`form-control form-control-lg m-4 ${
                formik.touched.routing_number && formik.errors.routing_number ? 'is-invalid' : ''
              }`}
              placeholder='Routing Number'
              value={formik.values.routing_number}
              onChange={(e) => {
                // Limit the input to exactly 9 digits
                const inputValue = e.target.value
                if (/^\d{0,9}$/.test(inputValue)) {
                  formik.handleChange(e)
                }
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.routing_number && formik.errors.routing_number && (
              <div className='invalid-feedback' style={{marginLeft: '10px'}}>
                {formik.errors.routing_number}
              </div>
            )}
          </div>
          <div className='form-group' style={{width: '300px'}}>
            <div style={{position: 'relative', width: '300px'}}>
              <select
                id='account_holder_type'
                name='account_holder_type'
                autoComplete='off'
                className={`form-control form-control-lg m-4 ${
                  formik.touched.account_holder_type && formik.errors.account_holder_type
                    ? 'is-invalid'
                    : ''
                }`}
                value={formik.values.account_holder_type}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{
                  width: '300px',
                  color: 'var(--bs-gray-700',
                }}
              >
                <option value='' disabled>
                  Select Account Holder Type
                </option>
                <option value='Individual' style={{color: 'black'}}>
                  Individual
                </option>
                <option value='Company' style={{color: 'black'}}>
                  Company
                </option>
              </select>
              <div
                style={{
                  position: 'absolute',
                  top: '50%',
                  right: '22px',
                  transform: 'translateY(-50%)',
                  width: 0,
                  height: 0,
                  borderLeft: '6px solid transparent',
                  borderRight: '6px solid transparent',
                  borderTop: '6px solid #000',
                  pointerEvents: 'none',
                }}
              ></div>
            </div>
            {formik.touched.account_holder_type && formik.errors.account_holder_type && (
              <div className='invalid-feedback' style={{marginLeft: '10px'}}>
                {formik.errors.account_holder_type}
              </div>
            )}
          </div>

          <button
            type='submit'
            className='btn btn-primary font-weight-bold px-9 py-4 my-3'
            disabled={loading}
          >
            {loading ? (
              <div className='d-flex align-items-center'>
                <span>Submitting ...</span>
                <span
                  className='spinner-border spinner-border-sm mx-2'
                  role='status'
                  aria-hidden='true'
                ></span>
              </div>
            ) : (
              'Add Account'
            )}{' '}
          </button>
        </div>
      </div>
      <ToastContainer />
    </form>
  )
}

export default AddBankAccountBody

import React, {useState, useEffect, useRef} from 'react'
import Select from 'react-select'
import {KTIcon} from '../../_metronic/helpers'
import 'react-datepicker/dist/react-datepicker.css'

export default function ExportFilters({handlePdfDownload, handleCsvDownload, isLoading}) {
  const [state, setState] = useState({
    isOpen: false,
    selectedOption: null as {label: string; value: string} | null,
  })

  const filterRef = useRef<HTMLDivElement | null>(null)
  const btnFilterWrapper = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        filterRef.current &&
        !filterRef?.current?.contains(event.target) &&
        !btnFilterWrapper.current?.contains(event.target)
      ) {
        setState({...state, isOpen: false})
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [state])

  const options = [
    {label: 'PDF', value: 'pdf'},
    {label: 'CSV', value: 'csv'},
  ]

  const handleDownload = () => {
    setState({...state, isOpen: false})

    if (state.selectedOption) {
      const {value} = state.selectedOption
      if (value === 'pdf') {
        handlePdfDownload()
      } else if (value === 'csv') {
        handleCsvDownload()
      }
    }
  }

  return (
    <>
      <button
        type='button'
        className='btn btn-success me-3'
        ref={btnFilterWrapper}
        onClick={() => setState({...state, isOpen: !state.isOpen})}
        style={
          state.isOpen
            ? {
                position: 'relative',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }
            : {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }
        }
        disabled={isLoading}
      >
        {isLoading ? (
          <>
            {' '}
            <span>Exporting...</span>
            <span
              className='spinner-border spinner-border-sm mx-2'
              role='status'
              aria-hidden='true'
            ></span>
          </>
        ) : (
          <>
            <KTIcon iconName='cloud-download' className='fs-2' />
            Export
          </>
        )}
      </button>

      <div
        ref={filterRef}
        className={`menu menu-sub menu-sub-dropdown w-300px w-md-325px ${
          state.isOpen ? 'exportOptionsContainer' : ''
        }`}
        data-kt-menu={state.isOpen}
        // style={
        //   state.isOpen
        //     ? {
        //         display: 'flex',
        //         zIndex: 5,
        //         position: 'absolute',
        //         inset: ' 0px 0px auto auto',
        //         margin: '0px',
        //         top: 65,
        //         right: 170,
        //       }
        //     : {}
        // }
      >
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark'>Export Options</div>
        </div>
        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5'>
          <div className='mb-4'>
            <Select
              className='capitalize'
              isSearchable
              isClearable
              onChange={(selectedOption) => setState({...state, selectedOption})}
              options={options}
              placeholder='Select Export Option'
              value={state.selectedOption}
            />
          </div>

          <div className='d-flex justify-content-end'>
            <button type='button' className='btn btn-primary fw-bold px-6' onClick={handleDownload}>
              Download
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

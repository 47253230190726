import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {planTypeObj} from '../../../utils'
import {TSubscription} from './body'

export default function SubscriptionBody({subscription}: {subscription: TSubscription}) {
  return (
    <>
      <div className='card'>
        <div className='card-body p-0'>
          <div className='d-flex align-items-center flex-grow-1'>
            <div className='symbol symbol-45px me-5'>
              <img
                src={
                  subscription.profilePic
                    ? subscription.profilePic
                    : toAbsoluteUrl('/media/avatars/blank.png')
                }
                className='h-[75px] w-[75px] symbol-label align-self-end'
                alt=''
              />
            </div>
            <div className='d-flex flex-column'>
              <span className='text-gray-800 fs-6 fw-bold'>{subscription.charityName}</span>
              <span className='text-gray-400 fw-semibold'>
                {subscription?.charityCategory?.join(', ')}
              </span>
            </div>
          </div>
          <div className='my-4'>
            <div className='separator border-gray-200'></div>

            <div className='d-flex align-items-center flex-row-fluid flex-wrap my-4'>
              <div className='flex-grow-1 me-2 d-flex align-items-center'>
                <div className='symbol symbol-40px me-5'>
                  <span className='symbol-label bg-light-info'>
                    <KTIcon iconName='crown' className='fs-2x text-danger' />
                  </span>
                </div>
                <span className='text-gray-800 fs-6 fw-bold'>Subscription Plan</span>
              </div>
              <div className='badge badge-light fw-bold my-2 fs-6'>Recurring</div>
            </div>

            <div className='d-flex align-items-center flex-row-fluid flex-wrap my-4'>
              <div className='flex-grow-1 me-2 d-flex align-items-center'>
                <div className='symbol symbol-40px me-5'>
                  <span className='symbol-label bg-light-info'>
                    <KTIcon iconName='time' className='fs-2x text-danger' />
                  </span>
                </div>
                <span className='text-gray-800 fs-6 fw-bold'>Plan Type</span>
              </div>
              <div className='badge badge-light fw-bold my-2 fs-6'>
                {planTypeObj[`${subscription.planType}`]}
              </div>
            </div>

            <div className='d-flex align-items-center flex-row-fluid flex-wrap my-4'>
              <div className='flex-grow-1 me-2 d-flex align-items-center'>
                <div className='symbol symbol-40px me-5'>
                  <span className='symbol-label bg-light-info'>
                    <KTIcon iconName='check' className='fs-2x text-danger' />
                  </span>
                </div>
                <span className='text-gray-800 fs-6 fw-bold'>Status</span>
              </div>
              <div className=''>
                <span
                  className={`badge uppercase badge-light-secondary p-4 text-${
                    subscription.status === 'Active' ? 'success' : 'danger'
                  }`}
                >
                  {subscription.status}
                </span>
              </div>
            </div>

            <div className='d-flex align-items-center mb-3'>
              <div className='symbol symbol-45px w-40px me-5'>
                <span className='symbol-label bg-lighten'>
                  <KTIcon iconName='calendar' className='fs-2x text-danger' />
                </span>
              </div>
              <div className='d-flex align-items-center flex-wrap w-100'>
                <div className='mb-1 pe-3 flex-grow-1'>
                  <span className='text-gray-800 fs-6 fw-bold'>Subscribed Date</span>
                </div>
                <div className='d-flex align-items-center'>
                  <div className='badge badge-light fw-bold my-2 fs-6'>
                    {new Date(subscription?.subscriptionDate || '').toLocaleDateString('en-GB')}{' '}
                    {(() => {
                      const date = new Date(subscription?.subscriptionDate || '')
                      const hours = date.getHours()
                      const minutes = date.getMinutes()
                      const ampm = hours >= 12 ? 'PM' : 'AM'
                      const formattedHours = hours % 12 === 0 ? 12 : hours % 12

                      return `${formattedHours.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                      })}:${minutes.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                      })} ${ampm}`
                    })()}
                  </div>
                </div>
              </div>
            </div>
            {subscription.endDate && (
              <div className='d-flex align-items-center mb-3'>
                <div className='symbol symbol-45px w-40px me-5'>
                  <span className='symbol-label bg-lighten'>
                    <KTIcon iconName='calendar' className='fs-2x text-danger' />
                  </span>
                </div>
                <div className='d-flex align-items-center flex-wrap w-100'>
                  <div className='mb-1 pe-3 flex-grow-1'>
                    <span className='text-gray-800 fs-6 fw-bold'>End Date</span>
                  </div>
                  <div className='d-flex align-items-center'>
                    <div className='badge badge-light fw-bold my-2 fs-6'>
                      {new Date(subscription?.endDate || '').toLocaleDateString('en-GB')}{' '}
                      {(() => {
                        const date = new Date(subscription?.endDate || '')
                        const hours = date.getHours()
                        const minutes = date.getMinutes()
                        const ampm = hours >= 12 ? 'PM' : 'AM'
                        const formattedHours = hours % 12 === 0 ? 12 : hours % 12

                        return `${formattedHours.toLocaleString('en-US', {
                          minimumIntegerDigits: 2,
                        })}:${minutes.toLocaleString('en-US', {
                          minimumIntegerDigits: 2,
                        })} ${ampm}`
                      })()}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className='d-flex align-items-center flex-row-fluid flex-wrap my-4'>
              <div className='flex-grow-1 me-2 d-flex align-items-center'>
                <div className='symbol symbol-40px me-5'>
                  <span className='symbol-label bg-light-info'>
                    <KTIcon iconName='dollar' className='fs-2x text-danger' />
                  </span>
                </div>
                <span className='text-gray-800 fs-6 fw-bold'>Amount</span>
              </div>
              <div className='badge badge-light fw-bold my-2 fs-6'>${subscription.amount}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import React, {useCallback, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../_metronic/helpers'
import SectionWrapper from './SectionWrapper'
import {useAuth} from '../../app/modules/auth'
import {
  ACCOUNT_TYPE,
  ROLE_TYPE,
  addBankAccount,
  charityUserAccountDetails,
  userAccountDetails,
} from '../../app/modules/auth/core/_requests'
import AccountCardLoader from './AccountCardLoader'
import {CustomModal, MODAL_TYPE_ENUM} from '../Modal'
import AddBankAccountBody from '../Modal/Body/AddBankAccountBody'
import {ToastContainer, toast} from 'react-toastify'

export interface Account {
  card?: string
  bank_account?: string
  is_default?: boolean
  customerId?: string
  cardId?: string
  bankId?: string
  last4: string
  name: string
  status: string
}

export interface AccountState {
  accounts: Account[]
  loading: boolean
  error: null | any
  changeStatusId?: string | null
  index?: any
}

const initialState = {
  accounts: [],
  loading: false,
  error: null,
}

export default function BankAccounts({
  id,
  type,
  role,
  deleteHandler,
  deleteLoading,
  setDefaultLoading,
  setDefaultAccount,
}: {
  id: string
  type: ACCOUNT_TYPE
  role: ROLE_TYPE
  deleteLoading?: boolean
  setDefaultLoading?: 'process' | 'complete' | 'failed' | null
  deleteHandler: (account) => void
  setDefaultAccount?: (account) => void
}) {
  const {auth, currentUser} = useAuth()
  const [state, setState] = useState<AccountState>(initialState)
  const [AddBankAccountView, setAddBankAccountView] = useState(false)
  const [loading, setLoading] = useState(false)
  const [modelView, setModelView] = useState(false)
  const [title, setTitle] = useState('')
  const [stripeModelView, setStripeModelView] = useState(false)
  const [stripeMessage, setStripeMessage] = useState('')

  const [loadingAccount, setLoadingAccount] = useState<string | null>(null)
  const onClickHandler = async () => {
    try {
      setLoading(true)
      if (state.accounts.length === 0) {
        const result = await addBankAccount(currentUser?.token)
        if (result.status === 202) {
          window.open(result.data.data.url, '_blank')
        }
      } else {
        setAddBankAccountView(true)
      }
    } catch (error: any) {
      if (error.response.status === 409) {
        setAddBankAccountView(true)
      } else if (error.response.status === 600) {
        setTitle(error?.response?.data?.message)
        setModelView(true)
      } else {
        toast.error(error?.response?.data?.message || error?.message || 'Something went wrong!')
      }
    } finally {
      setLoading(false)
    }
  }

  const closeModal = () => {
    setAddBankAccountView(false)
  }

  const getAccountDetails = useCallback(
    async (token: any, type: ACCOUNT_TYPE, role: ROLE_TYPE, id: string) => {
      try {
        setState({...state, loading: true})
        let result
        if (currentUser?.role === 'charity') {
          result = await charityUserAccountDetails(token, type, role)
        } else {
          result = await userAccountDetails(token, type, role, id)
        }

        if (result.status === 266) {
          setStripeModelView(true)
          setState({...state, loading: false})
          setStripeMessage(result?.data?.message)
        }

        if (result.status === 200) {
          setState({...state, accounts: result.data.data, loading: false})
        }
      } catch (error: any) {
        setState({...state, loading: false})
        toast.error(error?.response?.data?.message || error?.message || 'Something went wrong!')
      } finally {
        setLoadingAccount(null)
      }
    },
    [state]
  )

  useEffect(() => {
    if (id || (auth && auth.token)) {
      getAccountDetails(auth?.token, type, role, id)
    }
  }, [id, auth, type, role, deleteLoading, setDefaultLoading])

  // useEffect(() => {
  //   if (setDefaultLoading === 'complete' && auth?.token) {
  //     getAccountDetails(auth?.token, type, role, id)
  //   }
  // }, [setDefaultLoading])

  const setDefaultAccountHandler = async (bankId: string | undefined) => {
    try {
      if (bankId) {
        setLoadingAccount(bankId)
        if (setDefaultAccount) {
          await setDefaultAccount(bankId)
        }
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Something went wrong!')
    }
  }

  if (state.loading && setDefaultLoading === null) {
    return (
      <SectionWrapper title='Account Details' id='account_details'>
        <AccountCardLoader />
      </SectionWrapper>
    )
  }

  const editButton = (
    <>
      {currentUser?.role === 'charity' && (
        <div className='d-flex p-3 flex-end'>
          <button
            type='button'
            className='btn btn-primary'
            onClick={(e) => {
              e.stopPropagation()
              onClickHandler()
            }}
            disabled={loading}
          >
            {loading ? (
              <div className='d-flex align-items-center'>
                <span>Please Wait...</span>
                <span
                  className='spinner-border spinner-border-sm mx-2'
                  role='status'
                  aria-hidden='true'
                ></span>
              </div>
            ) : (
              'Add Bank Account'
            )}{' '}
          </button>
        </div>
      )}
    </>
  )

  return (
    <>
      <SectionWrapper title='Account Details' id='account_details' buttonComponent={editButton}>
        {state.accounts.length > 0 ? (
          state.accounts.map((ac: Account, i) => (
            <div className='d-flex align-items-sm-center mb-7' key={i}>
              <div className='symbol symbol-70px symbol-3by4 me-4'>
                <img src={`${toAbsoluteUrl('/media/custom-img/bank.png')}`} alt='bank png' />
              </div>
              <div className='d-flex flex-row-fluid flex-wrap align-items-center'>
                <div className='flex-grow-1 me-2'>
                  <span className='text-gray-800 fw-bold fs-6'>{ac.name}</span>
                  <span className='text-muted fw-semibold d-block pt-1'>
                    ************{ac?.last4}
                  </span>
                </div>
                <button
                  className={`btn btn-sm btn-${ac?.is_default ? 'primary' : 'secondary'}`}
                  disabled={
                    ac?.is_default ||
                    // loadingAccount === ac.bankId ||
                    state.loading ||
                    setDefaultLoading === 'process'
                  }
                  onClick={() => setDefaultAccountHandler(ac.bankId)}
                >
                  {(loadingAccount === ac.bankId && setDefaultLoading === 'process') ||
                  (state.loading && loadingAccount === ac.bankId)
                    ? 'Loading...'
                    : ac?.is_default
                    ? 'DEFAULT'
                    : 'SET DEFAULT'}
                </button>

                <div className='mx-4'>
                  <span
                    className={`badge uppercase badge-light-secondary p-4 text-${
                      ac.status === 'new'
                        ? 'black'
                        : ac.status === 'verified'
                        ? 'success'
                        : 'danger'
                    }`}
                  >
                    {ac.status}
                  </span>
                </div>
                {!ac?.is_default && (
                  <button
                    onClick={() => deleteHandler(ac)}
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTIcon iconName='trash' className='fs-3' />
                  </button>
                )}
              </div>
            </div>
          ))
        ) : (
          <div className='text-center'>
            <h6 className='fw-bolder fs-2x text-gray-900'>Oops! Accounts not found</h6>
            <div className=''>
              <img
                src={toAbsoluteUrl('/media/auth/table-404-error.png')}
                className='mw-100 mh-300px theme-light-show'
                alt=''
              />
            </div>
          </div>
        )}
        <ToastContainer />
      </SectionWrapper>

      {AddBankAccountView && (
        <CustomModal
          handleClose={() => setAddBankAccountView(false)}
          body={
            <AddBankAccountBody handleClose={closeModal} setLoader={(val) => setLoading(val)} />
          }
          type={MODAL_TYPE_ENUM.ADD_BANK_ACCOUNT}
        />
      )}

      {stripeModelView && (
        <CustomModal
          handleClose={() => {
            setStripeModelView(false)
          }}
          body={{message: stripeMessage, charityId: id}}
          type={MODAL_TYPE_ENUM.ADMIN_STRIPE_ACCOUNT}
        />
      )}

      {/* {currentUser?.role === 'charity' && modelView && (
        <CustomModal
          type={MODAL_TYPE_ENUM.SIGNOUT_CHARITY}
          handleClose={() => setModelView(false)}
          body={{title: title}}
        />
      )} */}
    </>
  )
}

import React from 'react'
import {KTIcon} from '../../_metronic/helpers'

export default function ActionButton({
  viewCallback,
  editCallback = undefined,
  deleteCallback = undefined,
  approveCallback = undefined,
  rejectCallback = undefined,
  editDisabled,
  hideApproveRejectButtons,
}: {
  viewCallback?: () => void | undefined
  editCallback?: (() => void) | undefined
  deleteCallback?: (() => void) | undefined
  approveCallback?: (() => void) | undefined
  rejectCallback?: (() => void) | undefined
  editDisabled?: boolean
  hideApproveRejectButtons?: boolean
}) {
  return (
    <>
      <>
        {viewCallback && (
          <button
            onClick={viewCallback}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            data-toggle='tooltip'
            data-placement='top'
            title='View'
          >
            <KTIcon iconName='eye' className='fs-3' />
          </button>
        )}
        {editCallback && (
          <button
            onClick={editCallback}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            disabled={editDisabled}
            data-toggle='tooltip'
            data-placement='top'
            title='Edit'
          >
            <KTIcon iconName='pencil' className='fs-3' />
          </button>
        )}
        {deleteCallback && (
          <button
            onClick={deleteCallback}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
            data-toggle='tooltip'
            data-placement='top'
            title='View'
          >
            <KTIcon iconName='trash' className='fs-3' />
          </button>
        )}
        {hideApproveRejectButtons && (
          <>
            {approveCallback && (
              <button
                onClick={approveCallback}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                data-toggle='tooltip'
                data-placement='top'
                title='Approve'
              >
                <KTIcon iconName='check' className='fs-3 ' />
              </button>
            )}
            {rejectCallback && (
              <button
                onClick={rejectCallback}
                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                data-toggle='tooltip'
                data-placement='top'
                title='Reject'
              >
                <KTIcon iconName='cross' className='fs-3 ' />
              </button>
            )}
          </>
        )}
      </>
    </>
  )
}

import React, {useState, useEffect, useRef} from 'react'
import {KTIcon} from '../../_metronic/helpers'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select from 'react-select'

export default function Filters({filters, filtersCallback, isClearCalled}) {
  const [state, setState] = useState({
    startDate: '',
    endDate: '',
    isOpen: false,
    startRangeStartDate: '',
    endRangeStartDate: '',
    startRangeEndDate: '',
    endRangeEndDate: '',
  })
  const filterRef = useRef<HTMLDivElement | null>(null)
  const btnFilterWrapper = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        filterRef.current &&
        !filterRef?.current?.contains(event.target) &&
        !btnFilterWrapper.current?.contains(event.target)
      ) {
        setState({...state, isOpen: false})
      }
    }

    document.addEventListener('mousedown', handleOutsideClick)

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [state])

  const onChange = (dates) => {
    const [start, end] = dates
    setState({...state, endDate: end, startDate: start})
  }

  const onChangeStartDate = (dates) => {
    const [start, end] = dates
    setState({...state, endRangeStartDate: end, startRangeStartDate: start})
  }
  const onChangeEndDate = (dates) => {
    const [start, end] = dates
    setState({...state, endRangeEndDate: end, startRangeEndDate: start})
  }

  const clearFilters = () => {
    const newState: any = {}
    Object.keys(state).forEach((e) => {
      newState[e] = ''
    })
    setState(newState)
    filtersCallback({...newState, reset: true})
  }

  useEffect(() => {
    if (isClearCalled) {
      clearFilters()
    }
  }, [isClearCalled])

  return (
    <>
      <button
        type='button'
        className='btn btn-light-primary me-3'
        onClick={() => setState({...state, isOpen: !state.isOpen})}
        ref={btnFilterWrapper}
        style={
          state.isOpen
            ? {
                position: 'relative',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }
            : {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }
        }
      >
        <KTIcon iconName='filter' className='fs-2' />
        Filter
      </button>

      <div
        ref={filterRef}
        className={`menu menu-sub menu-sub-dropdown w-300px w-md-325px ${
          state.isOpen ? 'filterContainer' : ''
        }`}
        data-kt-menu={state.isOpen}
        // style={
        //   state.isOpen
        //     ? {
        //         display: 'flex',
        //         zIndex: 5,
        //         position: 'absolute',
        //         inset: ' 0px 0px auto auto',
        //         margin: '0px',
        //         top: 65,
        //         right: 50,
        //       }
        //     : {}
        // }
      >
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark'>Filter Options</div>
        </div>
        <div className='separator border-gray-200'></div>

        <div className='px-7 py-5'>
          {filters.dropdown &&
            filters.dropdown.map((ele, ind) => {
              return (
                <div className='mb-4' key={ind}>
                  <Select
                    className='capitalize'
                    isSearchable
                    noOptionsMessage={() => 'No options found'}
                    onChange={(e: any) => setState({...state, [ele.label]: e})}
                    options={ele.options.map((opt) => ({
                      ...opt,
                      label:
                        opt?.label?.length > 30 ? opt.label.substring(0, 30) + '...' : opt.label,
                    }))}
                    placeholder={ele.label}
                    value={
                      state[ele.label] || ele.selected
                        ? ele?.options?.find((opt) => opt.value === ele.selected)
                        : ''
                    }
                    // isClearable
                  />
                </div>
              )
            })}

          {filters.date && (
            <div className='mb-4'>
              <label className='form-label fs-6 fw-bold'>Start Date - End Date</label>
              <DatePicker
                className='form-control form-control-lg form-control-solid'
                onChange={onChange}
                startDate={state.startDate}
                endDate={state.endDate}
                selectsRange
                popperPlacement='auto'
                shouldCloseOnSelect={false}
                dateFormat='dd/MM/yy'
              />
            </div>
          )}
          {filters.startDate && (
            <div className='mb-4'>
              <label className='form-label fs-6 fw-bold'>Event Start Date</label>
              <DatePicker
                className='form-control form-control-lg form-control-solid'
                onChange={onChangeStartDate}
                startDate={state.startRangeStartDate}
                endDate={state.endRangeStartDate}
                selectsRange
                popperPlacement='auto'
                shouldCloseOnSelect={false}
                dateFormat='dd/MM/yy'
              />
            </div>
          )}
          {filters.endDate && (
            <div className='mb-4'>
              <label className='form-label fs-6 fw-bold'>Event End Date</label>
              <DatePicker
                className='form-control form-control-lg form-control-solid'
                onChange={onChangeEndDate}
                startDate={state.startRangeEndDate}
                endDate={state.endRangeEndDate}
                selectsRange
                popperPlacement='auto'
                shouldCloseOnSelect={false}
                dateFormat='dd/MM/yy'
              />
            </div>
          )}
          {filters.contactDate && (
            <div className='mb-4'>
              <label className='form-label fs-6 fw-bold'>Date</label>
              <DatePicker
                className='form-control form-control-lg form-control-solid'
                onChange={onChange}
                startDate={state.startDate}
                endDate={state.endDate}
                selectsRange
                popperPlacement='auto'
                shouldCloseOnSelect={false}
                dateFormat='dd/MM/yy'
              />
            </div>
          )}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              onClick={() => clearFilters()}
            >
              Reset
            </button>
            <button
              type='button'
              className='btn btn-primary fw-bold px-6'
              onClick={() => {
                setState({...state, isOpen: false})
                filtersCallback(state)
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

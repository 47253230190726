import {KTIcon} from '../../_metronic/helpers'

const ModalHeader = ({handleClose, loader}) => {
  return (
    <div className='modal-header p-4'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder mx-4 mb-0'>{''}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        style={{cursor: 'pointer'}}
      >
        {!loader && (
          <div onClick={handleClose}>
            {' '}
            <KTIcon iconName='cross' className='fs-1' />
          </div>
        )}
      </div>
      {/* end::Close */}
    </div>
  )
}

export {ModalHeader}

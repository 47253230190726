/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import noUiSlider, {target} from 'nouislider'
import {useLayout} from '../../core'
import {DefaultTitle} from './page-title/DefaultTitle'
import {AsideToolbar} from '../aside/AsideToolbar'
import {profilePic} from '../../../../app/modules/auth/core/_requests'
import {updateUserAuth, useAuth} from '../../../../app/modules/auth'
import {toast} from 'react-toastify'

interface TInitialState {
  userData: User
  loading: boolean
}
interface User {
  name: string
  profilePic: string
  email: string
}
const initialState = {
  userData: {
    name: '',
    profilePic: '',
    email: '',
  },
  loading: true,
}
const HeaderToolbar = () => {
  const {auth} = useAuth()
  const [state, setState] = useState<TInitialState>(initialState)
  const {classes} = useLayout()
  const [modelView, setModelView] = useState(false)
  const [title, setTitle] = useState('')

  useEffect(() => {
    const slider: target = document.querySelector('#kt_toolbar_slider') as target
    const rangeSliderValueElement: Element | null = document.querySelector(
      '#kt_toolbar_slider_value'
    )

    if (!slider) {
      return
    }

    slider.innerHTML = ''

    noUiSlider.create(slider, {
      start: [5],
      connect: [true, false],
      step: 1,
      range: {
        min: [1],
        max: [10],
      },
    })

    slider.noUiSlider?.on('update', function (values: any, handle: any) {
      if (!rangeSliderValueElement) {
        return
      }

      rangeSliderValueElement.innerHTML = parseInt(values[handle]).toFixed(1)
    })
  }, [])

  const getProfilePic = async (token) => {
    try {
      setState((prev) => ({...prev, loading: true}))
      const result = await profilePic(token)

      if (result.status === 200) {
        setState((prev) => ({
          ...prev,
          userData: {
            name: result.data?.data.name || '',
            profilePic: result.data?.data.profilePic || '',
            email: result.data?.data.email || '',
          },
          loading: false,
        }))
        updateUserAuth(result.data?.data.name || '', result.data?.data.profilePic || '')
      } else {
        toast(result.data.message)
      }
    } catch (error: any) {
      if (error.response.status === 600) {
        setTitle(error?.response?.data?.message)
        setModelView(true)
      } else {
        setState((prev) => ({...prev, loading: false, error}))
        toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
      }
    }
  }

  useEffect(() => {
    if (auth && auth.token) {
      getProfilePic(auth.token)
    }
  }, [])

  return (
    <div className='toolbar d-flex align-items-stretch'>
      <div
        className={`${classes.headerContainer.join(
          ' '
        )} py-6 py-lg-0 d-flex flex-lg-row align-items-lg-stretch justify-content-between`}
      >
        <DefaultTitle />
        <AsideToolbar userData={state.userData} />
      </div>
    </div>
  )
}

export {HeaderToolbar}

import {useEffect, useState} from 'react'
import {useAuth} from '../../../../app/modules/auth'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu} from '../../../partials'
import {toast} from 'react-toastify'
import {profilePic} from '../../../../app/modules/auth/core/_requests'
/* eslint-disable jsx-a11y/anchor-is-valid */

interface User {
  name: string
  profilePic: string
  email: string
}

interface MyComponentProps {
  userData: User
}
const AsideToolbar: React.FC<MyComponentProps> = ({userData}) => {
  const {currentUser, auth} = useAuth()

  return (
    <div className='aside-user d-flex align-items-center justify-content-center'>
      <div className='mx-2 d-flex align-items-center fw-bold fs-4'>
        {' '}
        <a
          href={currentUser?.role === 'charity' ? '/profile' : '/profile'}
          className='text-black text-hover-primary fs-6 fw-bolder'
          data-toggle='tooltip'
          data-placement='top'
          title={userData.name}
        >
          {/* {userData?.name} */}
          {userData.name?.length > 15 ? `${userData.name.substring(0, 15)}...` : userData.name}
        </a>
      </div>
      <div className='ms-6 mt-1'>
        <a
          href='#'
          className='btn btn-icon btn-sm btn-active-color-primary mt-n2 d-flex'
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-start'
          data-kt-menu-overflow='false'
        >
          <div className='symbol symbol-35px'>
            <img
              className='rounded-circle'
              src={userData?.profilePic || toAbsoluteUrl('/media/avatars/blank.png')}
              alt=''
            />
          </div>
          <div className=''>
            <KTIcon iconName='down' className='text-muted fs-4 ms-1' />
          </div>
        </a>

        <HeaderUserMenu userData={userData} />
      </div>
    </div>
  )
}

export {AsideToolbar}

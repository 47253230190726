import DataTable from 'react-data-table-component'
import {KTIcon} from '../../_metronic/helpers'
import CustomPagination from './CustomPagination'
import {Filters} from '../Common'
import TableLoader from './TableLoader'
import {
  setCharityDonorStore,
  setDonations,
  setEventPostStore,
  setName,
} from '../../app/modules/auth/core/StorageHelper'
import {useLocation, useNavigate} from 'react-router-dom'
import {POST_EVENT_TYPE} from '../../app/modules/auth/core/_requests'
import NotFound from './NotFound'
import {useEffect, useState} from 'react'
import {useAuth} from '../../app/modules/auth'
import {CustomModal, MODAL_TYPE_ENUM} from '../Modal'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import ExportFilters from './Export'
import Papa from 'papaparse'

const initialFilterState = {
  search: '',
  clearCalled: false,
}

const CustomDataTable = ({
  data,
  columns,
  handleSearch,
  viewDetailsLink,
  getDetails,
  filters,
  filtersCallback,
  loading,
  handlePageChange,
  currentPage,
  totalPages,
  onSelectedRowsChange,
  onDelete,
  selectableRows,
  totalActiveSubscriptions,
  totalPausedSubscriptions,
  totalInactiveSubscriptions,
  totalActiveCharity,
  totalInActiveCharity,
  totalDeletedCharity,
  totalActiveDonor,
  totalInActiveDonor,
  totalDeletedDonor,
  totalActivePost,
  totalInActivePost,
  totalActiveEvent,
  totalInActiveEvent,
  totalActiveContact,
  totalInActiveContact,
  totalPendingApproval,
  totalRejectedApproval,
  totalActiveRoundup,
  totalInActiveRoundup,
  totalDonation,
  onPageSizeChange,
  pageSize,
  pdfGenerate,
  totalPausedRoundup,
}: DataTableWrapperInter) => {
  const navigate = useNavigate()
  const [state, setState] = useState(initialFilterState)
  const [modelView, setModelView] = useState({
    addEditPost: false,
    addEditEvent: false,
    deleteConfirmation: false,
    addCharityCategory: false,
  })
  const {currentUser} = useAuth()
  const {pathname} = useLocation()
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [clearSelected, setClearSelected] = useState(false)

  const handleRowSelected = (state) => {
    setSelectedRows(state.selectedRows)
    onSelectedRowsChange && onSelectedRowsChange(state.selectedRows)
  }
  const handleDelete = () => {
    onDelete && onDelete(selectedRows)
    setSelectedRows([])
    setClearSelected(true)
  }

  useEffect(() => {
    setClearSelected(false)
  }, [clearSelected])

  const sortIcon = (
    <div
      className={`header-sortIcon d-flex flex-column ms-2 ${
        sortDirection === 'desc' ? 'rotate-180' : 'rotate-180'
      }`}
      style={{display: 'inline-block'}}
      onClick={() => setSortDirection(sortDirection === 'desc' ? 'desc' : 'asc')}
    >
      <KTIcon iconName='down' className='fs-2 fw-bold' />
    </div>
  )
  const checkBox: any = (props) => {
    return (
      <input
        className='form-check-input ms-2 cursor-pointer'
        type='checkbox'
        data-kt-check-target='#kt_table_users .form-check-input'
        {...props}
      />
    )
  }

  const customStyles = {
    headCells: {
      style: {
        color: '#000000 !important',
        fontSize: '15px !important',
        fontWeight: 'bold !important',
      },
    },
    header: {
      style: {
        '&:hover': {
          backgroundColor: 'inherit !important',
        },
      },
    },
    sortFocus: {
      outline: 'none !important',
      '&:focus': {
        boxShadow: 'none !important',
      },
    },
    sortIcon: {
      '&:before': {
        content: '""',
        display: 'inline-block',
        verticalAlign: 'middle',
        marginLeft: '5px',
        border: '5px solid transparent',
        borderTop: '5px solid #000',
        opacity: 1,
      },
    },
  }

  const [pdfLoading, setPdfLoading] = useState(false)
  const exportToPDF = async () => {
    setPdfLoading(true)

    // @ts-ignore
    const res = await pdfGenerate()

    if (res) {
      setPdfLoading(false)
    }

    const datas = res.data.data.transactionList
    const doc = new jsPDF({orientation: 'landscape'}) as any
    const adminHeaders = [
      'SR NO',
      'DATE',
      'TIME',
      'TRANSACTION ID',
      'CHARITY NAME',
      'DONOR NAME',
      'STATUS',
      'TYPE',
      'AMOUNT',
    ]
    const charityHeaders = [
      'SR NO',
      'DATE',
      'TIME',
      'TRANSACTION ID',
      'DONOR NAME',
      'STATUS',
      'TYPE',
      'AMOUNT',
    ]
    const headers = currentUser?.role === 'charity' ? charityHeaders : adminHeaders

    const data: any[] = []

    datas.forEach((item, index) => {
      const dateObj = new Date(item.date)
      const formattedDate = dateObj.toLocaleDateString()
      const formattedTime = dateObj
        .toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })
        .toUpperCase()

      const charityRowData: any[] = [
        index + 1,
        formattedDate,
        formattedTime,
        item?.transactionId ? item?.transactionId : '-',
        item?.donor ? item?.donor?.name?.toUpperCase() : '',
        item?.status ? item?.status?.toUpperCase() : '',
        item?.roundup == null
          ? item?.subscription === true
            ? 'RECURRING'
            : 'ONE-TIME'
          : 'ROUND-UP',
        item?.amount,
      ]

      const adminRowData: any[] = [
        index + 1,
        formattedDate,
        formattedTime,
        item?.transactionId ? item?.transactionId : '-',
        item?.charity ? item?.charity?.name?.toUpperCase() : '',
        item?.donor ? item?.donor?.name?.toUpperCase() : '',
        item?.status ? item?.status?.toUpperCase() : '',
        item?.roundup == null
          ? item?.subscription === true
            ? 'RECURRING'
            : 'ONE-TIME'
          : 'ROUND-UP',
        item?.amount,
      ]

      const rowData: any = currentUser?.role === 'charity' ? charityRowData : adminRowData
      data.push(rowData)
      setPdfLoading(false)
    })

    const style =
      currentUser?.role === 'charity'
        ? {
            0: {cellWidth: 19},
            1: {cellWidth: 25},
            2: {cellWidth: 25},
            3: {cellWidth: 70},
            4: {cellWidth: 50},
            5: {cellWidth: 35},
            6: {cellWidth: 30},
            7: {cellWidth: 25},
          }
        : {
            0: {cellWidth: 15},
            1: {cellWidth: 22},
            2: {cellWidth: 20},
            3: {cellWidth: 62},
            4: {cellWidth: 50},
            5: {cellWidth: 30},
            6: {cellWidth: 29},
            7: {cellWidth: 25},
            8: {cellWidth: 19},
          }

    const downloadDate = new Date().toLocaleDateString()
    const numTransactions = datas.length
    const filename = `transactions_${downloadDate}.pdf`
    const totalDonation = new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'USD',
    }).format(res?.data?.data?.totalDonation ?? 0)

    doc.setFontSize(26)
    doc.text('TRANSACTION REPORT', doc.internal.pageSize.width / 2, 10, {align: 'center'})

    doc.setFontSize(18)
    doc.text(`Download Date: ${downloadDate}`, 20, 20)
    doc.text(`Number of Transactions: ${numTransactions}`, 20, 30)
    doc.text(`Total Donation: ${totalDonation}`, 20, 40)

    doc.autoTable({
      head: [headers],
      body: data,
      startY: 50,
      columnStyles: style,
      didDrawPage: function (data) {
        if (data.pageNumber !== 1) {
          doc.setFontSize(12)
          doc.text('CHERRY PAY', doc.internal.pageSize.width - 20, 10, {align: 'right'})
        }
      },
    })
    doc.save(filename)
  }

  const exportToCSV = async () => {
    setPdfLoading(true)
    // @ts-ignore
    const res = await pdfGenerate()
    if (!res) {
      setPdfLoading(true)
    } else {
      setPdfLoading(false)
    }
    const datas = res.data.data.transactionList
    const adminHeaders = [
      'SR NO',
      'DATE',
      'TIME',
      'TRANSACTION ID',
      'CHARITY NAME',
      'DONOR NAME',
      'STATUS',
      'TYPE',
      'AMOUNT',
    ]
    const charityHeaders = [
      'SR NO',
      'DATE',
      'TIME',
      'TRANSACTION ID',
      'DONOR NAME',
      'STATUS',
      'TYPE',
      'AMOUNT',
    ]
    const headers = currentUser?.role === 'charity' ? charityHeaders : adminHeaders

    const data: any = []

    datas.forEach((item, index) => {
      const dateObj = new Date(item.date)
      const formattedDate = dateObj.toLocaleDateString()
      const formattedTime = dateObj
        .toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        })
        .toUpperCase()

      const charityRowData: any[] = [
        index + 1,
        formattedDate,
        formattedTime,
        item?.transactionId ? item?.transactionId : '-',
        item?.donor ? item?.donor?.name?.toUpperCase() : '',
        item?.status ? item?.status?.toUpperCase() : '',
        item?.roundup == null
          ? item?.subscription === true
            ? 'RECURRING'
            : 'ONE-TIME'
          : 'ROUND-UP',
        item?.amount,
      ]

      const adminRowData: any[] = [
        index + 1,
        formattedDate,
        formattedTime,
        item?.transactionId ? item?.transactionId : '-',
        item?.charity ? item?.charity?.name?.toUpperCase() : '',
        item?.donor ? item?.donor?.name?.toUpperCase() : '',
        item?.status ? item?.status?.toUpperCase() : '',
        item?.roundup == null
          ? item?.subscription === true
            ? 'RECURRING'
            : 'ONE-TIME'
          : 'ROUND-UP',
        item?.amount,
      ]

      const rowData: any = currentUser?.role === 'charity' ? charityRowData : adminRowData
      data.push(rowData)
    })

    const csv = Papa.unparse({
      fields: headers,
      data: data,
    })

    const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'})
    const downloadDate = new Date().toLocaleDateString()
    const filename = `transactions_${downloadDate}.csv`

    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = filename
    link.click()
    setPdfLoading(false)
  }

  return (
    <>
      <DataTable
        progressPending={loading}
        progressComponent={<TableLoader />}
        columns={columns}
        data={data}
        sortIcon={sortIcon}
        pagination
        paginationPerPage={pageSize}
        paginationServer
        fixedHeader
        fixedHeaderScrollHeight='610px'
        customStyles={customStyles}
        paginationComponent={() =>
          !viewDetailsLink ? (
            <>
              {' '}
              <CustomPagination
                currentPage={currentPage}
                onPageChange={handlePageChange}
                totalPages={totalPages}
                pageSize={pageSize}
                onPageSizeChange={onPageSizeChange}
              />
            </>
          ) : (
            <div className='text-center mt-4' style={{cursor: 'pointer'}}>
              <span
                className='text-primary'
                onClick={() => {
                  // if (
                  //   POST_EVENT_TYPE.EVENT === viewDetailsLink.role ||
                  //   POST_EVENT_TYPE.POST === viewDetailsLink.role
                  // ) {
                  //   setEventPostStore(viewDetailsLink)
                  // } else {
                  //   // setDonations(viewDetailsLink)
                  //   setCharityDonorStore(viewDetailsLink)
                  // }

                  const name =
                    getDetails && getDetails.length > 25
                      ? getDetails.substring(0, 25) + '..'
                      : getDetails
                  setName(name)
                  navigate(viewDetailsLink ? viewDetailsLink?.link : '')
                }}
              >
                View More
              </span>
            </div>
          )
        }
        paginationTotalRows={totalPages}
        onChangePage={(page) => (handlePageChange ? handlePageChange(page) : null)}
        highlightOnHover
        pointerOnHover
        subHeader={viewDetailsLink === undefined}
        selectableRows={selectableRows}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={clearSelected}
        selectableRowsComponent={checkBox}
        selectableRowDisabled={(rows: any) => rows.isDeleted}
        noDataComponent={
          <NotFound
            searchKeyword={state.search}
            filters={filters}
            data={data}
            clearSearch={() => {
              setState({...state, clearCalled: true})
              handleSearch && handleSearch('')
              setTimeout(() => setState(initialFilterState), 1000)
            }}
          />
        }
        subHeaderComponent={
          <>
            <div className='table-header d-flex justify-between mt-4 align-items-center'>
              {selectedRows.length > 0 && (
                <div
                  style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                  className='mx-3'
                >{`${selectedRows.length} selected`}</div>
              )}
              {selectedRows.length > 0 && (
                <button
                  type='button'
                  className='btn btn-danger font-weight-bold mx-3'
                  onClick={() => {
                    setModelView((prev) => ({...prev, deleteConfirmation: true}))
                    handleDelete()
                    setSelectedRows([])
                  }}
                >
                  Delete
                </button>
              )}
              {(pathname === '/subscriptions' ||
                pathname === '/view-subscriptions' ||
                pathname.includes('view-subscription')) && (
                <div className='d-flex justify-start align-items-center'>
                  <span
                    style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    className='text-dark fw-bold fs-6 mx-3'
                  >
                    Total Active :
                    <span className='text-red fw-bolder fs-6 mx-1'>{totalActiveSubscriptions ? totalActiveSubscriptions : "0"}</span>
                  </span>
                  <span
                    style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    className='text-dark fw-bold fs-6 mx-3'
                  >
                    Total Inactive :{' '}
                    <span className='text-red fw-bolder fs-6 mx-1'>
                      {totalInactiveSubscriptions ? totalInactiveSubscriptions : "0"}
                    </span>
                  </span>
                  {currentUser?.role === 'admin' && (
                    <span
                      style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                      className='text-dark fw-bold fs-6 mx-3'
                    >
                      Total Paused :
                      <span className='text-red fw-bolder fs-6 mx-1'>
                        {totalPausedSubscriptions}
                      </span>
                    </span>
                  )}
                </div>
              )}
              {pathname === '/charity-management' && (
                <div className='d-flex justify-start align-items-center'>
                  <span
                    style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    className='text-dark fw-bold fs-6 mx-3'
                  >
                    Total Active:
                    <span className='text-red fw-bolder fs-6 mx-1'>{totalActiveCharity ? totalActiveCharity : "0"}</span>
                  </span>
                  <span
                    style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    className='text-dark fw-bold fs-6 mx-3'
                  >
                    Total Inactive:{' '}
                    <span className='text-red fw-bolder fs-6 mx-1'>{totalInActiveCharity ? totalInActiveCharity : "0"}</span>
                  </span>
                  <span
                    style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    className='text-dark fw-bold fs-6 mx-3'
                  >
                    Total Deleted:{' '}
                    <span className='text-red fw-bolder fs-6 mx-1'>{totalDeletedCharity}</span>
                  </span>
                  <span
                    style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    className='text-dark fw-bold fs-6 mx-3'
                  >
                    Total Pending Approvals:{' '}
                    <span className='text-red fw-bolder fs-6 mx-1'>{totalPendingApproval}</span>
                  </span>
                  <span
                    style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    className='text-dark fw-bold fs-6 mx-3'
                  >
                    Total Rejected Approvals:{' '}
                    <span className='text-red fw-bolder fs-6 mx-1'>{totalRejectedApproval}</span>
                  </span>
                </div>
              )}
              {(pathname === '/posts' || pathname.includes('view-post')) && (
                <div className='d-flex justify-start align-items-center'>
                  <span
                    style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    className='text-dark fw-bold fs-6 mx-3'
                  >
                    Total Active:
                    <span className='text-red fw-bolder fs-6 mx-1'>{totalActivePost ? totalActivePost : "0"}</span>
                  </span>
                  <span
                    style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    className='text-dark fw-bold fs-6 mx-3'
                  >
                    Total Inactive:{' '}
                    <span className='text-red fw-bolder fs-6 mx-1'>{totalInActivePost ? totalInActivePost : "0"}</span>
                  </span>
                </div>
              )}
              {(pathname === '/donations-management' ||
                pathname === '/view-donations' ||
                pathname.includes('view-charity-donation') ||
                pathname.includes('view-donor-donation')) && (
                <div className='d-flex justify-start align-items-center'>
                  <span
                    style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    className='text-dark fw-bold fs-6 mx-3'
                  >
                    Total Donation:
                    <span className='text-red fw-bolder fs-6 mx-1'>
                      {new Intl.NumberFormat('en-IN', {
                        style: 'currency',
                        currency: 'USD',
                      }).format(totalDonation ?? 0)}
                    </span>
                  </span>
                </div>
              )}
              {(pathname === '/roundup' ||
                pathname === '/view-roundup' ||
                pathname.includes('view-charity-roundup') ||
                pathname.includes('view-donor-roundup')) && (
                <div className='d-flex justify-start align-items-center'>
                  <span
                    style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    className='text-dark fw-bold fs-6 mx-3'
                  >
                    Total Active :
                    <span className='text-red fw-bolder fs-6 mx-1'>{totalActiveRoundup ? totalActiveRoundup : "0"}</span>
                  </span>
                  <span
                    style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    className='text-dark fw-bold fs-6 mx-3'
                  >
                    Total Inactive :{' '}
                    <span className='text-red fw-bolder fs-6 mx-1'>{totalInActiveRoundup ? totalInActiveRoundup : "0"}</span>
                  </span>

                  <span
                    style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    className='text-dark fw-bold fs-6 mx-3'
                  >
                    Total Paused :{' '}
                    <span className='text-red fw-bolder fs-6 mx-1'>{totalPausedRoundup}</span>
                  </span>
                </div>
              )}
              {pathname === '/donor-management' && (
                <div className='d-flex justify-start align-items-center'>
                  <span
                    style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    className='text-dark fw-bold fs-6 mx-3'
                  >
                    Total Active :
                    <span className='text-red fw-bolder fs-6 mx-1'>{totalActiveDonor ? totalActiveDonor : "0"}</span>
                  </span>
                  <span
                    style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    className='text-dark fw-bold fs-6 mx-3'
                  >
                    Total Inactive :{' '}
                    <span className='text-red fw-bolder fs-6 mx-1'>{totalInActiveDonor ? totalInActiveDonor : "0"}</span>
                  </span>
                  <span
                    style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    className='text-dark fw-bold fs-6 mx-3'
                  >
                    Total Deleted :{' '}
                    <span className='text-red fw-bolder fs-6 mx-1'>{totalDeletedDonor}</span>
                  </span>
                </div>
              )}
              {(pathname === '/events' || pathname.includes('view-event')) && (
                <div className='d-flex justify-start align-items-center'>
                  <span
                    style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    className='text-dark fw-bold fs-6 mx-3'
                  >
                    Total Active:
                    <span className='text-red fw-bolder fs-6 mx-1'>{totalActiveEvent ? totalActiveEvent : "0"}</span>
                  </span>
                  <span
                    style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    className='text-dark fw-bold fs-6 mx-3'
                  >
                    Total Inactive:{' '}
                    <span className='text-red fw-bolder fs-6 mx-1'>{totalInActiveEvent ? totalInActiveEvent : "0"}</span>
                  </span>
                </div>
              )}
              {pathname === '/contact' && currentUser?.role === 'admin' && (
                <div className='d-flex justify-start align-items-center'>
                  <span
                    style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    className='text-dark fw-bold fs-6 mx-3'
                  >
                    Total Viewed Queries:
                    <span className='text-red fw-bolder fs-6 mx-1'>{totalActiveContact}</span>
                  </span>
                  <span
                    style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}
                    className='text-dark fw-bold fs-6 mx-3'
                  >
                    Total Pending Queries:
                    <span className='text-red fw-bolder fs-6 mx-1'>{totalInActiveContact}</span>
                  </span>
                </div>
              )}
              <div style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                {currentUser?.role === 'charity' && pathname === '/posts' && (
                  <button
                    type='submit'
                    className='btn btn-primary font-weight-bold mx-3'
                    onClick={() => setModelView((prev) => ({...prev, addEditPost: true}))}
                  >
                    Add Post
                  </button>
                )}{' '}
              </div>
              <div style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                {currentUser?.role === 'charity' && pathname === '/events' && (
                  <button
                    type='submit'
                    className='btn btn-primary font-weight-bold mx-3'
                    onClick={() => setModelView((prev) => ({...prev, addEditEvent: true}))}
                  >
                    Add Event
                  </button>
                )}{' '}
              </div>
              {pathname === '/charity-category-management' && (
                <div style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>
                  <button
                    type='submit'
                    className='btn btn-primary font-weight-bold mx-3'
                    onClick={() => setModelView((prev) => ({...prev, addCharityCategory: true}))}
                  >
                    Add Category
                  </button>
                </div>
              )}{' '}
              {(pathname === '/donations-management' ||
                pathname === '/view-donations' ||
                pathname.includes('view-charity-donation') ||
                pathname.includes('view-donor-donation')) && (
                <ExportFilters
                  handlePdfDownload={exportToPDF}
                  handleCsvDownload={exportToCSV}
                  isLoading={pdfLoading}
                />
              )}
              {filters && filtersCallback && (
                <div>
                  <Filters
                    filtersCallback={filtersCallback}
                    isClearCalled={state.clearCalled}
                    filters={filters}
                  />
                </div>
              )}
              {handleSearch && (
                <div className='d-flex align-items-center position-relative my-1'>
                  <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
                  <input
                    type='text'
                    className='form-control form-control-solid w-250px ps-14'
                    placeholder='Search'
                    value={state.search}
                    onChange={(e) => {
                      setState({...state, search: e.target.value})
                      handleSearch(e.target.value)
                    }}
                  />
                </div>
              )}
            </div>
            {modelView.addEditPost && pathname === '/posts' && (
              <CustomModal
                handleClose={() => setModelView((prev) => ({...prev, addEditPost: false}))}
                body={[]}
                type={MODAL_TYPE_ENUM.ADD_EDIT_POST}
              />
            )}
            {modelView.addEditEvent && pathname === '/events' && (
              <CustomModal
                handleClose={() => setModelView((prev) => ({...prev, addEditEvent: false}))}
                body={[]}
                type={MODAL_TYPE_ENUM.ADD_EDIT_EVENT}
              />
            )}
            {modelView.addCharityCategory && pathname === '/charity-category-management' && (
              <CustomModal
                handleClose={() => setModelView((prev) => ({...prev, addCharityCategory: false}))}
                body={[]}
                type={MODAL_TYPE_ENUM.ADD_CHARITY_CATEGORY}
              />
            )}
          </>
        }
      />
    </>
  )
}
interface DataTableWrapperInter {
  data: any
  columns: any[]
  handleSearch?: (value) => void | undefined
  viewDetailsLink?:
    | {name?: string; id: string; link: string; role: string; userRole?: string}
    | undefined

  getDetails?: string
  filters?: any | undefined
  filtersCallback?: (state) => void | undefined
  loading: boolean
  handlePageChange?: (pageNumber) => void
  currentPage?: number
  totalPages?: number
  onSelectedRowsChange?: (selectedRows: any[]) => void
  onDelete?: (selectedRows: any[]) => void
  selectableRows?: boolean
  totalActiveSubscriptions?: number
  totalInactiveSubscriptions?: number
  totalPausedSubscriptions?: number
  totalActiveCharity?: number
  totalInActiveCharity?: number
  totalDeletedCharity?: number
  totalActiveDonor?: number
  totalInActiveDonor?: number
  totalDeletedDonor?: number
  totalActivePost?: number
  totalInActivePost?: number
  totalActiveEvent?: number
  totalInActiveEvent?: number
  totalActiveContact?: number
  totalInActiveContact?: number
  totalPendingApproval?: number
  totalRejectedApproval?: number
  totalActiveRoundup?: number
  totalInActiveRoundup?: number
  totalPausedRoundup?: number
  totalDonation?: number
  onPageSizeChange?: (newValue: number) => void
  pageSize?: number
  pdfGenerate?: () => Promise<any>
}
const DataTableWrapper = ({
  data,
  columns,
  handleSearch = undefined,
  viewDetailsLink,
  filters = undefined,
  filtersCallback = undefined,
  loading,
  handlePageChange,
  currentPage,
  totalPages,
  onSelectedRowsChange,
  onDelete,
  selectableRows,
  totalActiveSubscriptions,
  totalInactiveSubscriptions,
  totalPausedSubscriptions,
  totalActiveCharity,
  totalInActiveCharity,
  totalDeletedCharity,
  totalActiveDonor,
  totalInActiveDonor,
  totalDeletedDonor,
  totalActivePost,
  totalInActivePost,
  totalActiveEvent,
  totalInActiveEvent,
  totalActiveContact,
  totalInActiveContact,
  totalPendingApproval,
  totalRejectedApproval,
  totalActiveRoundup,
  totalInActiveRoundup,
  totalPausedRoundup,
  totalDonation,
  onPageSizeChange,
  pageSize,
  pdfGenerate,
  getDetails,
}: DataTableWrapperInter) => {
  return (
    <>
      <CustomDataTable
        data={data}
        viewDetailsLink={viewDetailsLink}
        getDetails={getDetails}
        columns={columns}
        handleSearch={handleSearch}
        filters={filters}
        loading={loading}
        filtersCallback={filtersCallback}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        totalPages={totalPages}
        onSelectedRowsChange={onSelectedRowsChange}
        onDelete={onDelete}
        selectableRows={selectableRows}
        totalActiveSubscriptions={totalActiveSubscriptions}
        totalInactiveSubscriptions={totalInactiveSubscriptions}
        totalPausedSubscriptions={totalPausedSubscriptions}
        totalActiveCharity={totalActiveCharity}
        totalInActiveCharity={totalInActiveCharity}
        totalActiveDonor={totalActiveDonor}
        totalInActiveDonor={totalInActiveDonor}
        totalActivePost={totalActivePost}
        totalInActivePost={totalInActivePost}
        totalActiveEvent={totalActiveEvent}
        totalInActiveEvent={totalInActiveEvent}
        totalActiveContact={totalActiveContact}
        totalInActiveContact={totalInActiveContact}
        totalDeletedCharity={totalDeletedCharity}
        totalDeletedDonor={totalDeletedDonor}
        totalPendingApproval={totalPendingApproval}
        totalRejectedApproval={totalRejectedApproval}
        totalActiveRoundup={totalActiveRoundup}
        totalInActiveRoundup={totalInActiveRoundup}
        totalPausedRoundup={totalPausedRoundup}
        totalDonation={totalDonation}
        onPageSizeChange={onPageSizeChange}
        pageSize={pageSize}
        pdfGenerate={pdfGenerate}
      />
    </>
  )
}

export {DataTableWrapper}

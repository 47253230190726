import {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'

type YourStateType = {
  currentPage: any
  filters: {status: string}
}

type UseSessionStateHook<S> = [S, any]

export const useSessionState = function <S extends YourStateType>(
  initialValue: S,
  key: string
): UseSessionStateHook<S> {
  const {pathname} = useLocation()
  const storedRoutesString = sessionStorage.getItem('storedRoutes')

  let storedRoutes = storedRoutesString ? JSON.parse(storedRoutesString) : []
  const initialValueJSON = sessionStorage.getItem(key)
  let currentPage = initialValue.currentPage
  let filters = initialValue.filters
  if (
    storedRoutes[1] === pathname &&
    storedRoutes[2]?.includes(storedRoutes[1]) &&
    storedRoutes[2] !== storedRoutes[1] &&
    initialValueJSON
  ) {
    currentPage = JSON.parse(initialValueJSON).currentPage
    filters = JSON.parse(initialValueJSON).filters
  }

  const [value, setValue] = useState<S>({...initialValue, currentPage, filters})

  useEffect(() => {
    sessionStorage.setItem(
      key,
      JSON.stringify({currentPage: value?.currentPage, filters: value?.filters})
    )
  }, [value])

  const updateValue = (newValue: S) => {
    setValue(newValue)
  }

  return [value, updateValue]
}

import React, {FC, useCallback, useEffect, useState} from 'react'
import {DataTableWrapper} from '../../DataTable'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {SectionWrapper} from '../../Common'
import ActionButton from '../../Common/ActionButton'
import {
  ACCOUNT_TYPE,
  ROLE_TYPE,
  charityUserTransactionDetails,
  userTransactionDetails,
} from '../../../app/modules/auth/core/_requests'
import {useAuth} from '../../../app/modules/auth'

interface CharityDonationProps {
  handleView: any
  charityId: any
  getDetails?: any
}
const CharityDonation: FC<CharityDonationProps> = ({handleView, charityId, getDetails}) => {
  const {auth, currentUser} = useAuth()

  const donationColumns = [
    {
      name: 'Donor Name',
      selector: (row, i) => row.donor.name,
      width: '200px',
      format: (row, i) => {
        return (
          <>
            <div className='d-flex align-items-center m-2'>
              <div className='symbol symbol-50px me-5' onClick={() => handleView(row)}>
                <img
                  src={
                    row.donor.profilePic
                      ? row.donor.profilePic
                      : toAbsoluteUrl('/media/avatars/blank.png')
                  }
                  className='h-[75px] w-[75px] symbol-label align-self-end'
                  alt=''
                />
              </div>
              <div className='d-flex justify-content-start flex-column'>
                <span
                  onClick={() => handleView(row)}
                  className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                >
                  {row.donor.name}
                </span>
              </div>
            </div>
          </>
        )
      },
      sortable: true,
    },
    {
      name: 'Date & Time',
      selector: (row, i) => row.date,
      format: (row, i) => {
        return (
          <>
            <div
              className='text-dark text-hover-primary fw-bold fs-6'
              onClick={() => handleView(row)}
            >
              {new Date(row.date || '').toLocaleDateString('en-GB')}{' '}
              {(() => {
                const date = new Date(row.date || '')
                const hours = date.getHours()
                const minutes = date.getMinutes()
                const ampm = hours >= 12 ? 'PM' : 'AM'
                const formattedHours = hours % 12 === 0 ? 12 : hours % 12

                return `${formattedHours.toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                })}:${minutes.toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                })} ${ampm}`
              })()}{' '}
            </div>
          </>
        )
      },
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row, i) => parseFloat(row.amount),
      format: (row, i) => {
        return (
          <>
            <div
              className='text-dark text-hover-primary fw-bold fs-6'
              onClick={() => handleView(row)}
            >
              ${row.amount}
            </div>
          </>
        )
      },
      sortable: true,
      sortCompare: (a, b) => a - b,
    },
    {
      name: 'Payment method',
      selector: (row, i) => row.payment_method,
      format: (row, i) => {
        return (
          <>
            <div
              className='text-dark text-hover-primary fw-bold fs-6'
              onClick={() => handleView(row)}
            >
              {row.payment_method === ACCOUNT_TYPE.BANK ? 'BANK' : 'CARD'}
            </div>
          </>
        )
      },
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row, i) => row.status,
      format: (row, i) => {
        return (
          <span
            className={`badge uppercase text-hover-primary badge-light-${
              row.status.includes('succeeded') ? 'success' : 'danger'
            }`}
            onClick={() => handleView(row)}
          >
            {row.status}
          </span>
        )
      },
      sortable: true,
    },

    {
      name: 'Action',
      selector: (row, i) => row.status,
      format: (row, i) => {
        return (
          <>
            <ActionButton viewCallback={() => handleView(row)} />
          </>
        )
      },
      sortable: false,
    },
  ]

  const [state, setState] = useState({transactions: [], loading: true, error: null})
  const [pageSize, setPageSize] = useState(10)

  const getTransactionsByCharity = useCallback(
    async (token, role, id) => {
      try {
        let result
        if (currentUser?.role === 'charity') {
          result = await charityUserTransactionDetails(token, role, pageSize)
        } else {
          result = await userTransactionDetails(token, role, id, pageSize)
        }
        if (result.status === 200) {
          setState({...state, transactions: result.data.data.transactionList, loading: false})
        }
      } catch (error: any) {
        setState({...state, transactions: [], loading: false, error: error})
      }
    },
    [state, pageSize]
  )

  useEffect(() => {
    if (charityId || auth) {
      getTransactionsByCharity(auth?.token, ROLE_TYPE.CHARITY, charityId)
    }
  }, [])

  return (
    <>
      <SectionWrapper title='Donation Details' id='donation_details'>
        <DataTableWrapper
          viewDetailsLink={{
            id: charityId,
            role: ROLE_TYPE.CHARITY,
            link: `/view-charity-donation/${charityId}`,
          }}
          getDetails={getDetails}
          columns={donationColumns}
          data={state.transactions}
          loading={state.loading}
        />
      </SectionWrapper>
    </>
  )
}
export default CharityDonation

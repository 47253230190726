import React, {useEffect, useState} from 'react'
import {useFormik, FormikProvider} from 'formik'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Category, SectionWrapper} from '../../Common'
import {updateUserAuth, useAuth} from '../../../app/modules/auth'
import {LoadScript, Autocomplete} from '@react-google-maps/api'
import {
  ROLE_TYPE,
  charityUserProfile,
  checkEmailAvailable,
  updatePassword,
  updateCharityUserDetails,
  updateUserDetails,
} from '../../../app/modules/auth/core/_requests'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import 'react-phone-input-2/lib/bootstrap.css'

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || ''

const initialValues = {
  profilePic: '',
  name: '',
  email: '',
  phone: '',
  charityCategory: [],
  charityLocation: {
    coordinates: {
      lat: '',
      long: '',
    },
    address: {
      lane: '',
      state: '',
      city: '',
      country: '',
      pinCode: '',
    },
  },
  description: '',
  website: '',
  facebook: '',
  instagram: '',
  twitter: '',
  currentPassword: '',
  password: '',
  confirmPassword: '',
}

export interface CharityProfileDetailsType {
  profilePic: any
  name: string
  email: string
  description: string
  phone: string
  charityCategory: string[]
  charityLocation: {
    coordinates: {
      lat: string
      long: string
    }
    address: {
      lane: string
      state: string
      city: string
      country: string
      pinCode: string
    } | null
  }
  website: string
  facebook: string
  instagram: string
  twitter: string
  password: string
  confirmPassword: string
  currentPassword: string
}

const websiteUrlReg = /^(ftp|http|https):\/\/[^ "]+$/
const facebookUrlReg = /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9_.-]+\/?$/

const profileDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Title is required'),
  email: Yup.string().email(),
  website: Yup.string().matches(websiteUrlReg, 'Enter a valid URL'),
  // phone: Yup.number().positive().required('Phone number is required'),
  phone: Yup.number()
    .positive()
    .integer()
    .required('Phone number is required')
    .typeError('Phone number must be a number')
    .test('len', 'Phone number must be exactly 10 digits', (value) => String(value).length === 10),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(16, 'Password must be at most 16 characters')
    .matches(/^\S*$/, 'Password cannot contain spaces'),
  confirmPassword: Yup.mixed()
    .test('match', 'Password must match', function (value) {
      return value === this.parent.password || value === null
    })
    .nullable(),
  facebook: Yup.string().matches(facebookUrlReg, 'Enter a valid Facebook profile URL'),
  instagram: Yup.string().matches(
    /^(https?:\/\/)?(www\.)?instagram\.com\/(?:[^\/?]+\/)?(?:@)?([a-zA-Z0-9_.-]+)(?:\/?|\/(?:(?:p|tv|reel)\/[^\/?]+\/?)?)$/,
    'Enter a valid Instagram profile URL'
  ),
  twitter: Yup.string().matches(
    /^(https?:\/\/)?(www\.)?twitter\.com\/[a-zA-Z0-9_]+\/?$/,
    'Enter a valid Twitter profile URL'
  ),
})

export default function CharityProfileDetails({
  isView = true,
  charity,
  setCharity,
  charityId,
  editButton,
  isDeleted,
}) {
  const {auth, currentUser} = useAuth()
  let file
  const [image, setImage] = useState('')
  const [loading, setLoading] = useState(false)
  const [emailCheck, setEmailCheck] = useState('')
  const [showFullBio, setShowFullBio] = useState(false)
  const wordLimit = 150
  const handleBioChange = (event) => {
    const wordCount = event.target.value.split(/\s+/).filter(Boolean).length
    setShowFullBio(wordCount <= wordLimit)
    formik.handleChange(event)
  }

  const [showPassword, setShowPassword] = useState({
    password: false,
    newPassword: false,
    confirmPassword: false,
  })

  const [autoComplete, setAutoComplete] = useState<any>(null)

  const [charityLocations, setCharityLocation] = useState<any>({
    coordinates: {
      lat: '',
      long: '',
    },
    address: {
      lane: '',
      city: '',
      state: '',
      pinCode: '',
      country: '',
    },
    place_id: '',
  })
  const [inputLocation, setInputLocation] = useState('')
  const [addressError, setAddressError] = useState('')

  const formik = useFormik<CharityProfileDetailsType>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      try {
        if (values.currentPassword) {
          setLoading(true)
          const updatePswResult = await updatePassword(
            values.currentPassword,
            values.confirmPassword,
            auth?.token
          )
          if (updatePswResult.status === 200) {
            toast.success(updatePswResult.data.message)
            setLoading(false)
          }
        }
        if (!emailCheck) {
          const formData = new FormData()
          formData.append('name', values.name || '')
          formData.append('email', values.email || '')
          formData.append('phone', values.phone || '')
          formData.append('description', values.description || '')
          formData.append('website', values.website || '')
          formData.append('instagram', values.instagram || '')
          formData.append('facebook', values.facebook || '')
          formData.append('twitter', values.twitter || '')
          formData.append('charityLocation', JSON.stringify(charityLocations) || '')
          formData.append('profilePic', image && file ? file : values.profilePic)
          formData.append('charityCategory', values.charityCategory?.toString() || '')

          if (values.password) formData.append('password', values.password)

          setLoading(true)

          const result =
            currentUser?.role === 'charity'
              ? await updateCharityUserDetails(auth?.token, ROLE_TYPE.CHARITY, formData)
              : await updateUserDetails(auth?.token, charityId, ROLE_TYPE.CHARITY, formData)

          if (result.status === 200) {
            toast.success(result.data.message || 'Profile updated successfully')
            if (currentUser?.role === 'charity') {
              // Update the Profile name
              const updatedProfileName = await charityUserProfile(auth?.token, currentUser?.role)
              const userData = updatedProfileName.data.data.title
              const userImg = updatedProfileName.data.data.profilePic
              updateUserAuth(userData, userImg)
              window.location.reload()
              window.location.href = '/profile'
            } else {
              setTimeout(() => {
                window.location.href = '/charity-management'
              }, 2000)
            }
            setLoading(false)
          } else {
            toast(result?.data?.data?.message || 'Something went wrong!')
            setLoading(false)
          }
        }
      } catch (error: any) {
        setLoading(false)
        toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
      }
    },
  })

  useEffect(() => {
    if (charity) {
      const {
        profilePic,
        name,
        email,
        phone,
        charityCategory,
        charityLocation,
        description,
        website,
        facebook,
        instagram,
        twitter,
      } = charity

      formik.setFieldValue('profilePic', profilePic || '')
      formik.setFieldValue('name', name || '')
      formik.setFieldValue('email', email || '')
      formik.setFieldValue('phone', phone || '')
      formik.setFieldValue('charityCategory', charityCategory || [])

      if (charityLocation) {
        setCharityLocation((prevCharityLocation) => {
          const {_id, ...updatedState} = {
            ...prevCharityLocation,
            ...charityLocation,
            coordinates: {
              lat: charityLocation.coordinates.lat,
              long: charityLocation.coordinates.long,
            },
            address: charityLocation.address ? {...charityLocation.address} : null,
          } as any

          return updatedState
        })
      }

      if (charityLocation.address) {
        const {lane, city, state, pinCode, country} = charityLocation.address
        setInputLocation(`${lane}, ${city}, ${state} - ${pinCode}, ${country}`)
      }

      formik.setFieldValue('description', description || '')
      formik.setFieldValue('website', website || '')

      formik.setFieldValue('facebook', facebook || '')
      formik.setFieldValue('instagram', instagram || '')
      formik.setFieldValue('twitter', twitter || '')
    }
  }, [charity])

  const checkEmail = async (e) => {
    try {
      const result = await checkEmailAvailable(auth?.token, e.target.value)
      if (result.status === 200) {
        setEmailCheck('')
      }
    } catch (error: any) {
      setEmailCheck(error?.response?.data?.message || error?.message || 'Something went wrong!')
    }
  }

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const place = autoComplete?.getPlace()
      if (place?.address_components && place?.geometry) {
        const {address, lane} = getAddressComponents(place)
        const {city, state, postal_code, country} = address

        setCharityLocation({
          coordinates: {
            lat: place?.geometry.location.lat(),
            long: place?.geometry.location.lng(),
          },
          address: {
            lane,
            city,
            state,
            pinCode: postal_code,
            country,
          },
          place_id: place.place_id,
        })
        if (
          !lane ||
          !city ||
          !state ||
          !country ||
          !postal_code ||
          !place.place_id ||
          !place?.geometry.location.lat() ||
          !place?.geometry.location.lng()
        ) {
          setAddressError('Please enter complete address')
        } else {
          setAddressError('')
        }
        setInputLocation(`${lane}, ${city}, ${state} - ${postal_code}, ${country}`)
      }
    }
  }

  const getAddressComponents = (place) => {
    const address: any = {}
    var lane = place.formatted_address.split(',', 2).join(',')
    for (const component of place.address_components) {
      const componentType = component.types[0]
      switch (componentType) {
        case 'locality':
          address.city = component.long_name
          break

        case 'administrative_area_level_1':
          address.state = component.long_name
          break

        case 'postal_code':
          address.postal_code = component.long_name
          break

        case 'lane':
          address.lane = place.formatted_address.split(',', 2).join(',')
          break

        case 'country':
          address.country = component.long_name
          break

        default:
          break
      }
    }
    return {address, lane}
  }

  return (
    <SectionWrapper title='Profile Details' id='profile_details' buttonComponent={editButton}>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
            <div className='col-lg-8'>
              <div
                className='image-input image-input-outline'
                data-kt-image-input='true'
                style={{
                  backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                }}
              >
                <label
                  htmlFor='profile_img'
                  style={{
                    backgroundImage:
                      charity?.profilePic && image === ''
                        ? `url(${charity?.profilePic})`
                        : image
                        ? `url(${image})`
                        : `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                  }}
                  className={`${isView ? '' : 'input-preview-after'} input-preview`}
                ></label>
                {!isView && (
                  <>
                    <label
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='change'
                      htmlFor='profileImage'
                    >
                      <i className='ki-duotone ki-pencil fs-7'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>

                      <input
                        type='file'
                        id='profileImage'
                        name='avatar'
                        accept='.png, .jpg, .jpeg, .webp'
                        className='input-preview__src w-125px h-125px'
                        onChange={(event: any) => {
                          if (event.target.files && event.target.files?.length > 0) {
                            file = event.target.files[0]
                            setImage(URL.createObjectURL(file))
                            formik.setFieldValue('profilePic', file)
                          }
                        }}
                      />
                    </label>
                    {(formik.values.profilePic || formik.values.profilePic) && (
                      <span
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='remove'
                        onClick={() => {
                          formik.setFieldValue('profilePic', undefined)
                          setImage('')
                          setCharity()
                        }}
                      >
                        <i className='ki-duotone ki-cross fs-2'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label  fw-bold fs-6'>Title</label>
            <div className='col-lg-8'>
              <input
                type='text'
                autoComplete='off'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Charity Title'
                {...formik.getFieldProps('name')}
                maxLength={50}
                disabled={isView}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.name}</div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label  fw-bold fs-6'>Email</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Email'
                autoComplete='off'
                {...formik.getFieldProps('email')}
                value={formik.values.email}
                onChange={(e) => formik.setFieldValue('email', e.target.value)}
                onBlur={checkEmail}
                disabled={isView || currentUser?.role === 'charity' || !isView}
              />
              {(formik.touched.email && formik.errors.email) ||
                (emailCheck && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email || emailCheck}</div>
                  </div>
                ))}
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Phone Number</label>
            <div className='col-lg-8 fv-row'>
              <div className='input-group'>
                <span className='input-group-text' style={{border: 'none', fontWeight: 'bold'}}>
                  +1
                </span>
                <input
                  type='tel'
                  autoComplete='off'
                  className='form-control form-control-lg form-control-solid px-1'
                  placeholder='Phone number'
                  {...formik.getFieldProps('phone')}
                  disabled={isView}
                  maxLength={10}
                  pattern='[0-9]{10}'
                />
              </div>
              {formik.touched.phone && formik.errors.phone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.phone}</div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Bio</label>
            <div className='col-lg-8 fv-row'>
              <div
                style={{
                  whiteSpace: showFullBio ? 'normal' : 'nowrap',
                  overflow: showFullBio ? 'visible' : 'hidden',
                  maxHeight: showFullBio ? 'none' : '3em',
                }}
              ></div>
              <textarea
                autoComplete='off'
                className='form-control form-control-lg form-control-solid'
                placeholder='Charity Bio'
                {...formik.getFieldProps('description')}
                onChange={handleBioChange}
                disabled={isView}
                maxLength={3000}
                style={{
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-all',
                }}
              />
              {formik.touched.description && formik.errors.description && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.description}</div>
                </div>
              )}
            </div>
          </div>

          <Category
            onChange={(values) => {
              formik.setFieldValue(
                'charityCategory',
                values?.map(({label}) => label)
              )
            }}
            isView={isView}
            value={formik.getFieldProps('charityCategory').value}
          />

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Location</label>
            <div className='col-lg-8 fv-row'>
              <LoadScript googleMapsApiKey={API_KEY} libraries={['places']}>
                <Autocomplete
                  onLoad={(autocomplete: any) => {
                    setAutoComplete(autocomplete)
                  }}
                  onPlaceChanged={onPlaceChanged}
                >
                  <input
                    placeholder='Location'
                    defaultValue={inputLocation}
                    className='form-control form-control-lg form-control-solid'
                    type='text'
                    autoComplete='off'
                    onChange={() => {
                      setAddressError('')
                    }}
                    disabled={isView}
                  />
                </Autocomplete>
              </LoadScript>
              {addressError && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{addressError}</div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>
              <span className=''>Website Link</span>
            </label>

            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                autoComplete='off'
                className='form-control form-control-lg form-control-solid'
                placeholder='Charity website'
                {...formik.getFieldProps('website')}
                disabled={isView}
              />
              {formik.touched.website && formik.errors.website && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block' style={{color: 'red'}}>
                    {formik.errors.website}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Social Media Links</label>
            <div className='col-lg-8 fv-row'>
              <div className='mt-2'>
                <label className='text-dark' htmlFor='facebook'>
                  Facebook
                </label>
                <input
                  type='text'
                  autoComplete='off'
                  className='form-control form-control-lg form-control-solid'
                  id='facebook'
                  placeholder='Facebook'
                  name='facebook'
                  value={formik.values.facebook}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={isView}
                />
                {formik.touched.facebook && formik.errors.facebook && (
                  <div className='error' style={{color: 'red'}}>
                    {formik.errors.facebook}
                  </div>
                )}
              </div>

              <div className='mt-2'>
                <label className='text-dark' htmlFor='instagram'>
                  Instagram
                </label>
                <input
                  type='text'
                  autoComplete='off'
                  className='form-control form-control-lg form-control-solid'
                  id='instagram'
                  placeholder='Instagram'
                  name='instagram'
                  value={formik.values.instagram}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={isView}
                />
                {formik.touched.instagram && formik.errors.instagram && (
                  <div className='error' style={{color: 'red'}}>
                    {formik.errors.instagram}
                  </div>
                )}
              </div>

              <div className='mt-2'>
                <label className='text-dark' htmlFor='twitter'>
                  Twitter
                </label>
                <input
                  type='text'
                  autoComplete='off'
                  className='form-control form-control-lg form-control-solid'
                  id='twitter'
                  placeholder='Twitter'
                  name='twitter'
                  value={formik.values.twitter}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={isView}
                />
                {formik.touched.twitter && formik.errors.twitter && (
                  <div className='error' style={{color: 'red'}}>
                    {formik.errors.twitter}
                  </div>
                )}
              </div>
            </div>
          </div>

          {!isView && (
            <>
              {currentUser?.role === 'charity' && (
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className=''>Current Password</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <input
                      type={showPassword?.password ? 'text' : 'password'}
                      autoComplete='new-password'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Current Password'
                      {...formik.getFieldProps('currentPassword')}
                      disabled={isView}
                    />
                    {showPassword?.password ? (
                      <span
                        className='password-toggle-icon'
                        onClick={() =>
                          setShowPassword({...showPassword, password: !showPassword.password})
                        }
                      >
                        <KTIcon iconName='eye' className='fs-2' />
                      </span>
                    ) : (
                      <span
                        className='password-toggle-icon'
                        onClick={() =>
                          setShowPassword({...showPassword, password: !showPassword.password})
                        }
                      >
                        <KTIcon iconName='eye-slash' className='fs-2' />
                      </span>
                    )}
                    {formik.touched.currentPassword && formik.errors.currentPassword && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.currentPassword}</div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {!isDeleted && (
                <>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className=''>New Password</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type={showPassword?.newPassword ? 'text' : 'password'}
                        autoComplete='new-password'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='New Password'
                        {...formik.getFieldProps('password')}
                        disabled={isView}
                      />
                      {showPassword?.newPassword ? (
                        <span
                          className='password-toggle-icon'
                          onClick={() =>
                            setShowPassword({
                              ...showPassword,
                              newPassword: !showPassword.newPassword,
                            })
                          }
                        >
                          <KTIcon iconName='eye' className='fs-2' />
                        </span>
                      ) : (
                        <span
                          className='password-toggle-icon'
                          onClick={() =>
                            setShowPassword({
                              ...showPassword,
                              newPassword: !showPassword.newPassword,
                            })
                          }
                        >
                          <KTIcon iconName='eye-slash' className='fs-2' />
                        </span>
                      )}
                      {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.password}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className=''>Confirm Password</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type={showPassword?.confirmPassword ? 'text' : 'password'}
                        autoComplete='confirm-password'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Confirm Password'
                        {...formik.getFieldProps('confirmPassword')}
                        disabled={isView}
                      />
                      {showPassword?.confirmPassword ? (
                        <span
                          className='password-toggle-icon'
                          onClick={() =>
                            setShowPassword({
                              ...showPassword,
                              confirmPassword: !showPassword.confirmPassword,
                            })
                          }
                        >
                          <KTIcon iconName='eye' className='fs-2' />
                        </span>
                      ) : (
                        <span
                          className='password-toggle-icon'
                          onClick={() =>
                            setShowPassword({
                              ...showPassword,
                              confirmPassword: !showPassword.confirmPassword,
                            })
                          }
                        >
                          <KTIcon iconName='eye-slash' className='fs-2' />
                        </span>
                      )}
                      {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.confirmPassword}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
          {!isView && (
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          )}
        </form>
      </FormikProvider>
    </SectionWrapper>
  )
}

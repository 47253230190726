import React from 'react'
import {toAbsoluteUrl} from '../../_metronic/helpers'

export default function NotFound({searchKeyword, clearSearch, filters, data}) {
  const selectedMethod = (searchKeyword && searchKeyword) || (filters && '') || (data && undefined)

  return (
    <div>
      <h6 className='fw-bolder fs-2x text-gray-900 text-center'>
        {selectedMethod
          ? `ops! No result found for "${selectedMethod}"`
          : 'oops! No result found.'}
      </h6>
      <div className=''>
        <img
          src={toAbsoluteUrl('/media/auth/table-404-error.png')}
          className='mw-100 mh-300px theme-light-show'
          alt=''
        />
      </div>
      {selectedMethod && (
        <div className='fw-semibold fs-6 text-gray-500 mb-7'>
          - Did not match any records.
          <br />- Suggestions:
          <ul>
            {searchKeyword && <li>Make sure that all words are spelled correctly.</li>}
            <li>Try different {(searchKeyword && 'keywords') || selectedMethod}.</li>
            {searchKeyword && <li>Try more general keywords.</li>}
          </ul>
          <span className='text-primary cursor-pointer' onClick={clearSearch}>
            - Clear {(searchKeyword && 'Search') || selectedMethod}
          </span>
        </div>
      )}
    </div>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {Link} from 'react-router-dom'
import {ToastContainer, toast} from 'react-toastify'
import {LoadScript, Autocomplete} from '@react-google-maps/api'
import {registerCharity} from '../core/_requests'
import {useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers/components/KTIcon'
import 'react-phone-input-2/lib/bootstrap.css'

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY || ''

const signupSchema = Yup.object().shape({
  name: Yup.string().required('Charity name is required'),
  email: Yup.string().email('Wrong email format').required('Email is required'),
  // location: Yup.object().required('Location is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(16, 'Password must be at most 16 characters')
    .matches(/^\S*$/, 'Password cannot contain spaces')
    .required('Password is required'),
  confirmPassword: Yup.mixed()
    .test('match', 'Password must match', function (value) {
      return value === this.parent.password || value === null
    })
    .nullable()
    .required('Please re-enter your password'),
})

const initialValues = {
  name: '',
  email: '',
  // location: '',
  password: '',
  confirmPassword: '',
}

export function CharitySignup() {
  const [loading, setLoading] = useState(false)
  const [autoComplete, setAutoComplete] = useState<any>(null)
  const navigate = useNavigate()
  const [addressError, setAddressError] = useState('')
  const [charityLocation, setCharityLocation] = useState({
    coordinates: {lat: '0', long: '0'},
    address: {
      lane: '',
      city: '',
      state: '',
      pinCode: '',
      country: '',
    },
    place_id: '',
  })

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  })
  const formik = useFormik({
    initialValues,
    validationSchema: signupSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        const {name, email, password} = values
        const charityData = {
          name,
          email,
          password,
          role: 'charity',
          charityLocation,
        }

        const result = await registerCharity(charityData)
        if (result && result.status === 202) {
          toast.success(result.data.message)
          setLoading(false)
          localStorage.setItem('charityLogin', JSON.stringify(values.password))
          localStorage.setItem('charityVerifiedEmailForOtp', JSON.stringify(values.email))
          navigate('/charity-verify-otp')
        }
      } catch (error: any) {
        toast.error(error.response.data.message || 'something went wrong')
        setLoading(false)
      }
    },
  })

  const onPlaceChanged = () => {
    if (autoComplete !== null) {
      const place = autoComplete?.getPlace()
      if (place?.address_components && place?.geometry) {
        const {address, lane} = getAddressComponents(place)
        const {city, state, postal_code, country} = address

        setCharityLocation({
          coordinates: {
            lat: place?.geometry.location.lat().toString(),
            long: place?.geometry.location.lng().toString(),
          },
          address: {
            lane,
            city,
            state,
            pinCode: postal_code,
            country,
          },
          place_id: place.place_id,
        })

        if (
          !lane ||
          !city ||
          !state ||
          !country ||
          !postal_code ||
          !place.place_id ||
          !place?.geometry.location.lat() ||
          !place?.geometry.location.lng()
        ) {
          setAddressError('Please enter complete address')
        } else {
          setAddressError('')
        }
      }
    }
  }

  const getAddressComponents = (place) => {
    const address: any = {}
    var lane = place.formatted_address.split(',', 2).join(',')
    for (const component of place.address_components) {
      const componentType = component.types[0]
      switch (componentType) {
        case 'locality':
          address.city = component.long_name
          break

        case 'administrative_area_level_1':
          address.state = component.long_name
          break

        case 'postal_code':
          address.postal_code = component.long_name
          break

        case 'lane':
          address.lane = place.formatted_address.split(',', 2).join(',')
          break

        case 'country':
          address.country = component.long_name
          break

        default:
          break
      }
    }
    return {address, lane}
  }

  return (
    <form className='form w-100' onSubmit={formik.handleSubmit} noValidate>
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Charity Signup</h1>
      </div>

      {/* Charity Name */}
      <div className='fv-row mb-4'>
        <label className='form-label fs-6 fw-bolder text-dark required'>Charity Name</label>
        <input
          placeholder='Charity Name'
          {...formik.getFieldProps('name')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.name && formik.errors.name,
            },
            {
              'is-valid': formik.touched.name && !formik.errors.name,
            }
          )}
          type='text'
          autoComplete='off'
          maxLength={50}
        />
        {formik.touched.name && formik.errors.name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{formik.errors.name}</div>
          </div>
        )}
      </div>

      {/* Email */}
      <div className='fv-row mb-4'>
        <label className='form-label fs-6 fw-bolder text-dark required'>Email address</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.email && formik.errors.email,
            },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{formik.errors.email}</div>
          </div>
        )}
      </div>

      {/* Location */}
      <div className='fv-row mb-4'>
        <label className='form-label fs-6 fw-bolder text-dark required'>Location</label>
        <LoadScript googleMapsApiKey={API_KEY} libraries={['places']}>
          <Autocomplete
            onLoad={(autocomplete: any) => {
              setAutoComplete(autocomplete)
            }}
            onPlaceChanged={onPlaceChanged}
          >
            <input
              placeholder='Location'
              className={clsx('form-control bg-transparent')}
              type='text'
              autoComplete='off'
              onChange={() => {
                setAddressError('')
              }}
            />
          </Autocomplete>
        </LoadScript>

        {addressError && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{addressError}</div>
          </div>
        )}
      </div>

      {/* Password */}
      <div className='fv-row mb-4'>
        <label className='form-label fs-6 fw-bolder text-dark required'>Password</label>
        <input
          placeholder='Password'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
          // type='password'
          type={showPassword?.password ? 'text' : 'password'}
          autoComplete='new-password'
        />
        {showPassword?.password ? (
          <span
            className={clsx(
              {'password-toggle-icon-auth': formik.touched.password && formik.errors.password},
              {
                'password-toggle-icon-auth': formik.touched.password && !formik.errors.password,
              },
              'password-toggle-icon'
            )}
            onClick={() => setShowPassword({...showPassword, password: !showPassword.password})}
          >
            <KTIcon iconName='eye' className='fs-2' />
          </span>
        ) : (
          <span
            className={clsx(
              {'password-toggle-icon-auth': formik.touched.password && formik.errors.password},
              {
                'password-toggle-icon-auth': formik.touched.password && !formik.errors.password,
              },
              'password-toggle-icon'
            )}
            onClick={() => setShowPassword({...showPassword, password: !showPassword.password})}
          >
            <KTIcon iconName='eye-slash' className='fs-2' />
          </span>
        )}
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{formik.errors.password}</div>
          </div>
        )}
      </div>

      {/* Re-enter Password */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark required'>Re-enter Password</label>
        <input
          placeholder='Re-enter Password'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
          // type='password'
          type={showPassword?.confirmPassword ? 'text' : 'password'}
          autoComplete='new-password'
        />
        {showPassword?.confirmPassword ? (
          <span
            className={clsx(
              {
                'password-toggle-icon-auth':
                  formik.touched.confirmPassword && formik.errors.confirmPassword,
              },
              {
                'password-toggle-icon-auth':
                  formik.touched.confirmPassword && !formik.errors.confirmPassword,
              },
              'password-toggle-icon'
            )}
            onClick={() =>
              setShowPassword({...showPassword, confirmPassword: !showPassword.confirmPassword})
            }
          >
            <KTIcon iconName='eye' className='fs-2' />
          </span>
        ) : (
          <span
            className={clsx(
              {
                'password-toggle-icon-auth':
                  formik.touched.confirmPassword && formik.errors.confirmPassword,
              },
              {
                'password-toggle-icon-auth':
                  formik.touched.confirmPassword && !formik.errors.confirmPassword,
              },
              'password-toggle-icon'
            )}
            onClick={() =>
              setShowPassword({...showPassword, confirmPassword: !showPassword.confirmPassword})
            }
          >
            <KTIcon iconName='eye-slash' className='fs-2' />
          </span>
        )}
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>{formik.errors.confirmPassword}</div>
          </div>
        )}
      </div>

      {/* Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Sign Up</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      <div className='text-center'>
          <Link to='/auth' className='fs-6 fw-bold'>
            Sign In
          </Link>
      </div>
      <ToastContainer />
    </form>
  )
}

import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

import {DonorWrapper} from '../pages/Donor'
import {CharityWrapper} from '../pages/Charity'
import {DonationWrapper} from '../pages/Donations'
import {CharityIdWrapper} from '../pages/Charity/id'
import {DonorIdWrapper} from '../pages/Donor/id'
import {ContactWrapper} from '../pages/Contact'
import {EventsWrapper} from '../pages/Events'
import {PostsWrapper} from '../pages/Posts'
import {SubscriptionsWrapper} from '../pages/Subscriptions'
import {ViewSubscriptionsWrapper} from '../pages/view-subscrptions'
import {ViewDonationWrapper} from '../pages/view-donatations'
import {AdminProfileWrapper} from '../pages/AdminProfile'
import {RoundupWrapper} from '../pages/Roundup'
import {ViewRoundupWrapper} from '../pages/view-roundup'
import {ViewPostsWrapper} from '../pages/view-post'
import {ViewEventsWrapper} from '../pages/view-event'
import {ViewSubscriptionWrapper} from '../pages/view-subscription'
import {ViewCharityDonationWrapper} from '../pages/view-charity-donation'
import {ViewDonorDonationWrapper} from '../pages/view-donor-donation'
import {ViewCharityRoundupWrapper} from '../pages/view-charity-roundup'
import {ViewDonorRoundupWrapper} from '../pages/view-donor-roundup'
import {CharityCategoryWrapper} from '../pages/CharityCategory'
import { ReportsWrapper } from '../pages/Reports'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='charity-management' element={<CharityWrapper />} />
        <Route path='charity-category-management' element={<CharityCategoryWrapper />} />
        <Route path='charity-management/:id' element={<CharityIdWrapper />} />
        <Route path='donor-management' element={<DonorWrapper />} />
        <Route path='donor-management/:id' element={<DonorIdWrapper />} />
        <Route path='donations-management' element={<DonationWrapper />} />
        <Route path='view-subscriptions' element={<ViewSubscriptionsWrapper />} />
        <Route path='view-donations' element={<ViewDonationWrapper />} />
        <Route path='view-roundup' element={<ViewRoundupWrapper />} />
        <Route path='posts' element={<PostsWrapper />} />
        <Route path='events' element={<EventsWrapper />} />
        <Route path='subscriptions' element={<SubscriptionsWrapper />} />
        <Route path='contact' element={<ContactWrapper />} />
        <Route path='profile' element={<AdminProfileWrapper />} />
        <Route path='roundup' element={<RoundupWrapper />} />
        <Route path='reports' element={<ReportsWrapper />} />
        <Route path='view-post' />
        <Route path='view-event' />
        <Route path='view-subscription' />
        <Route path='view-charity-donation' />
        <Route path='view-donor-donation' />
        <Route path='view-charity-roundup' />
        <Route path='view-donor-roundup' />
        <Route path='view-post/:id' element={<ViewPostsWrapper />} />
        <Route path='view-event/:id' element={<ViewEventsWrapper />} />
        <Route path='view-subscription/:id' element={<ViewSubscriptionWrapper />} />
        <Route path='view-charity-donation/:id' element={<ViewCharityDonationWrapper />} />
        <Route path='view-donor-donation/:id' element={<ViewDonorDonationWrapper />} />
        <Route path='view-charity-roundup/:id' element={<ViewCharityRoundupWrapper />} />
        <Route path='view-donor-roundup/:id' element={<ViewDonorRoundupWrapper />} />


        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}

/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useAuth} from '../../../../app/modules/auth'
import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../helpers'
interface User {
  name: string
  profilePic: string
  email: string
}

interface MyComponentProps {
  userData: User
}
const HeaderUserMenu: FC<MyComponentProps> = ({userData}) => {
  const {logout, currentUser} = useAuth()
  const navigate = useNavigate()

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded mt-3 menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
      data-popper-placement='bottom-start'
    >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img
              src={
                userData?.profilePic
                  ? userData.profilePic
                  : toAbsoluteUrl('/media/avatars/blank.png')
              }
              alt='LOGO'
            />{' '}
          </div>

          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              <a
                href={currentUser?.role === 'charity' ? '/profile' : '/profile'}
                className='text-black fs-6 fw-bolder text-hover-primary'
                data-toggle='tooltip'
                data-placement='top'
                title={userData.name}
              >
                {/* {userData.name} */}
                {userData.name?.length > 15
                  ? `${userData.name.substring(0, 15)}...`
                  : userData.name}
              </a>
            </div>
            <span className='text-muted fs-7 fw-bolder'>{userData?.email}</span>
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>
      <div className='menu-item px-2'>
        {currentUser?.role === 'charity' && (
          <>
            {' '}
            <a onClick={() => navigate('/profile')} className='menu-link px-5 fw-bolder'>
              My profile
            </a>
            <a
              onClick={() => {
                dispatchEvent(
                  new CustomEvent('openDisplay', {
                    detail: 'sd',
                  })
                )
              }}
              className='menu-link px-5 fw-bolder'
            >
              Delete Account
            </a>
          </>
        )}
        {currentUser?.role === 'admin' && (
          <>
            {' '}
            <a onClick={() => navigate('/profile')} className='menu-link px-5 fw-bolder'>
              My profile
            </a>
            <div className='separator my-2'></div>
          </>
        )}
        {currentUser?.role === 'charity' && <div className='separator my-2'></div>}
        <a onClick={logout} className='menu-link px-5 fw-bolder'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}

/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from '../../../../app/modules/auth'

export function AsideMenuMain() {
  const intl = useIntl()
  const {currentUser} = useAuth()

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      />
      {currentUser?.role !== 'charity' && (
        <>
          <AsideMenuItem to='/charity-management' icon='wanchain' title='Charity' />
          {/* <AsideMenuItem to='/charity-category-management' icon='basket' title='Charity Category' /> */}

          {/* <div className='menu-item menu-accordion' data-kt-menu-trigger='click'>
            <span className='menu-link'>
              <span className='menu-icon'>
                <i className='ki-duotone ki-element-plus fs-2'>
                  <span className='path1'></span>
                  <span className='path2'></span>
                  <span className='path3'></span>
                  <span className='path4'></span>
                  <span className='path5'></span>
                </i>
              </span>
              <span className='menu-title'>Charity</span>
              <span className='menu-arrow'></span>
            </span>
            <div
              className='menu-sub menu-sub-accordion'
              kt-hidden-height='81'
              style={{display: 'none', overflow: 'hidden'}}
            >
              <div className='menu-item menu-accordion' data-kt-menu-trigger='click'>
                <span className='menu-link'>
                  <AsideMenuItem
                    to='/charity-management'
                    icon='wanchain'
                    title='Charity Management'
                  />
                </span>
              </div>
              <div className='menu-item menu-accordion' data-kt-menu-trigger='click'>
                <span className='menu-link'>
                  <AsideMenuItem
                    to='/charity-category-management'
                    icon='basket'
                    title='Charity Category'
                  />
                </span>
              </div>
            </div>
          </div> */}
          <AsideMenuItem to='/posts' icon='heart-circle' title='Posts' />
          <AsideMenuItem to='/events' icon='wanchain' title='Events' />
          <AsideMenuItem to='/donor-management' icon='heart-circle' title='Donor' />
          <AsideMenuItem to='/donations-management' icon='dollar' title='Donations' />
          <AsideMenuItem to='/subscriptions' icon='diamonds' title='Subscriptions' />
          <AsideMenuItem to='/roundup' icon='switch' title='Round Up' />
          <AsideMenuItem to='/Reports' icon='document' title="Reports" />
          <AsideMenuItem to='/contact' icon='profile-circle' title='Contact' />
        </>
      )}
      {currentUser?.role === 'charity' && (
        <>
          <AsideMenuItem to='/events' icon='wanchain' title='Events' />
          <AsideMenuItem to='/posts' icon='heart-circle' title='Posts' />
          <AsideMenuItem to='/donations-management' icon='dollar' title='Donations' />
          <AsideMenuItem to='/subscriptions' icon='diamonds' title='Subscriptions' />
          <AsideMenuItem to='/roundup' icon='switch' title='Round Up' />
          <AsideMenuItem to='/contact' icon='profile-circle' title='Contact' />
        </>
      )}
    </>
  )
}

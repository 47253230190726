import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import {getCategories, getCategoriesforSignup} from '../../app/modules/auth/core/_requests'
import {useAuth} from '../../app/modules/auth'
import {toast} from 'react-toastify'
import {useLocation} from 'react-router-dom'

let options: {value: string; label: string}[] = []

export default function Category({onChange, isView, value}) {
  const {auth} = useAuth()
  const [categories, setCategories] = useState([])
  options = categories?.map((t) => ({value: t, label: t}))
  value = value?.map((t) => ({value: t, label: t}))
  const {pathname} = useLocation()

  const callGetCategories = async (token) => {
    try {
      const result = await getCategories(token)
      if (result.status === 200) {
        setCategories(result.data.allcharitiesByName)
      }
    } catch (error: any) {
      toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
    }
  }

  const callGetCategoriesSignup = async () => {
    try {
      const result = await getCategoriesforSignup()
      if (result.status === 200) {
        setCategories(result.data.allcharitiesByName)
      }
    } catch (error: any) {
      toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
    }
  }

  useEffect(() => {
    if (auth && auth.token) {
      callGetCategories(auth?.token)
    } else {
      callGetCategoriesSignup()
    }
  }, [])

  return (
    <>
      <div className='row mb-6'>
        {pathname === '/signup' ? (
          <label className='form-label fs-6 fw-bolder text-dark'>Category</label>
        ) : (
          <label className='col-lg-4 col-form-label fw-bold fs-6'>Category</label>
        )}

        <div className={`fv-row ${pathname === '/signup' ? '' : 'col-lg-8'}`}>
          <Select
            isMulti
            isSearchable
            onChange={onChange}
            options={options}
            value={value}
            isDisabled={isView}
          />
        </div>
      </div>
    </>
  )
}

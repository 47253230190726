import {Suspense, useEffect, useState} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {ThemeModeProvider} from '../_metronic/partials'
import {CustomModal, MODAL_TYPE_ENUM} from '../components/Modal'
import {deleteUser} from './modules/auth/core/_requests'
import {toast} from 'react-toastify'
import {useAuth} from './modules/auth'

const App = () => {
  const {currentUser, logout} = useAuth()
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [multipleDeleteModelView, setMultipleDeleteModelView] = useState(false)
  const [modelView, setModelView] = useState(false)
  const [title, setTitle] = useState('')
  const {pathname} = useLocation()

  useEffect(() => {
    window.addEventListener('openDisplay', (e) => {
      setMultipleDeleteModelView(true)
    })
    return () => {
      window.removeEventListener('openDisplay', (e) => {
        setMultipleDeleteModelView(true)
      })
    }
  }, [])

  useEffect(() => {
    // Retrieve existing routes from sessionStorage or initialize an empty array
    const storedRoutesString = sessionStorage.getItem('storedRoutes')
    let storedRoutes = storedRoutesString ? JSON.parse(storedRoutesString) : []
    if (storedRoutes.length >= 3) {
      storedRoutes = storedRoutes.slice(-2)
    }
    // Get the current route
    const currentRoute = pathname

    // Update the storedRoutes array with the current route
    const updatedRoutes = [...storedRoutes, currentRoute]
    // Store the updatedRoutes array in sessionStorage
    sessionStorage.setItem('storedRoutes', JSON.stringify(updatedRoutes))
  }, [pathname])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <Outlet />
            <MasterInit />
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
      <div
        style={{
          display: multipleDeleteModelView ? 'block' : 'none',
        }}
      >
        {multipleDeleteModelView && (
          <CustomModal
            type={'DELETE'}
            body={[]}
            deleteLoading={deleteLoading}
            title={true}
            handleConfirm={async () => {
              try {
                setDeleteLoading(true)
                const result = await deleteUser(currentUser?.token, [], 'charity')
                if (result.status === 200) {
                  toast.success(result.data.message || 'Account Deleted Succesfully')
                  setDeleteLoading(false)
                  logout()
                  setMultipleDeleteModelView(false)
                }
              } catch (error: any) {
                if (error?.response?.status === 600) {
                  setTitle(error?.response?.data?.message)
                  setModelView(true)
                } else {
                  toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
                }
                setDeleteLoading(false)
                setMultipleDeleteModelView(false)
              }
            }}
            handleClose={() => {
              setMultipleDeleteModelView(false)
            }}
          />
        )}
      </div>

      {modelView && (
        <CustomModal
          type={MODAL_TYPE_ENUM.SIGNOUT_CHARITY}
          handleClose={() => {
            setModelView(false)
          }}
          body={{title: title}}
        />
      )}
    </Suspense>
  )
}

export {App}

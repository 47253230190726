import React, {useState, ReactNode} from 'react'
import {KTIcon} from '../../_metronic/helpers'

interface SectionWrapperProps {
  children: ReactNode
  title: string
  id: string
  buttonComponent?: JSX.Element | string
}

export default function SectionWrapper({
  children,
  title,
  id,
  buttonComponent,
}: SectionWrapperProps) {
  const [showSection, setShowSection] = useState(true)

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className={`card-header border-0 cursor-pointer d-flex  gap-2 align-items-center ${
            !showSection && 'collapsed'
          }`}
          onClick={() => {
            setShowSection((oldState) => !oldState)
          }}
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{title}</h3>
          </div>
          {buttonComponent && (
            <div className='card-subtitle mt-2' style={{marginLeft: 'auto'}}>
              {buttonComponent}
            </div>
          )}
          <KTIcon
            iconName={`arrow-${showSection ? 'down' : 'up'}`}
            className='fs-1 text-hover-primary'
          />
        </div>

        <div className={`collapse ${showSection && 'show'}`} id={id}>
          <div className='card-body border-top'>{children}</div>
        </div>
      </div>
    </>
  )
}

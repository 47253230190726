import {useCallback, useEffect, useState} from 'react'
import { getReports, setEventHidden, setPostHidden } from '../../modules/auth/core/_requests'
import {useAuth} from '../../modules/auth'
import {toast} from 'react-toastify'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import DataTable from 'react-data-table-component'
import TableLoader from '../../../components/DataTable/TableLoader'
import { Dialog } from '@mui/material'
import PostBody from '../../../components/Modal/Body/PostBody'
import EventBody from '../../../components/Modal/Body/EventBody'
import { auto } from '@popperjs/core'
import { SelectOption } from '../../../components/Common'
import { updateReportStatus } from '../../modules/auth/core/_requests'

const Reports = () => {
    const {auth, currentUser} = useAuth()
    const [reports, setReports] = useState<any>()
    const [modalView, setModalView] = useState(false)
    const [selectedReport, setSelectedReport] = useState<any>()
    const [selectedComment, setSelectedComment] = useState<string>()
    
    const handleClose = () => {
      setModalView(false)
      setSelectedComment(undefined)
      setSelectedReport(undefined)
      }

    const getReportsList = useCallback(async (token) => {
        try {
            const result = await getReports(token)
            if (result.status === 200) {
                setReports(result.data.data)
            }
          } catch (error: any) {
            toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
          }
        }, [])
    
    useEffect(() => {
        if (auth && auth.token) {
        getReportsList(auth.token)}
      }, []) 
    
    const reportColumns = [
      {name: 'Report Date',
          selector: (row) => row.createdAt,
          width: '150px',
          format: (row) => {
            return (
              <div className='text-dark fw-bold fs-6'>
                <p
                  className='text-dark fw-bold mb-1 fs-6'
                  onClick={() => {}}
                >
                  {new Date(row.createdAt).toLocaleDateString('en-GB')}
                </p>
              </div>
            )
          },
          sortable: true,
        },
        {name: 'Reported By',
        selector: (row) => row.username,
        width: '225px',
        format: (row) => {
          return (
            <>
            <div className='d-flex align-items-center m-2'>
          <div
            className='symbol symbol-50px me-5'
            onClick={() => {}}
          >
            <img
              src={row.profilePic || toAbsoluteUrl('/media/avatars/blank.png')}
              className='h-[75px] w-[75px] symbol-label align-self-end'
              alt=''
            />
          </div>
          <div className='d-flex justify-content-start flex-column'
          style={{overflow: auto}}>
            <p
              className='text-dark fw-bold mb-1 fs-6'
              onClick={() => {}}
              data-toggle='tooltip'
              data-placement='top'
              title={row.username}
            >
              {row.username}
            </p>
          </div>
        </div>
            </>
          )
        },
        sortable: true,
      },
      {name: 'Reported Content',
        selector: (row) => row.content.title || row.content.name,
        width: '200px',
        format: (row) => {
          return (
            <div className='d-flex align-items-start'>
              <div className='d-flex justify-content-start flex-column'>
                <button
                  type="submit"
                  className='btn btn-secondary'
                  onClick={() =>{
                    setModalView(true)
                    setSelectedReport(row)
                  }}
                  title={row.content.title || row.content.name}
                >
                {row.contentType === 'post' ? "View Post" : "View Event"}
                </button>
              </div>
            </div>
          )
        },
        sortable: true,
        },
        {
          name: 'Comment',
          selector: (row) => row.comment,
          width: '300px',
          format: (row) => {
            return (
              <div className='text-dark fw-bold fs-6' style={{overflow: auto}}>
                <p
                  className='text-dark fw-bold mb-1 fs-6'
                  onClick={() => {
                    setModalView(true)
                    setSelectedComment(row.comment)
                  }}
                >
                  {row.comment}
                </p>
              </div>
            )
          },
          sortable: true,
        },
        {name: 'Report Status',
        selector: (row) => row.resolved,
        width: '150px',
        format: (row) => {
          return (
            <div className='text-dark fw-bold fs-6'>
              <SelectOption
            options={[
              {value: 'true', title: 'Resolved'},
              {value: 'false', title: 'Unresolved'}]}
            selected={row.resolved === true ? 'true' : 'false'}
            onChange={async (e) => {
              try {
                const value = e.target.value
                const result = await updateReportStatus(auth?.token, {
                  resolved: value === "true",
                  reportId: row._id,
                })
                if (result.status === 200) {
                  if (auth) {
                    getReportsList(auth.token)
                  }
                } else {
                  toast(result.data.message)
                }
              } catch (error: any) {
                toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
              }
            }}
          />
            </div>
          )
        },
        sortable: true,
      },
      {name: 'Content Visibility',
        selector: (row) => row.content.hidden,
        width: '200px',
        format: (row) => {
          return (
            <div className='text-dark fw-bold fs-6'>
              <SelectOption
            options={[
              {value: 'visible', title: 'Visible'},
              {value: 'hidden', title: 'Hidden'}]}
            selected={row.content.hidden === true ? 'hidden' : 'visible'}
            onChange={async (e) => {
              try {
                const value = e.target.value
                let result
                if (row.contentType === 'post'){
                  result = await setPostHidden(auth?.token, {
                  hidden: value === "hidden",
                  postId: row.content._id,
                })} else { 
                  result = await setEventHidden(auth?.token, {
                  hidden: value === "hidden",
                  eventId: row.content._id,
                })}
                if (result.status === 200) {
                  if (auth) {
                    getReportsList(auth.token)
                  }
                } else {
                  toast(result.data.message)
                }
              } catch (error: any) {
                toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
              }
            }}
            />
            </div>
          )
        },
        sortable: true,
        }
      ]

      return (
        <>
        <Dialog
              open={modalView}
              onClose={handleClose}
              id="content-details-modal"
              fullWidth
              maxWidth='sm'
            >
            {selectedComment && 
              <div style={{padding: 30}}> 
                <p className='text-dark fw-bold mb-1 fs-6'> 
                  {selectedComment} 
                </p> 
              </div>}
            {selectedReport && selectedReport.content && 
              <>
                {selectedReport.contentType === 'post' ? 
                  <div style={{padding: 30}}>  
                    <PostBody post={selectedReport.content}></PostBody>
                  </div>
               : 
                  <div style={{padding: 30}}>
                    <EventBody event={selectedReport.content}></EventBody>
                  </div>} 
              </> }
        </Dialog>
        <DataTable
          progressComponent={<TableLoader />}
          data={reports}
          columns={reportColumns}
          fixedHeader
          fixedHeaderScrollHeight='610px'
          />
          <div> 
          </div>
        </>
      )
}

const ReportsWrapper = () => (
  <>
    <PageTitle>Reports</PageTitle>
    <Reports />
  </>
)

export {ReportsWrapper}
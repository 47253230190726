import {useCallback, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {DataTableWrapper} from '../../../components/DataTable'
import ActionButton from '../../../components/Common/ActionButton'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {CustomModal, MODAL_TYPE_ENUM} from '../../../components/Modal'
import {useAuth} from '../../modules/auth'
import {
  ACCOUNT_TYPE,
  ROLE_TYPE,
  getDonorsCharityListForCharity,
  viewCharityDonationsListing,
} from '../../modules/auth/core/_requests'
import {getName} from '../../modules/auth/core/StorageHelper'
import {toast} from 'react-toastify'
import {TDonations} from '../../../components/Modal/Body/body'
import {useParams} from 'react-router-dom'
import {debounce} from '../../../utils'

interface TInitialState {
  loading: boolean
  donations: TDonations[]
  currentPage: number
  totalPages: number
  error: any | null
  modalView: boolean
  selectedRow: any
  type: any
  search: string
  donors: any[]
  charities: any[]
  role: string
  filters: Filters
  totalDonation: number
}

interface Filters {
  donor_id?: string
  accountType: string
  startDate: string
  endDate: string
  status: string
  amount: string
  paymentType: string
}

const initialState = {
  loading: true,
  donations: [],
  currentPage: 1,
  totalPages: 1,
  error: null,
  modalView: false,
  selectedRow: null,
  totalDonation: 0,
  type: null,
  search: '',
  donors: [],
  charities: [],
  role: 'donor',
  filters: {
    donor_id: '',
    accountType: '',
    startDate: '',
    endDate: '',
    status: '',
    amount: '',
    paymentType: '',
  },
}

const ViewCharityDonation = () => {
  const {auth} = useAuth()
  const param = useParams()
  const [state, setState] = useState<TInitialState>(initialState)
  const [pageSize, setPageSize] = useState(10)
  const totalPage: any = localStorage.getItem('totalPage')
  const currentPage: any = localStorage.getItem('currentPage')
  const values = getName()
  const charityName: any =
    state?.donations[0]?.charity?.name && state?.donations[0]?.charity?.name.length > 25
      ? state?.donations[0]?.charity?.name.substring(0, 25) + '..'
      : state?.donations[0]?.charity?.name
  const donationBreadcrumbs: Array<PageLink> = [
    {
      title: 'Donation /',
      path: `/charity-management/${charityName}`,
      isSeparator: false,
      isActive: false,
    },
  ]
  const getDonations = useCallback(
    async (token, role, page, search, filters, pageSize) => {
      try {
        let result
        setState({...state, loading: true})

        result = await viewCharityDonationsListing(
          token,
          role,
          page,
          search,
          filters,
          pageSize,
          param.id
        )

        if (result.status === 200) {
          setState((prev) => ({
            ...prev,
            loading: false,
            donations: result.data.data.transactionList,
            currentPage: result?.data?.data?.currentPage || 1,
            totalDonation: result.data.data?.totalDonation,
            totalPages: result.data.data.totalPages,
          }))
        }
        localStorage.setItem('totalPage', result?.data?.data?.totalPages)
        localStorage.setItem('currentPage', result?.data?.data?.currentPage)
      } catch (error: any) {
        setState({...state, error: error, loading: false})
      }
    },
    [state, pageSize]
  )

  const getDonorsListData = useCallback(async (token) => {
    try {
      const result = await getDonorsCharityListForCharity(
        token,
        ROLE_TYPE.DONOR,
        'transaction',
        param?.id
      )

      if (result.status === 200) {
        setState((prev) => ({...prev, donors: result.data.data}))
      } else {
        toast(result.data.message)
      }
    } catch (error: any) {
      toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
    }
  }, [])

  useEffect(() => {
    if (auth && auth.token) {
      getDonorsListData(auth?.token as unknown as string)
    }
  }, [])

  useEffect(() => {
    if (auth && auth.token) {
      getDonations(
        auth?.token,
        'charity',
        totalPage === currentPage ? 1 : state.currentPage,
        state.search,
        state.filters,
        pageSize
      )
    }
  }, [state.currentPage, state.filters, state.search, pageSize])

  const adminDonationColumns = [
    {
      name: 'Donor Name',
      selector: (row, i) => row.donor.name,
      width: '200px',
      format: (row, i) => {
        return (
          <>
            <div className='d-flex align-items-center m-2 min-w-500px'>
              <div
                className='symbol symbol-50px me-5'
                onClick={() => {
                  setState({
                    ...state,
                    modalView: true,
                    selectedRow: row,
                    type: MODAL_TYPE_ENUM.TRANSACTION,
                  })
                }}
              >
                <img
                  src={
                    row.donor.profilePic
                      ? row.donor.profilePic
                      : toAbsoluteUrl('/media/avatars/blank.png')
                  }
                  className='h-[75px] w-[75px] symbol-label align-self-end'
                  alt=''
                />
              </div>
              <div className='d-flex justify-content-start flex-column'>
                <span
                  className='text-dark text-hover-primary fw-bold mb-1 fs-6'
                  onClick={() => {
                    setState({
                      ...state,
                      modalView: true,
                      selectedRow: row,
                      type: MODAL_TYPE_ENUM.TRANSACTION,
                    })
                  }}
                  data-toggle='tooltip'
                  data-placement='top'
                  title={row.donor.name}
                >
                  {row.donor.name}
                </span>
              </div>
            </div>
          </>
        )
      },
      sortable: true,
    },
    {
      name: 'Charity Name',
      selector: (row, i) => row.charity.name,
      width: '200px',
      format: (row, i) => {
        return (
          <>
            <div className='d-flex align-items-center m-2 min-w-500px'>
              <div
                className='symbol symbol-50px me-5'
                onClick={() => {
                  setState({
                    ...state,
                    modalView: true,
                    selectedRow: row,
                    type: MODAL_TYPE_ENUM.TRANSACTION,
                  })
                }}
              >
                <img
                  src={
                    row.charity.profilePic
                      ? row.charity.profilePic
                      : toAbsoluteUrl('/media/avatars/blank.png')
                  }
                  className='h-[75px] w-[75px] symbol-label align-self-end'
                  alt=''
                />
              </div>
              <div className='d-flex justify-content-start flex-column'>
                <span
                  className='text-dark text-hover-primary fw-bold mb-1 fs-6'
                  onClick={() => {
                    setState({
                      ...state,
                      modalView: true,
                      selectedRow: row,
                      type: MODAL_TYPE_ENUM.TRANSACTION,
                    })
                  }}
                  data-toggle='tooltip'
                  data-placement='top'
                  title={row.charity.name}
                >
                  {row.charity.name}
                </span>
              </div>
            </div>
          </>
        )
      },
      sortable: true,
    },
    {
      name: 'Date & Time',
      selector: (row, i) => row.date,
      minWidth: '220px',
      format: (row, i) => {
        return (
          <>
            <div
              className='text-dark text-hover-primary fw-bold fs-6'
              onClick={() => {
                setState({
                  ...state,
                  modalView: true,
                  selectedRow: row,
                  type: MODAL_TYPE_ENUM.TRANSACTION,
                })
              }}
            >
              {new Date(row.date || '').toLocaleDateString('en-GB')}{' '}
              {(() => {
                const date = new Date(row.date || '')
                const hours = date.getHours()
                const minutes = date.getMinutes()
                const ampm = hours >= 12 ? 'PM' : 'AM'
                const formattedHours = hours % 12 === 0 ? 12 : hours % 12

                return `${formattedHours.toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                })}:${minutes.toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                })} ${ampm}`
              })()}
            </div>
          </>
        )
      },
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row, i) => parseFloat(row.amount),
      format: (row, i) => (
        <>
          <span
            className='text-dark text-hover-primary fw-bold fs-6'
            onClick={() => {
              setState({
                ...state,
                modalView: true,
                selectedRow: row,
                type: MODAL_TYPE_ENUM.TRANSACTION,
              })
            }}
          >
            ${row.amount}
          </span>
        </>
      ),
      sortable: true,
      sortCompare: (a, b) => a - b,
    },
    {
      name: 'Payment method',
      selector: (row, i) => row.payment_method,
      format: (row, i) => {
        return (
          <>
            <div
              className='text-dark text-hover-primary fw-bold fs-6'
              onClick={() => {
                setState({
                  ...state,
                  modalView: true,
                  selectedRow: row,
                  type: MODAL_TYPE_ENUM.TRANSACTION,
                })
              }}
            >
              {row?.payment_method === ACCOUNT_TYPE.BANK ? 'BANK' : 'CARD'}
            </div>
          </>
        )
      },
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row, i) => row.status,
      format: (row, i) => {
        return (
          <span
            className={`badge uppercase text-hover-primary badge-light-${
              row.status.includes('succeeded') ? 'success' : 'danger'
            }`}
            onClick={() => {
              setState({
                ...state,
                modalView: true,
                selectedRow: row,
                type: MODAL_TYPE_ENUM.TRANSACTION,
              })
            }}
          >
            {row.status}
          </span>
        )
      },
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row, i) => row.status,
      format: (row, i) => {
        return (
          <>
            <ActionButton
              viewCallback={() =>
                setState({
                  ...state,
                  modalView: true,
                  selectedRow: row,
                  type: MODAL_TYPE_ENUM.TRANSACTION,
                })
              }
            />
          </>
        )
      },
      sortable: false,
    },
  ]

  const debouncedOnChange = debounce(
    (value) =>
      setState((prev) => ({
        ...prev,
        search: value,
      })),
    1000
  )

  const donationAmountRange = Array.from({length: 41}, (_, index) => {
    const rangeStart = index * 50
    const rangeEnd = (index + 1) * 50

    if (index === 40) {
      return `>${rangeStart}`
    }

    return `${rangeStart}-${rangeEnd}`
  })

  return (
    <>
      <PageTitle breadcrumbs={charityName && donationBreadcrumbs}>
        {charityName && charityName}
      </PageTitle>

      <DataTableWrapper
        loading={state.loading}
        filters={{
          dropdown: [
            {
              label: 'Donor Name',
              options: state.donors?.map((t) => ({
                value: t['_id'],
                label: t['name'],
              })),
            },
            {
              label: 'Status',
              options: ['succeeded', 'failed'].map((t) => ({value: t, label: t})),
            },
            {
              label: 'Payment Method',
              options: [ACCOUNT_TYPE.CARD, ACCOUNT_TYPE.BANK].map((t) => ({
                value: t,
                label: ACCOUNT_TYPE.BANK === t ? 'Bank' : 'Card',
              })),
            },
            {
              label: 'Amount',
              options: donationAmountRange.map((t) => ({
                value: t,
                label: t,
              })),
            },
            {
              label: 'Payment Type',
              options: ['recurring', 'oneTime', 'roundUp'].map((t) => ({value: t, label: t})),
            },
          ],
          date: true,
        }}
        filtersCallback={(filters) => {
          if (filters?.reset) {
            setState((prev) => ({
              ...prev,
              filters: initialState.filters,
              currentPage: initialState.currentPage,
              search: initialState.search,
            }))
          } else {
            setState((prev) => ({
              ...prev,
              currentPage: initialState.currentPage,
              filters: {
                endDate: filters.endDate
                  ? new Date(filters.endDate).toLocaleDateString('en-ZA')
                  : '',
                startDate: filters.startDate
                  ? new Date(filters.startDate).toLocaleDateString('en-ZA')
                  : '',
                donor_id: filters['Donor Name']?.value || '',
                accountType: filters['Payment Method']?.value || '',
                status: filters['Status']?.value || '',
                amount: filters['Amount']?.value || '',
                paymentType: filters['Payment Type']?.value || '',
              },
            }))
          }
        }}
        columns={adminDonationColumns}
        data={state.donations}
        currentPage={state.currentPage}
        totalPages={state.totalPages}
        handlePageChange={(page) => setState((prev) => ({...prev, currentPage: page}))}
        handleSearch={debouncedOnChange}
        totalDonation={state.totalDonation}
        onPageSizeChange={(newValue: number) => setPageSize(newValue)}
        pageSize={pageSize}
        pdfGenerate={async () => {
          return viewCharityDonationsListing(
            auth?.token,
            'charity',
            'all',
            state.search,
            state.filters,
            '',
            param.id
          )
        }}
      />

      {state.modalView && (
        <CustomModal
          type={state.type}
          body={state.selectedRow}
          handleClose={() => setState({...state, modalView: false, selectedRow: null, type: null})}
        />
      )}
    </>
  )
}

const ViewCharityDonationWrapper = () => (
  <>
    <ViewCharityDonation />
  </>
)

export {ViewCharityDonationWrapper}

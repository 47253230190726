export function getCurrentUrl(pathname: string) {
  return pathname.split(/[?#]/)[0]
}

export function checkIsActive(pathname: string, url: string) {
  const current = getCurrentUrl(pathname)

  if (
    current === url ||
    (current.includes('view-post') && url === '/charity-management') ||
    (current.includes('view-event') && url === '/charity-management') ||
    (current.includes('view-charity') && url === '/charity-management') ||
    (current.includes('view-donor') && url === '/donor-management')
  ) {
    return true
  }

  if (!current || !url) {
    return false
  }

  if (current.indexOf(url) > -1) {
    return true
  }

  return false
}

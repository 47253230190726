import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {Category, SectionWrapper} from '../../Common'
import {
  ROLE_TYPE,
  checkEmailAvailable,
  updateUserDetails,
} from '../../../app/modules/auth/core/_requests'
import {useAuth} from '../../../app/modules/auth'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import 'react-phone-input-2/lib/bootstrap.css'

const initialValues = {
  profilePic: '',
  fullName: '',
  charityCategory: [],
  email: '',
  address: '',
  phone: '',
  confirmPassword: '',
  password: '',
}

interface DonorProfileDetailsType {
  profilePic: string
  fullName: string
  charityCategory: string[]
  email: string
  phone: string
  confirmPassword: string
  password: string
  address: string
}
const profileDetailsSchema = Yup.object().shape({
  fullName: Yup.string().required('Donor Title is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(16, 'Password must be at most 16 characters')
    .matches(/^\S*$/, 'Password cannot contain spaces'),
  confirmPassword: Yup.mixed()
    .test('match', 'Password must match', function (value) {
      return value === this.parent.password || value === null
    })
    .nullable(),
  email: Yup.string().email(),
  phone: Yup.number()
    .positive()
    .integer()
    .required('Phone number is required')
    .typeError('Phone number must be a number')
    .test('len', 'Phone number must be exactly 10 digits', (value) => String(value).length === 10),
})
export default function DonorProfileDetails({
  isView = true,
  donor,
  setDonor,
  donorId,
  editButton,
  isDeleted,
}) {
  const {auth} = useAuth()
  let file
  const [image, setImage] = useState('')
  const [loading, setLoading] = useState(false)
  const [emailCheck, setEmailCheck] = useState('')

  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  })

  const formik = useFormik<DonorProfileDetailsType>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      try {
        if (!emailCheck) {
          const formData = new FormData()
          formData.append('name', values.fullName || '')
          formData.append('email', values.email || '')
          formData.append('phone', values.phone || '')
          formData.append('address', values.address || '')
          formData.append('profilePic', image && file ? file : values.profilePic)
          formData.append('charityCategory', values?.charityCategory?.toString() || '')
          if (values.password) formData.append('password', values.password)

          setLoading(true)
          const result = await updateUserDetails(auth?.token, donorId, ROLE_TYPE.DONOR, formData)
          if (result.status === 200) {
            toast('Profile updated')
            setLoading(false)
            setTimeout(() => {
              window.location.href = '/donor-management'
            }, 2000)
          } else {
            toast(result?.data?.data?.message || 'Something went wrong!')
            setLoading(false)
          }
        }
      } catch (error: any) {
        setLoading(false)
        toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
      }
    },
  })

  useEffect(() => {
    if (donor) {
      formik.setValues({...donor, confirmPassword: '', password: ''})
    }
  }, [donor])

  const checkEmail = async (e) => {
    try {
      if (donor.email === formik.values.email) {
        return
      }
      const result = await checkEmailAvailable(auth?.token, e.target.value)
      if (result.status === 200) {
        setEmailCheck('')
      }
    } catch (error: any) {
      setEmailCheck(error?.response?.data?.message || error?.message || 'Something went wrong!')
    }
  }

  return (
    <SectionWrapper title='Profile Details' id='donor_profile' buttonComponent={editButton}>
      <form onSubmit={formik.handleSubmit} noValidate className='form'>
        <div className='card-body p-9'>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
            <div className='col-lg-8'>
              <div
                className='image-input image-input-outline'
                data-kt-image-input='true'
                style={{
                  backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                }}
              >
                <label
                  htmlFor='profile_img'
                  style={{
                    backgroundImage:
                      donor?.profilePic && image === ''
                        ? `url(${donor?.profilePic})`
                        : image
                        ? `url(${image})`
                        : `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                  }}
                  className={`${isView ? '' : 'input-preview-after'} input-preview`}
                ></label>
                {!isView && (
                  <>
                    <label
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='change'
                      htmlFor='profileImage'
                    >
                      <i className='ki-duotone ki-pencil fs-7'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>

                      <input
                        type='file'
                        id='profileImage'
                        name='avatar'
                        accept='.png, .jpg, .jpeg, .webp'
                        className='input-preview__src w-125px h-125px'
                        onChange={(event: any) => {
                          if (event.target.files && event.target.files?.length > 0) {
                            file = event.target.files[0]
                            setImage(URL.createObjectURL(file))
                            formik.setFieldValue('profilePic', file)
                          }
                        }}
                      />
                    </label>
                    {(formik.values.profilePic || formik.values.profilePic) && (
                      <span
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='remove'
                        onClick={() => {
                          formik.setFieldValue('profilePic', undefined)
                          setImage('')
                          setDonor()
                        }}
                      >
                        <i className='ki-duotone ki-cross fs-2'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label  fw-bold fs-6'>Full Name</label>
            <div className='col-lg-8'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Donor Title'
                {...formik.getFieldProps('fullName')}
                autoComplete='off'
                disabled={isView}
                maxLength={30}
              />
              {formik.touched.fullName && formik.errors.fullName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.fullName}</div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label  fw-bold fs-6'>Email</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='email'
                className='form-control form-control-lg form-control-solid'
                placeholder='Email'
                {...formik.getFieldProps('email')}
                value={formik.values.email}
                onChange={(e) => formik.setFieldValue('email', e.target.value)}
                autoComplete='off'
                onBlur={checkEmail}
                disabled={isView}
              />
              {(formik.touched.email && formik.errors.email) ||
                (emailCheck && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email || emailCheck}</div>
                  </div>
                ))}
            </div>
          </div>
          {/* Phone Number */}
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Phone Number</label>
            <div className='col-lg-8 fv-row'>
              <div className='input-group'>
                <span className='input-group-text' style={{border: 'none', fontWeight: 'bold'}}>
                  +1
                </span>
                <input
                  type='tel'
                  autoComplete='off'
                  className='form-control form-control-lg form-control-solid px-1'
                  placeholder='Phone number'
                  {...formik.getFieldProps('phone')}
                  maxLength={10}
                  pattern='[0-9]{10}'
                />
              </div>
              {formik.touched.phone && formik.errors.phone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.phone}</div>
                </div>
              )}
            </div>
          </div>

          <Category
            onChange={(values) => {
              formik.setFieldValue(
                'charityCategory',
                values?.map(({label}) => label)
              )
            }}
            isView={isView}
            value={formik.getFieldProps('charityCategory').value}
          />

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label  fw-bold fs-6'>Address</label>
            <div className='col-lg-8 fv-row'>
              <textarea
                // type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='address'
                {...formik.getFieldProps('address')}
                autoComplete='off'
                disabled={isView || !isView}
              />
              {formik.touched.address && formik.errors.address && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.address}</div>
                </div>
              )}
            </div>
          </div>

          {!isView && (
            <>
              {!isDeleted && (
                <>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className=''>Password</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type={showPassword?.password ? 'text' : 'password'}
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Password'
                        autoComplete='new-password'
                        {...formik.getFieldProps('password')}
                        disabled={isView}
                      />
                      {showPassword?.password ? (
                        <span
                          className='password-toggle-icon'
                          onClick={() =>
                            setShowPassword({...showPassword, password: !showPassword.password})
                          }
                        >
                          <KTIcon iconName='eye' className='fs-2' />
                        </span>
                      ) : (
                        <span
                          className='password-toggle-icon'
                          onClick={() =>
                            setShowPassword({...showPassword, password: !showPassword.password})
                          }
                        >
                          <KTIcon iconName='eye-slash' className='fs-2' />
                        </span>
                      )}
                      {formik.touched.password && formik.errors.password && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.password}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span className=''>Confirm password</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type={showPassword?.confirmPassword ? 'text' : 'password'}
                        className='form-control form-control-lg form-control-solid'
                        autoComplete='confirm-password'
                        placeholder='Confirm password'
                        {...formik.getFieldProps('confirmPassword')}
                        disabled={isView}
                      />
                      {showPassword?.confirmPassword ? (
                        <span
                          className='password-toggle-icon'
                          onClick={() =>
                            setShowPassword({
                              ...showPassword,
                              confirmPassword: !showPassword.confirmPassword,
                            })
                          }
                        >
                          <KTIcon iconName='eye' className='fs-2' />
                        </span>
                      ) : (
                        <span
                          className='password-toggle-icon'
                          onClick={() =>
                            setShowPassword({
                              ...showPassword,
                              confirmPassword: !showPassword.confirmPassword,
                            })
                          }
                        >
                          <KTIcon iconName='eye-slash' className='fs-2' />
                        </span>
                      )}
                      {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.confirmPassword}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>

        {!isView && (
          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        )}
      </form>
    </SectionWrapper>
  )
}

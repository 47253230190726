/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {newPassword} from '../core/_requests'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers/components/KTIcon'

const loginSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(16, 'Password must be at most 16 characters')
    .matches(/^\S*$/, 'Password cannot contain spaces'),
  confirmPassword: Yup.mixed()
    .test('match', 'Password must match', function (value) {
      return value === this.parent.newPassword || value === null
    })
    .nullable(),
})

const initialValues = {
  newPassword: '',
  confirmPassword: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const storedValue = localStorage.getItem('verifiedEmailForOtp')
  const email = storedValue ? JSON.parse(storedValue) : null
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState({
    confirmPassword: false,
    newPassword: false,
  })
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting, setErrors}) => {
      setLoading(true)
      try {
        if (values.newPassword !== values.confirmPassword) {
          setErrors({
            confirmPassword: 'Confirm password must match the new password.',
          })
          setLoading(false)
          return
        }
        const result = await newPassword(values.confirmPassword, email)
        if (result.status === 202) {
          toast(result.data.message)
          navigate('/auth')
        } else {
          toast(result.data.message)
        }
      } catch (error: any) {
        setSubmitting(false)
        setLoading(false)
        toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Create new password</h1>
      </div>
      {/* begin::Heading */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>New Password</label>
        <input
          // type='password'
          type={showPassword?.newPassword ? 'text' : 'password'}
          autoComplete='new-password'
          placeholder='Password'
          {...formik.getFieldProps('newPassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
            },
            {
              'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
            }
          )}
        />
        {showPassword?.newPassword ? (
          <span
            className={clsx(
              {
                'password-toggle-icon-auth':
                  formik.touched.newPassword && formik.errors.newPassword,
              },
              {
                'password-toggle-icon-auth':
                  formik.touched.newPassword && !formik.errors.newPassword,
              },
              'password-toggle-icon'
            )}
            onClick={() =>
              setShowPassword({...showPassword, newPassword: !showPassword.newPassword})
            }
          >
            <KTIcon iconName='eye' className='fs-2' />
          </span>
        ) : (
          <span
            className={clsx(
              {
                'password-toggle-icon-auth':
                  formik.touched.newPassword && formik.errors.newPassword,
              },
              {
                'password-toggle-icon-auth':
                  formik.touched.newPassword && !formik.errors.newPassword,
              },
              'password-toggle-icon'
            )}
            onClick={() =>
              setShowPassword({...showPassword, newPassword: !showPassword.newPassword})
            }
          >
            <KTIcon iconName='eye-slash' className='fs-2' />
          </span>
        )}
        {formik.touched.newPassword && formik.errors.newPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.newPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>Confirm Password</label>
        <input
          // type='password'
          type={showPassword?.confirmPassword ? 'text' : 'password'}
          placeholder='Confirm Password'
          autoComplete='new-password'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
        />
        {showPassword?.confirmPassword ? (
          <span
            className={clsx(
              {
                'password-toggle-icon-auth':
                  formik.touched.confirmPassword && formik.errors.confirmPassword,
              },
              {
                'password-toggle-icon-auth':
                  formik.touched.confirmPassword && !formik.errors.confirmPassword,
              },
              'password-toggle-icon'
            )}
            onClick={() =>
              setShowPassword({...showPassword, confirmPassword: !showPassword.confirmPassword})
            }
          >
            <KTIcon iconName='eye' className='fs-2' />
          </span>
        ) : (
          <span
            className={clsx(
              {
                'password-toggle-icon-auth':
                  formik.touched.confirmPassword && formik.errors.confirmPassword,
              },
              {
                'password-toggle-icon-auth':
                  formik.touched.confirmPassword && !formik.errors.confirmPassword,
              },
              'password-toggle-icon'
            )}
            onClick={() =>
              setShowPassword({...showPassword, confirmPassword: !showPassword.confirmPassword})
            }
          >
            <KTIcon iconName='eye-slash' className='fs-2' />
          </span>
        )}
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {setCharity} from '../../app/modules/auth/core/StorageHelper'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {Charity} from '../../app/pages/dashboard/DashboardWrapper'
import {KTIcon} from '../../_metronic/helpers'

type Props = {
  className: string
  charities: Charity[]
}

const TopCharities: React.FC<Props> = ({className, charities}) => {
  const navigate = useNavigate()

  return (
    <div className={`card ${className} relative`}>
      {/* begin::Header */}
      <div className='card-header border-0 p-4 sticky'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Latest Charities</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_2'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {charities.length > 0 &&
                    charities.map((charity: Charity, i) => (
                      <tr key={i}>
                        <td>
                          <div
                            className='symbol symbol-50px me-2 cursor-pointer'
                            onClick={() => {
                              navigate(`/charity-management/${charity?.name}`)
                              setCharity(charity?._id)
                            }}
                          >
                            <img
                              src={
                                charity.profilePic
                                  ? charity.profilePic
                                  : toAbsoluteUrl('/media/avatars/blank.png')
                              }
                              className='h-[60px] w-[60px] symbol-label align-self-end'
                              alt=''
                            />
                          </div>
                        </td>
                        <td style={{maxWidth: '20px', overflow: 'hidden'}}>
                          <span
                            onClick={() => {
                              navigate(`/charity-management/${charity?.name}`)
                              setCharity(charity?._id)
                            }}
                            className='text-dark fw-bold mb-1 fs-6 cursor-pointer text-hover-primary'
                            style={{
                              whiteSpace: 'nowrap',
                              display: 'inline-block',
                              width: '100%',
                            }}
                            data-toggle='tooltip'
                            data-placement='top'
                            title={charity?.name}
                          >
                            {charity?.name?.length > 25
                              ? charity?.name.slice(0, 25) + '...'
                              : charity?.name}
                          </span>
                        </td>
                        <td className='text-end'>
                          <button
                            onClick={() => {
                              navigate(`/charity-management/${charity?.name}`)
                              setCharity(charity?._id)
                            }}
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                          >
                            <KTIcon iconName='eye' className='fs-2' />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
                {/* end::Table body */}
              </table>
              {charities.length >= 10 && (
                <div className='text-center mb-4'>
                  <Link to='/charity-management'>View More</Link>
                </div>
              )}
            </div>
            {/* end::Table */}
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TopCharities}

import React from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {ACCOUNT_TYPE} from '../../../app/modules/auth/core/_requests'
import CountUp from 'react-countup'
import {TDonations} from './body'

export default function TransactionBody({transaction}: {transaction: TDonations}) {
  return (
    <>
      {' '}
      <div className='card-body p-0'>
        <div
          className={`px-9 pt-7 card-rounded h-275px w-100 bg-${
            transaction?.status !== 'failed' ? 'success' : 'danger'
          }`}
        >
          <div className='d-flex flex-stack'>
            <h1 className='m-0 text-white capitalize fw-bold fs-1'>
              {(
                transaction?.charityName ||
                transaction?.donorName ||
                transaction?.donor?.name ||
                ''
              ).length > 10
                ? (
                    transaction?.charityName ||
                    transaction?.donorName ||
                    transaction?.donor?.name ||
                    ''
                  ).substring(0, 10) + '...'
                : transaction?.charityName ||
                  transaction?.donorName ||
                  transaction?.donor?.name ||
                  ''}{' '}
            </h1>
            {transaction?.charity && (
              <>
                <span className='symbol-label'>
                  <KTIcon iconName='black-right' className='fs-1 text-white' />
                </span>
                <h1 className='m-0 text-white capitalize fw-bold fs-1'>
                  {transaction?.charity?.name.length > 10
                    ? transaction?.charity?.name.substring(0, 10) + '...'
                    : transaction?.charity?.name}
                </h1>
              </>
            )}
          </div>
          <div className='d-flex text-center flex-column text-white pt-8'>
            <span className='fw-semibold fs-6'>Donation Amount</span>
            <span className='fw-bold fs-2x pt-1'>
              $
              <CountUp
                className='fw-bold fs-2x pt-1'
                end={parseFloat(transaction?.amount)}
                duration={1}
                decimals={2}
                decimal='.'
                start={0.0}
              />
            </span>
          </div>
        </div>
        <div
          className='shadow-xs card-rounded mx-4 mb-4 px-4 py-4 position-relative z-index-1 bg-body'
          style={{marginTop: '-100px'}}
        >
          <div className='d-flex align-items-center mb-3'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='badge' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='fs-5 text-gray-800 fw-bold'>Transaction ID</span>
              </div>
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-600 pe-1 transactionId-smallScreen'>
                  {transaction?.transactionId}
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center mb-3'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon
                  iconName={transaction?.status === 'failed' ? 'information-5' : 'verify'}
                  className='fs-1'
                />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='fs-5 text-gray-800 fw-bold'>Status</span>
              </div>
              <div className='d-flex align-items-center'>
                <div
                  className={`fw-bold fs-5 badge badge-light-${
                    transaction?.status === 'failed' ? 'danger' : 'success'
                  } pe-1`}
                >
                  {transaction?.status}
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center mb-3'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon
                  iconName={
                    transaction?.payment_method === ACCOUNT_TYPE.BANK ? 'bank' : 'two-credit-cart'
                  }
                  className='fs-1'
                />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='fs-5 text-gray-800 fw-bold'>Method</span>
              </div>
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-600 pe-1'>
                  {transaction?.payment_method === ACCOUNT_TYPE.BANK ? 'BANK' : 'CARD'}
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center mb-3'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='dollar' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='fs-5 text-gray-800 fw-bold'>Currency Type</span>
              </div>
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 uppercase text-gray-600 pe-1'>
                  {transaction?.currency}
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex align-items-center mb-3'>
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='calendar' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='fs-5 text-gray-800 fw-bold'>Date & Time</span>
              </div>
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 text-gray-600 pe-1'>
                  {new Date(transaction.date || '').toLocaleDateString('en-GB')}{' '}
                  {(() => {
                    const date = new Date(transaction.date || '')
                    const hours = date.getHours()
                    const minutes = date.getMinutes()
                    const ampm = hours >= 12 ? 'PM' : 'AM'
                    const formattedHours = hours % 12 === 0 ? 12 : hours % 12

                    return `${formattedHours.toLocaleString('en-US', {
                      minimumIntegerDigits: 2,
                    })}:${minutes.toLocaleString('en-US', {
                      minimumIntegerDigits: 2,
                    })} ${ampm}`
                  })()}
                </div>
              </div>
            </div>
          </div>
          {transaction?.subscription && (
            <div className='d-flex align-items-center mb-3'>
              <div className='symbol symbol-45px w-40px me-5'>
                <span className='symbol-label bg-lighten'>
                  <KTIcon iconName='crown-2' className='fs-1' />
                </span>
              </div>
              <div className='d-flex align-items-center flex-wrap w-100'>
                <div className='mb-1 pe-3 flex-grow-1'>
                  <span className='fs-5 text-gray-800 fw-bold'>Subscription</span>
                </div>
                <div className='d-flex align-items-center'>
                  <div
                    className={`fw-bold fs-5 badge badge-light-${
                      transaction?.subscription ? 'success' : 'danger'
                    } pe-1`}
                  >
                    {transaction?.subscription ? 'Active' : 'Inactive'}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className='d-flex align-items-center mb-3'>
            {/* One-Time section */}
            <div className='symbol symbol-45px w-40px me-5'>
              <span className='symbol-label bg-lighten'>
                <KTIcon iconName='euro' className='fs-1' />
              </span>
            </div>
            <div className='d-flex align-items-center flex-wrap w-100'>
              <div className='mb-1 pe-3 flex-grow-1'>
                <span className='fs-5 text-gray-800 fw-bold'>Payment Type</span>
              </div>
              <div className='d-flex align-items-center'>
                <div className='fw-bold fs-5 uppercase text-gray-600 pe-1'>
                  {transaction?.roundup
                    ? 'Round Up'
                    : transaction?.subscription
                    ? 'Recurring'
                    : 'One-Time'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import React from 'react'
import ContentLoader from 'react-content-loader'

export default function AccountCardLoader() {
  return (
    <>
      <ContentLoader
        speed={2}
        // width={720}
        // height={200}
        // viewBox='0 0 720 200'
        width='100%'
        height='100%'
        viewBox='0 0 100% 100%'
        backgroundColor='#c4c4c4'
        foregroundColor='#e3e3e3'
      >
        <rect x='5' y='23' rx='5' ry='5' width='148' height='110' />
        <rect x='166' y='27' rx='5' ry='5' width='235' height='28' />
        <rect x='171' y='75' rx='5' ry='5' width='151' height='20' />

        <rect x='460' y='55' rx='5' ry='5' width='100' height='40' />
        <rect x='570' y='55' rx='5' ry='5' width='100' height='40' />
        <rect x='680' y='55' rx='5' ry='5' width='40' height='40' />
      </ContentLoader>
    </>
  )
}

import React, {useState} from 'react'
import {addBankAccount} from '../../../app/modules/auth/core/_requests'
import {useAuth} from '../../../app/modules/auth'
import {toast} from 'react-toastify'

export default function StripeConnectBody() {
  const {currentUser, logout} = useAuth()
  const [loading, setLoading] = useState(false)

  const stripeConnectHandler = async () => {
    try {
      setLoading(true)
      const result = await addBankAccount(currentUser?.token)
      if (result.status === 202) {
        window.open(result.data.data.url, '_blank')
      }
      if (result.status === 244) {
        window.location.reload()
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error?.message || 'Something went wrong!')
    } finally {
      setLoading(false)
    }
  }

  return (
      <div className='card'>
        <div className='card-body p-4 text-center'>
          <p className='card-text text-muted text-dark fw-bolder fs-3 mb-5'>
            Click 'Connect with Stripe' to finalize the setup and start receiving contributions.
          </p>
          <button
            type='button'
            className='btn btn-primary mx-2'
            onClick={stripeConnectHandler}
            disabled={loading}
          >
            {loading ? (
              <div className='d-flex align-items-center'>
                <span>Please Wait...</span>
                <span
                  className='spinner-border spinner-border-sm mx-2'
                  role='status'
                  aria-hidden='true'
                ></span>
              </div>
            ) : (
              'Connect With Stripe'
            )}
          </button>
          {/* <button type='button' className='btn btn-danger mx-2' onClick={logout}>
            Signout
          </button> */}
        </div>
    </div>
  )
}

import {useLocation, useNavigate} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import {AdminProfileDetails} from '../../../components/Sections'
import {useCallback, useEffect, useState} from 'react'
import {useAuth} from '../../modules/auth'
import {toast} from 'react-toastify'
import {adminUserProfile} from '../../modules/auth/core/_requests'

export interface CharityProfileDetailsType {
  profilePic: string
  name: string
  email: string
  password: string
  confirmPassword: string
  currentPassword: string
}
export interface State {
  adminDetails?: CharityProfileDetailsType | null
  loading?: boolean
}
const AdminProfile: React.FC = () => {
  const {auth, currentUser} = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const [isEditing, setIsEditing] = useState(false)

  const [state, setState] = useState<State>({
    adminDetails: null,
    loading: true,
  })

  const getProfileDetails = useCallback(
    async (token, role) => {
      try {
        setState({...state, loading: true})
        const result = await adminUserProfile(currentUser?.token, 'admin')
        if (result.status === 200) {
          setState({...state, adminDetails: result.data.data, loading: false})
        }
      } catch (error: any) {
        toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
        setState({...state, loading: false})
      }
    },
    [state]
  )

  useEffect(() => {
    if (auth && auth.token) {
      getProfileDetails(auth?.token, 'admin')
      setIsEditing(location.search.includes('edit=true'))
    }
  }, [])

  const handleEditProfile = () => {
    const params = new URLSearchParams(location.search)
    params.set('edit', isEditing ? 'false' : 'true')
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    })
  }

  const editButton = (
    <div className='d-flex justify-content-end py-3'>
      <button
        onClick={(e) => {
          setIsEditing((prevIsEditing) => !prevIsEditing)
          handleEditProfile()
          e.stopPropagation()
        }}
        type='submit'
        className='btn btn-primary'
      >
        {isEditing ? 'Cancel Edit' : 'Edit Profile'}
      </button>
    </div>
  )

  return (
    <>
      <PageTitle>
        {currentUser?.name && currentUser?.name.length > 10
          ? currentUser?.name.substring(0, 10) + '..'
          : currentUser?.name}
      </PageTitle>

      <AdminProfileDetails
        admin={state.adminDetails}
        isView={location.search.includes('edit=true') ? false : true}
        editButton={editButton}
        setAdmin={() => {
          setState((prev: any) => ({
            ...prev,
            adminDetails: {
              ...prev.adminDetails,
              profilePic: undefined,
            },
          }))
        }}
      />
    </>
  )
}

const AdminProfileWrapper = () => (
  <>
    <AdminProfile />
  </>
)

export {AdminProfileWrapper}

/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../modules/auth'
import {App} from '../App'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {CharityRoutes} from './CharityRoutes'
import {VerifyOtp} from '../modules/auth/components/VerifyOtp'
import {AuthLayout} from '../modules/auth/AuthLayout'
import {ForgotPassword} from '../modules/auth/components/ForgotPassword'
import {ResetPassword} from '../modules/auth/components/ResetPassword'
import {CharitySignup} from '../modules/auth/components/CharitySignup'
import {CharityVerifyOtp} from '../modules/auth/components/CharityVerifyOtp'
import AdminContactUs from '../pages/admin-contact'
import {CharitySignupRoutes} from './CharitySignupRoutes'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const PUBLIC_URL = '/'

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()

  return (
    <>
      <BrowserRouter basename={PUBLIC_URL}>
        <Routes>
          <Route element={<App />}>
            <Route path='error/*' element={<ErrorsPage />} />
            <Route path='logout' element={<Logout />} />
            <Route element={<AuthLayout />}>
              <Route path='/forgot-password' element={<ForgotPassword />} />
              <Route path='/verify-otp' element={<VerifyOtp />} />
              <Route path='/reset-password' element={<ResetPassword />} />
              <Route path='/signup' element={<CharitySignup />} />
              <Route path='/charity-verify-otp' element={<CharityVerifyOtp />} />
              <Route path='/contact-us' element={<AdminContactUs />} />
            </Route>
            {currentUser ? (
              currentUser.role === 'charity' ? (
                // Render CharityRoutes for charity user
                <>
                  {currentUser.status === 222 ||
                  currentUser.status === 233 ||
                  currentUser.status === 244 ||
                  currentUser.status === 255 ? (
                    <>
                      <Route path='/*' element={<CharitySignupRoutes />} />
                      <Route index element={<Navigate to='/charity-profile' />} />
                    </>
                  ) : (
                    <>
                      <Route path='/*' element={<CharityRoutes />} />
                      <Route index element={<Navigate to='/dashboard' />} />
                    </>
                  )}
                </>
              ) : (
                // Render PrivateRoutes for other users
                <>
                  <Route path='/*' element={<PrivateRoutes />} />
                  <Route index element={<Navigate to='/dashboard' />} />
                </>
              )
            ) : (
              <>
                <Route path='auth/*' element={<AuthPage />} />
                <Route path='*' element={<Navigate to='/auth' />} />
              </>
            )}
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  )
}

export {AppRoutes}

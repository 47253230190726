import React from 'react'
import {useAuth} from '../../../app/modules/auth'
import {useNavigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

export default function AccountStatusBody() {
  const {logout} = useAuth()
  const navigate = useNavigate()

  return (
    
      <div className='card'>
        <div className='card-body p-4 text-center'>
          <div className='card-title m-0'>
            <img
              className='mx-auto w-80px w-md-80 w-xl-80px mb-4'
              style={{objectFit: 'contain', maxHeight: '100%'}}
              src={toAbsoluteUrl('/media/custom-img/progress.png')}
              alt=''
            />

            <h3 className='fw-bolder text-black mb-6'> Pending for Approval!</h3>
            <h3 className='fw-bolder text-danger mb-6'>
              {' '}
              Your profile is currently undergoing a verification and approval process.
            </h3>
            <h3 className='fw-bolder text-muted mb-6'>
              {' '}
              We appreciate your patience and understanding during this time.
            </h3>
            <h3 className='fw-bolder text-muted mb-6'>
              {' '}
              If you have any urgent inquiries or require immediate assistance, please hit the
              Contact button.{' '}
            </h3>
            <h3 className='fw-bolder text-muted mb-6'>Thank you for your cooperation.</h3>
          </div>
          <div className='d-flex justify-content-around align-items-center m-3'>
            <button
              type='button'
              className='btn btn-primary my-2'
              onClick={() => navigate('/contact')}
            >
              Contact Us
            </button>
            {/* <button type='button' className='btn btn-primary my-2' onClick={logout}>
              Sign Out
            </button> */}
          </div>
        </div>
      </div>
  )
}

import {useCallback, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {DataTableWrapper} from '../../../components/DataTable'
import ActionButton from '../../../components/Common/ActionButton'
import {SelectOption} from '../../../components/Common'
import {CustomModal, MODAL_TYPE, MODAL_TYPE_ENUM} from '../../../components/Modal'
import {
  POST_EVENT_TYPE,
  ROLE_TYPE,
  charityPostEvent,
  charityUserPostEvent,
  deleteMultiplePostEvent,
  deletePostOrEvent,
  getDonorsCharityList,
  setPostHidden,
  updatePostAndEventStatus,
} from '../../modules/auth/core/_requests'
import {useAuth} from '../../modules/auth'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {getEventPostStore, removeEventPostStore} from '../../modules/auth/core/StorageHelper'
import {ToastContainer, toast} from 'react-toastify'
import {TCharity, TPost} from '../../../components/Modal/Body/body'
import {debounce} from '../../../utils'
export interface TFilters {
  id: string
  startDate: string
  endDate: string
  status?: string
}
export interface TInitialState {
  loading: boolean
  posts: TPost[]
  charities: TCharity[]
  currentPage: number
  totalPages: number
  error: any
  modalView: boolean
  multipleDeleteModelView: boolean
  addEditModelView: boolean
  selectedRow: any | null
  type: MODAL_TYPE_ENUM | MODAL_TYPE | any
  search: string
  deleteLoading: boolean
  changeStatusId: string | null
  filters: TFilters
  totalActivePost: number
  totalInActivePost: number
}

const initialState = {
  loading: true,
  posts: [],
  charities: [],
  currentPage: 1,
  totalPages: 1,
  error: null,
  modalView: false,
  multipleDeleteModelView: false,
  addEditModelView: false,
  selectedRow: null,
  type: null,
  search: '',
  deleteLoading: false,
  changeStatusId: null,
  filters: {
    id: '',
    startDate: '',
    endDate: '',
    status: '',
  },
  totalActivePost: 0,
  totalInActivePost: 0,
}

const Post = () => {
  const {auth, currentUser} = useAuth()
  const values = getEventPostStore()

  const [state, setState] = useState<TInitialState>(initialState)
  const [title, setTitle] = useState('')
  const [modelView, setModelView] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const totalPage: any = localStorage.getItem('totalPage')
  const currentPage: any = localStorage.getItem('currentPage')

  //delete the multiple posts
  const [selectedRow, setSelectedRows] = useState([])
  const [deleteValue, setDeleteValue] = useState(false)
  const handleSelectedRowsChange = (selectedRows) => {
    setSelectedRows(selectedRows.map((row) => row.id))
  }
  useEffect(() => {
    setDeleteValue(false)
  }, [deleteValue])

  const getCharityListData = useCallback(async (token) => {
    try {
      const result = await getDonorsCharityList(token, ROLE_TYPE.CHARITY, 'post')
      if (result.status === 200) {
        setState((prev) => ({...prev, charities: result.data.data}))
      }
    } catch (error: any) {
      if (error.response.status === 600) {
        setTitle(error?.response?.data?.message)
        setModelView(true)
      } else {
        toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
      }
    }
  }, [])

  const getPosts = useCallback(
    async (token, page, id, filters, search, pageSize) => {
      try {
        setState({...state, loading: true})
        let result
        if (currentUser?.role === 'charity') {
          result = await charityUserPostEvent(
            token,
            POST_EVENT_TYPE.POST,
            page,
            filters,
            search,
            pageSize
          )
        } else {
          result = await charityPostEvent(
            token,
            POST_EVENT_TYPE.POST,
            id,
            page,
            filters,
            search,
            pageSize
          )
        }
        if (result.status === 200) {
          setState((prev) => ({
            ...prev,
            loading: false,
            posts: result.data.data?.postList || [],
            currentPage: result.data.data?.currentPage || 1,
            totalPages: result.data.data?.totalPages || 1,
            totalActivePost: result.data.data?.totalActivePosts,
            totalInActivePost: result.data.data?.totalInActivePosts,
          }))
          localStorage.setItem('totalPage', result?.data?.data?.totalPages)
          localStorage.setItem('currentPage', result?.data?.data?.currentPage)
        } else {
          toast(result.data.message)
        }
      } catch (error: any) {
        setState({...state, error: error, loading: false})
        if (error.response.status === 600) {
          setTitle(error?.response?.data?.message)
          setModelView(true)
        } else {
          toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
        }
      }
    },
    [state, pageSize]
  )

  useEffect(() => {
    if (auth && auth.token) {
      getCharityListData(auth.token)
    }
  }, [])

  useEffect(() => {
    if (auth && auth.token) {
      if (values) state.filters.id = values?.id

      getPosts(
        auth.token,
        totalPage === currentPage ? 1 : state.currentPage,
        state.filters.id,
        state.filters,
        state.search,
        pageSize
      )
    }
  }, [state.currentPage, state.filters, state.search, values?.id, deleteValue, pageSize])

  const postColumns = [
    {
      name: 'Post Title',
      selector: (row, i) => row.title,
      width: '300px',
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center m-2'>
            <div
              className='symbol symbol-50px me-5'
              onClick={() =>
                setState({
                  ...state,
                  modalView: true,
                  selectedRow: row,
                  type: MODAL_TYPE_ENUM.POST,
                })
              }
            >
              <img
                src={row.media[0]?.thumbnailURL ? row.media[0]?.thumbnailURL : row.media[0]?.url}
                className='h-[70px] w-[70px] symbol-label align-self-center'
                alt=''
              />
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <p
                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                onClick={() =>
                  setState({
                    ...state,
                    modalView: true,
                    selectedRow: row,
                    type: MODAL_TYPE_ENUM.POST,
                  })
                }
                data-toggle='tooltip'
                data-placement='top'
                title={row.title}
              >
                {row.title}
              </p>
            </div>
          </div>
        )
      },
      sortable: true,
    },

    {
      name: 'Date & Time',
      selector: (row, i) => row.date,
      minWidth: '220px',
      format: (row, i) => {
        return (
          <div className='text-dark fw-bold fs-6'>
            <p
              className='text-dark fw-bold text-hover-primary mb-1 fs-6'
              onClick={() =>
                setState({
                  ...state,
                  modalView: true,
                  selectedRow: row,
                  type: MODAL_TYPE_ENUM.POST,
                })
              }
            >
              {new Date(row.date || '').toLocaleDateString('en-GB')}{' '}
              {(() => {
                const date = new Date(row.date || '')
                const hours = date.getHours()
                const minutes = date.getMinutes()
                const ampm = hours >= 12 ? 'PM' : 'AM'
                const formattedHours = hours % 12 === 0 ? 12 : hours % 12

                return `${formattedHours.toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                })}:${minutes.toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                })} ${ampm}`
              })()}
            </p>
          </div>
        )
      },
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row, i) => row.status,
      minWidth: '130px',
      format: (row, i) => {
        return state.changeStatusId === row.id ? (
          <span className='badge text-black p-4 badge-light'>Loading...</span>
        ) : (
          <SelectOption
            options={[
              {value: 'Active', title: 'Active'},
              {value: 'InActive', title: 'Inactive'},
            ]}
            selected={row.status}
            onChange={async (e) => {
              try {
                setState({...state, changeStatusId: row.id})
                const value = e.target.value
                const result = await updatePostAndEventStatus(auth?.token, {
                  id: row.id,
                  status: value,
                  type: POST_EVENT_TYPE.POST,
                })
                if (result.status === 200) {
                  window.location.reload()
                  setState({
                    ...state,
                    posts: [
                      ...state.posts.slice(0, i),
                      Object.assign({}, state.posts[i], {status: value}),
                      ...state.posts.slice(i + 1),
                    ],
                    changeStatusId: null,
                  })
                } else {
                  toast(result.data.message)
                  setState({...state, changeStatusId: null})
                }
              } catch (error: any) {
                setState({...state, changeStatusId: null})
                toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
              }
            }}
          />
        )
      },
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row, i) => row.status,
      minWidth: '150px',
      format: (row, i) => {
        return (
          <>
            {currentUser?.role === 'charity' ? (
              <ActionButton
                deleteCallback={() =>
                  setState({
                    ...state,
                    modalView: true,
                    selectedRow: {title: row.title, id: row.id},
                    type: MODAL_TYPE_ENUM.DELETE,
                  })
                }
                editCallback={() => {
                  if (row.status === 'InActive' && row.inActiveBy !== row.charity.id) {
                    toast.error(
                      'Not allowed to edit because this post has been InActivated by the administrator'
                    )
                  } else {
                    setState({
                      ...state,
                      addEditModelView: true,
                      selectedRow: {row},
                      type: MODAL_TYPE_ENUM.ADD_EDIT_POST,
                    })
                  }
                }}
                viewCallback={() =>
                  setState({
                    ...state,
                    modalView: true,
                    selectedRow: row,
                    type: MODAL_TYPE_ENUM.POST,
                  })
                }
              />
            ) : (
              <ActionButton
                deleteCallback={() =>
                  setState({
                    ...state,
                    modalView: true,
                    selectedRow: {title: row.title, id: row.id},
                    type: MODAL_TYPE_ENUM.DELETE,
                  })
                }
                viewCallback={() =>
                  setState({
                    ...state,
                    modalView: true,
                    selectedRow: row,
                    type: MODAL_TYPE_ENUM.POST,
                  })
                }
              />
            )}
          </>
        )
      },
      sortable: false,
    },
  ]

  const postColumnsAdmin = [
    {
      name: 'Charity Name',
      selector: (row, i) => row.charity.name,
      width: '220px',
      format: (row, i) => {
        return (
          <>
            <div className='d-flex align-items-center m-2 min-w-500px'>
              <div
                className='symbol symbol-50px me-5'
                onClick={() => {
                  setState({
                    ...state,
                    modalView: true,
                    selectedRow: row,
                    type: MODAL_TYPE_ENUM.POST,
                  })
                }}
              >
                <img
                  src={
                    row.charity.profilePic
                      ? row.charity.profilePic
                      : toAbsoluteUrl('/media/avatars/blank.png')
                  }
                  className='h-[75px] w-[75px] symbol-label align-self-end'
                  alt=''
                />
              </div>
              <div className='d-flex justify-content-start flex-column'>
                <span
                  className='text-dark text-hover-primary fw-bold mb-1 fs-6'
                  onClick={() => {
                    setState({
                      ...state,
                      modalView: true,
                      selectedRow: row,
                      type: MODAL_TYPE_ENUM.POST,
                    })
                  }}
                  data-toggle='tooltip'
                  data-placement='top'
                  title={row.charity.name}
                >
                  {row.charity.name}
                </span>
              </div>
            </div>
          </>
        )
      },
      sortable: true,
    },

    {
      name: 'Post Title',
      selector: (row, i) => row.title,
      width: '220px',
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center m-2'>
            <div
              className='symbol symbol-50px me-5'
              onClick={() =>
                setState({
                  ...state,
                  modalView: true,
                  selectedRow: row,
                  type: MODAL_TYPE_ENUM.POST,
                })
              }
            >
              <img
                src={row.media[0]?.thumbnailURL ? row.media[0]?.thumbnailURL : row.media[0]?.url}
                className='h-[70px] w-[70px] symbol-label align-self-center'
                alt=''
              />
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <p
                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                onClick={() =>
                  setState({
                    ...state,
                    modalView: true,
                    selectedRow: row,
                    type: MODAL_TYPE_ENUM.POST,
                  })
                }
                data-toggle='tooltip'
                data-placement='top'
                title={row.title}
              >
                {row.title}
              </p>
            </div>
          </div>
        )
      },
      sortable: true,
    },

    {
      name: 'Date & Time',
      selector: (row, i) => row.date,
      minWidth: '175px',
      format: (row, i) => {
        return (
          <div className='text-dark fw-bold fs-6'>
            <p
              className='text-dark fw-bold text-hover-primary mb-1 fs-6'
              onClick={() =>
                setState({
                  ...state,
                  modalView: true,
                  selectedRow: row,
                  type: MODAL_TYPE_ENUM.POST,
                })
              }
            >
              {new Date(row.date || '').toLocaleDateString('en-GB')}{' '}
              {(() => {
                const date = new Date(row.date || '')
                const hours = date.getHours()
                const minutes = date.getMinutes()
                const ampm = hours >= 12 ? 'PM' : 'AM'
                const formattedHours = hours % 12 === 0 ? 12 : hours % 12

                return `${formattedHours.toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                })}:${minutes.toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                })} ${ampm}`
              })()}
            </p>
          </div>
        )
      },
      sortable: true,
    },

    {
      name: 'Status',
      selector: (row, i) => row.status,
      minWidth: '130px',
      format: (row, i) => {
        return state.changeStatusId === row.id ? (
          <span className='badge text-black p-4 badge-light'>Loading...</span>
        ) : (
          <SelectOption
            options={[
              {value: 'Active', title: 'Active'},
              {value: 'InActive', title: 'Inactive'},
            ]}
            selected={row.status}
            onChange={async (e) => {
              try {
                setState({...state, changeStatusId: row.id})
                const value = e.target.value
                const result = await updatePostAndEventStatus(auth?.token, {
                  id: row.id,
                  status: value,
                  type: POST_EVENT_TYPE.POST,
                })
                if (result.status === 200) {
                  window.location.reload()
                  setState({
                    ...state,
                    posts: [
                      ...state.posts.slice(0, i),
                      Object.assign({}, state.posts[i], {status: value}),
                      ...state.posts.slice(i + 1),
                    ],
                    changeStatusId: null,
                  })
                } else {
                  toast(result.data.message)
                  setState({...state, changeStatusId: null})
                }
              } catch (error: any) {
                setState({...state, changeStatusId: null})
                toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
              }
            }}
          />
        )
      },
      sortable: true,
    },

    {
      name: 'Action',
      selector: (row, i) => row.status,
      minWidth: '100px',
      format: (row, i) => {
        return (
          <>
            {currentUser?.role === 'charity' ? (
              <ActionButton
                deleteCallback={() =>
                  setState({
                    ...state,
                    modalView: true,
                    selectedRow: {title: row.title, id: row.id},
                    type: MODAL_TYPE_ENUM.DELETE,
                  })
                }
                editCallback={() => {
                  if (row.status === 'InActive' && row.inActiveBy !== row.charity.id) {
                    toast.error(
                      'Not allowed to edit because this post has been InActivated by the administrator'
                    )
                  } else {
                    setState({
                      ...state,
                      addEditModelView: true,
                      selectedRow: {row},
                      type: MODAL_TYPE_ENUM.ADD_EDIT_POST,
                    })
                  }
                }}
                viewCallback={() =>
                  setState({
                    ...state,
                    modalView: true,
                    selectedRow: row,
                    type: MODAL_TYPE_ENUM.POST,
                  })
                }
              />
            ) : (
              <ActionButton
                deleteCallback={() =>
                  setState({
                    ...state,
                    modalView: true,
                    selectedRow: {title: row.title, id: row.id},
                    type: MODAL_TYPE_ENUM.DELETE,
                  })
                }
                viewCallback={() =>
                  setState({
                    ...state,
                    modalView: true,
                    selectedRow: row,
                    type: MODAL_TYPE_ENUM.POST,
                  })
                }
              />
            )}
          </>
        )
      },
      sortable: false,
    },
    {
        name: 'Post Visibility',
        selector: (row) => row.hidden,
        width: '200px',
        format: (row) => {
          return (
            <div className='text-dark fw-bold fs-6'>
              <SelectOption
            options={[
              {value: 'visible', title: 'Visible'},
              {value: 'hidden', title: 'Hidden'}]}
            selected={row.hidden === true ? 'hidden' : 'visible'}
            onChange={async (e) => {
              try {
                const value = e.target.value
                const result = await setPostHidden(auth?.token, {
                  hidden: value === "hidden",
                  postId: row.id,
                })
                if (result.status === 200) {
                  if (auth) {
                    getPosts(
                      auth.token,
                      totalPage === currentPage ? 1 : state.currentPage,
                      state.filters.id,
                      state.filters,
                      state.search,
                      pageSize)
                    }} else {
                  toast(result.data.message)
                }
              } catch (error: any) {
                toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
              }
            }}
            />
            </div>
          )
        },
        sortable: true,
        }
  ]

  const debouncedOnChange = debounce(
    (value) =>
      setState((prev) => ({
        ...prev,
        search: value,
      })),
    1000
  )

  return (
    <>
      {currentUser?.role === 'charity' ? (
        <DataTableWrapper
          loading={state.loading}
          filters={{
            dropdown: [
              {
                label: 'Status',
                options: ['Active', 'InActive'].map((t) => ({value: t, label: t.toLowerCase()})),
              },
            ],
            date: true,
          }}
          filtersCallback={(filters) => {
            if (filters?.reset) {
              removeEventPostStore()
              state.filters.id = ''
              setState((prev) => ({
                ...prev,
                filters: initialState.filters,
                currentPage: initialState.currentPage,
                search: initialState.search,
              }))
            } else {
              setState((prev) => ({
                ...prev,
                currentPage: initialState.currentPage,
                filters: {
                  endDate: filters.endDate
                    ? new Date(filters.endDate).toLocaleDateString('en-ZA')
                    : '',
                  startDate: filters.startDate
                    ? new Date(filters.startDate).toLocaleDateString('en-ZA')
                    : '',
                  id: filters['Charity Name']?.value || '',
                  status: filters['Status']?.value || '',
                },
              }))
            }
          }}
          totalPages={state.totalPages}
          currentPage={state.currentPage}
          handlePageChange={(page) => setState({...state, currentPage: page})}
          columns={postColumns}
          data={state.posts}
          handleSearch={debouncedOnChange}
          onSelectedRowsChange={handleSelectedRowsChange}
          onDelete={() => setState({...state, multipleDeleteModelView: true})}
          selectableRows
          totalActivePost={state.totalActivePost}
          totalInActivePost={state.totalInActivePost}
          onPageSizeChange={(newValue: number) => setPageSize(newValue)}
          pageSize={pageSize}
        />
      ) : (
        <DataTableWrapper
          loading={state.loading}
          filters={{
            dropdown: [
              {
                label: 'Charity Name',
                options: state.charities?.map((t) => ({
                  value: t['_id'],
                  label: t['name'],
                })),
                // selected: values?.id,
              },

              {
                label: 'Status',
                options: ['Active', 'InActive'].map((t) => ({value: t, label: t.toLowerCase()})),
              },
            ],
            date: true,
          }}
          filtersCallback={(filters) => {
            if (filters?.reset) {
              removeEventPostStore()
              state.filters.id = ''
              setState((prev) => ({
                ...prev,
                filters: initialState.filters,
                currentPage: initialState.currentPage,
                search: initialState.search,
              }))
            } else {
              setState((prev) => ({
                ...prev,
                currentPage: initialState.currentPage,
                filters: {
                  endDate: filters.endDate
                    ? new Date(filters.endDate).toLocaleDateString('en-ZA')
                    : '',
                  startDate: filters.startDate
                    ? new Date(filters.startDate).toLocaleDateString('en-ZA')
                    : '',
                  id: filters['Charity Name']?.value || '',
                  status: filters['Status']?.value || '',
                },
              }))
            }
          }}
          totalPages={state.totalPages}
          currentPage={state.currentPage}
          handlePageChange={(page) => setState({...state, currentPage: page})}
          columns={postColumnsAdmin}
          data={state.posts}
          handleSearch={debouncedOnChange}
          onSelectedRowsChange={handleSelectedRowsChange}
          onDelete={() => setState({...state, multipleDeleteModelView: true})}
          selectableRows
          totalActivePost={state.totalActivePost}
          totalInActivePost={state.totalInActivePost}
          onPageSizeChange={(newValue: number) => setPageSize(newValue)}
          pageSize={pageSize}
        />
      )}

      {state.multipleDeleteModelView && (
        <CustomModal
          type={'DELETE'}
          body={selectedRow}
          deleteLoading={state.deleteLoading}
          handleConfirm={async () => {
            try {
              setState({...state, deleteLoading: true})
              const result = await deleteMultiplePostEvent(currentUser?.token, selectedRow, 'post')
              if (result.status === 200) {
                setDeleteValue(true)
                toast.success(result.data.message || 'Posts Deleted Succesfully')
                setState({
                  ...state,
                  multipleDeleteModelView: false,
                  deleteLoading: false,
                })
                setSelectedRows([])
              }
            } catch (error: any) {
              setState({
                ...state,
                multipleDeleteModelView: false,
                deleteLoading: false,
              })
              toast(error?.response?.data?.message || 'Something went wrong!')
            }
            setSelectedRows([])
          }}
          handleClose={() => {
            setState({...state, multipleDeleteModelView: false})
            setSelectedRows([])
          }}
        />
      )}
      {state.modalView && (
        <CustomModal
          type={state.type}
          body={state.selectedRow}
          deleteLoading={state.deleteLoading}
          handleConfirm={async () => {
            try {
              setState({...state, deleteLoading: true})
              const result = await deletePostOrEvent(
                auth?.token,
                POST_EVENT_TYPE.POST,
                state.selectedRow.id
              )
              if (result.status === 200) {
                setState({
                  ...state,
                  modalView: false,
                  posts: state.posts.filter((post) => post.id !== state.selectedRow.id),
                  selectedRow: null,
                  type: null,
                  deleteLoading: false,
                })
                toast.success(result?.data?.message)
              }
            } catch (error: any) {
              setState({
                ...state,
                modalView: false,
                selectedRow: null,
                type: null,
                deleteLoading: false,
              })
              toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
            }
          }}
          handleClose={() => setState({...state, modalView: false, selectedRow: null, type: null})}
        />
      )}
      {state.addEditModelView && (
        <CustomModal
          handleClose={() => {
            setState({...state, addEditModelView: false, selectedRow: null, type: null})
          }}
          body={state.selectedRow}
          type={MODAL_TYPE_ENUM.ADD_EDIT_POST}
        />
      )}

      {currentUser?.role === 'charity' && modelView && (
        <CustomModal
          type={MODAL_TYPE_ENUM.SIGNOUT_CHARITY}
          body={{title: title}}
          handleClose={() => setModelView(false)}
        />
      )}
    </>
  )
}

const PostsWrapper = () => (
  <>
    <PageTitle>Posts</PageTitle>
    <Post />
    <ToastContainer />
  </>
)

export {PostsWrapper}

import React, {FC, useCallback, useEffect, useState} from 'react'
import {DataTableWrapper} from '../../DataTable'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {SectionWrapper} from '../../Common'
import ActionButton from '../../Common/ActionButton'
import {useAuth} from '../../../app/modules/auth'
import {
  ACCOUNT_TYPE,
  ROLE_TYPE,
  userDonorTransactionDetails,
} from '../../../app/modules/auth/core/_requests'
import {toast} from 'react-toastify'

interface DonorTransactionsProps {
  donorId: any
  getDetails?: any
  handleView: any
}
const DonorTransactions: FC<DonorTransactionsProps> = ({donorId, getDetails, handleView}) => {
  const {auth} = useAuth()

  const transactionColumns = [
    {
      name: 'Charity Name',
      selector: (row, i) => row.charityName,
      width: '200px',
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center m-2'>
            <div className='symbol symbol-50px me-5' onClick={() => handleView(row)}>
              <span className='symbol-label bg-light'>
                <img
                  src={row.profilePic ? row.profilePic : toAbsoluteUrl('/media/avatars/blank.png')}
                  className='h-[75px] w-[75px] symbol-label align-self-end'
                  alt=''
                />
              </span>
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <span
                className='text-dark fw-bold mb-1 fs-6 text-hover-primary'
                onClick={() => handleView(row)}
              >
                {row.charityName}
              </span>
            </div>
          </div>
        )
      },
      sortable: true,
    },

    {
      name: 'Date & Time',
      selector: (row, i) => row.date,
      format: (row, i) => {
        return (
          <div
            className='text-dark fw-bold fs-6 text-hover-primary'
            onClick={() => handleView(row)}
          >
            {new Date(row.date || '').toLocaleDateString('en-GB')}{' '}
            {(() => {
              const date = new Date(row.date || '')
              const hours = date.getHours()
              const minutes = date.getMinutes()
              const ampm = hours >= 12 ? 'PM' : 'AM'
              const formattedHours = hours % 12 === 0 ? 12 : hours % 12

              return `${formattedHours.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
              })}:${minutes.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
              })} ${ampm}`
            })()}{' '}
          </div>
        )
      },
      sortable: true,
    },
    {
      name: 'Amount',
      selector: (row, i) => parseFloat(row.amount),
      format: (row, i) => (
        <>
          <span
            className='text-dark fw-bold fs-6 text-hover-primary'
            onClick={() => handleView(row)}
          >
            ${row.amount}
          </span>
        </>
      ),
      sortable: true,
      sortCompare: (a, b) => a - b,
    },
    {
      name: 'Payment method',
      selector: (row, i) => row.payment_method,
      format: (row, i) => {
        return (
          <>
            <div
              className='text-dark fw-bold fs-6 text-hover-primary'
              onClick={() => handleView(row)}
            >
              {row.payment_method === ACCOUNT_TYPE.BANK ? 'BANK' : 'CARD'}
            </div>
          </>
        )
      },
      sortable: true,
    },
    {
      name: 'Status',
      selector: (row, i) => row.status,
      format: (row, i) => {
        return (
          <span
            className={`badge uppercase text-hover-primary badge-light-${
              !row.status.includes('succeeded') ? 'danger' : 'success'
            }`}
            onClick={() => handleView(row)}
          >
            {row.status}
          </span>
        )
      },
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row, i) => row.status,
      format: (row, i) => {
        return (
          <>
            <ActionButton viewCallback={() => handleView(row)} />
          </>
        )
      },
      sortable: false,
    },
  ]

  const [state, setState] = useState({transactions: [], loading: true, error: null})
  const [pageSize, setPageSize] = useState(10)

  const getTransactionsByDonor = useCallback(
    async (token, role, id) => {
      try {
        const result = await userDonorTransactionDetails(token, role, id, pageSize)
        if (result.status === 200) {
          setState({...state, transactions: result.data.data.transactionList, loading: false})
        }
      } catch (error: any) {
        setState({...state, transactions: [], loading: false, error: error})
        toast(error?.response?.data?.message)
      }
    },
    [state, pageSize]
  )

  useEffect(() => {
    if (donorId && auth) {
      getTransactionsByDonor(auth?.token, ROLE_TYPE.DONOR, donorId)
    }
  }, [])

  return (
    <>
      <SectionWrapper title='Transaction Details' id='tran_details'>
        <DataTableWrapper
          viewDetailsLink={{
            id: donorId,
            role: ROLE_TYPE.DONOR,
            link: `/view-donor-donation/${donorId}`,
          }}
          getDetails={getDetails}
          columns={transactionColumns}
          data={state.transactions}
          loading={state.loading}
        />
      </SectionWrapper>
    </>
  )
}

export default DonorTransactions

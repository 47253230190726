import React from 'react'
import {useNavigate, useLocation} from 'react-router-dom'

export default function CharitySignoutBody({body, handleClose}) {
  const navigate = useNavigate()
  const path = useLocation()
  const pathname = path.pathname
  const getContactPagePath = () => {
    if (pathname.startsWith('/auth')) {
      return '/contact-us' + pathname.slice('/auth'.length)
    } else {
      handleClose()
      return '/contacts-us'
    }
  }

  return (
    <div className='text-center'>
      <div className='card-body'>
        <p className='card-text text-muted text-dark fw-bolder fs-3 mb-0'>{body?.title}</p>

        <div className='d-flex my-4 flex-center'>
          <button
            className='btn btn-danger font-weight-bold px-9 py-4 my-3'
            onClick={() => navigate(getContactPagePath())}
          >
            Contact
          </button>
        </div>
      </div>
    </div>
  )
}

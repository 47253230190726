/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../_metronic/helpers'
import {TEvent} from '../Modal/Body/body'

type Props = {
  className: string
  events: TEvent[]
  viewCallback: (event) => void
}

const TopEvents: React.FC<Props> = ({className, events, viewCallback}) => {
  return (
    <div className={`card ${className} relative`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5 sticky'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Latest Events</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 w-50px'></th>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {events.length > 0 &&
                    events.map((event: TEvent, i) => (
                      <tr key={i}>
                        <td>
                          <div
                            className='symbol symbol-45px me-2 cursor-pointer'
                            onClick={() => viewCallback(event)}
                          >
                            <img
                              src={
                                event.media[0]?.thumbnailURL
                                  ? event.media[0]?.thumbnailURL
                                  : event.media[0]?.url
                              }
                              className='h-[70px] w-[70px] symbol-label align-self-center'
                              alt=''
                            />
                          </div>
                        </td>
                        <td style={{maxWidth: '20px', overflow: 'hidden'}}>
                          <span
                            className='text-dark fw-bold mb-1 fs-6 cursor-pointer text-hover-primary'
                            onClick={() => viewCallback(event)}
                            style={{whiteSpace: 'nowrap', display: 'inline-block', width: '100%'}}
                            data-toggle='tooltip'
                            data-placement='top'
                            title={event.title}
                          >
                            {event.title}
                          </span>
                        </td>
                        <td className='text-end'>
                          <button
                            onClick={() => viewCallback(event)}
                            className='btn btn-sm btn-icon btn-bg-light btn-active-color-primary'
                          >
                            <KTIcon iconName='eye' className='fs-2' />
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
                {/* end::Table body */}
              </table>
              {events.length >= 10 && (
                <div
                  className='text-center mb-4'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '0px',
                  }}
                >
                  <Link to='/events'>View More</Link>
                </div>
              )}
            </div>
            {/* end::Table */}
          </div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {TopEvents}

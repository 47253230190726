import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useAuth} from '../../modules/auth'
import {useCallback, useEffect, useState} from 'react'
import {dashboard, userDetails} from '../../modules/auth/core/_requests'
import {CustomModal, MODAL_TYPE_ENUM} from '../../../components/Modal'
import DashBoardLoader from './Loader'
import {RecentPosts, TopCharities, TotalCountCard, TopEvents} from '../../../components/Dashboard'
import {TEvent, TMedia, TPost} from '../../../components/Modal/Body/body'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import AccountStatusBody from '../../../components/Modal/Body/AccountStatusBody'
import StripeConnectBody from '../../../components/Modal/Body/StripeConnectBody'

const DashboardPage = ({
  state,
  viewPost,
  viewEvent,
  currentUser,
}: {
  state: InitialState
  viewPost: (post: TPost) => void
  viewEvent: (event: TEvent) => void
  currentUser
}) => {
  const navigate = useNavigate()
  return (
    <>
      <div style={{ margin: '20px 0' }}> 
          <StripeConnectBody/>
      </div>
      <div>
        {!currentUser.isApproved && (<AccountStatusBody/>)}
      </div>
      <div className='row g-5 g-xl-8'>
        {currentUser?.role === 'admin' && (
          <div className='col-xl-4'>
            <TotalCountCard
              className='card-xl-stretch mb-xl-8 cursor-pointer '
              color='body-white'
              numbersColor='primary'
              title='Total No. Of Charities'
              numbers={state.totalCharities as unknown as string}
              titleColor='gray-900'
              onClick={() => navigate('/charity-management')}
            />
          </div>
        )}
         <div ></div>
        <div className='col-xl-4'>
          <TotalCountCard
            className={`card-xl-stretch mb-xl-8 ${
              currentUser.role === 'admin' ? 'cursor-pointer' : ''
            }`}
            color='primary'
            numbersColor='white'
            title='Total No. Of Donors'
            numbers={state.totalDonors as unknown as string}
            titleColor='white'
            onClick={() => (currentUser.role === 'admin' ? navigate('/donor-management') : '')}
          />
        </div>
        <div className='col-xl-4'>
          <TotalCountCard
            className='card-xl-stretch mb-xl-8 cursor-pointer '
            color='dark'
            numbersColor='white'
            title='Total Donations'
            numbers={state.totalDonations as unknown as string}
            titleColor='white'
            onClick={() => navigate('/donations-management')}
          />
        </div>
        {currentUser?.role === 'charity' && (
          <div className='col-xl-4'>
            <TotalCountCard
              className='card-xl-stretch mb-xl-8 cursor-pointer '
              color='body-white'
              numbersColor='primary'
              title='Total No. Of Subscriptions'
              numbers={state.totalSubscription as unknown as string}
              titleColor='gray-900'
              onClick={() => navigate('/subscriptions')}
            />
          </div>
        )}
      </div>

      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          {currentUser.role === 'charity' ? (
            <TopEvents
              viewCallback={viewEvent}
              events={state.events}
              className='card-xl-stretch mb-5 mb-xl-8 dashboard-list'
            />
          ) : (
            <TopCharities
              charities={state.charities}
              className='card-xl-stretch mb-xl-8 dashboard-list'
            />
          )}
        </div>

        <div className='col-xl-8'>
          <RecentPosts
            viewCallback={viewPost}
            posts={state.posts}
            className='card-xl-stretch mb-5 mb-xl-8 dashboard-list'
          />
        </div>
      </div>
    </>
  )
}

export interface Charity {
  _id: string
  name: string
  profilePic: string
}
export interface Event {
  _id: string
  title: string
  media: TMedia[]
}

interface InitialState {
  posts: TPost[]
  charities: Charity[]
  events: TEvent[]
  totalCharities: number
  totalDonors: number
  totalDonations: number
  totalSubscription: number
  loading: boolean
  showPostDialog: boolean
  showEventDialog: boolean
  selectedPost: TPost | null
  selectedEvent: TEvent | null
  error: any | null
  approvedView:boolean
  title:string
}
const initialState = {
  posts: [],
  charities: [],
  events: [],
  totalCharities: 0,
  totalDonors: 0,
  totalDonations: 0,
  totalSubscription: 0,
  loading: true,
  showPostDialog: false,
  showEventDialog: false,
  selectedPost: null,
  selectedEvent: null,
  error: null,
  approvedView:false,
  title:''
}

const DashboardWrapper = () => {
  const intl = useIntl()
  const {auth, currentUser, setCurrentUser} = useAuth()
  const [state, setState] = useState<InitialState>(initialState)
  const [modelView, setModelView] = useState(false)
  const [title, setTitle] = useState('')
  const img=(<img
    className='mx-auto w-80px w-md-80 w-xl-80px mb-4'
    style={{objectFit: 'contain', maxHeight: '100%'}}
    src={toAbsoluteUrl('/media/custom-img/approved.png')}
    alt=''
  />)
  const getDashboard = useCallback(
    async (token: string) => {
      try {
        const result = await dashboard(token)
        // console.log("-------");
        // console.log(result);
        if (result?.status === 200) {
          setState({
            ...state,
            charities: result.data.data.latestCharity,
            events: result.data.data.latestEvent,
            posts: result.data.data.latestPost,
            totalCharities: result.data.data.totalCharities,
            totalDonations: result.data.data.totalDonation,
            totalDonors: result.data.data.totalDonors,
            totalSubscription: result.data.data.totalSubscription,
            loading: false,
            ...(result.data.data.adminComment && { approvedView: true, title: result.data.data.adminComment })
          })
         
        } else {
          toast(result.data.message)
        }
      } catch (error: any) {
        setState({...state, loading: false, error})
        if (error.response.status === 600) {
          setTitle(error?.response?.data?.message)
          setModelView(true)
        } else {
          toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
        }
      }
    },
    []
  )

  const fetchUserProfile = useCallback(async () => {
    if (auth?.token) {
      try {
        const userResult = await userDetails(auth.token, auth.role);
        if (userResult?.status === 200) {
          setCurrentUser({
            ...userResult.data.data,
            token: auth.token,
            role: userResult.data.data.role
          });
        } else {
          toast('Failed to fetch user details');
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
        toast('Error fetching user details');
      }
    }
  }, [auth, setCurrentUser]); // Ensure dependencies are listed correctly
  
  useEffect(() => {
    if (auth && auth.token) {
      getDashboard(auth.token);
      fetchUserProfile(); // Moved inside the check for auth and token
    }
  }, [auth, getDashboard, fetchUserProfile]); // 

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      {state.loading ? (
        <DashBoardLoader />
      ) : (
        <>
          <DashboardPage
            viewPost={(post) => {
              setState({...state, selectedPost: post, showPostDialog: true})
            }}
            viewEvent={(event) => {
              setState({...state, selectedEvent: event, showEventDialog: true})
            }}
            state={state}
            currentUser={currentUser}
          />
          {currentUser?.role === 'charity' && modelView && (
            <CustomModal
              type={MODAL_TYPE_ENUM.SIGNOUT_CHARITY}
              handleClose={() => setModelView(false)}
              body={{title: title}}
            />
          )}

          {state.showEventDialog && (
            <CustomModal
              type={MODAL_TYPE_ENUM.EVENT}
              body={state.selectedEvent}
              handleClose={() => setState({...state, showEventDialog: false, selectedEvent: null})}
            />
          )}
          {state.showPostDialog && (
            <CustomModal
              type={MODAL_TYPE_ENUM.POST}
              body={state.selectedPost}
              handleClose={() => setState({...state, showPostDialog: false, selectedPost: null})}
            />
          )}
           {state.approvedView && (
            <CustomModal
              handleClose={() => {
                setState({...state,approvedView:false,})
              }}
              headerView={true}
              type={MODAL_TYPE_ENUM.SINGLE_TEXT_BUTTON}
              body={{title: state.title}}
              buttonText='Ok'
              img={img}
            />
          )}
        </>
      )}
    </>
  )
}

export {DashboardWrapper}

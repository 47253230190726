/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {login, userDetails} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {toast} from 'react-toastify'
import {Link} from 'react-router-dom'
import {CustomModal, MODAL_TYPE_ENUM} from '../../../../components/Modal'
import {KTIcon} from '../../../../_metronic/helpers/components/KTIcon'
import useMobile from '../../../../hooks/useMobile'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(16, 'Password must be at most 16 characters')
    .matches(/^\S*$/, 'Password cannot contain spaces')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [modelView, setModelView] = useState(false)
  const isMobile = useMobile();
  const [title, setTitle] = useState('')

  const [showPassword, setShowPassword] = useState({
    password: false,
  })

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {

    
        const {data: auth, status} = await login(values.email, values.password);
        // console.log(auth)
        if (auth) {
          try {
            // console.log(auth.role);

            const user = await userDetails(auth.token, auth.role)
            
            // console.log(user.data);
            // console.log(user.data.data);
            const currentUser = {
              ...user.data.data,
              token: auth.token,
              role: user.data.data.role,
              status: status || user.status,
            }
            saveAuth(currentUser)
            setCurrentUser(currentUser)
          } catch (error: any) {
            toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
          }
        }
      } catch (error: any) {
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
        if (error.response.status === 600) {
          setTitle(error?.response?.data?.message)
          setModelView(true)
        } else {
          toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
        }
      }
    },
  })

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        {/* begin::Heading */}
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Sign In</h1>
        </div>
        {/* begin::Heading */}

        {/* begin::Form group */}
        <div className='fv-row mb-8' style={{width: isMobile ? "80vw" : "100%"}}>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control bg-transparent',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
            type='email'
            name='email'
            autoComplete='off'
            
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors.email}</div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-3'>
          <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
          <input
            // type='password'
            type={showPassword?.password ? 'text' : 'password'}
            autoComplete='new-password'
            placeholder='Password'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control bg-transparent',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {showPassword?.password ? (
            <span
              className={clsx(
                {'password-toggle-icon-auth': formik.touched.password && formik.errors.password},
                {
                  'password-toggle-icon-auth': formik.touched.password && !formik.errors.password,
                },
                'password-toggle-icon'
              )}
              onClick={() => setShowPassword({...showPassword, password: !showPassword.password})}
            >
              <KTIcon iconName='eye' className='fs-2' />
            </span>
          ) : (
            <span
              className={clsx(
                {'password-toggle-icon-auth': formik.touched.password && formik.errors.password},
                {
                  'password-toggle-icon-auth': formik.touched.password && !formik.errors.password,
                },
                'password-toggle-icon'
              )}
              onClick={() => setShowPassword({...showPassword, password: !showPassword.password})}
            >
              <KTIcon iconName='eye-slash' className='fs-2' />
            </span>
          )}
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Action */}
        <div className='d-grid mb-10'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-primary'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Action */}
      </form>
      <div className='d-none d-lg-block'>
        <div style={{textAlign: 'right'}}>
          <Link to='/forgot-password' className='fs-6 fw-bold'>
            Forgot Password?
          </Link>
        </div>
        <div className='text-center'>
          <Link to='/signup' className='fs-6 fw-bold'>
            Signup
          </Link>
        </div>
      </div>
      <div className='d-lg-none' style={{display: 'flex', justifyContent: 'space-between'}}>
        <div>
          <Link to='/signup' className='fs-6 fw-bold'>
            Signup
          </Link>
        </div>
        <div>
          <Link to='/forgot-password' className='fs-6 fw-bold'>
            Forgot Password?
          </Link>
        </div>
      </div>

      {modelView && (
        <CustomModal
          type={MODAL_TYPE_ENUM.SIGNOUT_CHARITY}
          handleClose={() => setModelView(false)}
          body={{title: title}}
        />
      )}
    </>
  )
}

import {useFormik} from 'formik'
import * as Yup from 'yup'
import {addCharityCategory, addStripeBank} from '../../../app/modules/auth/core/_requests'
import {ToastContainer, toast} from 'react-toastify'
import {useState} from 'react'
import {useAuth} from '../../../app/modules/auth'

const validationSchema = Yup.object({
  charityCategory: Yup.string()
    .required('Category Name is required')
    .matches(/^[A-Za-z\s]+$/, 'Only characters are allowed'),
})

const AddCharityCategoryBody = ({handleClose, setLoader}) => {
  const [loading, setLoading] = useState(false)
  const {currentUser} = useAuth()

  const handleAddStripeBankAccount = async (values) => {
    try {
      setLoading(true)
      const response = await addCharityCategory(currentUser?.role, values)
      if (response.status === 201) {
        toast.success(response?.data?.message || 'Charity category added successfully')
        handleClose()
        formik.resetForm()
        setTimeout(() => {
          window.location.reload()
        }, 1000)
      }
    } catch (error: any) {
      toast.error(error.response?.data?.message || 'Error adding charity category')
    } finally {
      setLoading(false)
      setLoader(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      charityCategory: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      handleAddStripeBankAccount(values)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='card-body p-0'>
        <div className='d-flex flex-column align-items-center'>
          {/* Category Name Field */}
          <div className='form-group'>
            <input
              type='text'
              name='charityCategory'
              autoComplete='off'
              placeholder='Category Name'
              maxLength={30}
              className={`form-control form-control-lg form-control-solid mx-4 mb-4 ${
                formik.touched.charityCategory && formik.errors.charityCategory ? 'is-invalid' : ''
              }`}
              value={formik.values.charityCategory}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.charityCategory && formik.errors.charityCategory && (
              <div className='invalid-feedback' style={{marginLeft: '10px'}}>
                {formik.errors.charityCategory}
              </div>
            )}
          </div>
          <div className='d-flex my-4 flex-center'>
            <button
              className='btn btn-danger font-weight-bold px-9 py-4 my-3 mx-4 '
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type='submit'
              className='btn btn-primary font-weight-bold px-9 py-4 my-3'
              disabled={loading}
            >
              {loading ? (
                <div className='d-flex align-items-center'>
                  <span>Submitting ...</span>
                  <span
                    className='spinner-border spinner-border-sm mx-2'
                    role='status'
                    aria-hidden='true'
                  ></span>
                </div>
              ) : (
                'Add'
              )}
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </form>
  )
}

export default AddCharityCategoryBody

import React, {useCallback, useEffect, useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {readableDate} from '../../../utils'
import {Slider} from '../../Common'
import {TComment, TEvent} from './body'
import {charityPostComment, deletePostEventComment} from '../../../app/modules/auth/core/_requests'
import {useAuth} from '../../../app/modules/auth'
import {POST_EVENT_TYPE} from '../../../app/modules/auth/core/_requests'
import {formatDistanceToNow} from 'date-fns'
import {ToastContainer, toast} from 'react-toastify'
import {Link} from 'react-router-dom'
import {CustomModal} from '..'
export interface TInitialState {
  comments: TComment[]
  currentPage: number
  totalPages: number
  type: any
  modalView: boolean
  commentCategory: string
  id: string
  deleteLoading: boolean
  totalComments: number
}

const initialState = {
  comments: [],
  currentPage: 1,
  totalPages: 1,
  type: null,
  modalView: false,
  commentCategory: '',
  id: '',
  deleteLoading: false,
  totalComments: 0,
}

export default function EventBody({event}: {event: TEvent}) {
  const {auth, currentUser} = useAuth()
  const [showComment, setShowComment] = useState(false)

  const [state, setState] = useState<TInitialState>(initialState)

  const timeAgo = (dateString: string) => {
    const date = new Date(dateString)
    return formatDistanceToNow(date, {addSuffix: true})
  }

  const getComments = useCallback(
    async (id, token, type, page) => {
      try {
        const result = await charityPostComment(id, token, type, page)

        if (result.status === 200) {
          setState((prev) => ({
            ...prev,
            comments: [...prev.comments, ...(result.data.data?.commentList || [])],
            totalComments: result.data.data?.totalComments || 0,
            currentPage: result.data.data?.currentPage || 1,
            totalPages: result.data.data?.totalPages || 1,
          }))
        } else {
          toast(result.data.message)
        }
      } catch (error: any) {
        toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
      }
    },
    [state.currentPage]
  )

  const handleSeeMore = () => {
    if (auth && auth.token) {
      getComments(
        event.id ? event.id : event._id,
        auth.token,
        POST_EVENT_TYPE.EVENT,
        state.currentPage + 1
      )
    }
  }

  useEffect(() => {
    if (auth && auth.token) {
      getComments(
        event.id ? event.id : event._id,
        auth.token,
        POST_EVENT_TYPE.EVENT,
        state.currentPage
      )
    }
  }, [])
  const handleDeleteComment = (id, commentCategory) => {
    setState((prev) => ({...prev, id: id, commentCategory: commentCategory, modalView: true}))
  }

  const handleShowComment = () => {
    if (state.comments.length > 0) {
      setShowComment(true)
    }
  }

  const showSeeMoreButton = state.currentPage < state.totalPages

  const [showFullComments, setShowFullComments] = useState<boolean[]>([])

  const toggleComment = (commentIndex: number) => {
    setShowFullComments((prev) => {
      const newShowFullComments = [...prev]
      newShowFullComments[commentIndex] = !newShowFullComments[commentIndex]
      return newShowFullComments
    })
  }

  useEffect(() => {
    setShowFullComments(new Array<boolean>(state.comments.length).fill(false))
  }, [state.comments])

  const handleTotalComments = () => {
    setState((prev) => ({
      ...prev,
      totalComments: prev.totalComments - 1,
    }))
  }

  return (
    <>
      <div className='card'>
        <div className='card-body p-0'>
          <div className='d-flex align-items-center flex-grow-1'>
            <div className='symbol symbol-45px me-5'>
              <img
                src={
                  event.charity?.profilePic
                    ? event.charity.profilePic
                    : toAbsoluteUrl('/media/avatars/blank.png')
                }
                alt={event.charity.name}
              />
            </div>
            <div className='d-flex flex-column'>
              {currentUser?.role !== 'charity' ? (
                <Link to={`/charity-management/${event.charity.name}`}>
                  <span className='text-gray-800 fs-6 fw-bold'>{event.charity.name}</span>
                </Link>
              ) : (
                <span className='text-gray-800 fs-6 fw-bold'>{event.charity.name}</span>
              )}
              <span className='text-gray-400 fw-semibold'>
                {event.charity.charityCategory?.join(', ')}
              </span>
            </div>
          </div>
          <div className='my-4'>
            {event.media.length > 0 && <Slider allMedia={event.media} />}
            {!showComment ? (
              <div>
                <div
                  className='text-gray-800 mb-5 fv-row col-form-label fw-bold query-form-pop-up rounded'
                  style={{
                    maxHeight: '80px',
                    wordBreak: 'break-all',
                    overflowY: 'scroll',
                    wordSpacing: '2px',
                  }}
                >
                  {event.title}
                </div>
                <div
                  className='text-gray-800 mb-5 fs-2 fv-row col-form-label fw-bold query-form-pop-up rounded'
                  style={{
                    maxHeight: '100px',
                    wordBreak: 'break-all',
                    overflowY: 'scroll',
                    wordSpacing: '2px',
                  }}
                >
                  {event.body ? event.body : event.description}
                </div>

                <div className='card-body p-0 my-2'>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-40px me-5'>
                      <span className='symbol-label bg-light-info'>
                        <KTIcon iconName='calendar' className='fs-2x text-danger' />
                      </span>
                    </div>
                    <div className='d-flex flex-column'>
                      <span className='text-gray-600 fw-bold'>
                        {readableDate(event.startDate).split(' ')[0]} TO{' '}
                        {readableDate(event.endDate).split(' ')[0]}
                      </span>
                    </div>
                  </div>
                </div>

                <div className='card-body p-0 my-2'>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-40px me-5'>
                      <span className='symbol-label bg-light-info'>
                        <KTIcon iconName='time' className='fs-2x text-danger' />
                      </span>
                    </div>
                    <div className='d-flex flex-column'>
                      <span className='text-gray-600 fw-bold'>
                        {event.starttime} TO {event.endtime}
                      </span>
                    </div>
                  </div>
                </div>

                {event.location && (
                  <div className='card-body p-0 my-2'>
                    <div className='d-flex align-items-center'>
                      <div className='symbol symbol-40px me-5'>
                        <span className='symbol-label bg-light-info'>
                          <KTIcon iconName='geolocation' className='fs-2x text-danger' />
                        </span>
                      </div>
                      <div
                        className='text-gray-600 mb-5 d-flex flex-column fv-row col-form-label fw-bold query-form-pop-up rounded'
                        style={{
                          maxHeight: '80px',
                          wordBreak: 'break-all',
                          overflowY: 'scroll',
                          wordSpacing: '2px',
                        }}
                      >
                        {event.location}{' '}
                      </div>
                    </div>
                  </div>
                )}

                <div className='card-body p-0 my-2'>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-40px me-5'>
                      <span className='symbol-label bg-light-info'>
                        <KTIcon iconName='compass' className='fs-2x text-danger' />
                      </span>
                    </div>
                    <div className='d-flex flex-column'>
                      <span className='text-gray-600 fw-bold w-200px w-sm-350px'>
                        {event.eventLink}
                      </span>
                    </div>
                  </div>
                </div>

                <div className='card-body p-0 my-2 cursor-pointer' onClick={handleShowComment}>
                  <div className='d-flex align-items-center'>
                    <div className='symbol symbol-40px me-5'>
                      <span className='symbol-label bg-light-info'>
                        <KTIcon iconName='message-notif' className='fs-2x text-danger' />
                      </span>
                    </div>
                    <div className='d-flex flex-column'>
                      {state.comments.length > 0 ? (
                        <span className='text-gray-600 fw-bold'>See All Comments</span>
                      ) : (
                        <span className='text-gray-600 fw-bold'>No Comments Found</span>
                      )}
                    </div>
                  </div>
                </div>

                <div className='d-flex align-items-center mt-4'>
                  <span className='btn btn-sm btn-color-success px-4 py-2 me-4'>
                    <KTIcon iconName='message-text-2' className='fs-2' />
                    {state?.totalComments || 0}
                  </span>
                  <span className='btn btn-sm btn-color-danger px-4 py-2'>
                    <KTIcon iconName='heart' className='fs-2' />
                    {event.totalLikes || 0}
                  </span>
                  <span className='btn btn-sm btn-color-primary px-4 py-2'>
                    <KTIcon iconName='send' className='fs-2' />
                    {event.totalShare || 0}
                  </span>
                </div>
              </div>
            ) : (
              <div>
                <div className='modal-header py-2 px-0'>
                  {/* begin::Modal title */}
                  <h2 className='fw-bolder mx-4 mb-0'>{''}</h2>
                  {/* end::Modal title */}

                  {/* begin::Close */}
                  <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-users-modal-action='close'
                    onClick={() => setShowComment(false)}
                    style={{cursor: 'pointer'}}
                  >
                    <KTIcon iconName='cross' className='fs-1' />
                  </div>
                  {/* end::Close */}
                </div>
                <div>
                  <div className='mh-300px overflow-auto'>
                    {state.comments?.map((el, index) => {
                      return (
                        <div className='d-flex    my-4'>
                          <div className='d-flex  flex-grow-1'>
                            <div className='symbol symbol-40px me-5'>
                              <img
                                src={
                                  el.user?.profilePic
                                    ? el.user?.profilePic
                                    : toAbsoluteUrl('/media/avatars/blank.png')
                                }
                                alt={event.charity.name}
                              />
                            </div>
                            <div className='d-flex flex-column me-8 flex-wrap'>
                              <div className='d-flex align-items-center text-gray-800 fs-6 fw-bold flex-wrap'>
                                <div>
                                  <span style={{marginRight: '10px'}}>
                                    {el.user?.name.length > 20
                                      ? `${el.user?.name.slice(0, 20)}...`
                                      : el.user?.name}
                                  </span>
                                </div>
                                <div>
                                  <div className=' text-gray-400'>{timeAgo(el.createdAt)}</div>
                                </div>
                              </div>
                              <span
                                className='text-gray-400 fw-semibold'
                                style={{wordBreak: 'break-word'}}
                              >
                                {showFullComments[index]
                                  ? el.content
                                  : `${el.content.slice(0, 200)}...`}
                                {el.content.length > 200 && (
                                  <span
                                    className='text-primary cursor-pointer'
                                    onClick={() => toggleComment(index)}
                                  >
                                    {showFullComments[index] ? ' See less' : ' See more'}
                                  </span>
                                )}
                              </span>
                            </div>
                          </div>
                          <div
                            className='symbol symbol-40px me-5'
                            onClick={() => handleDeleteComment(el?._id, el?.commentCategory)}
                          >
                            <span className='symbol-label bg-light-info flex-grow-1'>
                              <KTIcon
                                iconName='trash'
                                className='fs-3 text-danger cursor-pointer'
                              />
                            </span>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div
                    className='d-flex align-items-center my-5'
                    style={{justifyContent: 'center'}}
                  >
                    {showSeeMoreButton && (
                      <button className='btn btn-danger p-2' onClick={handleSeeMore}>
                        See More
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {state.modalView && (
        <CustomModal
          type={'DELETE'}
          deleteLoading={state.deleteLoading}
          handleConfirm={async () => {
            try {
              setState({...state, deleteLoading: true})
              const result = await deletePostEventComment(
                auth && auth.token,
                state.id,
                state.commentCategory
              )

              if (result.status === 200) {
                const commentIndex = state.comments.findIndex((comment) => comment._id === state.id)

                if (commentIndex !== -1) {
                  const updatedComments = [...state.comments]
                  updatedComments.splice(commentIndex, 1)

                  setState((prev) => ({
                    ...prev,
                    comments: updatedComments,
                    modalView: false,
                    deleteLoading: false,
                  }))
                  handleTotalComments()
                  if (updatedComments.length === 0) {
                    setShowComment(false)
                  }
                }
              } else {
                toast(result.data.message)
              }
            } catch (error: any) {
              setState({
                ...state,
                modalView: false,
                deleteLoading: false,
              })
              toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
            }
          }}
          handleClose={() => {
            setState({...state, modalView: false})
          }}
        />
      )}
      <ToastContainer />
    </>
  )
}

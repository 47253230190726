import React, {FC, useCallback, useEffect, useState} from 'react'
import {DataTableWrapper} from '../../DataTable'
import {SectionWrapper, SelectOption} from '../../Common'
import ActionButton from '../../Common/ActionButton'
import {
  POST_EVENT_TYPE,
  roundUpStatus,
  userDonorRoundUpList,
} from '../../../app/modules/auth/core/_requests'
import {useAuth} from '../../../app/modules/auth'
import {toast} from 'react-toastify'
import {CustomModal, MODAL_TYPE, MODAL_TYPE_ENUM} from '../../Modal'
import {getCharityDonorStore} from '../../../app/modules/auth/core/StorageHelper'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
export interface TInitialState {
  loading: boolean
  roundups: any[]
  donors: {_id: string; name: string}[]
  charities: any[]
  currentPage: number
  totalPages: number
  error: any
  modalView: boolean
  selectedRow: any
  type: MODAL_TYPE_ENUM | MODAL_TYPE | any
  search: string
  changeStatusId: string | null
  filters: Filters
  totalActiveRoundUp: number
  totalInActiveRoundUp: number
}

export interface Filters {
  id: string
  charity_id?: string
  paymentMethod: string
  status: string
  amount: string
}

const initialState = {
  loading: false,
  roundups: [],
  donors: [],
  currentPage: 1,
  totalPages: 1,
  totalActiveRoundUp: 0,
  totalInActiveRoundUp: 0,
  error: null,
  modalView: false,
  selectedRow: null,
  type: null,
  search: '',
  changeStatusId: null,
  charities: [],
  filters: {
    id: '',
    charity_id: '',
    paymentMethod: '',
    amount: '',
    status: '',
    startDate: '',
    endDate: '',
  },
}

interface DonorRoundupProps {
  donorId: any
  getDetails?: any
}
const DonorRoundup: FC<DonorRoundupProps> = ({donorId, getDetails}) => {
  const {auth, currentUser} = useAuth()
  const [pageSize, setPageSize] = useState(10)
  const [state, setState] = useState<TInitialState>(initialState)
  const [modelView, setModelView] = useState(false)
  const values = getCharityDonorStore()
  const [title, setTitle] = useState('')
  const totalPage: any = localStorage.getItem('totalPage')
  const currentPage: any = localStorage.getItem('currentPage')

  const roundupDetails = useCallback(
    async (token, page, pageSize) => {
      try {
        setState({...state, loading: true})
        let result
        if (currentUser?.role === 'admin') {
          result = await userDonorRoundUpList(token, 'admin', page, pageSize, donorId, 'donor')
        }
        if (result.status === 200) {
          setState((prev) => ({
            ...prev,
            loading: false,
            roundups: result.data.data.roundUpList,
            totalActiveRoundUp: result.data.data.totalActiveRoundUp,
            totalInActiveRoundUp: result.data.data.totalInActiveRoundUp,
            totalPages: result.data.data.totalPages,
            currentPage: result?.data?.data?.currentPage || 1,
          }))
          localStorage.setItem('totalPage', result?.data?.data?.totalPages)
          localStorage.setItem('currentPage', result?.data?.data?.currentPage)
        } else {
          toast(result.data.message)
        }
      } catch (error: any) {
        setState({...state, loading: false, error: error})
        if (error.response.status === 600) {
          setTitle(error?.response?.data?.message)
          setModelView(true)
        } else {
          toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
        }
      }
    },
    [state, pageSize]
  )

  useEffect(() => {
    if (auth && auth?.token) {
      roundupDetails(auth?.token, totalPage === currentPage ? 1 : state.currentPage, pageSize)
    }
  }, [state.currentPage, state.search, state.filters, values?.id, pageSize])

  const roundupColumns = [
    {
      name: 'Donor Name',
      selector: (row, i) => row.donorName,
      width: '250px',
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center m-2 min-w-350px'>
            <div className='symbol symbol-50px me-5'>
              <span
                className='symbol-label bg-light'
                onClick={() =>
                  setState({
                    ...state,
                    selectedRow: row,
                    modalView: true,
                    type: MODAL_TYPE_ENUM.ROUNDUP_TRANSACTION,
                  })
                }
              >
                <img
                  src={
                    row.donorProfilePic
                      ? row.donorProfilePic
                      : toAbsoluteUrl('/media/avatars/blank.png')
                  }
                  className='h-[75px] w-[75px] symbol-label align-self-end'
                  alt=''
                />
              </span>
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <span
                onClick={() =>
                  setState({
                    ...state,
                    selectedRow: row,
                    modalView: true,
                    type: MODAL_TYPE_ENUM.ROUNDUP_TRANSACTION,
                  })
                }
                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                data-toggle='tooltip'
                data-placement='top'
                title={row.donorName}
              >
                {row.donorName}
              </span>
            </div>
          </div>
        )
      },
      sortable: true,
    },
    {
      name: 'Charity',
      selector: (row, i) => row.totalCharities,
      width: '150px',
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center m-2 min-w-350px'>
            <div className='d-flex justify-content-start flex-column'>
              <span
                onClick={() =>
                  setState({
                    ...state,
                    selectedRow: row,
                    modalView: true,
                    type: MODAL_TYPE_ENUM.ROUNDUP_TRANSACTION,
                  })
                }
                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
              >
                {row.totalCharities}
              </span>
            </div>
          </div>
        )
      },
      sortable: true,
    },
    {
      name: 'Date & Time',
      selector: (row, i) => row.createdAt,
      format: (row, i) => {
        return (
          <span
            className='text-dark fw-bold fs-6 min-w-100px text-hover-primary '
            onClick={() =>
              setState({
                ...state,
                selectedRow: row,
                modalView: true,
                type: MODAL_TYPE_ENUM.ROUNDUP_TRANSACTION,
              })
            }
          >
            {new Date(row.createdAt || '').toLocaleDateString('en-GB')}{' '}
            {(() => {
              const date = new Date(row.createdAt || '')
              const hours = date.getHours()
              const minutes = date.getMinutes()
              const ampm = hours >= 12 ? 'PM' : 'AM'
              const formattedHours = hours % 12 === 0 ? 12 : hours % 12

              return `${formattedHours.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
              })}:${minutes.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
              })} ${ampm}`
            })()}{' '}
          </span>
        )
      },
      sortable: true,
    },
    {
      name: 'Payment Method',
      selector: (row, i) => row.paymentMethod,
      format: (row, i) => (
        <>
          <span
            className='text-dark text-hover-primary fw-bold fs-6'
            onClick={() =>
              setState({
                ...state,
                selectedRow: row,
                modalView: true,
                type: MODAL_TYPE_ENUM.ROUNDUP_TRANSACTION,
              })
            }
          >
            {row?.paymentMethod.toUpperCase()}
          </span>
        </>
      ),
      sortable: true,
      sortCompare: (a, b) => a - b,
    },
    {
      name: 'Status',
      selector: (row, i) => row.status,
      format: (row, i) => {
        const isLoading = state.changeStatusId === row._id
        if (isLoading) {
          return <span className='badge text-black p-4 badge-light'>Loading...</span>
        } else {
          return row.status === 'Active' || row.status === 'Inactive' ? (
            <>
              <SelectOption
                options={[
                  {value: 'Active', title: 'Active'},
                  {value: 'InActive', title: 'Inactive'},
                ]}
                selected={row.status}
                onChange={async (e) => {
                  try {
                    setState({...state, changeStatusId: row._id})
                    const value = e.target.value
                    const result = await roundUpStatus(row._id, auth?.token)
                    if (result.status === 200) {
                      setState({
                        ...state,
                        roundups: [
                          ...state.roundups.slice(0, i),
                          Object.assign({}, state.roundups[i], {
                            status: value,
                          }),
                          ...state.roundups.slice(i + 1),
                        ],
                        changeStatusId: null,
                      })
                    } else {
                      toast(result.data.message)
                      setState({...state, changeStatusId: null})
                    }
                  } catch (error: any) {
                    setState({...state, changeStatusId: null})
                    toast(
                      error?.response?.data?.message || error?.message || 'Something went wrong!'
                    )
                  }
                }}
              />
            </>
          ) : (
            <>
              <span className='badge capitalize bg-gray-100 px-6 py-3  text-danger'>
                {row.status}
              </span>
            </>
          )
        }
      },
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row, i) => row.status,
      format: (row, i) => {
        return (
          <>
            <ActionButton
              viewCallback={() =>
                setState({
                  ...state,
                  selectedRow: row,
                  modalView: true,
                  type: MODAL_TYPE_ENUM.ROUNDUP_TRANSACTION,
                })
              }
            />
          </>
        )
      },
      sortable: false,
    },
  ]

  return (
    <>
      <SectionWrapper title='Roundup Details' id='roundup_details'>
        <DataTableWrapper
          loading={state.loading}
          viewDetailsLink={{
            id: donorId,
            role: POST_EVENT_TYPE.DONOR_ROUNDUP,
            link: `/view-donor-roundup/${donorId}`,
            userRole: 'donor',
          }}
          columns={roundupColumns}
          getDetails={getDetails}
          data={state.roundups}
        />
      </SectionWrapper>
      {state.modalView && (
        <CustomModal
          type={state.type}
          body={state.selectedRow}
          handleClose={() => setState({...state, modalView: false, selectedRow: null, type: null})}
        />
      )}
    </>
  )
}
export default DonorRoundup

/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {verifyOtp} from '../core/_requests'
import {toast} from 'react-toastify'
import OtpInput from 'react-otp-input'
import {useNavigate} from 'react-router-dom'

export function VerifyOtp() {
  const [loading, setLoading] = useState(false)
  const [message, setMassage] = useState('')

  const navigate = useNavigate()
  const [otp, setOtp] = useState('')
  const storedValue = localStorage.getItem('verifiedEmailForOtp')
  const email = storedValue ? JSON.parse(storedValue) : null

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    setMassage('')
    try {
      if (otp.length !== 6) {
        setMassage('Please enter a 6-digit OTP.')
        setLoading(false)
        return
      }
      if (!/^\d+$/.test(otp)) {
        setMassage('Please enter numeric values only.')
        setLoading(false)
        return
      }
      const result = await verifyOtp(Number(otp), email)
      if (result.status === 200) {
        toast(result.data.message)
        navigate('/reset-password')
      } else {
        toast(result.data.message)
      }
    } catch (error: any) {
      setLoading(false)
      toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
    }
  }

  return (
    <form className='form w-100' onSubmit={handleSubmit} noValidate id='kt_login_signin_form'>
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Verify 6 Digits Code</h1>
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-8 d-flex flex-column align-items-center justify-content-center'>
        <OtpInput
          value={otp}
          onChange={(value) => setOtp(value)}
          numInputs={6}
          shouldAutoFocus
          renderSeparator={<span>-</span>}
          inputStyle={{
            border: '1px solid transparent',
            borderRadius: '8px',
            width: '50px',
            height: '50px',
            fontSize: '16px',
            color: '#000',
            fontWeight: '600',
            caretColor: 'blue',
            outline: 'none',
            textAlign: 'center',
          }}
          // Add the renderInput property
          renderInput={(props) => <input {...props} />}
        />
        {message && (
          <div className='fv-plugins-message-container'>
            <span role='alert' style={{color: 'red'}}>
              {message}
            </span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button type='submit' id='kt_sign_in_submit' className='btn btn-primary'>
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}

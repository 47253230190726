import React, {FC, useCallback, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {DataTableWrapper} from '../../DataTable'
import {SectionWrapper, SelectOption} from '../../Common'
import ActionButton from '../../Common/ActionButton'
import {useAuth} from '../../../app/modules/auth'
import {
  ROLE_TYPE,
  updateSubscriptionStatus,
  userDonorSubscriptionDetails,
} from '../../../app/modules/auth/core/_requests'
import {toast} from 'react-toastify'
import {planTypeObj} from '../../../utils'

interface DonorSubscriptionProps {
  donorId: any
  getDetails?: any
  handleView: any
}
const DonorSubscription: FC<DonorSubscriptionProps> = ({donorId, getDetails, handleView}) => {
  const {auth} = useAuth()

  const [state, setState] = useState({
    subscriptions: [],
    loading: true,
    error: null,
    changeStatusId: null,
  })
  const [pageSize, setPageSize] = useState(10)

  const subscriptionDetails = useCallback(
    async (token, id) => {
      try {
        const result = await userDonorSubscriptionDetails(token, id, pageSize)
        if (result.status === 200) {
          setState({...state, subscriptions: result.data.data.subscriptionList, loading: false})
        }
      } catch (error: any) {
        setState({...state, subscriptions: [], loading: false, error: error})
      }
    },
    [state, pageSize]
  )

  useEffect(() => {
    if (donorId && auth) {
      subscriptionDetails(auth?.token, donorId)
    }
  }, [])

  const columns = [
    {
      name: 'Charity Name',
      selector: (row, i) => row.charityName,
      width: '200px',
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center m-2 min-w-350px'>
            <div className='symbol symbol-50px me-5'>
              <span className='symbol-label bg-light' onClick={() => handleView(row)}>
                <img
                  src={row.profilePic ? row.profilePic : toAbsoluteUrl('/media/avatars/blank.png')}
                  className='h-[75px] w-[75px] symbol-label align-self-end'
                  alt=''
                />
              </span>
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <span
                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                onClick={() => handleView(row)}
              >
                {row.charityName}
              </span>
            </div>
          </div>
        )
      },
      sortable: true,
    },
    {
      name: 'Subscription Plan',
      selector: (row, i) => row.planType,
      format: (row, i) => {
        return (
          <span
            className='text-dark fw-bold text-hover-primary fs-6 min-w-100px'
            onClick={() => handleView(row)}
          >
            {' '}
            {planTypeObj[`${row.planType}`]}
          </span>
        )
      },
      sortable: true,
    },
    {
      name: 'Donation Amount',
      selector: (row, i) => parseFloat(row.amount),
      format: (row, i) => (
        <>
          <span
            className='text-dark text-hover-primary fw-bold fs-6'
            onClick={() => handleView(row)}
          >
            ${row.amount}
          </span>
        </>
      ),
      sortable: true,
      sortCompare: (a, b) => a - b,
    },
    {
      name: 'Status',
      selector: (row, i) => row.status,
      format: (row, i) => {
        return state.changeStatusId === row._id ? (
          <span className='badge text-black p-4 badge-light'>Loading...</span>
        ) : row.status === 'Active' ? (
          <>
            <SelectOption
              options={[
                {value: 'Active', title: 'Active'},
                {value: 'InActive', title: 'Inactive'},
              ]}
              selected={row.status}
              onChange={async (e) => {
                try {
                  setState({...state, changeStatusId: row._id})
                  const value = e.target.value
                  const result = await updateSubscriptionStatus(auth?.token, {
                    subId: row.subscriptionId,
                    status: value,
                    donorId: row.donorId,
                  })
                  if (result.status === 200) {
                    setState({
                      ...state,
                      subscriptions: [
                        ...state.subscriptions.slice(0, i),
                        Object.assign({}, state.subscriptions[i], {
                          status: value,
                        }),
                        ...state.subscriptions.slice(i + 1),
                      ],
                      changeStatusId: null,
                    })
                  } else {
                    toast(result.data.message)
                    setState({...state, changeStatusId: null})
                  }
                } catch (error: any) {
                  setState({...state, changeStatusId: null})
                  toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
                }
              }}
            />
          </>
        ) : (
          <>
            <span className='badge capitalize bg-gray-100 px-6 py-3  text-danger'>
              {row.status.toLowerCase()}
            </span>
          </>
        )
      },
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row, i) => row.status,
      format: (row, i) => {
        return (
          <>
            <ActionButton viewCallback={() => handleView(row)} />
          </>
        )
      },
      sortable: false,
    },
  ]

  return (
    <>
      <SectionWrapper title='Subscription Details' id='sub_sec'>
        <DataTableWrapper
          loading={state.loading}
          viewDetailsLink={{
            id: donorId,
            role: ROLE_TYPE.DONOR,
            link: `/view-subscription/${donorId}`,
          }}
          getDetails={getDetails}
          columns={columns}
          data={state.subscriptions}
        />
      </SectionWrapper>
    </>
  )
}
export default DonorSubscription

import React from 'react'
import ContentLoader from 'react-content-loader'

const TableLoader = (props) => (
  <ContentLoader
    speed={2}
    width={1100}
    height={570}
    viewBox='0 0 1100 570'
    backgroundColor='#c4c4c4'
    foregroundColor='#e3e3e3'
    {...props}
  >
    <rect x='0' y='30' rx='5' ry='5' width='50' height='36' />
    <rect x='60' y='30' rx='5' ry='5' width='240' height='36' />
    <rect x='320' y='30' rx='5' ry='5' width='300' height='36' />
    <rect x='640' y='30' rx='5' ry='5' width='150' height='36' />
    <rect x='810' y='30' rx='5' ry='5' width='280' height='36' />

    <rect x='0' y='90' rx='5' ry='5' width='50' height='36' />
    <rect x='60' y='90' rx='5' ry='5' width='240' height='36' />
    <rect x='320' y='90' rx='5' ry='5' width='300' height='36' />
    <rect x='640' y='90' rx='5' ry='5' width='150' height='36' />
    <rect x='810' y='90' rx='5' ry='5' width='280' height='36' />

    <rect x='0' y='150' rx='5' ry='5' width='50' height='36' />
    <rect x='60' y='150' rx='5' ry='5' width='240' height='36' />
    <rect x='320' y='150' rx='5' ry='5' width='300' height='36' />
    <rect x='640' y='150' rx='5' ry='5' width='150' height='36' />
    <rect x='810' y='150' rx='5' ry='5' width='280' height='36' />

    <rect x='0' y='210' rx='5' ry='5' width='50' height='36' />
    <rect x='60' y='210' rx='5' ry='5' width='240' height='36' />
    <rect x='320' y='210' rx='5' ry='5' width='300' height='36' />
    <rect x='640' y='210' rx='5' ry='5' width='150' height='36' />
    <rect x='810' y='210' rx='5' ry='5' width='280' height='36' />

    <rect x='0' y='270' rx='5' ry='5' width='50' height='36' />
    <rect x='60' y='270' rx='5' ry='5' width='240' height='36' />
    <rect x='320' y='270' rx='5' ry='5' width='300' height='36' />
    <rect x='640' y='270' rx='5' ry='5' width='150' height='36' />
    <rect x='810' y='270' rx='5' ry='5' width='280' height='36' />

    <rect x='0' y='330' rx='5' ry='5' width='50' height='36' />
    <rect x='60' y='330' rx='5' ry='5' width='240' height='36' />
    <rect x='320' y='330' rx='5' ry='5' width='300' height='36' />
    <rect x='640' y='330' rx='5' ry='5' width='150' height='36' />
    <rect x='810' y='330' rx='5' ry='5' width='280' height='36' />

    <rect x='0' y='390' rx='5' ry='5' width='50' height='36' />
    <rect x='60' y='390' rx='5' ry='5' width='240' height='36' />
    <rect x='320' y='390' rx='5' ry='5' width='300' height='36' />
    <rect x='640' y='390' rx='5' ry='5' width='150' height='36' />
    <rect x='810' y='390' rx='5' ry='5' width='280' height='36' />

    <rect x='0' y='450' rx='5' ry='5' width='50' height='36' />
    <rect x='60' y='450' rx='5' ry='5' width='240' height='36' />
    <rect x='320' y='450' rx='5' ry='5' width='300' height='36' />
    <rect x='640' y='450' rx='5' ry='5' width='150' height='36' />
    <rect x='810' y='450' rx='5' ry='5' width='280' height='36' />

    <rect x='0' y='510' rx='5' ry='5' width='50' height='36' />
    <rect x='60' y='510' rx='5' ry='5' width='240' height='36' />
    <rect x='320' y='510' rx='5' ry='5' width='300' height='36' />
    <rect x='640' y='510' rx='5' ry='5' width='150' height='36' />
    <rect x='810' y='510' rx='5' ry='5' width='280' height='36' />
  </ContentLoader>
)

export default TableLoader

import React, {useCallback, useEffect, useState} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {
  DonorProfileDetails,
  DonorSubscription,
  DonorTransactions,
} from '../../../../components/Sections'
import {BankAccounts, CardDetails} from '../../../../components/Common'
import {CustomModal, MODAL_TYPE_ENUM} from '../../../../components/Modal'
import {State} from '../../Charity/id'
import {useAuth} from '../../../modules/auth'
import {
  ACCOUNT_TYPE,
  ROLE_TYPE,
  deleteBankAccount,
  setDefaultAccount,
  userProfile,
} from '../../../modules/auth/core/_requests'
import {getDonor} from '../../../modules/auth/core/StorageHelper'
import {toast} from 'react-toastify'
import DonorRoundup from '../../../../components/Sections/DonorSections/DonorRoundup'

const donorBreadcrumbs: Array<PageLink> = [
  {
    title: 'Donor Management',
    path: '/donor-management',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const initialState = {
  modalView: false,
  selectedRow: null,
  type: null,
  donorDetails: null,
  isDeleted: false,
  loading: true,
  deleteLoading: {accounts: false},
  setDefaultLoading: null,
  cardLoading: false,
}

const DonorId: React.FC = () => {
  const id = getDonor()
  const param = useParams()
  const {auth} = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const [state, setState] = useState<State>(initialState)
  const [isEditing, setIsEditing] = useState(false)
  const [loading, setLoading] = useState(true)

  const getProfileDetails = useCallback(
    async (token, role, id) => {
      try {
        setState({...state, loading: true})
        const result = await userProfile(token, role, id)
        if (result.status === 200) {
          setLoading(false)

          setState({
            ...state,
            donorDetails: result.data.data,
            loading: false,
            isDeleted: result.data.data.isDeleted,
          })
        }
      } catch (error) {
        setState({...state, loading: false})
      }
    },
    [state]
  )

  useEffect(() => {
    if (id && auth && auth.token) {
      getProfileDetails(auth.token, ROLE_TYPE.DONOR, id)
      setIsEditing(location.search.includes('edit=true'))
      if (state.isDeleted && location.search.includes('edit=true')) {
        setIsEditing(false)
      }
    } else {
      navigate('/donor-management')
    }
  }, [])

  const handleDeleteAccount = async () => {
    setState({...state, deleteLoading: {...state.deleteLoading, accounts: true}})
    try {
      const result = await deleteBankAccount(
        state.selectedRow?.donorId,
        state.selectedRow?.bankId,
        ROLE_TYPE.DONOR
      )
      if (result.status === 200) {
        setState({
          ...state,
          modalView: false,
          selectedRow: null,
          type: null,
          deleteLoading: {...state.deleteLoading, accounts: false},
        })
        toast.success(result?.data?.message)
      }
    } catch (error: any) {
      setState({
        ...state,
        modalView: false,
        selectedRow: null,
        type: null,
        deleteLoading: {...state.deleteLoading, accounts: false},
      })
      toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
    }
  }

  const setDefaultBankAccount = async (accountId) => {
    try {
      setState((prev) => ({...prev, setDefaultLoading: 'process'}))
      const result = await setDefaultAccount(auth?.token, id, ROLE_TYPE.DONOR, accountId)
      if (result.status === 200) {
        setState({...state, setDefaultLoading: 'complete'})
      }
    } catch (error: any) {
      toast(error?.response?.data?.message || error?.message || 'Something went wrong!')

      setState((prev) => ({...prev, setDefaultLoading: 'failed', cardLoading: false}))
    }
  }

  const handleEditProfile = () => {
    const params = new URLSearchParams(location.search)
    params.set('edit', isEditing ? 'false' : 'true')
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    })
  }

  const editButton = (
    <div className='d-flex justify-content-end py-3'>
      <button
        onClick={(e) => {
          setIsEditing((prevIsEditing) => !prevIsEditing)
          handleEditProfile()
          e.stopPropagation()
        }}
        type='submit'
        className='btn btn-primary'
      >
        {isEditing ? 'Cancel Edit' : 'Edit Profile'}
      </button>
    </div>
  )
  return (
    <>
      {/* <PageTitle breadcrumbs={donorBreadcrumbs}>{param.id}</PageTitle> */}
      <PageTitle breadcrumbs={donorBreadcrumbs}>
        {param.id && param.id.length > 25 ? param.id.substring(0, 25) + '..' : param.id}
      </PageTitle>

      <DonorProfileDetails
        donorId={id}
        donor={state.donorDetails}
        isView={location.search.includes('edit=true') ? false : true}
        editButton={
          !loading && (
            <>
              {state?.isDeleted && state?.isDeleted ? (
                <span className='badge capitalize fs-6 text-danger'>Account Deleted</span>
              ) : (
                editButton
              )}
            </>
          )
        }
        setDonor={() => {
          setState((prev: any) => ({
            ...prev,
            donorDetails: {
              ...prev.donorDetails,
              profilePic: undefined,
            },
          }))
        }}
        isDeleted={state?.isDeleted}
      />

      {state?.isDeleted && state?.isDeleted ? (
        <>
          <DonorTransactions
            donorId={id}
            handleView={(row) =>
              setState({modalView: true, selectedRow: row, type: MODAL_TYPE_ENUM.TRANSACTION})
            }
            getDetails={param.id}
          />

          <DonorSubscription
            donorId={id}
            handleView={(row) =>
              setState({modalView: true, selectedRow: row, type: MODAL_TYPE_ENUM.SUBSCRIPTION})
            }
            getDetails={param.id}
          />
        </>
      ) : (
        <>
          <BankAccounts
            id={id}
            role={ROLE_TYPE.DONOR}
            type={ACCOUNT_TYPE.BANK}
            setDefaultAccount={setDefaultBankAccount}
            setDefaultLoading={state.setDefaultLoading}
            deleteLoading={state.deleteLoading?.accounts}
            deleteHandler={(ac) => {
              setState({
                ...state,
                modalView: true,
                selectedRow: {...ac, title: ac.name, type: 'account'},
                type: MODAL_TYPE_ENUM.DELETE,
              })
            }}
          />

          <CardDetails
            setDefaultAccount={setDefaultBankAccount}
            setDefaultLoading={state.setDefaultLoading}
            id={id}
            role={ROLE_TYPE.DONOR}
            type={ACCOUNT_TYPE.CARD}
            setCardLoading={() => setState((prev) => ({...prev, cardLoading: true}))}
            cardLoading={state.cardLoading}
          />

          <DonorTransactions
            donorId={id}
            handleView={(row) => {
              setState({modalView: true, selectedRow: row, type: MODAL_TYPE_ENUM.TRANSACTION})
            }}
            getDetails={param.id}
          />

          <DonorSubscription
            donorId={id}
            handleView={(row) =>
              setState({modalView: true, selectedRow: row, type: MODAL_TYPE_ENUM.SUBSCRIPTION})
            }
            getDetails={param.id}
          />
          <DonorRoundup donorId={id} getDetails={param.id} />
        </>
      )}

      {state.modalView && (
        <CustomModal
          type={state.type}
          body={state.selectedRow}
          deleteLoading={state.deleteLoading?.accounts}
          handleConfirm={() => {
            if (state.selectedRow.type === 'account') {
              handleDeleteAccount()
            }
          }}
          handleClose={() => setState({...state, modalView: false, selectedRow: null, type: null})}
        />
      )}
    </>
  )
}

const DonorIdWrapper = () => (
  <>
    <DonorId />
  </>
)

export {DonorIdWrapper}

import React, {useEffect, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {addCharityPost, editCharityPost} from '../../../app/modules/auth/core/_requests'
import getYouTubeID from 'get-youtube-id'
import {ToastContainer, toast} from 'react-toastify'
import {KTIcon} from '../../../_metronic/helpers'

const validationSchema = Yup.object({
  title: Yup.string().required('Post Title is required'),
  body: Yup.string().required('Post Details is required'),
})

const AddEditPostBody = ({selectedRow, handleClose, setLoader}) => {
  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState<File[]>([])
  const [seqCode, setSeqCode] = useState<number | undefined>(0)
  const [editImage, setEditImage] = useState([])
  const [youtubeUrl, setYouTubeUrl] = useState([])
  const [youtubeLinks, setYoutubeLinks] = useState<string[]>([])
  const [inputValue, setInputValue] = useState<string>('')
  const [youtubeInput, setyoutubeInput] = useState(false)
  const [showImage, setShowImage] = useState(false)
  const [youtubeError, setYoutubeError] = useState('')
  const seqCodes = editImage
    .map((item: any) => item?.seqCode)
    .concat(youtubeUrl.map((item: any) => item?.seqCode))

  const maxSeqCode = Math.max(...seqCodes.map(Number), 0)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
    setYoutubeError('')
  }

  const imageCount = editImage.length + images.length || 0
  const weburCount = youtubeLinks.length + youtubeUrl.length
  const handleAddLink = () => {
    try {
      const urlValidationSchema = Yup.string().matches(
        /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(\S*?)$/,
        'Enter a valid YouTube video URL'
      )
      urlValidationSchema.validateSync(inputValue)
      setYoutubeError('')
    } catch (error) {
      setYoutubeError('Enter a valid YouTube video URL')
      return
    }

    if (weburCount >= 7) {
      toast.error('Cannot add more than 7 YouTube Link.')
      return
    }
    if (inputValue.trim() !== '') {
      setSeqCode((prevSeqCode) => (prevSeqCode !== undefined ? prevSeqCode + 1 : 1))
      const currentSeqCode = seqCode !== undefined ? seqCode + 1 : 1
      const youtubeLink = `w${currentSeqCode}_` + inputValue
      setYoutubeLinks((prevLinks) => [...prevLinks, youtubeLink])
      setInputValue('')
    }
  }

  const generateThumbnail = (url) => {
    const extractedCode = getYouTubeID(url)
    return `https://img.youtube.com/vi/${extractedCode}/hqdefault.jpg` || null
  }

  let imageResult = youtubeLinks.map((link) => generateThumbnail(link))

  const handleAddPost = async (values) => {
    try {
      setLoader(true)
      const formData = new FormData()
      formData.append('title', values.title)
      formData.append('body', values.body)

      images.forEach((image, index) => {
        formData.append('images', image)
      })
      youtubeLinks.forEach((link, index) => {
        formData.append('weburl', link)
      })
      const response = await addCharityPost(formData)
      if (response && response.status === 201) {
        toast.success(response?.data?.message || 'Post is Add successfully')
        handleClose()
        setTimeout(() => {
          window.location.reload()
        }, 2000)
        formik.resetForm()
        setImages([])
        setEditImage([])
        setYouTubeUrl([])
        setYoutubeLinks([])
      }
    } catch (error: any) {
      toast.error(error.response.data.message || 'Error adding post')
    } finally {
      setLoading(false)
      setLoader(false)
    }
  }

  const handleEditPost = async (values) => {
    try {
      setLoader(true)
      const formData = new FormData()
      formData.append('title', values.title)
      formData.append('body', values.body)
      images.forEach((image, index) => {
        formData.append('images', image)
      })
      youtubeLinks.forEach((link, index) => {
        formData.append('weburl', link)
      })
      formData.append('seqCode', JSON.stringify(seqCodes))
      const response = await editCharityPost(formData, selectedRow.row.id)

      if (response && response.status === 200) {
        toast.success(response?.data?.message || 'Post is Edited successfully ')
        handleClose()
        setTimeout(() => {
          window.location.reload()
        }, 2000)
        formik.resetForm()
        setImages([])
        setEditImage([])
        setYouTubeUrl([])
        setYoutubeLinks([])
      }
    } catch (error: any) {
      toast.error(error.response.data.message || 'Error in Edit Post')
    } finally {
      setLoading(false)
      setLoader(false)
    }
  }

  const formik = useFormik({
    initialValues: {
      title: '',
      body: '',
      weburl: [],
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (imageCount === 0) {
        setShowImage(true)

        return
      }
      if (Object.keys(selectedRow).length > 0) {
        handleEditPost(values)
      } else {
        handleAddPost(values)
      }
      setLoading(true)
    },
  })
  useEffect(() => {
    if (Object.keys(selectedRow).length > 0) {
      setyoutubeInput(true)
      formik.setValues({
        title: selectedRow?.row?.title || '',
        body: selectedRow?.row?.body || '',
        weburl:
          selectedRow?.row?.media
            .map((item) => item.thumbnailURL)
            .filter((thumbnailURL) => thumbnailURL !== null) || '',
      })
      setYouTubeUrl(
        selectedRow?.row?.media
          .map((item) => item)
          .filter((thumbnailURL) => thumbnailURL.thumbnailURL !== null)
      )

      setEditImage(
        (selectedRow?.row?.media || [])
          .filter((item) => item.thumbnailURL === null)
          .map((item) => item) || []
      )
    }
  }, [selectedRow])

  useEffect(() => {
    setSeqCode((prevSeqCode) => maxSeqCode)
  }, [maxSeqCode])

  const handlePreviewImage = (index) => {
    const updatedImages = editImage.filter((_, i) => i !== index)
    setEditImage(updatedImages)
  }

  const handleUploadImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index)
    setImages(updatedImages)
  }

  const handlePreviewWeburl = (index) => {
    const updatedWebUrl = youtubeUrl.filter((_, i) => i !== index)
    setYouTubeUrl(updatedWebUrl)
  }

  const handleUploadWeburl = (index) => {
    const updatedWebUrl: any = youtubeLinks.filter((_, i) => i !== index)
    setYoutubeLinks(updatedWebUrl)
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='card-body p-0'>
        <div className='d-flex flex-column align-items-center'>
          {/* Title Field */}
          <div className='form-group'>
            <label htmlFor='starttime' style={{marginLeft: '15px', fontWeight: 'bold'}}>
              Post Title
            </label>
            <textarea
              // type='text'
              id='title'
              name='title'
              autoComplete='off'
              className={`form-control form-control-lg m-4 ${
                formik.touched.title && formik.errors.title ? 'is-invalid' : ''
              }`}
              placeholder='Post Title'
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{width: '300px'}}
              maxLength={100}
            />
            {formik.touched.title && formik.errors.title && (
              <div className='invalid-feedback' style={{marginLeft: '10px'}}>
                {formik.errors.title}
              </div>
            )}
          </div>

          {/* Body Field */}
          <div className='form-group'>
            <label htmlFor='starttime' style={{marginLeft: '15px', fontWeight: 'bold'}}>
              Post Details
            </label>
            <textarea
              id='body'
              name='body'
              className={`form-control form-control-lg m-4 ${
                formik.touched.body && formik.errors.body ? 'is-invalid' : ''
              }`}
              placeholder='Post Details'
              value={formik.values.body}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{width: '300px'}}
              maxLength={2500}
            />
            {formik.touched.body && formik.errors.body && (
              <div className='invalid-feedback' style={{marginLeft: '10px'}}>
                {formik.errors.body}
              </div>
            )}
          </div>

          {/* Image Field */}
          <div style={{display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: '10px'}}>
            {editImage.map((item: any, index) => (
              <div key={index} style={{position: 'relative', marginBottom: '10px'}}>
                <img src={item?.url} alt={`Preview ${index + 1}`} className='w-80px h-80px' />
                <button
                  type='button'
                  onClick={() => handlePreviewImage(index)}
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    background: '#FFFFFF',
                    color: 'black',
                    border: 'none',
                    cursor: 'pointer',
                    borderRadius: '5px',
                  }}
                >
                  ✖
                </button>
              </div>
            ))}
            {images.map((item, index) => (
              <div key={index} style={{position: 'relative', marginBottom: '10px'}}>
                <img
                  src={URL.createObjectURL(item)}
                  alt={`Preview ${index + 1}`}
                  className='w-80px h-80px'
                />
                <button
                  type='button'
                  onClick={() => handleUploadImage(index)}
                  style={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    background: '#FFFFFF',
                    color: 'black',
                    border: 'none',
                    cursor: 'pointer',
                    borderRadius: '5px',
                  }}
                >
                  ✖
                </button>
              </div>
            ))}
          </div>
          <div className='form-group'>
            <div className='col-lg-8'>
              <label
                htmlFor='profile_img'
                className='btn btn-sm btn-danger'
                style={{whiteSpace: 'nowrap'}}
              >
                <KTIcon iconName='message-add' className='fs-3' />
                Upload Image
              </label>
              <input
                className='input-preview__src w-100px h-100px'
                type='file'
                multiple
                autoComplete='off'
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const errorMessages: string[] = []
                  if (imageCount === 7) {
                    toast.error('Cannot add more than 7 images.')
                    return
                  }

                  if (event.target.files && event.target.files.length > 0) {
                    const newImages = Array.from(event.target.files)

                    setSeqCode((prevSeqCode) => {
                      let incrementedSeqCode = prevSeqCode !== undefined ? prevSeqCode + 1 : 1

                      const updatedImages = newImages
                        .map((newImage, index) => {
                          if (newImage.size > 2 * 1024 * 1024) {
                            errorMessages.push('Image size must be less than 2MB.')
                            return null
                          }
                          const allowedExtensions = ['jpeg', 'jpg', 'png', 'dng', 'HEIC']
                          const fileExtension = newImage.name.split('.').pop()?.toLowerCase()

                          if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
                            errorMessages.push('Invalid file type.')
                            return null
                          }
                          const customFileName = `i${incrementedSeqCode + index}.${
                            newImage.type.split('/')[1]
                          }`
                          const newFile = new File([newImage], customFileName, {
                            type: newImage.type,
                          })
                          return newFile
                        })
                        .filter(Boolean) as File[]

                      if (imageCount + updatedImages.length > 7) {
                        toast.error('Cannot add more than 7 images.')
                        return
                      }

                      setImages((prevImages) => [...prevImages, ...updatedImages])
                      if (showImage) {
                        setShowImage(false)
                      }
                      if (errorMessages.length > 0) {
                        if (errorMessages.includes('Image size must be less than 2MB.')) {
                          toast.error('Image size must be less than 2MB.')
                        }
                        if (errorMessages.includes('Invalid file type.')) {
                          toast.error('Invalid file type.')
                        }
                        return
                      }

                      return incrementedSeqCode + newImages.length
                    })
                  }
                }}
                id='profile_img'
                accept='image/png, image/jpeg'
              />
            </div>
          </div>
          {/* <p>Note: Please upload atleast one image</p> */}
          {showImage && (
            <div style={{marginLeft: '10px', color: 'red'}}>
              Note: Please upload atleast one image
            </div>
          )}

          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: '10px',
              marginTop: '20px',
            }}
          >
            {youtubeUrl &&
              youtubeUrl.map((item: any, index) => (
                <div key={index} style={{position: 'relative', marginBottom: '10px'}}>
                  <img
                    key={index}
                    src={item.thumbnailURL}
                    alt={`Thumbnail ${index + 1}`}
                    className='h-80px w-80px'
                  />
                  <button
                    type='button'
                    onClick={() => handlePreviewWeburl(index)}
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      background: '#FFFFFF',
                      color: 'black',
                      border: 'none',
                      cursor: 'pointer',
                      borderRadius: '5px',
                    }}
                  >
                    ✖
                  </button>
                </div>
              ))}
            {imageResult.length !== 0 &&
              imageResult?.map((image: any, index) => (
                <div key={index} style={{position: 'relative', marginBottom: '10px'}}>
                  <img
                    key={index}
                    src={image}
                    alt={`Thumbnail ${index + 1}`}
                    className='h-80px w-80px'
                  />
                  <button
                    type='button'
                    onClick={() => handleUploadWeburl(index)}
                    style={{
                      position: 'absolute',
                      top: '5px',
                      right: '5px',
                      background: '#FFFFFF',
                      color: 'black',
                      border: 'none',
                      cursor: 'pointer',
                      borderRadius: '5px',
                    }}
                  >
                    ✖
                  </button>
                </div>
              ))}
          </div>
          <div className='form-group d-flex flex-column align-items-center'>
            {youtubeInput ? (
              <React.Fragment>
                <input
                  type='text'
                  id='weburl'
                  name='weburl'
                  autoComplete='off'
                  className={`form-control form-control-lg m-4 ${
                    formik.touched.weburl && formik.errors.weburl ? 'is-invalid' : ''
                  }`}
                  placeholder='Youtube Link'
                  value={inputValue}
                  onChange={handleChange}
                  onBlur={formik.handleBlur}
                  style={{width: '300px'}}
                />
                {youtubeError && (
                  <div className='text-danger' style={{marginLeft: '10px', marginBottom: '5px'}}>
                    {youtubeError}
                  </div>
                )}
                <button type='button' className='btn btn-sm btn-danger ' onClick={handleAddLink}>
                  <KTIcon iconName='youtube' className='fs-3' /> Add Youtube Link
                </button>
              </React.Fragment>
            ) : (
              <button
                type='button'
                className='btn btn-sm btn-danger '
                onClick={() => setyoutubeInput(true)}
              >
                <KTIcon iconName='youtube' className='fs-3' /> Upload Youtube Link
              </button>
            )}
          </div>

          {/* Submit Button */}
          <button
            type='submit'
            className='btn btn-primary font-weight-bold px-9 py-4 my-3'
            disabled={loading}
          >
            {loading ? (
              <div className='d-flex align-items-center'>
                <span>Submitting</span>
                <span
                  className='spinner-border spinner-border-sm mx-2'
                  role='status'
                  aria-hidden='true'
                ></span>
              </div>
            ) : Object.keys(selectedRow).length > 0 ? (
              'Save Changes'
            ) : (
              'Create Post'
            )}
          </button>
        </div>
      </div>
      <ToastContainer />
    </form>
  )
}

export default AddEditPostBody

import {useEffect, useState} from 'react'
import {ModalHeader} from './ModalHeader'
import PostBody from './Body/PostBody'
import EventBody from './Body/EventBody'
import SubscriptionBody from './Body/SubscriptionBody'
import ContactBody from './Body/ContactBody'
import TransactionBody from './Body/TransactionBody'
import DeleteBody from './Body/DeleteBody'
import AddBankAccountBody from './Body/AddBankAccountBody'
import AddEditPostBody from './Body/AddEditPostBody'
import AddEditEventBody from './Body/AddEditEventBody'
import {ContactModalHeader} from './ContactModelHeader'
import CharitySignoutBody from './Body/CharitySignoutBody'
import StripeConnectBody from './Body/StripeConnectBody'
import AccountStatusBody from './Body/AccountStatusBody'
import AccountApproveBody from './Body/AccountApproveBody'
import CompleteProfileBody from './Body/CompleteProfileBody'
import RoundupTransactionBody from './Body/RoundupTransactionBody'
import SingleTextButtonBody from './Body/SingleTextButtonBody'
import CharityRoundupTransactionBody from './Body/CharityRoundupTransactionBody'
import AdminStipeAccountBody from './Body/AdminStripeAccountBody'
import AddCharityCategoryBody from './Body/AddCharityCategoryBody'

export type MODAL_TYPE =
  | 'POST'
  | 'EVENT'
  | 'SUBSCRIPTION'
  | 'CONTACT'
  | 'TRANSACTION'
  | 'DELETE'
  | 'ADD_BANK_ACCOUNT'
  | 'ADD_EDIT_POST'
  | 'ADD_EDIT_EVENT'
  | 'SIGNOUT_CHARITY'
  | 'STRIPE_CONNECT'
  | 'ACCOUNT_STATUS'
  | 'ACCOUNT_APPROVAL'
  | 'ACCOUNT_REJECTED'
  | 'COMPLETE_CHARITY_PROFILE'
  | 'SINGLE_TEXT_BUTTON'
  | 'ROUNDUP_TRANSACTION'
  | 'CHARITY_ROUNDUP_TRANSACTION'
  | 'ADMIN_STRIPE_ACCOUNT'
  | 'ADD_CHARITY_CATEGORY'
  | 'REPORT'
  | null

export enum MODAL_TYPE_ENUM {
  POST = 'POST',
  EVENT = 'EVENT',
  SUBSCRIPTION = 'SUBSCRIPTION',
  CONTACT = 'CONTACT',
  TRANSACTION = 'TRANSACTION',
  DELETE = 'DELETE',
  ADD_BANK_ACCOUNT = 'ADD_BANK_ACCOUNT',
  ADD_EDIT_POST = 'ADD_EDIT_POST',
  ADD_EDIT_EVENT = 'ADD_EDIT_EVENT',
  SIGNOUT_CHARITY = 'SIGNOUT_CHARITY',
  STRIPE_CONNECT = 'STRIPE_CONNECT',
  ACCOUNT_STATUS = 'ACCOUNT_STATUS',
  ACCOUNT_APPROVAL = 'ACCOUNT_APPROVAL',
  ACCOUNT_REJECTED = 'ACCOUNT_REJECTED',
  COMPLETE_CHARITY_PROFILE = 'COMPLETE_CHARITY_PROFILE',
  ROUNDUP_TRANSACTION = 'ROUNDUP_TRANSACTION',
  SINGLE_TEXT_BUTTON = 'SINGLE_TEXT_BUTTON',
  CHARITY_ROUNDUP_TRANSACTION = 'CHARITY_ROUNDUP_TRANSACTION',
  ADMIN_STRIPE_ACCOUNT = 'ADMIN_STRIPE_ACCOUNT',
  ADD_CHARITY_CATEGORY = 'ADD_CHARITY_CATEGORY',
  REPORT = 'REPORT',
  null = 'null',
}
interface CustomModalProps {
  handleClose: () => void
  handleConfirm?: (rejectReason: any) => Promise<void> | void
  deleteLoading?: boolean
  approveLoading?: boolean
  body?: any
  type: MODAL_TYPE
  loader?: boolean
  title?: boolean
  accountApproveTitle?: boolean
  accountRejectTitle?: boolean
  headerView?: boolean
  buttonText?: string
  signoutButtonText?: string
  img?: any
}
const CustomModal = ({
  handleClose,
  body,
  type,
  loader,
  handleConfirm,
  deleteLoading,
  approveLoading,
  title,
  accountRejectTitle,
  accountApproveTitle,
  headerView,
  buttonText,
  signoutButtonText,
  img,
}: CustomModalProps) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const customBody = () => {
    if (type === MODAL_TYPE_ENUM.POST) return <PostBody post={body} />
    if (type === MODAL_TYPE_ENUM.EVENT) return <EventBody event={body} />
    if (type === MODAL_TYPE_ENUM.SUBSCRIPTION) return <SubscriptionBody subscription={body} />
    if (type === MODAL_TYPE_ENUM.CONTACT) return <ContactBody contact={body} />
    if (type === MODAL_TYPE_ENUM.TRANSACTION) return <TransactionBody transaction={body} />
    if (type === MODAL_TYPE_ENUM.DELETE)
      return (
        <DeleteBody
          body={body}
          title={title}
          handleClose={handleClose}
          deleteLoading={deleteLoading}
          handleConfirm={handleConfirm}
        />
      )
    if (type === MODAL_TYPE_ENUM.ADD_BANK_ACCOUNT)
      return <AddBankAccountBody handleClose={handleClose} setLoader={(val) => setLoading(val)} />
    if (type === MODAL_TYPE_ENUM.ADD_EDIT_POST)
      return (
        <AddEditPostBody
          handleClose={handleClose}
          selectedRow={body}
          setLoader={(val) => setLoading(val)}
        />
      )
    if (type === MODAL_TYPE_ENUM.ADD_EDIT_EVENT)
      return (
        <AddEditEventBody
          handleClose={handleClose}
          selectedRow={body}
          setLoader={(val) => setLoading(val)}
        />
      )
    if (type === MODAL_TYPE_ENUM.SIGNOUT_CHARITY)
      return <CharitySignoutBody body={body} handleClose={handleClose} />

    if (type === MODAL_TYPE_ENUM.STRIPE_CONNECT) return <StripeConnectBody />

    if (type === MODAL_TYPE_ENUM.ACCOUNT_STATUS) return <AccountStatusBody />
    if (type === MODAL_TYPE_ENUM.ACCOUNT_APPROVAL)
      return (
        <AccountApproveBody
          body={body}
          accountRejectTitle={accountRejectTitle}
          accountApproveTitle={accountApproveTitle}
          handleClose={handleClose}
          approveLoading={approveLoading}
          handleConfirm={handleConfirm}
        />
      )

    if (type === MODAL_TYPE_ENUM.SINGLE_TEXT_BUTTON)
      return (
        <SingleTextButtonBody
          body={body}
          handleClose={handleClose}
          buttonText={buttonText}
          signoutButtonText={signoutButtonText}
          img={img}
        />
      )

    if (type === MODAL_TYPE_ENUM.COMPLETE_CHARITY_PROFILE)
      return <CompleteProfileBody handleClose={handleClose} />

    if (type === MODAL_TYPE_ENUM.ROUNDUP_TRANSACTION)
      return <RoundupTransactionBody RoundupTransaction={body} />

    if (type === MODAL_TYPE_ENUM.CHARITY_ROUNDUP_TRANSACTION)
      return <CharityRoundupTransactionBody RoundupTransaction={body} />

    if (type === MODAL_TYPE_ENUM.ADMIN_STRIPE_ACCOUNT)
      return <AdminStipeAccountBody body={body} handleClose={handleClose} />

    if (type === MODAL_TYPE_ENUM.ADD_CHARITY_CATEGORY)
      return (
        <AddCharityCategoryBody handleClose={handleClose} setLoader={(val) => setLoading(val)} />
      )
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal'
        role='dialog'
        tabIndex={-1}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}

        <div
          className={`modal-dialog modal-dialog-centered ${
            type === MODAL_TYPE_ENUM.ACCOUNT_STATUS ? 'mw-750px' : 'mw-650px'
          }`}
        >
          {/* begin::Modal content */}
          <div className='modal-content'>
            {type === MODAL_TYPE_ENUM.CONTACT ? (
              <ContactModalHeader handleClose={handleClose} contact={body} loader={loading} />
            ) : headerView !== true ? (
              <ModalHeader handleClose={handleClose} loader={loading} />
            ) : (
              ''
            )}

            {/* begin::Modal body */}
            <div className='modal-body scroll-y m-2'>{customBody()}</div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
      </div>
      {/* end::Modal dialog */}
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export {CustomModal}

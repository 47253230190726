import React, {useEffect, useState} from 'react'
import {useFormik, FormikProvider} from 'formik'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {SectionWrapper} from '../../Common'
import {updateUserAuth, useAuth} from '../../../app/modules/auth'
import {
  charityUserProfile,
  updatePassword,
  updateAdminUserDetails,
} from '../../../app/modules/auth/core/_requests'
import {toast} from 'react-toastify'
import * as Yup from 'yup'

const initialValues = {
  profilePic: '',
  name: '',
  email: '',
  currentPassword: '',
  password: '',
  confirmPassword: '',
}

export interface CharityProfileDetailsType {
  profilePic: string
  name: string
  email: string
  password: string
  confirmPassword: string
  currentPassword: string
}

const profileDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email(),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(16, 'Password must be at most 16 characters')
    .matches(/^\S*$/, 'Password cannot contain spaces'),
  confirmPassword: Yup.mixed()
    .test('match', 'Password must match', function (value) {
      return value === this.parent.password || value === null
    })
    .nullable(),
})

export default function AdminProfileDetails({isView = true, admin, setAdmin, editButton}) {
  const {auth, currentUser} = useAuth()
  let file
  const [image, setImage] = useState('')
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState({
    password: false,
    newPassword: false,
    confirmPassword: false,
  })

  const formik = useFormik<CharityProfileDetailsType>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      try {
        if (values.currentPassword) {
          setLoading(true)
          const updatePswResult = await updatePassword(
            values.currentPassword,
            values.confirmPassword,
            auth?.token
          )
          if (updatePswResult.status === 200) {
            toast.success(updatePswResult.data.message)
            setLoading(false)
          }
        }
        const formData = new FormData()
        formData.append('name', values.name || '')
        formData.append('email', values.email || '')
        formData.append('profilePic', image && file ? file : values.profilePic)
        if (values.currentPassword) formData.append('password', values.password)

        setLoading(true)

        const result = await updateAdminUserDetails(auth?.token, 'admin', formData)

        if (result.status === 200) {
          toast.success(result.data.message || 'Profile updated successfully')
          if (currentUser?.role === 'admin') {
            // Update the Profile name
            const updatedProfilename = await charityUserProfile(auth?.token, currentUser?.role)
            const userData = updatedProfilename.data.data.title
            const userImg = updatedProfilename.data.data.profilePic
            updateUserAuth(userData, userImg)
            window.location.reload()
            window.location.href = '/profile'
          }
          setLoading(false)
        } else {
          toast(result?.data?.data?.message || 'Something went wrong!')
          setLoading(false)
        }
      } catch (error: any) {
        setLoading(false)
        toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
      }
    },
  })

  useEffect(() => {
    if (admin) {
      formik.setValues({...admin})
    }
  }, [admin])

  return (
    <SectionWrapper id='profile_details' buttonComponent={editButton} title='Profile Details'>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label fw-bold fs-6'>Avatar</label>
            <div className='col-lg-8'>
              <div
                className='image-input image-input-outline'
                data-kt-image-input='true'
                style={{
                  backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                }}
              >
                <label
                  htmlFor='profile_img'
                  style={{
                    backgroundImage:
                      admin?.profilePic && image === ''
                        ? `url(${admin?.profilePic})`
                        : image
                        ? `url(${image})`
                        : `url(${toAbsoluteUrl('/media/avatars/blank.png')})`,
                  }}
                  className={`${isView ? '' : 'input-preview-after'} input-preview`}
                ></label>
                {!isView && (
                  <>
                    <label
                      className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                      data-kt-image-input-action='change'
                      htmlFor='profileImage'
                    >
                      <i className='ki-duotone ki-pencil fs-7'>
                        <span className='path1'></span>
                        <span className='path2'></span>
                      </i>

                      <input
                        type='file'
                        id='profileImage'
                        name='avatar'
                        accept='.png, .jpg, .jpeg, .webp'
                        className='input-preview__src w-125px h-125px'
                        onChange={(event: any) => {
                          if (event.target.files && event.target.files?.length > 0) {
                            file = event.target.files[0]
                            setImage(URL.createObjectURL(file))
                            formik.setFieldValue('profilePic', file)
                          }
                        }}
                      />
                    </label>
                    {(formik.values.profilePic || formik.values.profilePic) && (
                      <span
                        className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                        data-kt-image-input-action='remove'
                        onClick={() => {
                          formik.setFieldValue('profilePic', undefined)
                          setImage('')
                          setAdmin()
                        }}
                      >
                        <i className='ki-duotone ki-cross fs-2'>
                          <span className='path1'></span>
                          <span className='path2'></span>
                        </i>
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label  fw-bold fs-6'>Name</label>
            <div className='col-lg-8'>
              <input
                type='text'
                autoComplete='off'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Name'
                {...formik.getFieldProps('name')}
                maxLength={50}
                disabled={isView}
              />
              {formik.touched.name && formik.errors.name && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.name}</div>
                </div>
              )}
            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label  fw-bold fs-6'>Email</label>
            <div className='col-lg-8 fv-row'>
              <input
                type='text'
                className='form-control form-control-lg form-control-solid'
                placeholder='Email'
                autoComplete='off'
                {...formik.getFieldProps('email')}
                value={formik.values.email}
                onChange={(e) => formik.setFieldValue('email', e.target.value)}
                disabled={isView || !isView}
              />
              {formik.touched.email && formik.errors.email && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>{formik.errors.email}</div>
                </div>
              )}
            </div>
          </div>

          {!isView && (
            <>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Current Password</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    // type='password'
                    type={showPassword?.password ? 'text' : 'password'}
                    autoComplete='new-password'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Current Password'
                    {...formik.getFieldProps('currentPassword')}
                    disabled={isView}
                  />
                  {showPassword?.password ? (
                    <span
                      className='password-toggle-icon'
                      onClick={() =>
                        setShowPassword({...showPassword, password: !showPassword.password})
                      }
                    >
                      <KTIcon iconName='eye' className='fs-2' />
                    </span>
                  ) : (
                    <span
                      className='password-toggle-icon'
                      onClick={() =>
                        setShowPassword({...showPassword, password: !showPassword.password})
                      }
                    >
                      <KTIcon iconName='eye-slash' className='fs-2' />
                    </span>
                  )}
                  {formik.touched.currentPassword && formik.errors.currentPassword && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.currentPassword}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>New Password</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    // type='password'
                    type={showPassword?.newPassword ? 'text' : 'password'}
                    autoComplete='new-password'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='New Password'
                    {...formik.getFieldProps('password')}
                    disabled={isView}
                  />
                  {showPassword?.newPassword ? (
                    <span
                      className='password-toggle-icon'
                      onClick={() =>
                        setShowPassword({...showPassword, newPassword: !showPassword.newPassword})
                      }
                    >
                      <KTIcon iconName='eye' className='fs-2' />
                    </span>
                  ) : (
                    <span
                      className='password-toggle-icon'
                      onClick={() =>
                        setShowPassword({...showPassword, newPassword: !showPassword.newPassword})
                      }
                    >
                      <KTIcon iconName='eye-slash' className='fs-2' />
                    </span>
                  )}
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.password}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span className=''>Confirm Password</span>
                </label>

                <div className='col-lg-8 fv-row'>
                  <input
                    // type='password'
                    type={showPassword?.confirmPassword ? 'text' : 'password'}
                    autoComplete='confirm-password'
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Confirm Password'
                    {...formik.getFieldProps('confirmPassword')}
                    disabled={isView}
                  />
                  {showPassword?.confirmPassword ? (
                    <span
                      className='password-toggle-icon'
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          confirmPassword: !showPassword.confirmPassword,
                        })
                      }
                    >
                      <KTIcon iconName='eye' className='fs-2' />
                    </span>
                  ) : (
                    <span
                      className='password-toggle-icon'
                      onClick={() =>
                        setShowPassword({
                          ...showPassword,
                          confirmPassword: !showPassword.confirmPassword,
                        })
                      }
                    >
                      <KTIcon iconName='eye-slash' className='fs-2' />
                    </span>
                  )}
                  {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.confirmPassword}</div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          {!isView && (
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='submit' className='btn btn-primary' disabled={loading}>
                {!loading && 'Save Changes'}
                {loading && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Please wait...{' '}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          )}
        </form>
      </FormikProvider>
    </SectionWrapper>
  )
}

import React, {useState} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import {RoundupTransaction} from './body'
import {ToastContainer} from 'react-toastify'
import {getCharityDonorStore} from '../../../app/modules/auth/core/StorageHelper'

export default function RoundupTransactionBody({
  RoundupTransaction,
}: {
  RoundupTransaction: RoundupTransaction
}) {
  const [roundUpData, setRoundUpData] = useState(RoundupTransaction.roundUp)

  // const handleDeleteCharity = async (charityId, plaidId, last4, _id) => {
  //   try {
  //     const response = await roundupCharityDelete(
  //       currentUser?.token,
  //       [],
  //       [charityId],
  //       null,
  //       last4,
  //       plaidId
  //     )

  //     if (response.status === 200) {
  //       toast.success(response?.data?.message || 'Roundup charity is deleted successfully')
  //     }
  //   } catch (error: any) {
  //     toast.error(error.response.data.message || 'Error deleting roundup charity')
  //   } finally {
  //     setRoundUpData((prevData) => prevData.filter((item) => item._id !== _id))
  //   }
  // }

  return (
    <>
      <div className='card'>
        <div className='card-body p-0'>
          <div className='d-flex align-items-center flex-grow-1'>
            <div className='symbol symbol-45px me-5'>
              <img
                src={
                  RoundupTransaction.donorProfilePic
                    ? RoundupTransaction.donorProfilePic
                    : toAbsoluteUrl('/media/avatars/blank.png')
                }
                className='h-[75px] w-[75px] symbol-label align-self-end'
                alt=''
              />
            </div>
            <div className='d-flex flex-column'>
              <span className='text-gray-800 fs-6 fw-bold'>{RoundupTransaction.donorName}</span>
              <span className='text-gray-400 fw-semibold'>
                {RoundupTransaction?.donorCategory?.join(', ')}
              </span>
            </div>
          </div>
          <div className='my-4'>
            <div className='separator border-gray-200'></div>
            <div className='d-flex align-items-center flex-row-fluid flex-wrap my-4'>
              <div className='flex-grow-1 me-2 d-flex align-items-center'>
                <div className='symbol symbol-40px me-5'>
                  <span className='symbol-label bg-light-info'>
                    <KTIcon iconName='badge' className='fs-2x text-danger' />
                  </span>
                </div>
                <span className='text-gray-800 fs-6 fw-bold'>RoundUp ID</span>
              </div>
              <div className='badge badge-light fw-bold my-2 fs-6'>{RoundupTransaction._id}</div>
            </div>

            <div className='d-flex align-items-center flex-row-fluid flex-wrap my-4'>
              <div className='flex-grow-1 me-2 d-flex align-items-center'>
                <div className='symbol symbol-40px me-5'>
                  <span className='symbol-label bg-light-info'>
                    <KTIcon iconName='verify' className='fs-2x text-danger' />
                  </span>
                </div>
                <span className='text-gray-800 fs-6 fw-bold'>RoundUp Status</span>
              </div>
              <div className=''>
                <span
                  className={`badge uppercase badge-light-secondary p-4 text-${
                    RoundupTransaction.status === 'Active' ? 'success' : 'danger'
                  }`}
                >
                  {RoundupTransaction.status}
                </span>
              </div>
            </div>
            <div className='d-flex align-items-center mb-3'>
              <div className='symbol symbol-45px w-40px me-5'>
                <span className='symbol-label bg-lighten'>
                  <KTIcon iconName='calendar' className='fs-2x text-danger' />
                </span>
              </div>
              <div className='d-flex align-items-center flex-wrap w-100'>
                <div className='mb-1 pe-3 flex-grow-1'>
                  <span className='text-gray-800 fs-6 fw-bold'>Date & Time</span>
                </div>
                <div className='d-flex align-items-center'>
                  <div className='badge badge-light fw-bold my-2 fs-6'>
                    {new Date(RoundupTransaction?.createdAt || '').toLocaleDateString('en-GB')}{' '}
                    {(() => {
                      const date = new Date(RoundupTransaction?.createdAt || '')
                      const hours = date.getHours()
                      const minutes = date.getMinutes()
                      const ampm = hours >= 12 ? 'PM' : 'AM'
                      const formattedHours = hours % 12 === 0 ? 12 : hours % 12

                      return `${formattedHours.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                      })}:${minutes.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                      })} ${ampm}`
                    })()}
                  </div>
                </div>
              </div>
            </div>

            <div className='d-flex align-items-center flex-row-fluid flex-wrap my-4'>
              <div className='flex-grow-1 me-2 d-flex align-items-center'>
                <div className='symbol symbol-40px me-5'>
                  <span className='symbol-label bg-light-info'>
                    <KTIcon iconName='dollar' className='fs-2x text-danger' />
                  </span>
                </div>
                <span className='text-gray-800 fs-6 fw-bold'>Payment Method</span>
              </div>
              <div className='badge badge-light fw-bold my-2 fs-6'>
                {RoundupTransaction.paymentMethod.toUpperCase()}
              </div>
            </div>

            <div className='d-flex align-items-center flex-row-fluid flex-wrap my-4'>
              <div className='flex-grow-1 me-2 d-flex align-items-center'>
                <div className='symbol symbol-40px me-5'>
                  <span className='symbol-label bg-light-info'>
                    <KTIcon iconName='home' className='fs-2x text-danger' />
                  </span>
                </div>
                <span className='text-gray-800 fs-6 fw-bold'>Bank Name</span>
              </div>
              <div className='badge badge-light fw-bold my-2 fs-6'>
                {RoundupTransaction.bankName.toUpperCase()}
              </div>
            </div>

            <div
              className='text-gray-800 fv-row fw-bold query-form-pop-up'
              style={{
                maxHeight: '50px',
                minHeight: '200px',
                wordBreak: 'break-all',
                overflowY: 'scroll',
                wordSpacing: '2px',
              }}
            >
              <table className='table table-striped'>
                <thead
                  className='thead-dark'
                  style={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    background: 'white',
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                >
                  <tr className='text-center'>
                    <th scope='col'>Charity Name</th>
                    <th scope='col'>Percentage</th>
                    <th scope='col'>Status</th>
                    {/* <th scope='col'>Action</th> */}
                  </tr>
                </thead>
                <tbody>
                  {roundUpData?.map((item, index) => (
                    <tr key={index} className='text-center'>
                      <td
                        data-toggle='tooltip'
                        data-placement='top'
                        title={item.charityName}
                        style={{
                          maxWidth: '50px',
                        }}
                      >
                        {item?.charityName?.length > 10
                          ? `${
                              item?.charityName.substring(0, 10).charAt(0).toUpperCase() +
                              item?.charityName.slice(1)
                            }...`
                          : item?.charityName.charAt(0).toUpperCase() + item?.charityName.slice(1)}
                      </td>
                      <td>{parseFloat(item.percentage.$numberDecimal).toFixed(2)} %</td>
                      <td>
                        <span
                          className={`badge uppercase badge-light-secondary p-4 text-${
                            item.status === 'Active' ? 'success' : 'danger'
                          }`}
                        >
                          {item.status}
                        </span>
                      </td>
                      {/* <td>
                        <ActionButton
                          deleteCallback={() =>
                            handleDeleteCharity(
                              item.charityId,
                              item.plaidId,
                              RoundupTransaction.last4,
                              item._id
                            )
                          }
                        />
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  )
}

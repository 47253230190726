import {KTIcon} from '../../_metronic/helpers'
import {toAbsoluteUrl} from '../../_metronic/helpers'

const ContactModalHeader = ({handleClose, contact, loader}) => {
  return (
    <div className='modal-header p-4 mx-2'>
      {/* begin::Modal title */}
      <div className='d-flex align-items-center flex-grow-1'>
        <div className='symbol symbol-45px me-5'>
          <img
            src={
              contact.user?.profilePic
                ? contact.user.profilePic
                : toAbsoluteUrl('/media/avatars/blank.png')
            }
            alt={contact?.user.name}
          />
        </div>
        <div className='d-flex flex-column'>
          <span className='text-gray-800 fs-6 fw-bold'>{contact?.user.name}</span>
          <span className='text-gray-400 fw-semibold'>
            {contact?.user.charityCategory.join(', ')}
          </span>
        </div>
      </div>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-kt-users-modal-action='close'
        style={{cursor: 'pointer'}}
      >
        {!loader && (
          <div onClick={handleClose}>
            {' '}
            <KTIcon iconName='cross' className='fs-1' />
          </div>
        )}{' '}
      </div>
      {/* end::Close */}
    </div>
  )
}

export {ContactModalHeader}

import React, {useState} from 'react'

export default function AccountApproveBody({
  body,
  handleClose,
  handleConfirm,
  approveLoading,
  accountRejectTitle,
  accountApproveTitle,
}) {
  const [rejectReason, setRejectReason] = useState('')
  const [selectedReasons, setSelectedReasons] = useState<any>([])
  const [suggestedReasons] = useState([
    'Incomplete charity profile, please provide all required information for verification.',
    'Inaccurate or incomplete contact information provided; update for effective communication.',
    'Duplicate or misleading information present; ensure accuracy and uniqueness.',
    'Oops! We encountered an issue with your stripe connect account.',
  ])

  const confirmButtonLabel = approveLoading ? (
    <span className='indicator-progress' style={{display: 'block'}}>
      Submitting ...
      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    </span>
  ) : (
    "Yes, I'm sure"
  )

  const handleTextareaChange = (event) => {
    setRejectReason(event.target.value)
  }

  const handleCheckboxChange = (reason) => {
    setSelectedReasons((prevSelectedReasons) => {
      const isSelected = prevSelectedReasons.includes(reason)

      if (isSelected) {
        setRejectReason('')
        return prevSelectedReasons.filter((r) => r !== reason)
      } else {
        setRejectReason(reason)
        return [reason]
      }
    })
  }

  return (
    <div className='container'>
      <div className='card text-center'>
        <div className='card-body'>
          <h4 className='card-title fw-bold text-black fs-4'>
            {accountApproveTitle
              ? 'Are you sure you want to approve this charity?'
              : accountRejectTitle
              ? 'Are you sure you want to reject this charity?'
              : ''}
          </h4>
          <h2 className='card-title fw-bold text-muted my-5 fs-2'>{body?.title}</h2>
          {accountRejectTitle && (
            <div>
              <textarea
                value={rejectReason}
                onChange={handleTextareaChange}
                autoComplete='off'
                className='form-control form-control-lg mb-3'
                placeholder='Rejection Reason'
              />
              <div className='mb-3'>
                <strong className='text-start mb-2 d-block'>Suggested Reasons:</strong>
                <ul className='list-group suggested-reasons'>
                  {suggestedReasons.map((reason, index) => (
                    <li
                      key={index}
                      className='text-start list-group-item list-group-item-action d-flex align-items-center'
                    >
                      <div>
                        <input
                          type='checkbox'
                          checked={selectedReasons.includes(reason)}
                          onChange={() => {
                            handleCheckboxChange(reason)
                          }}
                          className='form-check-input me-2 cursor-pointer'
                          style={{height: '20px', width: '20px'}}
                        />
                      </div>
                      <span className='fs-5'>{reason}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          <div className='d-flex my-4 justify-content-center'>
            <button className='btn btn-light me-3' onClick={handleClose}>
              No, cancel
            </button>
            <button
              className='btn btn-danger'
              disabled={approveLoading}
              onClick={() => handleConfirm(rejectReason)}
            >
              {confirmButtonLabel}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

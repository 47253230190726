import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {
  CharityDonations,
  CharityEvents,
  CharityPosts,
  CharityProfileDetails,
} from '../../../../components/Sections'
import {BankAccounts} from '../../../../components/Common'
import {useCallback, useEffect, useState} from 'react'
import {CustomModal, MODAL_TYPE, MODAL_TYPE_ENUM} from '../../../../components/Modal'
import {getCharity} from '../../../modules/auth/core/StorageHelper'
import {
  ACCOUNT_TYPE,
  POST_EVENT_TYPE,
  ROLE_TYPE,
  deleteBankAccount,
  deletePostOrEvent,
  setDefaultAccount,
  userProfile,
} from '../../../modules/auth/core/_requests'
import {useAuth} from '../../../modules/auth'
import {CharityProfileDetailsType} from '../../../../components/Sections/CharitySections/CharityProfileDetails'
import {toast} from 'react-toastify'
import CommonComment from '../../../../components/Common/CommonComment'
import CharityRoundup from '../../../../components/Sections/CharitySections/CharityRoundup'
import AdminStipeAccountBody from '../../../../components/Modal/Body/AdminStripeAccountBody'

const charityBreadcrumbs: Array<PageLink> = [
  {
    title: 'Charity Management',
    path: '/charity-management',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
export interface State {
  modalView: boolean
  selectedRow: any
  type: MODAL_TYPE
  charityDetails?: CharityProfileDetailsType | null
  donorDetails?: any | null
  loading?: boolean
  isDeleted?: boolean
  deleteLoading?: {
    postsOrEvents?: boolean
    accounts?: boolean
  }
  setDefaultLoading?: 'process' | 'complete' | 'failed' | null
  cardLoading?: boolean
  index?: any
}
const CharityId: React.FC = () => {
  const id = getCharity()
  const {auth} = useAuth()
  const param = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const [isEditing, setIsEditing] = useState(false)

  const [state, setState] = useState<State>({
    modalView: false,
    selectedRow: null,
    type: null,
    charityDetails: null,
    loading: true,
    deleteLoading: {
      postsOrEvents: false,
      accounts: false,
    },
    setDefaultLoading: null,
  })

  const [loading, setLoading] = useState(true)

  const getProfileDetails = useCallback(
    async (token, role, id) => {
      try {
        setState({...state, loading: true})
        const result = await userProfile(token, role, id)
        if (result.status === 200) {
          setLoading(false)
          setState({
            ...state,
            charityDetails: result.data.data,
            loading: false,
            isDeleted: result.data.data.isDeleted,
          })
        }
      } catch (error) {
        setState({...state, loading: false})
      }
    },
    [state]
  )

  useEffect(() => {
    if (id && auth && auth.token) {
      getProfileDetails(auth.token, ROLE_TYPE.CHARITY, id)
      setIsEditing(location.search.includes('edit=true'))
      if (state.isDeleted && location.search.includes('edit=true')) {
        setIsEditing(false)
      }
    } else {
      navigate('/charity-management')
    }
  }, [])

  const handleDeletePostOrEvent = async () => {
    try {
      setState({...state, deleteLoading: {...state.deleteLoading, postsOrEvents: true}})
      const result = await deletePostOrEvent(
        auth?.token,
        state.selectedRow.type,
        state.selectedRow.id
      )
      if (result.status === 200) {
        setState({
          ...state,
          modalView: false,
          selectedRow: null,
          type: null,
          deleteLoading: {...state.deleteLoading, postsOrEvents: false},
        })
      }
    } catch (error: any) {
      setState({
        ...state,
        modalView: false,
        selectedRow: null,
        type: null,
        deleteLoading: {...state.deleteLoading, postsOrEvents: false},
      })
      toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
    }
  }

  const handleDeleteAccount = async () => {
    setState({...state, deleteLoading: {...state.deleteLoading, accounts: true}})
    try {
      const result = await deleteBankAccount(
        state.selectedRow?.charityId,
        state.selectedRow?.bankId,
        ROLE_TYPE.CHARITY
      )
      if (result.status === 200) {
        setState({
          ...state,
          modalView: false,
          selectedRow: null,
          type: null,
          deleteLoading: {...state.deleteLoading, accounts: false},
        })
        toast.success(result?.data?.message)
      }
    } catch (error: any) {
      setState({
        ...state,
        modalView: false,
        selectedRow: null,
        type: null,
        deleteLoading: {...state.deleteLoading, accounts: false},
      })
      toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
    }
  }

  const setDefaultBankAccount = async (accountId) => {
    try {
      setState({...state, setDefaultLoading: 'process'})
      const result = await setDefaultAccount(auth?.token, id, ROLE_TYPE.CHARITY, accountId)
      if (result.status === 200) {
        setState({...state, setDefaultLoading: 'complete'})
      }
    } catch (error: any) {
      toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
      setState({...state, setDefaultLoading: 'failed'})
    }
  }

  const handleEditProfile = () => {
    const params = new URLSearchParams(location.search)
    params.set('edit', isEditing ? 'false' : 'true')
    navigate({
      pathname: location.pathname,
      search: params.toString(),
    })
  }

  const editButton = (
    <div className='d-flex justify-content-end py-3'>
      <button
        onClick={(e) => {
          setIsEditing((prevIsEditing) => !prevIsEditing)
          handleEditProfile()
          e.stopPropagation()
        }}
        type='submit'
        className='btn btn-primary'
      >
        {isEditing ? 'Cancel Edit' : 'Edit Profile'}
      </button>
    </div>
  )
  return (
    <>
      {/* <PageTitle ReactNodeReactNode={charityBreadcrumbs}>{param.id}</PageTitle> */}
      <PageTitle breadcrumbs={charityBreadcrumbs}>
        {param.id && param.id.length > 25 ? param.id.substring(0, 25) + '..' : param.id}
      </PageTitle>

      <CharityProfileDetails
        charityId={id}
        charity={state.charityDetails}
        isView={location.search.includes('edit=true') ? false : true}
        editButton={
          !loading && (
            <>
              {state?.isDeleted && state?.isDeleted ? (
                <span className='badge capitalize fs-6 text-danger'>Account Deleted</span>
              ) : (
                editButton
              )}
            </>
          )
        }
        setCharity={() => {
          setState((prev: any) => ({
            ...prev,
            adminDetails: {
              ...prev.adminDetails,
              profilePic: undefined,
            },
          }))
        }}
        isDeleted={state?.isDeleted}
      />

      {state?.isDeleted && state?.isDeleted ? (
        <CharityDonations
          charityId={id}
          handleView={(row) =>
            setState({
              ...state,
              modalView: true,
              selectedRow: row,
              type: MODAL_TYPE_ENUM.TRANSACTION,
            })
          }
        />
      ) : (
        <>
          <CharityPosts
            deleteLoading={state.deleteLoading?.postsOrEvents}
            charityId={id}
            handleView={(row) => {
              setState({
                ...state,
                modalView: true,
                selectedRow: row,
                type: MODAL_TYPE_ENUM.POST,
              })
            }}
            handleDelete={(row) =>
              setState({
                ...state,
                modalView: true,
                selectedRow: {title: row.title, id: row.id, type: POST_EVENT_TYPE.POST},
                type: MODAL_TYPE_ENUM.DELETE,
              })
            }
            getDetails={param.id}
          />

          <CharityEvents
            deleteLoading={state.deleteLoading?.postsOrEvents}
            charityId={id}
            handleView={(row) =>
              setState({
                ...state,
                modalView: true,
                selectedRow: row,
                type: MODAL_TYPE_ENUM.EVENT,
              })
            }
            handleDelete={(row) =>
              setState({
                ...state,
                modalView: true,
                selectedRow: {title: row.title, id: row.id, type: POST_EVENT_TYPE.EVENT},
                type: MODAL_TYPE_ENUM.DELETE,
              })
            }
            getDetails={param.id}
          />

          <BankAccounts
            id={id}
            deleteLoading={state.deleteLoading?.accounts}
            setDefaultAccount={setDefaultBankAccount}
            setDefaultLoading={state.setDefaultLoading}
            role={ROLE_TYPE.CHARITY}
            type={ACCOUNT_TYPE.BANK}
            deleteHandler={(ac) => {
              setState({
                ...state,
                modalView: true,
                selectedRow: {...ac, title: ac.name, type: 'account'},
                type: MODAL_TYPE_ENUM.DELETE,
              })
            }}
          />

          <CharityDonations
            charityId={id}
            handleView={(row) =>
              setState({
                ...state,
                modalView: true,
                selectedRow: row,
                type: MODAL_TYPE_ENUM.TRANSACTION,
              })
            }
            getDetails={param.id}
          />
          <CharityRoundup charityId={id} getDetails={param.id} />
          <CommonComment id={id} type={ROLE_TYPE.CHARITY_PROFILE} />
        </>
      )}

      {state.modalView && (
        <CustomModal
          type={state.type}
          body={state.selectedRow}
          deleteLoading={
            state.selectedRow.type === POST_EVENT_TYPE.EVENT ||
            state.selectedRow.type === POST_EVENT_TYPE.POST
              ? state.deleteLoading?.postsOrEvents
              : state.deleteLoading?.accounts
          }
          handleConfirm={() => {
            if (
              state.selectedRow.type === POST_EVENT_TYPE.EVENT ||
              state.selectedRow.type === POST_EVENT_TYPE.POST
            ) {
              handleDeletePostOrEvent()
            }
            if (state.selectedRow.type === 'account') {
              handleDeleteAccount()
            }
          }}
          handleClose={() => setState({...state, modalView: false, selectedRow: null, type: null})}
        />
      )}
    </>
  )
}

const CharityIdWrapper = () => (
  <>
    <CharityId />
  </>
)

export {CharityIdWrapper}

import React from 'react'
import {TContact} from './body'
/**
 * Renders the contact's information, including their full name, email, phone number, and message.
 *
 * @param contact - The contact object containing the contact's information.
 * @returns The rendered HTML markup for the contact's information.
 */
export default function ContactBody({contact}: {contact: TContact}) {
  return (
    <>
      <div className='card-body p-5 py-0'>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label  fw-bolder fs-6'>Name</label>
          <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted'>
            {contact?.name.length > 40 ? `${contact?.name.substring(0, 40)}...` : contact?.name}
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label  fw-bolder fs-6'>Phone</label>
          <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted'>
            {contact?.phone}
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bolder fs-6'>Email</label>
          <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted'>
            {contact?.email}{' '}
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label  fw-bolder fs-6'>Message</label>
          <div
            className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted query-form-pop-up bg-light rounded'
            style={{
              maxHeight: '150px',
              wordBreak: 'break-all',
              overflowY: 'scroll',
              wordSpacing: '2px',
            }}
          >
            {contact?.message}
          </div>
        </div>
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label fw-bolder fs-6'>Status</label>
          <div className='col-lg-7 fv-row col-form-label fw-bold fs-6 text-muted'>
            <div
              className={`fw-bold fs-5 badge badge-light-${
                contact?.status === 'pending' ? 'danger' : 'success'
              } pe-1`}
            >
              {contact?.status}
            </div>{' '}
          </div>
        </div>
      </div>
    </>
  )
}

import {useAuth} from '../../../app/modules/auth'

export default function CompleteProfileBody({handleClose}) {
  const {logout} = useAuth()

  return (
    <div className='text-center'>
      <div className='card-body'>
        <p className='card-text text-muted text-dark fw-bolder fs-3 mb-0'>
          Your profile is incomplete, Please complete it first.
        </p>

        <div className='d-flex my-4 gap-4 flex-center'>
          <button
            className='btn btn-primary font-weight-bold px-9 py-4 my-3'
            onClick={() => handleClose()}
          >
            Complete Profile
          </button>
          <button
            type='button'
            className='btn btn-danger font-weight-bold px-9 py-4 my-3'
            onClick={logout}
          >
            Signout
          </button>
        </div>
      </div>
    </div>
  )
}

import axios from 'axios'
import { AuthModel } from './_models'


const API_URL = process.env.REACT_APP_API_URL;
// process.env.NODE_ENV === 'development'
// ? process.env.REACT_APP_LOCAL_URI
// : process.env.REACT_APP_API_URL;


console.log("API URL is:", API_URL);
console.log("process.env.NODE_ENV", process.env.NODE_ENV);


const LOGIN_URL = `${API_URL}/login`

const PROFILE_URL = (role) => `${API_URL}/admin/userProfile?role=${role}`

const RESET_PASSWORD_URL = `${API_URL}/forgotPassword`

const VERIFY_OTP = `${API_URL}/verifyOtpForgetPassword`

const CHARITY_VERIFY_OTP = `${API_URL}/verifyEmailDuringRegisteration`

const CREATE_NEW_PASSWORD = `${API_URL}/createnewPasword`

const DASHBOARD_URL = `${API_URL}/admin/dashboard`

const USER_STATUS_CHANGE_URL = `${API_URL}/admin/updateUserStatus`

const CHECK_EMAIL_AVAILABLE = (email) => `${API_URL}/checkAvailableEmail/${email}`

const UPDATE_PASSWORD = `${API_URL}/admin/changeCurrentPassword`

const UPDATE_USER_URL = (id, role) => `${API_URL}/admin/updateDonorCharity?id=${id}&role=${role}`

const UPDATE_CHARITY_USER_URL = (role) => `${API_URL}/admin/updateDonorCharity?role=${role}`

const UPDATE_ADMIN_USER_URL = (role) => `${API_URL}/admin/updateDonorCharity?role=${role}`

const UPDATE_CHARITY_PROFILE = (role) => `${API_URL}/updateCharity?role=${role}`

const CHANGE_CARD_STATUS_URL = `${API_URL}/admin/updateCardStatus`

const DELETE_BANK_ACCOUNT_URL = (userId, bankId, role) =>
  `${API_URL}/admin/deleteBankAccount?id=${userId}&bankId=${bankId}&role=${role}`

const DELETE_POST_EVENT_COMMENT = (id: string, commentCategory: string) =>
  `${API_URL}/admin/deleteComment/${id}/${commentCategory}`

const CHARITY_DELETE_BANK_ACCOUNT_URL = (bankId, role) =>
  `${API_URL}/admin/deleteBankAccount?bankId=${bankId}&role=${role}`

const CATEGORIES_URL = `${API_URL}/getAllCharityCategories`

const SET_DEFAULT_ACCOUNT_URL = `${API_URL}/admin/setDefaultSourceAccount`

const CHARITY_SET_DEFAULT_ACCOUNT_URL = `${API_URL}/admin/setDefaultSourceAccount`

const UPDATE_POST_EVENT_URL = `${API_URL}/admin/updatePostOrEvent`

const UPDATE_SUBSCRIPTION_URL = `${API_URL}/admin/updateSubscription`

const FETCH_CHARITY_CATEGORY = (search, page, limit) =>
  `${API_URL}/admin/fetchCharityCategoryList?keyword=${search}&page=${page}&limit=${limit}`

const DELETE_CHARITY_CATEGORY = (charityId: string) =>
  `${API_URL}/admin/deleteCharityCategory/${charityId}`

const ADD_CHARITY_CATEGORY = `${API_URL}/admin/addcharityCategory`

const DELETE_POST_EVENT_URL = (id: string, type: POST_EVENT_TYPE) =>
  `${API_URL}/admin/deletePostOrEvent/${id}/${type}`

const CONTACT_US_URL = (page, search, filters, limit) =>
  `${API_URL}/admin/contactUs?keyword=${search}&page=${page}&startDate=${filters.startDate}&endDate=${filters.endDate}&status=${filters.status}&limit=${limit}`

const CONTACT_US_URL_STATUS_UPDATE = `${API_URL}/admin/updateContactUsQueryStatus`

const USER_LIST_URL = (role: ROLE_TYPE, userListFor: string) =>
  `${API_URL}/admin/fetchUserList?role=${role}&userListFor=${userListFor}`

const USER_LIST_URL_CHARITY = (role: ROLE_TYPE, userListFor: string, charityId: string) =>
  `${API_URL}/admin/fetchUserList?role=${role}&userListFor=${userListFor}&charityId=${charityId}`

const CHARITY_USER_LIST_URLS = (role: ROLE_TYPE, userListFor: string, charityId) =>
  `${API_URL}/admin/fetchUserList?role=${role}&userListFor=${userListFor}&charityId=${charityId}`

const DONOR_USER_LIST_URLS = (role: ROLE_TYPE, userListFor: string, donorId: string) =>
  `${API_URL}/admin/fetchUserList?role=${role}&userListFor=${userListFor}&donorId=${donorId}`

const USER_MANAGEMENT_URL = (role, page, search, filters, limit) =>
  `${API_URL}/admin/userManagement?role=${role}&page=${page}&keyword=${search}&status=${filters.status}&limit=${limit}`

const USER_PROFILE_URL = (role: ROLE_TYPE, id: string) =>
  `${API_URL}/admin/userProfile?role=${role}&id=${id}`

const CHARITY_USER_PROFILE_URL = (role: ROLE_TYPE) => `${API_URL}/admin/userProfile?role=${role}`

const ADMIN_USER_PROFILE_URL = (role: ROLE_TYPE) => `${API_URL}/admin/userProfile?role=${role}`

const CHARITY_POST_EVENT_URL = (
  type: POST_EVENT_TYPE,
  id: string,
  page,
  filters,
  search,
  limit
) => {
  if (type === POST_EVENT_TYPE.EVENT) {
    return `${API_URL}/admin/postEventDetails?type=${type}&page=${page}&charityId=${id}&keyword=${search}&status=${filters.status}&startRangeEventStartDate=${filters.startRangeStartDate}&endRangeEventStartDate=${filters.endRangeStartDate}&startRangeEventEndDate=${filters.startRangeEndDate}&endRangeEventEndDate=${filters.endRangeEndDate}&limit=${limit}`
  }
  return `${API_URL}/admin/postEventDetails?type=${type}&page=${page}&charityId=${id}&startDate=${filters.startDate}&endDate=${filters.endDate}&keyword=${search}&status=${filters.status}&limit=${limit}`
}

const CHARITY_PROFILES_POST_EVENT_URL = (type: POST_EVENT_TYPE, id: string, limit) => {
  return `${API_URL}/admin/postEventDetails?type=${type}&limit=${limit}&charityId=${id}`
}

const CHARITY_PROFILE_POST_EVENT_URL = (type: POST_EVENT_TYPE, id: string, limit) => {
  return `${API_URL}/admin/postEventDetails?type=${type}&limit=${limit}&charityId=${id}`
}

const CHARITY_USER_POST_EVENT_URL = (type: POST_EVENT_TYPE, page, filters, search, limit) => {
  if (type === POST_EVENT_TYPE.EVENT) {
    return `${API_URL}/admin/postEventDetails?type=${type}&page=${page}&keyword=${search}&status=${filters.status}&startRangeEventStartDate=${filters.startRangeStartDate}&endRangeEventStartDate=${filters.endRangeStartDate}&startRangeEventEndDate=${filters.startRangeEndDate}&endRangeEventEndDate=${filters.endRangeEndDate}&limit=${limit}`
  }
  return `${API_URL}/admin/postEventDetails?type=${type}&page=${page}&startDate=${filters.startDate}&endDate=${filters.endDate}&keyword=${search}&status=${filters.status}&limit=${limit}`
}
const CHARITY_USER_PROFILE_POST_EVENT_URL = (type: POST_EVENT_TYPE, limit) => {
  return `${API_URL}/admin/postEventDetails?type=${type}&limit=${limit}`
}

const CHARITY_SECTION_USER_POST_EVENT_URL = (type: POST_EVENT_TYPE, limit) => {
  return `${API_URL}/admin/postEventDetails?type=${type}&limit=${limit}`
}

const CHARITY_POST_EVENT_COMMENT = (id: string, type, page) =>
  `${API_URL}/admin/commentList?id=${id}&type=${type}&page=${page}`

const CHARITY_USER_POST_EVENT_COMMENT = (type, page) =>
  `${API_URL}/admin/commentList?type=${type}&page=${page}`

const USER_ACCOUNT_DETAILS_URL = (type: ACCOUNT_TYPE, id: string, role: ROLE_TYPE) =>
  `${API_URL}/admin/accountCardDetails?type=${type}&id=${id}&role=${role}`

const CHARITY_USER_ACCOUNT_DETAILS_URL = (type: ACCOUNT_TYPE, role: ROLE_TYPE) =>
  `${API_URL}/admin/accountCardDetails?type=${type}&role=${role}`

const USER_TRANSACTION_DETAILS_URL = (charityId: string, role: ROLE_TYPE, limit) =>
  `${API_URL}/admin/transactionDetails?role=${role}&charityId=${charityId}&page=1&limit=${limit}`

const USER_DONOR_TRANSACTION_DETAILS_URL = (donorId: string, role: ROLE_TYPE, limit) =>
  `${API_URL}/admin/transactionDetails?role=${role}&donorId=${donorId}&page=1&limit=${limit}`

const CHARITY_USER_TRANSACTION_DETAILS_URL = (role: ROLE_TYPE, limit) =>
  `${API_URL}/admin/transactionDetails?role=${role}&page=1&donationModule=true&limit=${limit}`

const MULTIPLE_POST_EVENT_DELTE = `${API_URL}/admin/deleteMultiplePostEvent`

const DELETE_USER = `${API_URL}/deleteUser`

const USER_PROFILE_PIC_URL = `${API_URL}/admin/getAdminProfileDetails`

const STIRPE_ACCOUNT_MAIL = `${API_URL}/admin/sendMailForStripeRequirmentToCharity`

const SET_REPORT_STATUS_URL = `${API_URL}/admin/reports/status`

const SET_POST_HIDDEN_URL = `${API_URL}/admin/postHidden`

const SET_EVENT_HIDDEN_URL = `${API_URL}/admin/eventHidden`

export function profilePic(token) {
  return axios.get(USER_PROFILE_PIC_URL, {
    headers: {
      Authorization: token,
    },
  })
}

export function stripeAccountMail(token, charityId) {
  return axios.post(
    STIRPE_ACCOUNT_MAIL,
    {
      charityId,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}
const DONOR_SUBSCRIPTION_DETAILS_URL = (id, page, search, filters, limit) => {
  let minAmount = ''
  let maxAmount = ''
  if (filters.amount) {
    if (filters.amount === '>2000') {
      minAmount = '2000'
      maxAmount = ''
    } else {
      const amountValues = filters.amount.split('-')
      minAmount = amountValues[ 0 ]
      maxAmount = amountValues[ 1 ]
    }
  }
  let url = `${API_URL}/admin/subscriptionDetails?page=${page}&planType=${filters.planType}&status=${filters.status}&search=${search}&minAmount=${minAmount}&maxAmount=${maxAmount}&limit=${limit}`

  if (filters.id && filters.charity_id) {
    url += `&donorId=${filters.id}&charityId=${filters.charity_id}`
  } else {
    if (filters.id) {
      url += `&donorId=${filters.id}`
    }
    if (filters.charity_id) {
      url += `&charityId=${filters.charity_id}`
    }
  }
  return url
}

const USER_DONOR_SUBSCRIPTION_DETAILS_URL = (donorId: string, limit) =>
  `${API_URL}/admin/subscriptionDetails?donorId=${donorId}&limit=${limit}`

const VIEW_DONOR_SUBSCRIPTION_DETAILS_URL = (id, page, search, filters, limit) => {
  let minAmount = ''
  let maxAmount = ''
  if (filters.amount) {
    if (filters.amount === '>2000') {
      minAmount = '2000'
      maxAmount = ''
    } else {
      const amountValues = filters.amount.split('-')
      minAmount = amountValues[ 0 ]
      maxAmount = amountValues[ 1 ]
    }
  }
  let url = `${API_URL}/admin/subscriptionDetails?page=${page}&planType=${filters.planType}&status=${filters.status}&search=${search}&minAmount=${minAmount}&maxAmount=${maxAmount}&limit=${limit}`

  if (filters.id && filters.charity_id) {
    url += `&donorId=${filters.id}&charityId=${filters.charity_id}`
  } else {
    if (filters.id) {
      url += `&donorId=${filters.id}`
    }
    if (filters.charity_id) {
      url += `&charityId=${filters.charity_id}`
    }
  }
  return url
}

const VIEW_DONOR_SUBSCRIPTION = (id, page, search, filters, limit) => {
  let minAmount = ''
  let maxAmount = ''
  if (filters.amount) {
    if (filters.amount === '>2000') {
      minAmount = '2000'
      maxAmount = ''
    } else {
      const amountValues = filters.amount.split('-')
      minAmount = amountValues[ 0 ]
      maxAmount = amountValues[ 1 ]
    }
  }
  let url = `${API_URL}/admin/subscriptionDetails?page=${page}&planType=${filters.planType}&status=${filters.status}&search=${search}&minAmount=${minAmount}&maxAmount=${maxAmount}&limit=${limit}&donorId=${id}`

  if (filters.charity_id) {
    url += `&charityId=${filters.charity_id}`
  }
  return url
}

const DONOR_SUBSCRIPTION_PAGE_DETAILS_URL = (id: string, page, search, filters) => {
  let minAmount = ''
  let maxAmount = ''
  if (filters.amount) {
    if (filters.amount === '>2000') {
      minAmount = '2000'
      maxAmount = ''
    } else {
      const amountValues = filters.amount.split('-')
      minAmount = amountValues[ 0 ]
      maxAmount = amountValues[ 1 ]
    }
  }
  return `${API_URL}/admin/subscriptionDetails?page=${page}&id=${id}&planType=${filters.planType}&status=${filters.status}&search=${search}&minAmount=${minAmount}&maxAmount=${maxAmount}`
}

const CHARITY_DONOR_SUBSCRIPTION_DETAILS_URL = (page, search, filters, limit) => {
  let minAmount = ''
  let maxAmount = ''
  if (filters.amount) {
    if (filters.amount === '>2000') {
      minAmount = '2000'
      maxAmount = ''
    } else {
      const amountValues = filters.amount.split('-')
      minAmount = amountValues[ 0 ]
      maxAmount = amountValues[ 1 ]
    }
  }

  return `${API_URL}/admin/subscriptionDetails?donorId=${filters.id}&page=${page}&role=charity&planType=${filters.planType}&status=${filters.status}&search=${search}&minAmount=${minAmount}&maxAmount=${maxAmount}&limit=${limit}`
}

const DONATIONS_LISTING_URL = (role, page, search, filters, limit) => {
  let minAmount = ''
  let maxAmount = ''
  if (filters.amount) {
    if (filters.amount === '>2000') {
      minAmount = '2000'
      maxAmount = ''
    } else {
      const amountValues = filters.amount.split('-')
      minAmount = amountValues[ 0 ]
      maxAmount = amountValues[ 1 ]
    }
  }
  let url = `${API_URL}/admin/transactionDetails?role=${role}&page=${page}&limit=${limit}&donationModule=true&keyword=${search}&type=${filters.accountType}&startDate=${filters.startDate}&endDate=${filters.endDate}&status=${filters.status}&minAmount=${minAmount}&maxAmount=${maxAmount}&paymentType=${filters.paymentType}`
  if (filters.id && filters.charity_id) {
    url += `&donorId=${filters.id}&charityId=${filters.charity_id}`
  } else {
    if (filters.id) {
      url += `&donorId=${filters.id}`
    }
    if (filters.charity_id) {
      url += `&charityId=${filters.charity_id}`
    }
  }
  return url
}

const VIEW_DONATIONS_LISTING_URL = (role, page, search, filters, limit) => {
  let minAmount = ''
  let maxAmount = ''
  if (filters.amount) {
    if (filters.amount === '>2000') {
      minAmount = '2000'
      maxAmount = ''
    } else {
      const amountValues = filters.amount.split('-')
      minAmount = amountValues[ 0 ]
      maxAmount = amountValues[ 1 ]
    }
  }
  let url = `${API_URL}/admin/transactionDetails?role=${role}&page=${page}&donationModule=true&keyword=${search}&type=${filters.accountType}&startDate=${filters.startDate}&endDate=${filters.endDate}&status=${filters.status}&minAmount=${minAmount}&maxAmount=${maxAmount}&paymentType=${filters.paymentType}&limit=${limit}`
  if (filters.id && filters.charity_id) {
    url += `&donorId=${filters.id}&charityId=${filters.charity_id}`
  } else {
    if (filters.donor_id) {
      url += `&donorId=${filters.donor_id}`
    }

    if (role === 'charity') {
      url += `&charityId=${filters.id}`
    }

    // if (role === 'donor' && !filters.charity_id) {
    //   url += `&donorId=${filters.id}`
    // }

    if (filters.charity_id) {
      url += `&charityId=${filters.charity_id}`
    }
  }
  return url
}
const VIEW_CHARITY_DONATION_LISTING_URL = (role, page, search, filters, limit, charityId) => {
  let minAmount = ''
  let maxAmount = ''
  if (filters.amount) {
    if (filters.amount === '>2000') {
      minAmount = '2000'
      maxAmount = ''
    } else {
      const amountValues = filters.amount.split('-')
      minAmount = amountValues[ 0 ]
      maxAmount = amountValues[ 1 ]
    }
  }
  let url = `${API_URL}/admin/transactionDetails?role=${role}&page=${page}&donationModule=true&keyword=${search}&type=${filters.accountType}&startDate=${filters.startDate}&endDate=${filters.endDate}&status=${filters.status}&minAmount=${minAmount}&maxAmount=${maxAmount}&paymentType=${filters.paymentType}&limit=${limit}&charityId=${charityId}`

  if (filters.donor_id) {
    url += `&donorId=${filters.donor_id}`
  }

  return url
}
const VIEW_DONOR_DONATION_LISTING_URL = (role, page, search, filters, limit, donorId) => {
  let minAmount = ''
  let maxAmount = ''
  if (filters.amount) {
    if (filters.amount === '>2000') {
      minAmount = '2000'
      maxAmount = ''
    } else {
      const amountValues = filters.amount.split('-')
      minAmount = amountValues[ 0 ]
      maxAmount = amountValues[ 1 ]
    }
  }
  let url = `${API_URL}/admin/transactionDetails?role=${role}&page=${page}&donationModule=true&keyword=${search}&type=${filters.accountType}&startDate=${filters.startDate}&endDate=${filters.endDate}&status=${filters.status}&minAmount=${minAmount}&maxAmount=${maxAmount}&paymentType=${filters.paymentType}&limit=${limit}&donorId=${donorId}`

  if (filters.charity_id) {
    url += `&charityId=${filters.charity_id}`
  }

  return url
}

const DONOR_VIEW_DONATIONS_LISTING_URL = (role, page, search, filters, limit) => {
  let minAmount = ''
  let maxAmount = ''
  if (filters.amount) {
    if (filters.amount === '>2000') {
      minAmount = '2000'
      maxAmount = ''
    } else {
      const amountValues = filters.amount.split('-')
      minAmount = amountValues[ 0 ]
      maxAmount = amountValues[ 1 ]
    }
  }
  let url = `${API_URL}/admin/transactionDetails?role=${role}&page=${page}&donationModule=true&keyword=${search}&type=${filters.accountType}&startDate=${filters.startDate}&endDate=${filters.endDate}&status=${filters.status}&minAmount=${minAmount}&maxAmount=${maxAmount}&paymentType=${filters.paymentType}&limit=${limit}`
  if (filters.id && filters.charity_id) {
    url += `&donorId=${filters.id}&charityId=${filters.charity_id}`
  } else {
    if (filters.donor_id) {
      url += `&donorId=${filters.donor_id}`
    }

    if (role === 'charity') {
      url += `&charityId=${filters.id}`
    }

    if (role === 'donor') {
      url += `&donorId=${filters.id}`
    }

    if (filters.charity_id) {
      url += `&charityId=${filters.charity_id}`
    }
  }
  return url
}

const CHARITY_DONATIONS_LISTING_URL = (role, page, search, filters, limit) => {
  let minAmount = ''
  let maxAmount = ''
  if (filters.amount) {
    if (filters.amount === '>2000') {
      minAmount = '2000'
      maxAmount = ''
    } else {
      const amountValues = filters.amount.split('-')
      minAmount = amountValues[ 0 ]
      maxAmount = amountValues[ 1 ]
    }
  }
  return `${API_URL}/admin/transactionDetails?role=${role}&donorId=${filters.id}&page=${page}&limit=${limit}&donationModule=true&keyword=${search}&type=${filters.accountType}&startDate=${filters.startDate}&endDate=${filters.endDate}&status=${filters.status}&minAmount=${minAmount}&maxAmount=${maxAmount}&paymentType=${filters.paymentType}`
}
const CHARITY_ADD_BANK_ACCOUNT = `${API_URL}/stripe/connect/createAccount?platform=web_app`

const ADD_STRIPE_BANK_ACCOUNT = `${API_URL}/stripe/payment/createStriepExternalAccount`

const ADD_STRIPE_BANKS = `https://api.stripe.com/v1/tokens`

const ADD_CHARITY_POST = `${API_URL}/createPost`

const ADD_CHARITY_EVENT = `${API_URL}/createEvent`

const EDIT_CHARITY_POST = (postId) => `${API_URL}/updatePost/${postId}`

const EDIT_CHARITY_EVENT = (eventId) => `${API_URL}/updateEvent/${eventId}`

const CHARITY_REGISTER = `${API_URL}/register`

const CONTACT_US = `${API_URL}/contactUs`

const UPDATE_ACCOUNT_APPROVAL = `${API_URL}/admin/updateAdminApprovalStatus`

const ROUNDUP_LIST = (role, page, search, filters, limit, roundUpModuleFor) => {
  let url = `${API_URL}/admin/roundUpDetails?role=${role}&page=${page}&paymentMethodType=${filters.paymentMethod}&startDate=${filters.startDate}&endDate=${filters.endDate}&status=${filters.status}&search=${search}&limit=${limit}&roundUpModuleFor=${roundUpModuleFor}`

  if (filters.id && filters.charity_id) {
    url += `&donorId=${filters.id}&charityId=${filters.charity_id}`
  } else {
    if (filters.id) {
      url += `&donorId=${filters.id}`
    }
    if (filters.charity_id) {
      url += `&charityId=${filters.charity_id}`
    }
  }
  return url
}

const CHARITY_ROUNDUP_LIST = (role, page, search, filters, limit, roundUpModuleFor, charityId) => {
  let url = `${API_URL}/admin/roundUpDetails?role=${role}&page=${page}&paymentMethodType=${filters.paymentMethod}&startDate=${filters.startDate}&endDate=${filters.endDate}&status=${filters.status}&search=${search}&limit=${limit}&roundUpModuleFor=${roundUpModuleFor}&charityId=${charityId}`

  if (filters.donor_id) {
    url += `&donorId=${filters.donor_id}`
  }

  return url
}
const DONOR_ROUNDUP_LIST = (role, page, search, filters, limit, roundUpModuleFor, donorId) => {
  let url = `${API_URL}/admin/roundUpDetails?role=${role}&page=${page}&paymentMethodType=${filters.paymentMethod}&startDate=${filters.startDate}&endDate=${filters.endDate}&status=${filters.status}&search=${search}&limit=${limit}&roundUpModuleFor=${roundUpModuleFor}&donorId=${donorId}`

  if (filters.charity_id) {
    url += `&charityId=${filters.charity_id}`
  }

  return url
}
const ROUNDUP_LISTS = (role, page, search, filters, limit) => {
  let url = `${API_URL}/admin/roundUpDetails?role=${role}&page=${page}&paymentMethodType=${filters.paymentMethod}&startDate=${filters.startDate}&endDate=${filters.endDate}&status=${filters.status}&search=${search}&limit=${limit}`
  if (filters.id && filters.charity_id) {
    url += `&donorId=${filters.id}&charityId=${filters.charity_id}`
  } else {
    if (filters.id) {
      url += `&donorId=${filters.id}`
    }
    if (filters.charity_id) {
      url += `&charityId=${filters.charity_id}`
    }
  }
  return url
}
const USER_CHARITY_ROUNDUP_LIST = (role, page, limit, id, roundUpModuleFor) => {
  let url = `${API_URL}/admin/roundUpDetails?role=${role}&page=${page}&limit=${limit}&charityId=${id}&roundUpModuleFor=${roundUpModuleFor}`
  return url
}
const USER_DONOR_ROUNDUP_LIST = (role, page, limit, id, roundUpModuleFor) => {
  let url = `${API_URL}/admin/roundUpDetails?role=${role}&page=${page}&limit=${limit}&donorId=${id}&roundUpModuleFor=${roundUpModuleFor}`
  return url
}

const ROUNDUP_STATUS = `${API_URL}/plaid/deactivate`

const ROUNDUP_CHARITY_DELETE = `${API_URL}/plaid/edit`

const REPORTS_URL = `${API_URL}/admin/reports`

export enum ROLE_TYPE {
  CHARITY = 'charity',
  DONOR = 'donor',
  CHARITY_PROFILE = 'charityProfile',
}

export enum POST_EVENT_TYPE {
  POST = 'post',
  EVENT = 'event',
  CHARITY_ROUNDUP = 'charity-roundup',
  DONOR_ROUNDUP = 'donor-roundup',
}

export enum ACCOUNT_TYPE {
  CARD = 'card',
  BANK = 'bank_account',
}

export function login(email: string, password: string) {
  // console.log('LOGIN_URL ====', LOGIN_URL);
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

export function userDetails(token, role: string) {
  return axios.get(PROFILE_URL(role), {
    headers: {
      Authorization: token,
    },
  })
}

export function resetPassword(email: string) {
  return axios.post<AuthModel>(RESET_PASSWORD_URL, {
    email,
  })
}

export function verifyOtp(otp: number, email: string) {
  return axios.post<AuthModel>(VERIFY_OTP, {
    otp,
    email,
  })
}
export function charityVerifyOtp(otp: number, email: string) {
  return axios.post<AuthModel>(CHARITY_VERIFY_OTP, {
    otp,
    email,
  })
}
export function newPassword(password: string, email: string) {
  return axios.post<AuthModel>(CREATE_NEW_PASSWORD, {
    newPassword: password,
    email,
  })
}

export function getCategories(token) {
  return axios.get(CATEGORIES_URL, {
    headers: {
      Authorization: token,
    },
  })
}

export function getCategoriesforSignup() {
  return axios.get(CATEGORIES_URL)
}

export function userListing(token, role, page = 1, search, filters = { status: '' }, limit) {
  return axios.get(USER_MANAGEMENT_URL(role, page, search, filters, limit), {
    headers: {
      Authorization: token,
    },
  })
}

export function userProfile(token, role, id) {
  return axios.get(USER_PROFILE_URL(role, id), {
    headers: {
      Authorization: token,
    },
  })
}

export function charityUserProfile(token, role) {
  return axios.get(CHARITY_USER_PROFILE_URL(role), {
    headers: {
      Authorization: token,
    },
  })
}

export function adminUserProfile(token, role) {
  return axios.get(ADMIN_USER_PROFILE_URL(role), {
    headers: {
      Authorization: token,
    },
  })
}

export function deletePostOrEvent(token, type, id) {
  return axios.delete(DELETE_POST_EVENT_URL(id, type), {
    headers: {
      Authorization: token,
    },
  })
}

export function contactUs(
  token,
  page,
  search,
  filters = { startDate: '', endDate: '', status: '' },
  limit
) {
  return axios.get(CONTACT_US_URL(page, search, filters, limit), {
    headers: {
      Authorization: token,
    },
  })
}

export function contactStatusUpdate(token: string | undefined, id: string) {
  return axios.post(
    CONTACT_US_URL_STATUS_UPDATE,
    { id, status: 'viewed' },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function charityPostEvent(
  token,
  type,
  id,
  page = 1,
  filters = {
    startDate: '',
    endDate: '',
    status: '',
    startRangeStartDate: '',
    endRangeStartDate: '',
    startRangeEndDate: '',
    endRangeEndDate: '',
    hidden: 'false',
  },
  search = '',
  limit
) {
  return axios.get(CHARITY_POST_EVENT_URL(type, id, page, filters, search, limit), {
    headers: {
      Authorization: token,
    },
  })
}

export function charityProfilesPostEvent(token, type, id, limit) {
  return axios.get(CHARITY_PROFILES_POST_EVENT_URL(type, id, limit), {
    headers: {
      Authorization: token,
    },
  })
}

export function charityProfilePostEvent(token, type, id, limit) {
  return axios.get(CHARITY_PROFILE_POST_EVENT_URL(type, id, limit), {
    headers: {
      Authorization: token,
    },
  })
}

export function charityUserPostEvent(
  token,
  type,
  page = 1,
  filters = {
    startDate: '',
    endDate: '',
    status: '',
    startRangeStartDate: '',
    endRangeStartDate: '',
    startRangeEndDate: '',
    endRangeEndDate: '',
    hidden: '',
  },
  search = '',
  limit
) {
  return axios.get(CHARITY_USER_POST_EVENT_URL(type, page, filters, search, limit), {
    headers: {
      Authorization: token,
    },
  })
}

export function charityProfilesUserPostEvent(token, type, limit) {
  return axios.get(CHARITY_USER_PROFILE_POST_EVENT_URL(type, limit), {
    headers: {
      Authorization: token,
    },
  })
}

export function charityProfileUserPostEvent(token, type, limit) {
  return axios.get(CHARITY_SECTION_USER_POST_EVENT_URL(type, limit), {
    headers: {
      Authorization: token,
    },
  })
}

export function charityPostComment(id, token, type, page = 1) {
  return axios.get(CHARITY_POST_EVENT_COMMENT(id, type, page), {
    headers: {
      Authorization: token,
    },
  })
}

export function charityUserPostComment(token, type, page = 1) {
  return axios.get(CHARITY_USER_POST_EVENT_COMMENT(type, page), {
    headers: {
      Authorization: token,
    },
  })
}

export function userAccountDetails(token: string, type: ACCOUNT_TYPE, role: ROLE_TYPE, id: string) {
  return axios.get(USER_ACCOUNT_DETAILS_URL(type, id, role), {
    headers: {
      Authorization: token,
    },
  })
}

export function charityUserAccountDetails(token: string, type: ACCOUNT_TYPE, role: ROLE_TYPE) {
  return axios.get(CHARITY_USER_ACCOUNT_DETAILS_URL(type, role), {
    headers: {
      Authorization: token,
    },
  })
}

export function userTransactionDetails(token: string, role: ROLE_TYPE, id: string, limit) {
  return axios.get(USER_TRANSACTION_DETAILS_URL(id, role, limit), {
    headers: {
      Authorization: token,
    },
  })
}

export function userDonorTransactionDetails(token: string, role: ROLE_TYPE, id: string, limit) {
  return axios.get(USER_DONOR_TRANSACTION_DETAILS_URL(id, role, limit), {
    headers: {
      Authorization: token,
    },
  })
}

export function charityUserTransactionDetails(token: string, role: ROLE_TYPE, limit) {
  return axios.get(CHARITY_USER_TRANSACTION_DETAILS_URL(role, limit), {
    headers: {
      Authorization: token,
    },
  })
}
export function donorSubscriptionDetails(
  token: string,
  id: string,
  page = 1,
  search = '',
  filters = { planType: '', status: '', amount: '' },
  limit
) {
  return axios.get(DONOR_SUBSCRIPTION_DETAILS_URL(id, page, search, filters, limit), {
    headers: {
      Authorization: token,
    },
  })
}

export function userDonorSubscriptionDetails(token: string, id: string, limit) {
  return axios.get(USER_DONOR_SUBSCRIPTION_DETAILS_URL(id, limit), {
    headers: {
      Authorization: token,
    },
  })
}

export function viewDonorSubscriptionDetails(
  token: string,
  id: string,
  page = 1,
  search = '',
  filters = { planType: '', status: '', amount: '' },
  limit
) {
  return axios.get(VIEW_DONOR_SUBSCRIPTION_DETAILS_URL(id, page, search, filters, limit), {
    headers: {
      Authorization: token,
    },
  })
}

export function viewDonorSubscription(
  token: string,
  id: string,
  page = 1,
  search = '',
  filters = { planType: '', status: '', amount: '' },
  limit
) {
  return axios.get(VIEW_DONOR_SUBSCRIPTION(id, page, search, filters, limit), {
    headers: {
      Authorization: token,
    },
  })
}

export function donorSubscriptionPageDetails(
  token: string,
  id: string,
  page = 1,
  search = '',
  filters = { planType: '', status: '', amount: '' }
) {
  return axios.get(DONOR_SUBSCRIPTION_PAGE_DETAILS_URL(id, page, search, filters), {
    headers: {
      Authorization: token,
    },
  })
}

export function charityDonorSubscriptionDetails(
  token: string,
  page = 1,
  search = '',
  filters = { planType: '', status: '', amount: '' },
  limit
) {
  return axios.get(CHARITY_DONOR_SUBSCRIPTION_DETAILS_URL(page, search, filters, limit), {
    headers: {
      Authorization: token,
    },
  })
}

export function dashboard(token: string) {
  return axios.get(DASHBOARD_URL, {
    headers: {
      Authorization: token,
    },
  })
}

export function getDonorsCharityList(token: string, role: ROLE_TYPE, userListFor: string) {
  return axios.get(USER_LIST_URL(role, userListFor), {
    headers: {
      Authorization: token,
    },
  })
}

export function getDonorsCharityListForCharity(
  token: string,
  role: ROLE_TYPE,
  userListFor: string,
  charityId: any
) {
  return axios.get(USER_LIST_URL_CHARITY(role, userListFor, charityId), {
    headers: {
      Authorization: token,
    },
  })
}

export function getDonorsCharityLists(
  token: string,
  role: ROLE_TYPE,
  userListFor: string,
  donorId: any
) {
  return axios.get(DONOR_USER_LIST_URLS(role, userListFor, donorId), {
    headers: {
      Authorization: token,
    },
  })
}

export function donationsListing(token, role, page, search, filters, limit) {
  return axios.get(DONATIONS_LISTING_URL(role, page, search, filters, limit), {
    headers: {
      Authorization: token,
    },
  })
}
export function viewDonationsListing(token, role, page, search, filters, limit) {
  return axios.get(VIEW_DONATIONS_LISTING_URL(role, page, search, filters, limit), {
    headers: {
      Authorization: token,
    },
  })
}

export function viewCharityDonationsListing(token, role, page, search, filters, limit, charityId) {
  return axios.get(
    VIEW_CHARITY_DONATION_LISTING_URL(role, page, search, filters, limit, charityId),
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function viewDonorDonationsListing(token, role, page, search, filters, limit, donorId) {
  return axios.get(VIEW_DONOR_DONATION_LISTING_URL(role, page, search, filters, limit, donorId), {
    headers: {
      Authorization: token,
    },
  })
}

export function donorViewDonationsListing(token, role, page, search, filters, limit) {
  return axios.get(DONOR_VIEW_DONATIONS_LISTING_URL(role, page, search, filters, limit), {
    headers: {
      Authorization: token,
    },
  })
}

export function charityDonationsListing(token, role, page, search, filters, limit) {
  return axios.get(CHARITY_DONATIONS_LISTING_URL(role, page, search, filters, limit), {
    headers: {
      Authorization: token,
    },
  })
}
export function checkEmailAvailable(token, email) {
  return axios.get(CHECK_EMAIL_AVAILABLE(email), {
    headers: {
      Authorization: token,
    },
  })
}
export function updatePassword(currentPassword, newPassword, token) {
  return axios.post(UPDATE_PASSWORD, {
    currentPassword,
    newPassword,
    token,
  })
}

export function updatePostAndEventStatus(
  token: string | undefined,
  data: {
    id: string
    status: boolean
    type: POST_EVENT_TYPE
  }
) {
  return axios.post(
    UPDATE_POST_EVENT_URL,
    { ...data },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function updateSubscriptionStatus(
  token: string | undefined,
  data: {
    subId: string
    status: string
    donorId: string
  }
) {
  return axios.post(
    UPDATE_SUBSCRIPTION_URL,
    { ...data },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function updateUserDetails(
  token: string | undefined,
  id: string,
  role: string,
  userDetails
) {
  return axios.post(UPDATE_USER_URL(id, role), userDetails, {
    headers: {
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function updateCharityUserDetails(token: string | undefined, role: string, userDetails) {
  return axios.post(UPDATE_CHARITY_USER_URL(role), userDetails, {
    headers: {
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function updateAdminUserDetails(token: string | undefined, role: string, adminDetails) {
  return axios.post(UPDATE_ADMIN_USER_URL(role), adminDetails, {
    headers: {
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function changeCardStatus(token: string | undefined, data: any) {
  return axios.post(
    CHANGE_CARD_STATUS_URL,
    {
      customerId: data.customerId,
      cardId: data.cardId,
      status: data.status,
      donorId: data.donorId,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function changeUserStatus(
  token: string | undefined,
  data: { id: string; status: string; role: ROLE_TYPE }
) {
  return axios.post(
    USER_STATUS_CHANGE_URL,
    {
      id: data.id,
      status: data.status,
      role: data.role,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function setDefaultAccount(
  token: string | undefined,
  id: string,
  role: string,
  accountId: string
) {
  return axios.post(
    SET_DEFAULT_ACCOUNT_URL,
    {
      id: id,
      role: role,
      bankId: accountId,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function charitySetDefaultAccount(
  token: string | undefined,
  role: string,
  accountId: string
) {
  return axios.post(
    CHARITY_SET_DEFAULT_ACCOUNT_URL,
    {
      role: role,
      bankId: accountId,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function deleteBankAccount(userId, bankId, role) {
  return axios.delete(DELETE_BANK_ACCOUNT_URL(userId, bankId, role))
}

export function charityDeleteBankAccount(bankId, role) {
  return axios.delete(CHARITY_DELETE_BANK_ACCOUNT_URL(bankId, role))
}

export function addBankAccount(token) {
  return axios.post(CHARITY_ADD_BANK_ACCOUNT, {
    headers: {
      Authorization: token,
    },
  })
}

export function addStripeBankAccount(bankAccountInfo, bankToken) {
  const { account_holder_name, account_number, routing_number, account_holder_type } = bankAccountInfo

  return axios.post(ADD_STRIPE_BANK_ACCOUNT, {
    account_holder_name,
    account_number,
    routing_number,
    account_holder_type,
    bankToken,
  })
}

export function addStripeBank(token, bankAccountInfo) {
  const axiosWithoutInterceptors = axios.create()

  const { account_holder_name, account_number, routing_number, account_holder_type } = bankAccountInfo
  return axiosWithoutInterceptors.post(
    ADD_STRIPE_BANKS,
    {
      'bank_account[country]': 'US',
      'bank_account[currency]': 'usd',
      'bank_account[account_holder_name]': account_holder_name,
      'bank_account[account_holder_type]': account_holder_type,
      'bank_account[routing_number]': routing_number,
      'bank_account[account_number]': account_number,
    },
    {
      headers: {
        Authorization: token,
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  )
}

export function addCharityPost(formData) {
  return axios.post(ADD_CHARITY_POST, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function addEventCharity(formData) {
  return axios.post(ADD_CHARITY_EVENT, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function editCharityPost(formData, id) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }

  return axios.put(EDIT_CHARITY_POST(id), formData, config)
}

export function editCharityEvent(formData, id) {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  return axios.put(EDIT_CHARITY_EVENT(id), formData, config)
}

export function registerCharity(charityData) {
  const { name, email, password, role, charityLocation } = charityData

  return axios.post(CHARITY_REGISTER, {
    name,
    email,
    password,
    role,
    // category,
    // phone,
    charityLocation,
  })
}

export function deletePostEventComment(token, id, commentCategory) {
  return axios.delete(DELETE_POST_EVENT_COMMENT(id, commentCategory), {
    headers: {
      Authorization: token,
    },
  })
}

export function deleteMultiplePostEvent(token, postEventIds, type) {
  return axios.post(
    MULTIPLE_POST_EVENT_DELTE,
    {
      postEventIds,
      type,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function deleteUser(token, userIds, role) {
  return axios.post(
    DELETE_USER,
    {
      userIds,
      role,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function deleteCharityUser(token, role) {
  return axios.post(
    DELETE_USER,
    {
      role,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function charityContactUs(token, name, email, phone, message) {
  return axios.post(
    CONTACT_US,
    {
      name,
      email,
      phone,
      message,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function updateAdminApprovalStatus(token, charityId, approvalStatus, adminComment) {
  return axios.post(
    UPDATE_ACCOUNT_APPROVAL,
    {
      charityId,
      approvalStatus,
      adminComment,
    },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function updateCharityProfile(role: string, token: string | undefined, formData) {
  return axios.put(UPDATE_CHARITY_PROFILE(role), formData, {
    headers: {
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function roundUpList(token, role, page, search, filters, limit, roundUpModuleFor) {
  return axios.get(ROUNDUP_LIST(role, page, search, filters, limit, roundUpModuleFor), {
    headers: {
      Authorization: token,
    },
  })
}

export function charityRoundUpList(
  token,
  role,
  page,
  search,
  filters,
  limit,
  roundUpModuleFor,
  charityId
) {
  return axios.get(
    CHARITY_ROUNDUP_LIST(role, page, search, filters, limit, roundUpModuleFor, charityId),
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function donorRoundUpList(
  token,
  role,
  page,
  search,
  filters,
  limit,
  roundUpModuleFor,
  donorId
) {
  return axios.get(
    DONOR_ROUNDUP_LIST(role, page, search, filters, limit, roundUpModuleFor, donorId),
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function roundUpLists(token, role, page, search, filters, limit) {
  return axios.get(ROUNDUP_LISTS(role, page, search, filters, limit), {
    headers: {
      Authorization: token,
    },
  })
}

export function userCharityRoundUpList(token, role, page, limit, id, roundUpModuleFor) {
  return axios.get(USER_CHARITY_ROUNDUP_LIST(role, page, limit, id, roundUpModuleFor), {
    headers: {
      Authorization: token,
    },
  })
}
export function userDonorRoundUpList(token, role, page, limit, id, roundUpModuleFor) {
  return axios.get(USER_DONOR_ROUNDUP_LIST(role, page, limit, id, roundUpModuleFor), {
    headers: {
      Authorization: token,
    },
  })
}

export function fetchCharityCategory(token, search, page, limit) {
  return axios.get(FETCH_CHARITY_CATEGORY(search, page, limit), {
    headers: {
      Authorization: token,
    },
  })
}

export function deleteCharityCategory(token, charityId) {
  return axios.delete(DELETE_CHARITY_CATEGORY(charityId), {
    headers: {
      Authorization: token,
    },
  })
}

export function addCharityCategory(token, charityCategory) {
  return axios.post(ADD_CHARITY_CATEGORY, charityCategory, {
    headers: {
      Authorization: token,
    },
  })
}

export function roundUpStatus(plaidId: string, token) {
  return axios.post(
    ROUNDUP_STATUS,
    { plaidId },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}
export function roundUpCharityStatus(roundUpId: string, token) {
  return axios.post(
    ROUNDUP_STATUS,
    { roundUpId },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function roundupCharityDelete(
  token,
  updateCharity,
  deleteCharity,
  updatePayment,
  last4,
  plaidId
) {
  const requestData = {
    updateCharity: updateCharity,
    deleteCharity: deleteCharity,
    updatePayment: updatePayment,
    last4: last4,
    plaidId: plaidId,
  }
  return axios.post(ROUNDUP_CHARITY_DELETE, requestData, {
    headers: {
      Authorization: token,
    },
  })
}

export function getReports(token) {
  return axios.get(REPORTS_URL, {
    headers: {
      Authorization: token,
    },
  })
}

export function updateReportStatus(
  token: string | undefined,
  data: {
    resolved: boolean
    reportId: string
  }
) {
  return axios.patch(
    SET_REPORT_STATUS_URL,
    { ...data },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function setPostHidden(
  token: string | undefined,
  data: {
    hidden: boolean
    postId: string
  }
) {
  return axios.patch(
    SET_POST_HIDDEN_URL,
    { ...data },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}

export function setEventHidden(
  token: string | undefined,
  data: {
    hidden: boolean
    eventId: string
  }
) {
  return axios.patch(
    SET_EVENT_HIDDEN_URL,
    { ...data },
    {
      headers: {
        Authorization: token,
      },
    }
  )
}
import React from 'react'
import {Slide} from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'

interface Media {
  thumbnailURL?: string
  url: string
}

const containerStyle: React.CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '400px',
  overflow: 'hidden',
  position: 'relative',
  alignSelf: 'stretch',
}

const imageStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
  objectFit: 'fill',
  margin: '0 auto',
}

interface SliderProps {
  allMedia: Media[]
}
const Slider: React.FC<SliderProps> = ({allMedia}) => {
  return (
    <Slide>
      {allMedia.length > 0 &&
        allMedia.map((media, i) => (
          <div key={i} className='min-h-250px mb-5' style={containerStyle}>
            <img
              src={media.thumbnailURL ? media.thumbnailURL : media.url}
              alt={`slide-${i}`}
              style={imageStyle}
            />
          </div>
        ))}
    </Slide>
  )
}

export default Slider

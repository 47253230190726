import {useCallback, useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {DataTableWrapper} from '../../../components/DataTable'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useNavigate} from 'react-router-dom'
import {SelectOption} from '../../../components/Common'
import ActionButton from '../../../components/Common/ActionButton'
import {useAuth} from '../../modules/auth'
import {
  ROLE_TYPE,
  changeUserStatus,
  deleteUser,
  userListing,
} from '../../modules/auth/core/_requests'
import {setDonor} from '../../modules/auth/core/StorageHelper'
import {CharitiesDonors} from '../Charity'
import {toast} from 'react-toastify'
import {CustomModal} from '../../../components/Modal'
import {useSessionState} from '../../../hooks/useSessionState'
import {debounce} from '../../../utils'

export interface InitialState {
  loading: boolean
  charities: CharitiesDonors[]
  donors: CharitiesDonors[]
  currentPage: number
  totalPages: number
  error: null | any
  search: string
  changeStatusId?: null | string
  filters: {status: string}
  multipleDeleteModelView: boolean
  deleteLoading: boolean
  totalActiveDonors: number
  totalInActiveDonors: number
  totalDeletedDonors: number
}

const initialState = {
  loading: true,
  donors: [],
  charities: [],
  currentPage: 1,
  totalPages: 1,
  error: null,
  search: '',
  changeStatusId: null,
  multipleDeleteModelView: false,
  deleteLoading: false,
  filters: {
    status: '',
  },
  totalActiveDonors: 0,
  totalInActiveDonors: 0,
  totalDeletedDonors: 0,
}

const Donor = () => {
  const navigate = useNavigate()
  const {auth, currentUser} = useAuth()
  const [state, setState] = useSessionState<InitialState>(initialState, 'charity')
  const [pageSize, setPageSize] = useState(10)

  //delete the multiple charity
  const [selectedRow, setSelectedRows] = useState([])
  const [deleteValue, setDeleteValue] = useState(false)
  const handleSelectedRowsChange = (selectedRows) => {
    setSelectedRows(selectedRows.map((row) => row._id))
  }
  useEffect(() => {
    setDeleteValue(false)
  }, [deleteValue])
  const totalPage: any = localStorage.getItem('totalPage')
  const currentPage: any = localStorage.getItem('currentPage')
  const getDonors = useCallback(
    async (token, role, page, search, filters, pageSize) => {
      try {
        setState({...state, loading: true})
        const result = await userListing(token, role, page, search, filters, pageSize)
        if (result.status === 200) {
          setState({
            ...state,
            loading: false,
            donors: result.data.data.userList,
            currentPage: result.data.data.currentPage,
            totalPages: result.data.data.totalPages,
            totalActiveDonors: result.data.data.totalActiveUsers,
            totalInActiveDonors: result.data.data.totalInActiveUsers,
            totalDeletedDonors: result.data.data.totalDeletedUsers,
          })
          localStorage.setItem('totalPage', result?.data?.data?.totalPages)
          localStorage.setItem('currentPage', result?.data?.data?.currentPage)
        } else {
          toast(result.data.message)
        }
      } catch (error: any) {
        setState({...state, error: error, loading: false})
        toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
      }
    },
    [state, pageSize]
  )

  const columns = [
    {
      name: 'Name',
      selector: (row: CharitiesDonors, i) => row.name,
      width: '260px',
      format: (row: CharitiesDonors, i) => {
        return (
          <div className='d-flex align-items-center m-2 min-w-350px'>
            <div
              className='symbol symbol-50px me-5'
              onClick={() => {
                setDonor(row._id)
                navigate(row.name)
              }}
            >
              <img
                src={row.profilePic ? row.profilePic : toAbsoluteUrl('/media/avatars/blank.png')}
                className='h-[75px] w-[75px] symbol-label align-self-end'
                alt=''
              />
            </div>
            <div className='d-flex justify-content-start flex-column'>
              <span
                onClick={() => {
                  setDonor(row._id)
                  navigate(row.name)
                }}
                className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                data-toggle='tooltip'
                data-placement='top'
                title={row.name}
              >
                {row.name}
              </span>
            </div>
          </div>
        )
      },
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row: CharitiesDonors, i) => row.email,
      width: '260px',
      format: (row: CharitiesDonors, i) => {
        return (
          <div
            onClick={() => {
              setDonor(row._id)
              navigate(row.name)
            }}
            className='text-dark fw-bold text-hover-primary mb-1 fs-6'
          >
            {row.email}
          </div>
        )
      },

      sortable: true,
    },
    {
      name: 'Status',
      selector: (row: CharitiesDonors, i) => row.status,
      minWidth: '130px',
      format: (row: CharitiesDonors, i) => {
        return row.isDeleted ? (
          <>
            <span className='badge capitalize bg-gray-100 px-6 py-3  text-danger'>{'Deleted'}</span>
          </>
        ) : state.changeStatusId === row._id ? (
          <span className='badge text-black p-4 badge-light'>Loading...</span>
        ) : (
          <SelectOption
            options={[
              {value: 'Active', title: 'Active'},
              {value: 'InActive', title: 'Inactive'},
            ]}
            selected={row.status}
            onChange={async (e) => {
              setState({...state, changeStatusId: row._id})
              const value = e.target.value
              try {
                const result = await changeUserStatus(auth?.token, {
                  id: row._id,
                  status: value,
                  role: ROLE_TYPE.DONOR,
                })
                if (result.status === 200) {
                  setState({
                    ...state,
                    donors: [
                      ...state.donors!.slice(0, i),
                      Object.assign({}, state.donors![i], {
                        status: value,
                      }),
                      ...state.donors!.slice(i + 1),
                    ],
                    changeStatusId: null,
                  })
                  window.location.reload()
                } else {
                  toast(result.data.message)
                  setState({...state, changeStatusId: null})
                }
              } catch (error: any) {
                setState({...state, changeStatusId: null})
                toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
              }
            }}
          />
        )
      },
      sortable: false,
    },
    {
      name: 'Action',
      minWidth: '125px',
      selector: (row: CharitiesDonors, i) => row.status,
      format: (row: CharitiesDonors, i) => {
        return (
          <>
            <ActionButton
              viewCallback={() => {
                setDonor(row._id)
                navigate(row.name)
              }}
              editCallback={() => {
                setDonor(row._id)
                navigate(`${row.name}?edit=true`)
              }}
              editDisabled={row.isDeleted === true}
            />
          </>
        )
      },
      sortable: false,
    },
  ]

  useEffect(() => {
    if (auth && auth.token) {
      getDonors(
        auth?.token,
        ROLE_TYPE.DONOR,
        totalPage === currentPage ? 1 : state.currentPage,
        state.search,
        state.filters,
        pageSize
      )
    }
  }, [state.currentPage, state.search, state.filters, deleteValue, pageSize])

  const debouncedOnChange = debounce(
    (value) =>
      setState((prev) => ({
        ...prev,
        search: value,
      })),
    1000
  )

  return (
    <>
      <DataTableWrapper
        loading={state.loading}
        filters={{
          dropdown: [
            {
              label: 'Status',
              options: ['Active', 'InActive', 'Deleted'].map((t) => ({
                value: t,
                label: t.toLowerCase(),
              })),
            },
          ],
        }}
        filtersCallback={(filters) => {
          if (filters?.reset) {
            setState((prev) => ({
              ...prev,
              filters: initialState.filters,
              currentPage: initialState.currentPage,
              search: initialState.search,
            }))
          } else {
            setState((prev) => ({
              ...prev,
              currentPage: initialState.currentPage,
              filters: {
                status: filters['Status']?.value || '',
              },
            }))
          }
        }}
        columns={columns}
        data={state.donors}
        currentPage={state.currentPage}
        totalPages={state.totalPages}
        handlePageChange={(page) => setState({...state, currentPage: page})}
        handleSearch={debouncedOnChange}
        onSelectedRowsChange={handleSelectedRowsChange}
        onDelete={() => setState({...state, multipleDeleteModelView: true})}
        selectableRows
        totalActiveDonor={state.totalActiveDonors}
        totalInActiveDonor={state.totalInActiveDonors}
        totalDeletedDonor={state.totalDeletedDonors}
        onPageSizeChange={(newValue: number) => setPageSize(newValue)}
        pageSize={pageSize}
      />

      {state.multipleDeleteModelView && (
        <CustomModal
          type={'DELETE'}
          body={selectedRow}
          deleteLoading={state.deleteLoading}
          title={true}
          handleConfirm={async () => {
            try {
              setState({...state, deleteLoading: true})
              const result = await deleteUser(currentUser?.token, selectedRow, 'donor')
              if (result.status === 200) {
                setDeleteValue(true)
                toast.success(result.data.message || 'Account Deleted Succesfully')
                setState({
                  ...state,
                  multipleDeleteModelView: false,
                  deleteLoading: false,
                })
                setSelectedRows([])
              }
            } catch (error: any) {
              setState({
                ...state,
                multipleDeleteModelView: false,
                deleteLoading: false,
              })
              toast(error?.response?.data?.message || 'Something went wrong!')
            }
            setSelectedRows([])
          }}
          handleClose={() => {
            setState({...state, multipleDeleteModelView: false})
            setSelectedRows([])
          }}
        />
      )}
    </>
  )
}

const DonorWrapper = () => (
  <>
    <PageTitle>Donor Management</PageTitle>
    <Donor />
  </>
)

export {DonorWrapper}

import {useCallback, useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {DataTableWrapper} from '../../../components/DataTable'
import ActionButton from '../../../components/Common/ActionButton'
import {CustomModal, MODAL_TYPE, MODAL_TYPE_ENUM} from '../../../components/Modal'
import {deleteCharityCategory, fetchCharityCategory} from '../../modules/auth/core/_requests'
import {useAuth} from '../../modules/auth'
import {ToastContainer, toast} from 'react-toastify'
import {debounce} from '../../../utils'

export interface TInitialState {
  loading: boolean
  categories: any[]
  error: any
  modalView: boolean
  selectedRow: any | null
  type: MODAL_TYPE_ENUM | MODAL_TYPE | any
  search: string
  currentPage: number
  totalPages: number
  deleteLoading: boolean
}

const initialState = {
  loading: true,
  categories: [],
  error: null,
  modalView: false,
  selectedRow: null,
  currentPage: 1,
  totalPages: 1,
  type: null,
  search: '',
  deleteLoading: false,
}

const CharityCategory = () => {
  const {auth} = useAuth()

  const [state, setState] = useState<TInitialState>(initialState)
  const [title, setTitle] = useState('')
  const [modelView, setModelView] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const totalPage: any = localStorage.getItem('totalPage')
  const currentPage: any = localStorage.getItem('currentPage')

  const FetchCharityCategory = useCallback(
    async (token, search, page, limit) => {
      try {
        setState({...state, loading: true})
        let result
        result = await fetchCharityCategory(token, search, page, limit)

        if (result.status === 200) {
          setState((prev) => ({
            ...prev,
            loading: false,
            categories: result?.data?.data?.charityCategoryList || [],
            currentPage: result?.data?.data?.currentPage,
            totalPages: result?.data?.data?.totalPages,
          }))
          localStorage.setItem('totalPage', result?.data?.data?.currentPage)
          localStorage.setItem('currentPage', result?.data?.data?.totalPages)
        } else {
          toast(result.data.message)
        }
      } catch (error: any) {
        setState({...state, error: error, loading: false})
        if (error.response.status === 600) {
          setTitle(error?.response?.data?.message)
          setModelView(true)
        } else {
          toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
        }
      }
    },
    [state, pageSize]
  )

  useEffect(() => {
    if (auth && auth.token) {
      FetchCharityCategory(
        auth.token,
        state.search,
        totalPage === currentPage ? 1 : state.currentPage,
        pageSize
      )
    }
  }, [state.search, pageSize, state.currentPage])

  const columns = [
    {
      name: 'Category Name',
      selector: (row, i) => row.name,
      width: '500px',
      format: (row, i) => {
        return (
          <div className='d-flex align-items-center m-2'>
            <div className='d-flex justify-content-start flex-column'>
              <p
                className='text-dark fw-bold  mb-1 fs-6'
                data-toggle='tooltip'
                data-placement='top'
                title={row.name}
              >
                {row.name}
              </p>
            </div>
          </div>
        )
      },
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row, i) => row.status,
      minWidth: '200px',
      format: (row, i) => {
        return (
          <ActionButton
            deleteCallback={() =>
              setState({
                ...state,
                modalView: true,
                selectedRow: {title: row.name, id: row._id},
                type: MODAL_TYPE_ENUM.DELETE,
              })
            }
          />
        )
      },
      sortable: false,
    },
  ]

  const debouncedOnChange = debounce(
    (value) =>
      setState((prev) => ({
        ...prev,
        search: value,
      })),
    1000
  )

  return (
    <>
      <DataTableWrapper
        loading={state.loading}
        columns={columns}
        data={state.categories}
        handleSearch={debouncedOnChange}
        onPageSizeChange={(newValue: number) => setPageSize(newValue)}
        handlePageChange={(page) => setState({...state, currentPage: page})}
        pageSize={pageSize}
        currentPage={state.currentPage}
        totalPages={state.totalPages}
      />

      {state.modalView && (
        <CustomModal
          type={state.type}
          body={state.selectedRow}
          deleteLoading={state.deleteLoading}
          handleConfirm={async () => {
            try {
              setState({...state, deleteLoading: true})
              const result = await deleteCharityCategory(auth?.token, state.selectedRow.id)
              if (result.status === 201) {
                setState({
                  ...state,
                  modalView: false,
                  categories: state.categories.filter(
                    (category) => category._id !== state.selectedRow.id
                  ),
                  selectedRow: null,
                  type: null,
                  deleteLoading: false,
                })
                toast.success(result?.data?.message)
              }
            } catch (error: any) {
              setState({
                ...state,
                modalView: false,
                selectedRow: null,
                type: null,
                deleteLoading: false,
              })
              toast(error?.response?.data?.message || error?.message || 'Something went wrong!')
            }
          }}
          handleClose={() => setState({...state, modalView: false, selectedRow: null, type: null})}
        />
      )}
    </>
  )
}

const CharityCategoryWrapper = () => (
  <>
    <PageTitle>Charity Category</PageTitle>
    <CharityCategory />
    <ToastContainer />
  </>
)

export {CharityCategoryWrapper}

const CHARITY_LOCAL_STORAGE_KEY = process.env.CHARITY_LOCAL_STORAGE_KEY || 'charity-store'
const DONOR_LOCAL_STORAGE_KEY = process.env.DONOR_LOCAL_STORAGE_KEY || 'donor-store'
const DONATIONS_LOCAL_STORAGE_KEY = process.env.DONATIONS_LOCAL_STORAGE_KEY || 'donations-store'
const EVENT_POST_LOCAL_STORAGE_KEY = process.env.EVENT_POST_LOCAL_STORAGE_KEY || 'event-post-store'
const CHARITY_DONOR_LOCAL_STORAGE_KEY =
  process.env.EVENT_POST_LOCAL_STORAGE_KEY || 'charity-donor-store'
const DONATIONS_VIEW_LOCAL_STORAGE_KEY =
  process.env.DONATIONS_LOCAL_STORAGE_KEY || 'donations-view-store'
const NAME_LOCAL_STORAGE_KEY = process.env.NAME_LOCAL_STORAGE_KEY || 'username'

const getCharity = () => {
  if (!localStorage) {
    return
  }
  const lsValue: string | null = localStorage.getItem(CHARITY_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }
  try {
    const charity = JSON.parse(lsValue)
    if (charity) {
      return charity
    }
  } catch (error) {
    console.error('LOCAL STORAGE PARSE ERROR', error)
  }
}
const setCharity = (charity) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(charity)
    localStorage.setItem(CHARITY_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('LOCAL STORAGE SAVE ERROR', error)
  }
}

const getDonor = () => {
  if (!localStorage) {
    return
  }
  const lsValue: string | null = localStorage.getItem(DONOR_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }
  try {
    const charity = JSON.parse(lsValue)
    if (charity) {
      return charity
    }
  } catch (error) {
    console.error('LOCAL STORAGE PARSE ERROR', error)
  }
}
const setDonor = (donor) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(donor)
    localStorage.setItem(DONOR_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('LOCAL STORAGE SAVE ERROR', error)
  }
}

const getDonationsStore = () => {
  if (!localStorage) {
    return
  }
  const lsValue: string | null = localStorage.getItem(DONATIONS_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }
  try {
    const donation = JSON.parse(lsValue)
    if (donation) {
      return donation
    }
  } catch (error) {
    console.error('LOCAL STORAGE PARSE ERROR', error)
  }
}
const setDonations = (donation) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(donation)
    localStorage.setItem(DONATIONS_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('LOCAL STORAGE SAVE ERROR', error)
  }
}
const removeDonationsStore = () => {
  localStorage.removeItem(DONATIONS_LOCAL_STORAGE_KEY)
}

const removeDonor = () => {
  localStorage.removeItem(DONOR_LOCAL_STORAGE_KEY)
}

const getViewDonationsStore = () => {
  if (!localStorage) {
    return
  }
  const lsValue: string | null = localStorage.getItem(DONATIONS_VIEW_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }
  try {
    const donation = JSON.parse(lsValue)
    if (donation) {
      return donation
    }
  } catch (error) {
    console.error('LOCAL STORAGE PARSE ERROR', error)
  }
}
const setViewDonations = (donation) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(donation)
    localStorage.setItem(DONATIONS_VIEW_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('LOCAL STORAGE SAVE ERROR', error)
  }
}
const removeViewDonationsStore = () => {
  localStorage.removeItem(DONATIONS_VIEW_LOCAL_STORAGE_KEY)
}

const getEventPostStore = () => {
  if (!localStorage) {
    return
  }
  const lsValue: string | null = localStorage.getItem(EVENT_POST_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }
  try {
    const data = JSON.parse(lsValue)
    if (data) {
      return data
    }
  } catch (error) {
    console.error('LOCAL STORAGE PARSE ERROR', error)
  }
}
const setEventPostStore = (post_event) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(post_event)
    localStorage.setItem(EVENT_POST_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('LOCAL STORAGE SAVE ERROR', error)
  }
}
const removeEventPostStore = () => {
  localStorage.removeItem(EVENT_POST_LOCAL_STORAGE_KEY)
}

const getCharityDonorStore = () => {
  if (!localStorage) {
    return
  }
  const lsValue: string | null = localStorage.getItem(CHARITY_DONOR_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }
  try {
    const data = JSON.parse(lsValue)
    if (data) {
      return data
    }
  } catch (error) {
    console.error('LOCAL STORAGE PARSE ERROR', error)
  }
}

const setCharityDonorStore = (charity_donor_event) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(charity_donor_event)
    localStorage.setItem(CHARITY_DONOR_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeCharityDonorStore = () => {
  localStorage.removeItem(CHARITY_DONOR_LOCAL_STORAGE_KEY)
}

const getName = () => {
  if (!localStorage) {
    return null
  }
  const lsValue = localStorage.getItem(NAME_LOCAL_STORAGE_KEY)
  return lsValue ? lsValue : null
}

const setName = (name) => {
  if (!localStorage) {
    return
  }

  try {
    // const lsValue = JSON.stringify(name)
    localStorage.setItem(NAME_LOCAL_STORAGE_KEY, name)
  } catch (error) {
    console.error('LOCAL STORAGE SET NAME ERROR', error)
  }
}

const removeName = () => {
  localStorage.removeItem(NAME_LOCAL_STORAGE_KEY)
}

export {
  getCharity,
  setCharity,
  setDonor,
  getDonor,
  removeDonor,
  setDonations,
  setViewDonations,
  getViewDonationsStore,
  removeViewDonationsStore,
  getDonationsStore,
  removeDonationsStore,
  getEventPostStore,
  setEventPostStore,
  removeEventPostStore,
  getCharityDonorStore,
  setCharityDonorStore,
  removeCharityDonorStore,
  getName,
  setName,
  removeName,
}

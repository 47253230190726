import React, {useState} from 'react'
import {ToastContainer, toast} from 'react-toastify'
import * as Yup from 'yup'
import {PageTitle} from '../../../_metronic/layout/core'
import {useFormik} from 'formik'
import {charityContactUs} from '../../modules/auth/core/_requests'
import {useAuth} from '../../modules/auth'
import {useNavigate} from 'react-router-dom'

const AdminContactUsSchema = Yup.object().shape({
  name: Yup.string().required('Full Name is required'),
  email: Yup.string().email('Invalid email address').required('Email Address is required'),
  phone: Yup.number()
    .positive()
    .integer()
    .required('Phone is required')
    .typeError('Phone must be a number')
    .test('len', 'Phone must be exactly 10 digits', (value) => String(value).length === 10),
  message: Yup.string().required('Your Message is required'),
})

export default function AdminContactUs() {
  const {currentUser} = useAuth()
  const initialValues = {
    name: '',
    email: '',
    phone: '',
    message: '',
  }

  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: AdminContactUsSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true)
        const result = await charityContactUs(
          currentUser?.token,
          values.name,
          values.email,
          values.phone,
          values.message
        )
        if (result && result.status === 201) {
          toast.success(result.data.message)
          setLoading(false)
          navigate('/auth')
          formik.resetForm()
        }
      } catch (error: any) {
        toast.error(error.response?.data.message || 'something went wrong')
        setLoading(false)
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <div className='bg-white p-4 rounded'>
      <PageTitle>Contact</PageTitle>
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-2'>Contact Us</h1>
      </div>
      {/* Your form component */}
      <form className='form w-100 w-lg-100' onSubmit={formik.handleSubmit} noValidate>
        {/* Full Name */}
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>
          <div>
            <input
              type='text'
              placeholder='Enter your full name'
              autoComplete='off'
              className='form-control form-control-lg form-control mb-3 mb-lg-0'
              {...formik.getFieldProps('name')}
              maxLength={50}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.name}</div>
              </div>
            )}
          </div>
        </div>

        {/* Email Address */}
        <div>
          <label className='col-lg-4 col-form-label  required fw-bold fs-6'>Email Address</label>
          <div>
            <input
              type='email'
              placeholder='Enter your Email'
              autoComplete='off'
              className='form-control form-control-lg form-control mb-3 mb-lg-0'
              {...formik.getFieldProps('email')}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.email}</div>
              </div>
            )}
          </div>
        </div>

        {/* Phone Number */}
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label required fw-bold fs-6'>Phone Number</label>
          <div>
            <input
              type='text'
              placeholder='Enter your phone number'
              autoComplete='off'
              className='form-control form-control-lg form-control mb-3 mb-lg-0'
              maxLength={10}
              pattern='[0-9]{10}'
              {...formik.getFieldProps('phone')}
            />
            {formik.touched.phone && formik.errors.phone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.phone}</div>
              </div>
            )}
          </div>
        </div>

        {/* Your Message */}
        <div className='row mb-6'>
          <label className='col-lg-4 col-form-label required fw-bold fs-6'>Your Message</label>
          <div>
            <textarea
              placeholder='Enter Your Message...'
              autoComplete='off'
              className='form-control form-control-lg form-control mb-3 mb-lg-0'
              {...formik.getFieldProps('message')}
            />
            {formik.touched.message && formik.errors.message && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>{formik.errors.message}</div>
              </div>
            )}
          </div>
        </div>

        {/* Submit Button */}
        <div className='card-footer d-flex justify-content-center py-6 px-9'>
          <button type='submit' className='btn btn-primary' disabled={loading}>
            {loading ? (
              <div className='d-flex align-items-center'>
                <span>Submitting ...</span>
                <span
                  className='spinner-border spinner-border-sm mx-2'
                  role='status'
                  aria-hidden='true'
                ></span>
              </div>
            ) : (
              'Submit'
            )}
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  )
}
